// import { useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import BaseTextField from '../../../Common/Inputs/BaseTextField'
import useStore from '../../../../modules/store'
import { getEquirectangularFolders } from '../../../../modules/projectFolderStore'

const FolderSelect = ({ value, onChange, ...props }) => {
  const equirectangularFolders = useStore(getEquirectangularFolders)

  return (
    <BaseTextField select value={value} onChange={onChange} {...props}>
      {equirectangularFolders.map((folder, idx) => (
        <MenuItem value={folder.folder.id} key={idx}>
          {folder.folder.name ? folder.folder.name : 'Root'}
        </MenuItem>
      ))}
    </BaseTextField>
  )
}

export default FolderSelect
//
