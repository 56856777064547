import { useState, useEffect, useRef, useCallback } from 'react'

import Editor, { useMonaco } from '@monaco-editor/react'

import useStore from '../../../modules/store'
import {
  setModelOkAction,
  setValueAction,
  updatedAction,
  getCurrentModel
} from '../../../modules/textEditorModelsStore'

const TextEditor = () => {
  const [loaded, setLoaded] = useState('')
  const [timerId, setTimerId] = useState(null)
  const [markers, setMarkers] = useState([])
  const [debounced, setDebounced] = useState(false)
  const [inicializatedModel, setInicializatedModel] = useState('')
  const monaco = useMonaco()
  const model = useStore(getCurrentModel)
  const updated = useStore(updatedAction)
  const setModelValue = useStore(setValueAction)
  const setModelValid = useStore(setModelOkAction)

  const editorRef = useRef(null)

  useEffect(() => {
    if (loaded && monaco && inicializatedModel !== model?.id) {
      setInicializatedModel(model?.id)
      if (model) {
        editorRef.current.updateOptions({ readOnly: model.readOnly })
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: model.schemas.length > 0,
          schemas: model.schemas
        })
      }
      updated(model?.id)
    }
  }, [updated, loaded, monaco, model, inicializatedModel])

  useEffect(() => {
    //Ha pasado el tiempo desde el ultimo cambio.
    if (debounced) {
      setModelValue(model?.id, editorRef.current.getValue())
      setModelValid(model?.id, markers.length === 0)
      setDebounced(false)
    }
  }, [debounced, model, markers, setModelValue, setModelValid])

  useEffect(() => {
    //Actualizar contenido del editor con lo que hay en el model
    if (editorRef.current && model && model.update) {
      editorRef.current.setValue(model.value)
      // editorRef.current.getAction('editor.action.formatDocument').run()
      updated(model.id)
    }
  }, [updated, model])

  const handleEditorMount = useCallback((editor, monaco) => {
    console.log('mount')
    editorRef.current = editor
    setLoaded(true)
  }, [])

  const handleEditorChange = useCallback(
    (value, event) => {
      console.log('change')
      setDebounced(false)
      setMarkers([])
      timerId && clearTimeout(timerId)
      setTimerId(
        setTimeout(() => {
          console.log('debounced')
          setDebounced(true)
        }, 1000) //Poner un tiempo mayor que el de validate
      )
    },
    [timerId]
  )

  const handleEditorValidate = useCallback((markers) => {
    console.log('validate')
    setMarkers(markers)
  }, [])

  return model ? (
    <Editor
      height="100vh"
      options={{ fontSize: 18 }}
      path={model.name}
      defaultLanguage={model.language}
      defaultValue={model.value}
      onMount={handleEditorMount}
      onChange={handleEditorChange}
      onValidate={handleEditorValidate}
    />
  ) : null
}
export default TextEditor
//
