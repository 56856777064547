import { useCallback, memo } from 'react'
import { styled } from '@mui/material/styles'

import PropertyTreeNode from '../Node/PropertyTreeNode'

const Input = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0,
  cursor: 'pointer'
}))
const PropertyTreeTwoStatesNode = ({
  id,
  value,
  values,
  onChange,
  ...props
}) => {
  const handleClick = useCallback(() => {
    onChange && onChange(id, !Boolean(value))
  }, [onChange, id, value])

  return (
    <PropertyTreeNode
      id={id}
      {...props}
      input={
        <Input onClick={handleClick}>
          {Boolean(value) ? values[1] : values[0]}
        </Input>
      }
    />
  )
}
export default memo(PropertyTreeTwoStatesNode)
