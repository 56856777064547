import { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import Item from '../../DraggableList/Item'
import useStore from '../../../../modules/store'
import { getStyleMemo } from '../../../../modules/hotspotTypesStore'

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.contrastText}`,
  padding: 1
}))

const StyleItem = ({ id, ...props }) => {
  const gStyleMemo = useMemo(getStyleMemo, [])
  const style = useStore((state) => gStyleMemo(state, id))

  return style ? (
    <Root>
      <Item id={id} primaryText={style.name} {...props} />
    </Root>
  ) : null
}
export default StyleItem
