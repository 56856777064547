import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import ScrollToolbar from '../../Common/ScrollToolbar/ScrollToolbar'
import DraggableList from '../../Common/DraggableList/DraggableList'

import SelectAllButton from '../../Common/ToolbarButtons/SelectAllButton'
import UnselectAllButton from '../../Common/ToolbarButtons/UnselectAllButton'
import RemoveButton from '../../Common/ToolbarButtons/RemoveButton'
import MultipleEditButton from '../../Common/ToolbarButtons/MultipleEditButton'

import CubeItem from './CubeItem'

import { useStore } from '../../../modules/store'
import { cubesSelector } from '../../../modules/sceneDescriptionStore'
import {
  selectedSelector,
  swapByIdAction,
  selectAllAction,
  setSelectedAction,
  removeAction
} from '../../../modules/sceneCubeListStore'
import { setCurrentOptionPanelAction } from '../../../modules/lateralMenuStore'
import { LATERAL_PANELS } from '../../../settings/menuSettings'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const SScrollToolbar = styled(ScrollToolbar)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderBottom: 'none' // `1px solid ${theme.palette.primary.contrastText}`
}))

const CubesList = () => {
  const cubes = useStore(cubesSelector)
  const selectedCubes = useStore(selectedSelector)
  const swapCubesById = useStore(swapByIdAction)
  const selectAllCubes = useStore(selectAllAction)
  const setSelectedCubes = useStore(setSelectedAction)
  const removeCubes = useStore(removeAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)

  const handleSwap = useCallback(
    (fromIdx, toIdx) => {
      swapCubesById(cubes[fromIdx].id, cubes[toIdx].id)
    },
    [swapCubesById, cubes]
  )

  const handleSelectAll = useCallback(
    (e) => {
      selectAllCubes()
    },
    [selectAllCubes]
  )

  const handleUnselectAll = useCallback(
    (e) => {
      setSelectedCubes([])
    },
    [setSelectedCubes]
  )

  const handleRemoveSelected = useCallback(
    (e) => {
      removeCubes(selectedCubes)
    },
    [removeCubes, selectedCubes]
  )

  const handleEditSelected = useCallback(
    (e) => {
      setCurrentOptionPanel(LATERAL_PANELS.CUBE_EDITOR)
    },
    [setCurrentOptionPanel]
  )

  return (
    <Root>
      <SScrollToolbar
        mainDimension={32}
        firstItems={[
          <MultipleEditButton
            disabled={cubes.length === 0 || selectedCubes.length === 0}
            onClick={handleEditSelected}
          />,
          <RemoveButton
            disabled={cubes.length === 0 || selectedCubes.length === 0}
            onClick={handleRemoveSelected}
          />
        ]}
        secondItems={[
          <SelectAllButton
            disabled={
              cubes.length === 0 || selectedCubes.length === cubes.length
            }
            onClick={handleSelectAll}
          />,
          <UnselectAllButton
            disabled={cubes.length === 0 || selectedCubes.length === 0}
            onClick={handleUnselectAll}
          />
        ]}
      />
      <DraggableList onSwap={handleSwap}>
        {cubes.map((cube, index) => (
          <CubeItem key={cube.id} id={cube.id} index={index} />
        ))}
      </DraggableList>
    </Root>
  )
}
export default CubesList
