import { useCallback } from 'react'
import PropertyTreeNode from '../Node/PropertyTreeNode'
import Select from '@mui/material/Select'

const PropertyTreeSelectNode = ({
  id,
  options,
  tooltipDisabled,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange && onChange(id, e.target.value)
    },
    [onChange, id]
  )

  return (
    <PropertyTreeNode
      id={id}
      tooltipDisabled={tooltipDisabled}
      input={
        <Select {...props} onChange={handleChange}>
          {options}
        </Select>
      }
      {...props}
    />
  )
}
export default PropertyTreeSelectNode
