import { useMemo, memo, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../PropertyTree/Nodes/PropertyTreeSelectNode'
import HotspotNodeFactory from './HotspotNodeFactory'

import useStore from '../../../modules/store'
import { typesSelector, getTypeMemo } from '../../../modules/hotspotTypesStore'

const HotspotTypeSelectNode = ({
  hotspotId,
  id,
  value: typeId,
  data,
  onPropertyChanged,
  ...props
}) => {
  const types = useStore(typesSelector)
  const gTypeMemo = useMemo(getTypeMemo, [])
  const type = useStore((state) => gTypeMemo(state, typeId))

  const handleChange = useCallback(
    (nodeId, value) => {
      console.log(value)
      onPropertyChanged && onPropertyChanged(nodeId.slice(id.length + 1), value)
    },
    [id, onPropertyChanged]
  )

  const getProperties = () => {
    const properties = []
    for (const prop in type.schema.properties) {
      const propObject = type.schema.properties[prop]
      if (propObject.myData) {
        properties.push(
          <HotspotNodeFactory
            key={prop}
            id={prop}
            type={propObject.myData.type}
            label={propObject.myData.name}
            value={data[prop]}
            onChange={handleChange}
          />
        )
      }
    }
    return properties
  }

  return (
    <PropertyTreeSelectNode
      id={id}
      value={typeId}
      options={[
        types.map((type) => {
          return (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          )
        })
      ]}
      {...props}
    >
      {getProperties()}
    </PropertyTreeSelectNode>
  )
}
export default memo(HotspotTypeSelectNode)
