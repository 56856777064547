import { useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'

import FolderSelect from './FolderSelect'
import ImageSelect from './ImageSelect'

import useStore from '../../../../modules/store'
import { getCubeMemo } from '../../../../modules/sceneDescriptionStore'
import { setFolderIdAction } from '../../../../modules/equirectangularImagesMapperStore'
import { getEquirectangularImageMemo } from '../../../../modules/projectFolderStore'
import { getCombinationMemo } from '../../../../modules/equirectangularImagesMapperStore'

import {
  NOT_FOUND_IMAGE_SRC,
  getCubeFaceImages
} from '../../../../modules/sceneDrawDataStore'

const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 8,
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center'
}))
const PointName = styled('div')(({ theme }) => ({
  paddingBottom: 8
}))
const InputsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  color: theme.palette.primary.contrastText
}))

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'contain',
  maxHeight: 540
}))

const CubeImage = styled('img')(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  height: 'auto'
}))

const CubeItem = ({ combId, cubeId, folderId, imageId, imageSrc }) => {
  const gCubeMemo = useMemo(getCubeMemo, [])
  const cube = useStore((state) => gCubeMemo(state, cubeId))
  const setFolderId = useStore(setFolderIdAction)
  const gEquirectangularImageMemo = useMemo(getEquirectangularImageMemo, [])
  const image = useStore((state) => gEquirectangularImageMemo(state, imageId))
  const gCombinationMemo = useMemo(getCombinationMemo, [])
  const combination = useStore((state) => gCombinationMemo(state, combId))
  const gCubeFaceImages = useStore(getCubeFaceImages)

  const cubeFaceImages = gCubeFaceImages(cubeId, combination.id)
  const handleChange = useCallback(
    (id, value) => {
      setFolderId(combId, cubeId, value)
    },
    [setFolderId, combId, cubeId]
  )

  return cube ? (
    <Root>
      <PointName>
        {cube.name.es} ({cube.imageIdx}):
      </PointName>
      <InputsWrapper>
        <FolderSelect
          style={{ width: '50%', marginRight: 4 }}
          value={folderId}
          onChange={handleChange}
        />
        <ImageSelect
          style={{ width: '50%' }}
          combId={combId}
          cubeId={cubeId}
          folderId={folderId}
          imageId={imageId}
          imageSrc={imageSrc}
        />
      </InputsWrapper>
      <Grid
        container
        sx={{ width: '100%', marginTop: 0, padding: '8px' }}
        spacing={2}
      >
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.MuiGrid-item': { padding: '8px' }
          }}
        >
          <Image src={image ? image.url : NOT_FOUND_IMAGE_SRC} />
        </Grid>
        <Grid item xs={3} sx={{ '&.MuiGrid-item': { padding: '8px' } }}>
          <Grid container spacing={2}>
            {cubeFaceImages.map((img) => (
              <Grid item xs={6}>
                <Tooltip title={img.id}>
                  <CubeImage key={img.id} src={img.url} />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Root>
  ) : null
}

export default CubeItem
//
