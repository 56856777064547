import { findById } from '../utils/utils'
import { createSelector } from 'reselect'
import * as THREE from 'three'

const MAX_N_TEXTURES = 4
const cubeTextureLoader = new THREE.CubeTextureLoader()
const textureLoader = new THREE.TextureLoader()

export const loadTexture = (url) => {
  return new Promise((resolve, reject) => {
    const onLoad = (texture) => {
      console.log('Textura cargada')
      texture.minFilter = THREE.LinearFilter
      // texture.format = THREE.RGBAFormat
      // texture.encoding = THREE.sRGBEncoding
      // texture.mapping = THREE.EquirectangularReflectionMapping
      texture.needsUpdate = true
      resolve(texture)
    }
    // const onProgress = (url, itemsLoaded, itemsTotal) => {
    //   console.log('Progress')
    // }
    const onError = (e) => {
      console.log('Error en carga textura', url, e)
      resolve(null)
    }
    if (!url) {
      resolve(null)
    }
    textureLoader.load(url, onLoad, null, onError)
  })
}

export const loadCubeTexture = (urls) => {
  return new Promise((resolve, reject) => {
    const onLoad = (texture) => {
      console.log('Textura cargada')
      texture.minFilter = THREE.LinearFilter
      // texture.format = THREE.RGBAFormat
      // texture.encoding = THREE.sRGBEncoding
      texture.needsUpdate = true
      resolve(texture)
    }
    const onError = (e) => {
      console.log('Error en carga textura', urls, e)
      resolve(null)
    }
    if (!urls || urls.length < 6) {
      resolve(null)
    }
    cubeTextureLoader.load(urls, onLoad, null, onError)
  })
}

export const texturesStore = (set, get) => ({
  textures: {
    texture0: '',
    texture1: '',
    currentTexture: 0,
    textures: [],
    loadingTexture: false,
    //****************************************************************************
    //****************************************************************************
    reset: () => {
      set((st) => ({
        textures: {
          ...st.textures,
          texture0: '',
          texture1: '',
          currentTexture: 0,
          textures: [],
          loadingTexture: false
        }
      }))
    },
    setCurrentTexture: async (textureId, urls, sphere) => {
      await loadTextureAction(get())(textureId, urls, sphere)
      if (currentTextureSelector(get()) === 0) {
        set((st) => ({
          textures: {
            ...st.textures,
            currentTexture: 1,
            texture0: texture0Selector(get())
              ? texture0Selector(get())
              : textureId,
            texture1: textureId
          }
        }))
      } else {
        set((st) => ({
          textures: {
            ...st.textures,
            currentTexture: 0,
            texture0: textureId,
            texture1: texture1Selector(get())
              ? texture1Selector(get())
              : textureId
          }
        }))
      }
    },

    loadTexture: async (textureId, urls, sphere) => {
      if (findById(texturesSelector(get()), textureId)) {
        return
      } else {
        set((st) => ({ textures: { ...st.textures, loadingTexture: true } }))
        let texture = null

        if (sphere) {
          texture = await loadTexture(urls)
          //TODO:Que pasa si falla la carga de la textura
        } else {
          texture = await loadCubeTexture(urls)
          //TODO:Que pasa si falla la carga de la textura
        }
        if (texture) {
          pushTextureAction(get())({ id: textureId, texture })
        }
        set((st) => ({ textures: { ...st.textures, loadingTexture: false } }))
      }
    },
    pushTexture: (texture) => {
      let newTextures = texturesSelector(get())
      if (!texture || findById(newTextures, texture.id)) {
        return
      }
      if (newTextures.length >= MAX_N_TEXTURES) {
        //Hay que quitar
        const removedTexture = newTextures.find(
          (texture) =>
            texture.id !== texture0Selector(get()) &&
            texture.id !== texture1Selector(get())
        )
        if (removedTexture) {
          newTextures = newTextures.filter(
            (texture) => removedTexture.id !== texture.id
          )
          removedTexture.texture.dispose()
        }
      }
      set((st) => ({
        textures: { ...st.textures, textures: [...newTextures, texture] }
      }))
    },
    clear: () => {
      set((st) => ({
        textures: { ...st.textures, textures: [] }
      }))
    }
  }
})
export default texturesStore

export const resetAction = (state) => state.textures.reset
export const setCurrentTextureAction = (state) =>
  state.textures.setCurrentTexture
const loadTextureAction = (state) => state.textures.loadTexture
const pushTextureAction = (state) => state.textures.pushTexture
export const clearAction = (state) => state.textures.clear

export const texturesSelector = (state) => state.textures.textures
export const loadingTextureSelector = (state) => state.textures.loadingTexture
export const currentTextureSelector = (state) => state.textures.currentTexture
export const texture0Selector = (state) => state.textures.texture0
export const texture1Selector = (state) => state.textures.texture1

export const getTexture0 = createSelector(
  [texturesSelector, texture0Selector],
  (textures, t0Id) => {
    return findById(textures, t0Id)
  }
)

export const getTexture1 = createSelector(
  [texturesSelector, texture1Selector],
  (textures, t1Id) => {
    return findById(textures, t1Id)
  }
)
