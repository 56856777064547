import PropertyTreeTextNode from '../PropertyTree/Nodes/PropertyTreeTextNode'
import PropertyTreeIntegerNode from '../PropertyTree/Nodes/PropertyTreeIntegerNode'
import PropertyTreeFloatNode from '../PropertyTree/Nodes/PropertyTreeFloatNode'
import PropertyTreeColorNode from '../PropertyTree/Nodes/PropertyTreeColorNode'
import SizeInPixelsNode from './SizeInPixelsNode'
import ImageSelectNode from './ImageSelectNode'
import FillNode from './Fill/FillNode'
import BorderNode from './Border/BorderNode'
const HotspotNodeFactory = ({ type, data, ...props }) => {
  // console.log(type, data)
  switch (type) {
    case 'string':
      return <PropertyTreeTextNode {...props} />
    case 'integer':
      return <PropertyTreeIntegerNode {...props} />
    case 'number':
      return <PropertyTreeFloatNode {...props} />
    case 'size':
      return <SizeInPixelsNode {...props} />
    case 'image':
      return <ImageSelectNode {...props} />
    case 'color':
      return <PropertyTreeColorNode {...props} />
    case 'fill':
      return <FillNode {...props} />
    case 'border':
      return <BorderNode {...props} />

    default:
      return null
  }
}
export default HotspotNodeFactory
