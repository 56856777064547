import { useCallback } from 'react'
import { styled } from '@mui/material/styles'

import PropertyTreeNode from '../../PropertyTree/Node/PropertyTreeNode'
import FloatField from '../../Inputs/FloatField'

const Unit = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  // padding: 4,
  height: '100%',
  width: 64,
  minHeight: 0,
  cursor: 'pointer'
}))
const PixelPorcentageNode = ({
  id,
  value: valueObject,
  onChange,
  length,
  ...props
}) => {
  const handleChangeValue = useCallback(
    (nodeId, newValue) => {
      onChange && onChange(id, { ...valueObject, value: newValue })
    },
    [onChange, id, valueObject]
  )

  const handleUnitChange = useCallback(() => {
    if (onChange) {
      const newPixels = !valueObject.pixels
      const newValue = newPixels
        ? Math.round((valueObject.value * length) / 100)
        : (valueObject.value * 100) / length
      onChange(id, {
        ...valueObject,
        value: newValue,
        pixels: !valueObject.pixels
      })
    }
  }, [onChange, id, length, valueObject])

  return (
    <PropertyTreeNode
      id={id}
      {...props}
      input={
        <>
          <FloatField
            {...props}
            value={valueObject.value}
            onChange={handleChangeValue}
          />
          <Unit onClick={handleUnitChange}>
            <span style={{ height: '100%' }}>
              {valueObject.pixels ? 'px' : '%'}
            </span>
          </Unit>
        </>
      }
    />
  )
}
export default PixelPorcentageNode
