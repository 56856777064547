import { useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import FolderSelect from './FolderSelect'
import BaseTextField from '../../../Common/Inputs/BaseTextField'
import IntegerField from '../../../Common/Inputs/IntegerField'
import MyButton from '../../../Common/MyButton'

import useStore from '../../../../modules/store'
import {
  autoFillAction,
  getCombinationMemo,
  updateFillAction
} from '../../../../modules/equirectangularImagesMapperStore'

const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 8,
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'stretch',
  color: theme.palette.primary.contrastText
}))

const Filler = ({ combId }) => {
  const gCombinationMemo = useMemo(getCombinationMemo, [])
  const combination = useStore((state) => gCombinationMemo(state, combId))

  const autoFill = useStore(autoFillAction)
  const updateFill = useStore(updateFillAction)

  const handleFill = useCallback(() => {
    autoFill(combId)
  }, [autoFill, combId])

  const handleFolder = useCallback(
    (id, value) => {
      updateFill(combId, { ...combination.auto, folderId: value })
    },
    [combId, updateFill, combination]
  )

  const handlePrefix = useCallback(
    (id, value) => {
      updateFill(combId, { ...combination.auto, prefix: value })
    },
    [combId, updateFill, combination]
  )

  const handleSuffix = useCallback(
    (id, value) => {
      updateFill(combId, { ...combination.auto, suffix: value })
    },
    [combId, updateFill, combination]
  )

  const handleNumberLength = useCallback(
    (id, value) => {
      updateFill(combId, { ...combination.auto, numberLength: value })
    },
    [combId, updateFill, combination]
  )

  return combination ? (
    <Root>
      <FolderSelect
        style={{ flex: '1 1 auto', minWidth: 100 }}
        label="Carpeta"
        value={combination.auto.folderId ? combination.auto.folderId : ''}
        onChange={handleFolder}
      />
      <BaseTextField
        style={{ flex: '1 1 auto', marginLeft: 2, marginRight: 2 }}
        label="Prefix"
        variant="outlined"
        value={combination.auto.prefix}
        onChange={handlePrefix}
      />
      <IntegerField
        style={{ width: '152px', marginLeft: 2, marginRight: 2 }}
        label="Longitud número"
        variant="outlined"
        value={combination.auto.numberLength}
        onChange={handleNumberLength}
      />
      <BaseTextField
        style={{ flex: '1 1 auto', marginLeft: 2, marginRight: 2 }}
        label="Suffix"
        value={combination.auto.suffix}
        variant="outlined"
        onChange={handleSuffix}
      />
      <MyButton style={{ width: 120 }} onClick={handleFill}>
        Rellenar
      </MyButton>
    </Root>
  ) : null
}

export default Filler
//
