import { useMemo, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { useGesture } from 'react-use-gesture'
import { ResizeObserver } from '@juggle/resize-observer'

import ImageHotspot from '../../../Hotspots/Image/ImageHotspot'
import { getStyleMemo } from '../../../../modules/hotspotTypesStore'
import { getHotspotMemo } from '../../../../modules/sceneDescriptionStore'
import useStore from '../../../../modules/store'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scale'
})(({ theme, scale }) => ({
  // border: '4px solid red',
  boxSizing: 'border-box',
  padding: 40,
  width: 400,
  height: 400,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  margin: 20
}))

const HotspotWrapper = ({ hotspotId, styleId, onChange, ...props }) => {
  const [initial, setInitial] = useState(true)
  const [scale, setScale] = useState(1)
  const gHotspotMemo = useMemo(getHotspotMemo, [])
  const hotspot = useStore((state) => gHotspotMemo(state, hotspotId))

  const gStyleMemo = useMemo(getStyleMemo, [])
  const style = useStore((state) => gStyleMemo(state, styleId))

  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })

  useEffect(() => {
    if (initial && rootBounds.width && rootBounds.height) {
      const newScale = 300 / rootBounds.width
      setScale(newScale)
      setInitial(false)
    }
  }, [scale, rootBounds, initial])

  const bind = useGesture({
    onWheel: ({ direction, wheeling }) => {
      if (wheeling) {
        direction[1] > 0 ? setScale(scale + 0.1) : setScale(scale - 0.1)
      }
    }
  })

  return (
    <Root {...bind()}>
      <div
        ref={rootBoundsRef}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 20,
          transform: `scale(${scale})`
        }}
      >
        <ImageHotspot
          styleId={style ? style.id : ''}
          anchorId={hotspot.anchor}
          data={hotspot.data}
        />
      </div>
    </Root>
  )
}
export default HotspotWrapper
