import { useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'

import OKCancelButtons from '../../../Common/OKCancelButtons'
import PropertyTree from '../../../Common/PropertyTree/Tree/PropertyTree'
import PropertyTreeHeader from '../../../Common/PropertyTree/Header/PropertyTreeHeader'

import CEImageIndexNode from './CEImageIndexNode'
import CENameNode from './CENameNode'
import CEPOVNode from './CEPOVNode'
import CEPositionNode from './CEPositionNode'
import CEFinishSelectNode from './CEFinishSelectNode'
import CEHotspotSelectNode from './CEHotspotSelectNode'
import CEStartEventNode from './CEStartEventNode'

import { useStore } from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import {
  initAction,
  restoreAction,
  saveAction
} from '../../../../modules/cubeEditorStore'
import {
  setCurrentOptionPanelAction,
  setDisabledOptionsAction,
  setEnabledOptionsAction
} from '../../../../modules/lateralMenuStore'
import { LATERAL_PANELS, MENU_OPTIONS } from '../../../../settings/menuSettings'
import { TREE_IDS } from '../../../../modules/propertyTreeStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  minHeight: 0,
  overflow: 'auto',
  flex: '0 1 auto'
}))

const Message = styled('div')(({ theme }) => ({
  paddingBottom: 12,
  color: theme.palette.primary.contrastText
}))

const CubeEditor = () => {
  const init = useStore(initAction)
  const restore = useStore(restoreAction)
  const save = useStore(saveAction)
  const selectedCubes = useStore(selectedSelector)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)
  const setEnabledOptions = useStore(setEnabledOptionsAction)
  const setDisabledOptions = useStore(setDisabledOptionsAction)

  useEffect(() => {
    init()
    setEnabledOptions([MENU_OPTIONS.CUBES])
    return () => {
      setDisabledOptions([])
    }
  }, [init, setDisabledOptions, setEnabledOptions])

  const handleCancel = useCallback(() => {
    restore()
    setCurrentOptionPanel(LATERAL_PANELS.CUBES_LIST)
    setDisabledOptions([])
  }, [restore, setCurrentOptionPanel, setDisabledOptions])

  const handleOK = useCallback(() => {
    save()
    setCurrentOptionPanel(LATERAL_PANELS.CUBES_LIST)
    setDisabledOptions([])
  }, [save, setCurrentOptionPanel, setDisabledOptions])

  return (
    <Root>
      <ContentWrapper>
        {selectedCubes.length > 1 && (
          <Message>
            Múltiples cubos seleccionados. Los cambios se aplicarán a todos.
          </Message>
        )}
        <PropertyTree treeId={TREE_IDS.CUBE}>
          <PropertyTreeHeader />
          <CEImageIndexNode />
          <CENameNode />
          <CEPOVNode />
          <CEPositionNode />
          <CEFinishSelectNode />
          <CEHotspotSelectNode />
          <CEStartEventNode />
        </PropertyTree>
      </ContentWrapper>
      <OKCancelButtons onCancel={handleCancel} onOk={handleOK} />
    </Root>
  )
}
export default CubeEditor
