import AddPolygonIcon from '../../Icons/AddPolygonIcon'
import STButton from '../ScrollToolbar/STButton'

const AddPolygonButton = (props) => {
  return (
    <STButton
      tooltip={'Añadir polígono'}
      tooltipEnabled
      icon={<AddPolygonIcon />}
      {...props}
    />
  )
}
export default AddPolygonButton
