import PropertyTreeEmptyNode from '../../../Common/PropertyTree/Nodes/PropertyTreeEmptyNode'
import HEEventNode from './HEEventNode'

import { useStore } from '../../../../modules/store'

import { getCurrentHotspot } from '../../../../modules/sceneHotspotListStore'

const HEEventsNode = ({ cubeId, value, ...props }) => {
  const hs = useStore(getCurrentHotspot)

  return hs ? (
    <PropertyTreeEmptyNode id={'event'} label={'Eventos'} {...props}>
      <HEEventNode
        id={'onHover'}
        eventName={'onHover'}
        label={'Puntero sobre hotspot'}
        value={hs.onHover}
      />
      <HEEventNode
        id={'onOut'}
        eventName={'onOut'}
        label={'Puntero sale de hotspot'}
        value={hs.onOut}
      />
      <HEEventNode
        id={'onClick'}
        eventName={'onClick'}
        label={'Click en hotspot'}
        value={hs.onClick}
      />
    </PropertyTreeEmptyNode>
  ) : null
}
export default HEEventsNode
