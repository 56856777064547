import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'

import { useStore } from '../../../../modules/store'
import {
  getCurrentPanel,
  widthSelector,
  setWidthAction
} from '../../../../modules/lateralMenuStore'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'panelWidth' && prop !== 'zIndex'
})(({ theme, panelWidth, zIndex }) => ({
  position: 'relative',
  height: '100%',
  zIndex: zIndex - 1,
  display: 'flex',
  backgroundColor:
    panelWidth === 0
      ? theme.palette.secondary.light
      : theme.palette.primary.main,
  '&:hover': { backgroundColor: theme.palette.secondary.main }
}))

const DraggingMask = styled('div', {
  shouldForwardProp: (prop) => prop !== 'rest' && prop !== 'zIndex'
})(({ theme, zIndex }) => ({
  top: 0,
  left: 0,
  zIndex: zIndex + 1000,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  backgroundColor: 'transparent'
}))

const ResizeBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dragEnable' && prop !== 'panelWidth'
})(({ theme, dragEnable, panelWidth }) => ({
  touchAction: 'none',
  boxSizing: 'border-box',
  width: 4, //Si se cambia esto cambiarlo tambien en LateralMenuPanel
  height: '100%',
  cursor: dragEnable ? 'col-resize' : 'default',
  position: 'relative'
}))

const ResizeBarTransparent = styled('div')(({ theme }) => ({
  touchAction: 'none',
  boxSizing: 'border-box',
  width: 12,
  height: '100%',
  cursor: 'col-resize',

  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(-50%,0)',
  backgroundColor: 'transparent'
}))

const LPMResizeBar = ({ zIndex }) => {
  const width = useStore(widthSelector)
  const [localWidth, setLocalWidth] = useState(width)
  const [hover, setHover] = useState(false)
  const [dragging, setDragging] = useState(false)
  const currentPanel = useStore(getCurrentPanel)
  const setWidth = useStore(setWidthAction)

  useEffect(() => {
    if (!dragging) {
      setLocalWidth(width)
    }
  }, [width, dragging])

  const bind = useGesture(
    {
      onDragStart: () => {
        setDragging(true)
        setLocalWidth(width)
      },
      onDrag: ({ movement }) => {
        setWidth(localWidth + movement[0])
      },
      onDragEnd: () => {
        setDragging(false)
      }
    },
    {
      preventDefault: true
    }
  )

  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  return currentPanel ? (
    <Root
      panelWidth={width}
      zIndex={zIndex}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {dragging && <DraggingMask zIndex={zIndex} />}
      <ResizeBar {...bind()} dragEnable />
      {hover && <ResizeBarTransparent {...bind()} />}
    </Root>
  ) : null
}
export default LPMResizeBar
