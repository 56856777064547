import { useMemo, useState, useCallback } from 'react'

import VideoGeometry from '../../Three/FlatPolygon/VideoGeometry'
import ColorGeometry from '../../Three/FlatPolygon/ColorGeometry'
import { useStore } from '../../../modules/store'
import {
  getCurrentCubeHotspotMemo,
  getVideoSrc,
  hotspotHoverAction,
  hotspotClickAction
} from '../../../modules/sceneDrawDataStore'
import { draggingSelector } from '../../../modules/pointerEventsStore'

import { POLYGON_FILL_TYPE_IDS } from '../../../schemas/hotspotPolygonSchema'

const PolygonGeometry = ({ id, contour, holes }) => {
  const [hover, setHover] = useState(false)
  const gHotspot = useMemo(getCurrentCubeHotspotMemo, [])
  const hotspot = useStore((state) => gHotspot(state, id))
  const gVideoSrc = useStore(getVideoSrc)
  const hotspotHover = useStore(hotspotHoverAction)
  const hotspotClick = useStore(hotspotClickAction)
  const dragging = useStore(draggingSelector)
  const { COLOR, IMAGE, VIDEO } = POLYGON_FILL_TYPE_IDS

  const handleOver = useCallback(() => {
    if (!dragging) {
      setHover(true)
      hotspotHover(id, true)
    }
  }, [hotspotHover, id, dragging])

  const handleOut = useCallback(
    (e) => {
      if (!dragging) {
        setHover(false)
        hotspotHover(id, false)
      }
    },
    [hotspotHover, id, dragging]
  )

  const handleClick = useCallback(() => {
    hotspotClick(id)
    setHover(true)
  }, [hotspotClick, id])

  switch (hotspot?.data?.fill?.type) {
    case COLOR:
      return (
        <ColorGeometry
          contour={contour}
          holes={holes}
          color={
            hover
              ? hotspot.data.fill.data.hover.slice(0, -2)
              : hotspot.data.fill.data.color.slice(0, -2)
          }
          opacity={
            parseInt(
              hover
                ? hotspot.data.fill.data.hover.slice(-2)
                : hotspot.data.fill.data.color.slice(-2),
              16
            ) / 255
          }
          onPointerEnter={handleOver}
          onPointerOut={handleOut}
          onClick={handleClick}
        />
      )

    case IMAGE:
      break
    case VIDEO:
      return (
        <VideoGeometry
          contour={contour}
          holes={holes}
          src={gVideoSrc(hotspot.data.fill.data.src)}
          onPointerOver={handleOver}
          onPointerOut={handleOut}
          onClick={handleClick}
        />
      )
    default:
      return null
  }
}

export default PolygonGeometry
