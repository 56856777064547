import { styled } from '@mui/material/styles'

import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { useStore } from '../../../../modules/store'
import { getCurrentPanel } from '../../../../modules/lateralMenuStore'

const SToolbar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  minHeight: 32,
  paddingLeft: 8
}))

const SIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: 'inherit',
  '&:hover': { color: theme.palette.secondary.main }
}))

const LPMTopBar = ({ onClose }) => {
  const currentPanel = useStore(getCurrentPanel)
  return (
    <SToolbar disableGutters variant="dense">
      <div>{currentPanel ? currentPanel.title : ''}</div>
      <div style={{ flexGrow: 1 }} />
      {currentPanel?.closeButton && (
        <SIconButton onClick={onClose}>
          <CloseIcon />
        </SIconButton>
      )}
    </SToolbar>
  )
}
export default LPMTopBar
