import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'

import StyleItem from './StyleItem'
import { getTypeStylesMemo } from '../../../../modules/hotspotTypesStore'
import { getHotspotMemo } from '../../../../modules/sceneDescriptionStore'
import useStore from '../../../../modules/store'

const Root = styled(List)(({ theme }) => ({
  width: 320,
  height: '100%',
  minHeight: 0,
  overflow: 'auto',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderTop: 'none',
  padding: 0
  // backgroundColor: theme.palette.primary.light
}))

const StylesList = ({ hotspotId, styleId, onChange, ...props }) => {
  const gHotspotMemo = useMemo(getHotspotMemo, [])
  const hotspot = useStore((state) => gHotspotMemo(state, hotspotId))
  const gTypeStylesMemo = useMemo(getTypeStylesMemo, [])
  const styles = useStore((state) =>
    gTypeStylesMemo(state, hotspot ? hotspot.type : '')
  )

  return (
    <Root>
      {styles.map((style, index) => (
        <StyleItem
          key={style.id}
          id={style.id}
          selected={style.id === styleId}
          onClick={onChange}
        />
      ))}
    </Root>
  )
}
export default StylesList
