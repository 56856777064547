import { useCallback } from 'react'
// import { styled } from '@mui/material/styles'

import PropertyTree from '../../../Common/PropertyTree/Tree/PropertyTree'
import PropertyTreeMediaSelectNode from '../../../Common/PropertyTree/Nodes/MediaSelect/PropertyTreeMediaSelectNode'
import MultilanguageTextNode from '../../../Common/Nodes/MultilanguageTextNode'

import useStore from '../../../../modules/store'
import {
  getCurrentOption,
  updateOptionAction
} from '../../../../modules/finishEditorStore'

import {
  imagesSelector,
  getImageByName
} from '../../../../modules/projectFolderStore'

import { TREE_IDS } from '../../../../modules/propertyTreeStore'

const OptionEditor = () => {
  const currentOption = useStore(getCurrentOption)
  const updateOption = useStore(updateOptionAction)
  const images = useStore(imagesSelector)
  const gImageByName = useStore(getImageByName)

  const handleChange = useCallback(
    (nodeId, value) => {
      // console.log(nodeId, value)
      switch (nodeId) {
        case 'optionName': {
          updateOption(currentOption.id, { ...currentOption, name: value })
          break
        }
        case 'image': {
          updateOption(currentOption.id, { ...currentOption, imageSrc: value })
          break
        }
        default:
      }
    },
    [currentOption, updateOption]
  )

  const getCurrentImageSrc = useCallback(() => {
    const image = gImageByName(currentOption.imageSrc)
    return image ? image.url : null
  }, [gImageByName, currentOption])

  return (
    <PropertyTree treeId={TREE_IDS.FINISH} topBorder={false}>
      <MultilanguageTextNode
        id={'optionName'}
        label="Nombre"
        tooltipDisabled
        value={currentOption.name}
        onChange={handleChange}
      />
      {images.length > 0 && (
        <PropertyTreeMediaSelectNode
          id={'image'}
          label="Imagen"
          imageMode
          value={currentOption.imageSrc}
          medias={images.map((img) => ({ name: img.name, src: img.url }))}
          currentMediaSrc={getCurrentImageSrc()}
          onChange={handleChange}
        />
      )}
    </PropertyTree>
  )
}
export default OptionEditor
