import { useMemo, memo, useState, useCallback, useEffect } from 'react'

import StyleSelectDialogActions from './StyleSelectDialogActions'
import StyleSelectDialogContent from './StyleSelectDialogContent'

import PropertyTreeDialogNode from '../../PropertyTree/Nodes/PropertyTreeDialogNode'
import useStore from '../../../../modules/store'
import {
  getTypeMemo,
  getTypeStylesMemo,
  getStyleMemo
} from '../../../../modules/hotspotTypesStore'

const HotspotStyleSelectNode = ({
  id,
  hotspotId,
  hotspotType,
  value,
  onChange,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const gTypeMemo = useMemo(getTypeMemo, [])
  const type = useStore((state) => gTypeMemo(state, hotspotType))
  const gStyleMemo = useMemo(getStyleMemo, [])
  const style = useStore((state) => gStyleMemo(state, value))
  const gTypeStylesMemo = useMemo(getTypeStylesMemo, [])
  const styles = useStore((state) => gTypeStylesMemo(state, hotspotType))

  const [localStyle, setLocalStyle] = useState(style ? style.id : '')
  useEffect(() => {
    setLocalStyle(style ? style.id : '')
  }, [style])

  const handleOpen = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleClose = useCallback(
    (ok) => {
      setDialogOpen(false)
      if (ok) {
        onChange && onChange(id, localStyle)
      } else {
        setLocalStyle(style ? style.id : '')
      }
    },
    [onChange, id, localStyle, style]
  )

  const handleChange = useCallback((newStyleId) => {
    setLocalStyle(newStyleId)
  }, [])

  return type && styles.length ? (
    <PropertyTreeDialogNode
      id={id}
      text={style ? style.name : 'Sin estilo'}
      open={dialogOpen}
      onOpen={handleOpen}
      dialogTitle={'Elige estilo'}
      dialogContent={
        <StyleSelectDialogContent
          hotspotId={hotspotId}
          styleId={localStyle}
          hotspotType={hotspotType}
          onChange={handleChange}
        />
      }
      dialogActions={<StyleSelectDialogActions onClose={handleClose} />}
      {...props}
    />
  ) : null
}
export default memo(HotspotStyleSelectNode)
