// import { useCallback, useState } from 'react'
import { styled, lighten } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Draggable } from 'react-beautiful-dnd'

import Item from './Item'
const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  padding: 1
}))

const getItemStyle = (theme, isDragging, draggableProps, snapshot) => {
  return {
    // ...draggableProps.style,
    ...(isDragging && {
      backgroundColor: lighten(theme.palette.primary.light, 0.2),
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 30px 0px',
      border: `2px solid ${theme.palette.secondary.main}`
    })
  }
}

const DraggableListItem = ({ className, id, index, children, ...props }) => {
  const theme = useTheme()

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Root
          className={className}
          ref={provided.innerRef}
          style={getItemStyle(
            theme,
            snapshot.isDragging,
            provided.draggableProps,
            snapshot
          )}
          {...provided.draggableProps}
        >
          <Item id={id} {...props} {...provided.dragHandleProps} />
          {children}
        </Root>
      )}
    </Draggable>
  )
}
export default DraggableListItem
