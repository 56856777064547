import { useMemo, useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'
import Button from '@mui/material/Button'

import ScrollToolbar from '../../Common/ScrollToolbar/ScrollToolbar'
import ReloadButton from '../../Common/ToolbarButtons/ReloadButton'
import SaveButton from '../../Common/ToolbarButtons/SaveButton'
import ErrorsButton from '../../Common/ToolbarButtons/ErrorsButton'
import TextButton from '../../Common/ToolbarButtons/TextButton'

import useStore from '../../../modules/store'
import {
  getCurrentModel,
  modelsSelector,
  currentModelSelector,
  setCurrentModelAction
} from '../../../modules/textEditorModelsStore'

const FileButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected }) => ({
  textTransform: 'none',
  color: selected
    ? theme.palette.secondary.main
    : theme.palette.primary.contrastText,
  backgroundColor: lighten(theme.palette.primary.light, 0.2),
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.2)
  }
}))

const SceneTextEditorBar = ({
  modeJson,
  onUpload,
  onReload,
  onSetJsonMode
}) => {
  const model = useStore(getCurrentModel)
  const models = useStore(modelsSelector)
  const currentModel = useStore(currentModelSelector)
  const setCurrentModel = useStore(setCurrentModelAction)

  const handleSelectModel = useCallback(
    (id) => {
      setCurrentModel(id)
    },
    [setCurrentModel]
  )
  const handleChangeMode = useCallback(
    (jsonMode) => {
      onSetJsonMode && onSetJsonMode(jsonMode)
    },
    [onSetJsonMode]
  )

  return (
    <ScrollToolbar
      mainDimension={32}
      firstItems={[
        <SaveButton disabled={!model || !model.valid} onClick={onUpload} />,
        <ReloadButton onClick={onReload} />,
        <ErrorsButton
          selected={!modeJson}
          onClick={() => handleChangeMode(false)}
        />,
        <TextButton
          selected={modeJson}
          onClick={() => handleChangeMode(true)}
        />
      ]}
      secondItems={models.map((model) => (
        <FileButton
          key={model.id}
          selected={model.id === currentModel}
          onClick={() => handleSelectModel(model.id)}
        >
          {model.name}
        </FileButton>
      ))}
    />
  )
}
export default SceneTextEditorBar
