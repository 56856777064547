import { useCallback } from 'react'

import MultilanguageTextNode from '../../Common/Nodes/MultilanguageTextNode'

import { useStore } from '../../../modules/store'
import { nameSelector } from '../../../modules/sceneDescriptionStore'
import { setNameAction } from '../../../modules/sceneEditorStore'

const SENameNode = () => {
  const name = useStore(nameSelector)
  const setName = useStore(setNameAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setName(value)
    },
    [setName]
  )

  return (
    <MultilanguageTextNode
      id={'name'}
      label="Nombre"
      value={name}
      onChange={handleChange}
    />
  )
}
export default SENameNode
