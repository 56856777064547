import { useCallback, memo } from 'react'

import PropertyTreeTextNode from '../PropertyTree/Nodes/PropertyTreeTextNode'
import PropertyTreeFloatNode from '../PropertyTree/Nodes/PropertyTreeFloatNode'

const Point3DNode = ({
  id,
  label,
  tooltip,
  tooltipDisabled,
  level,
  warning,
  error,
  disabled,
  readOnly,
  value = [0, 0, 0],
  options,
  onChange,
  children
}) => {
  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === `${id}:x`) {
          onChange(id, [newValue, value[1], value[2]])
        } else if (nodeId === `${id}:y`) {
          onChange(id, [value[0], newValue, value[2]])
        } else if (nodeId === `${id}:z`) {
          onChange(id, [value[0], value[1], newValue])
        }
      }
    },
    [id, onChange, value]
  )

  return (
    <PropertyTreeTextNode
      id={id}
      label={label}
      level={level}
      tooltip={tooltip}
      tooltipDisabled={tooltipDisabled}
      value={`(${value[0]}  ${value[1]}  ${value[2]})`}
      warning={warning}
      error={error}
      disabled={disabled}
      readOnly={true}
      onChange={handleChange}
    >
      <PropertyTreeFloatNode
        id={'x'}
        label="X"
        value={value[0]}
        onChange={handleChange}
      />
      <PropertyTreeFloatNode
        id={'y'}
        label="Y"
        value={value[1]}
        onChange={handleChange}
      />
      <PropertyTreeFloatNode
        id={'z'}
        label="Z"
        value={value[2]}
        onChange={handleChange}
      />
    </PropertyTreeTextNode>
  )
}
export default memo(Point3DNode)
