import { useEffect } from 'react'
// import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import * as THREE from 'three'

import { Canvas } from '@react-three/fiber'

import Sphere from './Sphere'
import Camera from '../../Three/Camera'
import CameraControls from '../../Three/CameraControls'
import Loading from '../../Three/Loading'
import Transforms from '../../Three/Transforms'
import EditorHotspots3D from '../Hotspots/EditorHotspots3D'
import Polygons from '../Polygons/Polygons'

import useStore from '../../../modules/store'
import { loadingTextureSelector } from '../../../modules/texturesStore'
import { zoomInAction, zoomOutAction } from '../../../modules/cameraStore'

import { setThreeBoundsAction } from '../../../modules/mainStore'

const EditorScene = () => {
  // const [xy, setXY] = useState([0, 0])
  const [boundsRef, bounds] = useMeasure({
    polyfill: ResizeObserver
  })
  const setThreeBounds = useStore(setThreeBoundsAction)

  const zoomIn = useStore(zoomInAction)
  const zoomOut = useStore(zoomOutAction)
  const loadingTexture = useStore(loadingTextureSelector)

  useEffect(() => {
    if (bounds) {
      setThreeBounds(bounds)
    }
  }, [setThreeBounds, bounds])

  const bind = useGesture(
    {
      onDrag: ({ xy, tap }) => {
        if (tap) {
          // setXY(xy)
          // console.log(g3DPoint(xy[0], xy[1]))
        }
      },
      onWheel: ({ direction, wheeling }) => {
        if (wheeling) {
          direction[1] > 0 ? zoomIn() : zoomOut()
        }
      }
    },
    { filterTaps: true }
  )
  console.log('scene')

  //frameloop="demand"
  return (
    <div {...bind()} ref={boundsRef} style={{ width: '100%', height: '100%' }}>
      {/*<Suspense fallback={<Loading />}>*/}
      {loadingTexture && <Loading />}
      <Canvas
        frameloop="demand"
        gl={{
          preserveDrawingBuffer: true,
          antialias: true,
          encoding: THREE.sRGBEncoding,
          gammaFactor: 2.2
        }}
        linear
      >
        <EditorHotspots3D />
        <Transforms />
        <Sphere />
        <Camera />
        <CameraControls />
        <Polygons />
        <directionalLight intensity={0.5} />
      </Canvas>
      <Loading />
      {/*</Suspense>*/}
    </div>
  )
}
export default EditorScene

// <FrameUpdate />
// <Cube />
// <Hotspots />
