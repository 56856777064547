import {
  useMemo,
  useState,
  useEffect,
  useContext,
  cloneElement,
  Children,
  memo
} from 'react'
import { styled, useTheme, alpha, lighten } from '@mui/material/styles'

import { PropertyTreeContext } from '../PropertyTreeContext'
import PropertyTreeRow from '../Tree/PropertyTreeRow'
import PropertyTreeNodeLabel from './PropertyTreeNodeLabel'
import PropertyTreeNodeContent from './PropertyTreeNodeContent'
import PropertyTreeSplitter from '../Tree/PropertyTreeSplitter'

import useStore from '../../../../modules/store'
import { getTree } from '../../../../modules/propertyTreeStore'

const Root = styled(PropertyTreeRow, {
  shouldForwardProp: (prop) => prop !== 'level'
})(({ theme, height, disabled, level }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row',
  boxSizing: 'border-box',
  height: height,
  maxHeight: height,
  minHeight: height,
  width: '100%',
  backgroundColor: lighten(theme.palette.primary.light, level * 0.1),
  color: disabled
    ? theme.palette.text.disabled
    : theme.palette.primary.contrastText,
  padding: 1
}))

const RowContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'level'
})(({ theme, color }) => ({
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  backgroundColor: alpha(color, 0.1),
  border: `2px solid ${color}`,

  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    border: `2px solid ${theme.palette.secondary.main}`
  },
  display: 'flex',
  flexFlow: 'row'
}))

const ChildrenWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  minHeight: 0
}))

const PropertyTreeNode = ({
  id,
  index = 0,
  label,
  tooltip,
  tooltipDisabled,
  level = 0,
  disabled,
  warning,
  error,
  readOnly,
  onChange,
  input,
  children
}) => {
  const context = useContext(PropertyTreeContext)
  const [localLabelWidth, setLocalLabelWidth] = useState(0)
  const gTree = useMemo(getTree, [])
  const tree = useStore((state) => gTree(state, context.treeId))
  const expanded = tree ? tree.expanded.indexOf(id) >= 0 : false
  const theme = useTheme()
  const hijos = useMemo(() => {
    return Children.toArray(children).filter((child) => child)
  }, [children])

  console.log('node', id)
  useEffect(() => {
    if (localLabelWidth === 0 && tree && tree.labelWidth) {
      setLocalLabelWidth(tree.labelWidth)
    }
  }, [tree, localLabelWidth])

  const color = error
    ? theme.palette.error.light
    : warning
    ? theme.palette.warning.light
    : lighten(theme.palette.primary.light, level * 0.1)

  return tree ? (
    <>
      <Root height={context.nodeHeight} disabled={disabled} level={level}>
        <RowContentWrapper color={color} level={level}>
          <PropertyTreeNodeLabel
            id={id}
            hasChildren={hijos.length}
            label={label}
            level={level}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            hasInput={Boolean(input)}
          />
          {input && (
            <>
              <PropertyTreeSplitter />
              <PropertyTreeNodeContent input={input} />
            </>
          )}
        </RowContentWrapper>
      </Root>
      {expanded && (
        <ChildrenWrapper>
          {hijos &&
            hijos.map((node, idx) =>
              node
                ? cloneElement(node, {
                    key: idx,
                    level: level + 1,
                    id: `${id}:${node.props.id}`,
                    index: { idx }
                  })
                : null
            )}
        </ChildrenWrapper>
      )}
    </>
  ) : null
}
export default memo(PropertyTreeNode)
