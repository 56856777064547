import { useCallback, memo } from 'react'
import HotspotsSelectNode from '../../../Common/Nodes/HotspotsSelectNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setHotspotsAction } from '../../../../modules/sceneCubeStore'

const CEHotspotSelectNode = () => {
  const selected = useStore(selectedSelector)
  const { hotspots, disabledHotspotsIds } = useStore(
    useCallback((state) => {
      const { cube, disabledHotspotsIds } = getFirstSelected(state)
      return { hotspots: cube ? cube.hotspots : [], disabledHotspotsIds }
    }, [])
  )
  const setHotspots = useStore(setHotspotsAction)

  const handleChange = useCallback(
    (id, newValue) => {
      setHotspots(selected[0], newValue)
    },
    [selected, setHotspots]
  )
  return selected.length > 0 ? (
    <HotspotsSelectNode
      id="hotspots"
      label="Hotspots"
      allowEmpty
      multiple
      disabledItems={disabledHotspotsIds}
      value={hotspots}
      onChange={handleChange}
    />
  ) : null
}
export default memo(CEHotspotSelectNode)
