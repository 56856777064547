import { useRef, useEffect, useCallback } from 'react'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import useStore from '../../modules/store'
import {
  setPolarAngleAction,
  setAzimuthalAngleAction,
  enabledSelector,
  wantedPolarAngleSelector,
  wantedAzimuthSelector,
  cameraMovedAction,
  moveCameraSelector
} from '../../modules/cameraStore'

const CameraControls = () => {
  const ref = useRef()
  const { camera, gl } = useThree()
  const setPolarAngle = useStore(setPolarAngleAction)
  const setAzimuthalAngle = useStore(setAzimuthalAngleAction)
  const enabled = useStore(enabledSelector)
  const wantedPolarAngle = useStore(wantedPolarAngleSelector)
  const wantedAzimuth = useStore(wantedAzimuthSelector)
  const cameraMoved = useStore(cameraMovedAction)
  const moveCamera = useStore(moveCameraSelector)

  useEffect(() => {
    if (ref.current && moveCamera) {
      ref.current.setAzimuthalAngle(wantedAzimuth)
      ref.current.setPolarAngle(wantedPolarAngle)
      cameraMoved()
    }
  }, [wantedPolarAngle, wantedAzimuth, cameraMoved, moveCamera])

  const update = useCallback(() => {
    setAzimuthalAngle(ref.current.getAzimuthalAngle())
    setPolarAngle(ref.current.getPolarAngle())
  }, [setAzimuthalAngle, setPolarAngle])

  useEffect(() => {
    const controls = ref.current
    controls.addEventListener('change', update)
    return () => controls.removeEventListener('change', update)
  }, [camera, update])

  useEffect(() => {
    if (ref.current) {
      console.log('controls enabled: ', enabled)
      ref.current.enabled = enabled
      ref.current.update()
    }
  }, [enabled])

  return (
    <OrbitControls
      ref={ref}
      args={[camera, gl.domElement]}
      enablePan={false}
      enableZoom={false}
      enableRotate={true}
      rotateSpeed={-0.2}
      makeDefault
    />
  )
}
export default CameraControls
