import { Raycaster, Vector2, Vector3 } from 'three'
import { threeBoundsSelector } from './mainStore'
import { getHotspot } from './sceneDescriptionStore'
import {
  setPosition3DAction,
  setPosition2DAction
} from './sceneHotspotStore'
import { setPosition2DFromScreenCoords } from './positioning2D'

const vectorGet3DPoint = new Vector2()
const v = new Vector3()
const worldDirection = new Vector3()
const raycaster = new Raycaster()

export const transformStore = (set, get) => ({
  transform: {
    seed: 0,
    hotspots: [], //[{id,hotspotId,position,inc,twoD}]
    addHotspot: (hotspotId, position, inc, twoD) => {
      if (!hotspotId || (!position && !inc)) {
        return
      }
      set((st) => ({
        transform: {
          ...st.transform,
          hotspots: [
            ...hotspotsSelector(get()).filter((id) => hotspotId !== id),
            { id: seedSelector(get()), hotspotId, position, inc, twoD }
          ],
          seed: seedSelector(get()) + 1
        }
      }))
    },
    updateHotspots: (sphere, camera) => {
      const hotspotsTransformData = hotspotsSelector(get())
      const bounds = threeBoundsSelector(get())
      hotspotsTransformData.forEach((data) => {
        const hotspot = getHotspot(get())(data.hotspotId)

        if (hotspot) {
          if (data.twoD) {
            const screen = getScreenPosition(camera, bounds, data.position)
            setPosition2DAction(get())(
              data.hotspotId,
              setPosition2DFromScreenCoords(
                hotspot.position2D,
                bounds,
                screen[0],
                screen[1]
              )
            )
          } else {
            let position2D = [0, 0]
            if (data.position) {
              position2D = data.position
            } else {
              position2D = getScreenPosition(camera, bounds, hotspot.position3D)
              position2D[0] += data.inc[0]
              position2D[1] += data.inc[1]
            }

            if (sphere) {
              setPosition3DAction(get())(
                data.hotspotId,
                get3DPosition([sphere], camera, bounds, position2D)
              )
            }
          }
        }
      })
      set((st) => ({ transform: { ...st.transform, hotspots: [] } }))
    }
  }
})
export default transformStore

export const get3DPosition = (objects, camera, size, screenPosition) => {
  // const sphere = objects0.find(
  //   (o) => o.type === 'Mesh' && o.geometry.type === 'SphereGeometry'
  // )
  // console.log(objects0, sphere)
  // if (!sphere) {
  //   return
  // }
  // const objects = [sphere]
  vectorGet3DPoint.x = (screenPosition[0] / size.width) * 2 - 1
  vectorGet3DPoint.y = -(screenPosition[1] / size.height) * 2 + 1
  raycaster.setFromCamera(vectorGet3DPoint, camera)
  const intersects = raycaster.intersectObjects(objects)
  if (intersects.length) {
    return [intersects[0].point.x, intersects[0].point.y, intersects[0].point.z]
  }
  return null
}

export const getScreenPosition = (camera, size, position3D) => {
  camera.getWorldDirection(worldDirection)
  v.fromArray(position3D)
  v.project(camera)
  return [((v.x + 1) * size.width) / 2, (-(v.y - 1) * size.height) / 2]
}

export const addHotspotAction = (state) => state.transform.addHotspot
export const updateHotspotsAction = (state) => state.transform.updateHotspots

export const hotspotsSelector = (state) => state.transform.hotspots
const seedSelector = (state) => state.transform.seed
