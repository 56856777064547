import { useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import {
  getCubeMemo,
  cubesSelector
} from '../../../modules/sceneDescriptionStore'
import {
  currentCubeSelector,
  setCurrentCubeAction
} from '../../../modules/sceneDrawDataStore'

import useStore from '../../../modules/store'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: 12,
  left: 12,
  zIndex: 100,
  width: 100,
  backgroundColor: 'grey'
}))

const CubeItemRoot = styled('button')(({ theme, selected }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: selected
    ? theme.palette.secondary.main
    : theme.palette.primary.main
}))

const CubeItem = ({ id }) => {
  const currentCube = useStore(currentCubeSelector)
  const gCube = useMemo(getCubeMemo, [])
  const cube = useStore((state) => gCube(state, id))
  const setCurrentCube = useStore(setCurrentCubeAction)

  const handleClick = useCallback(() => {
    setCurrentCube(id)
  }, [setCurrentCube, id])

  return cube ? (
    <CubeItemRoot selected={currentCube === id} onClick={handleClick}>
      {cube.name.es}
    </CubeItemRoot>
  ) : null
}

const CubesMenu = () => {
  const cubes = useStore(cubesSelector)

  return cubes ? (
    <Root>
      {cubes.map((cube) => (
        <CubeItem key={cube.id} id={cube.id} />
      ))}
    </Root>
  ) : null
}

export default CubesMenu
