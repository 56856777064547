export const pointerEventsStore = (set, get) => ({
  pointerEvents: {
    dragging: false,
    setDragging: (dragging) =>
      set((prev) => ({ pointerEvents: { ...prev.pointerEvents, dragging } }))
  }
})
export default pointerEventsStore

export const setDraggingAction = (state) => state.pointerEvents.setDragging

export const draggingSelector = (state) => state.pointerEvents.dragging
