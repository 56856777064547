import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'

import HotspotContainer from '../../Hotspots/HotspotContainer'
import HotspotAnchor from './HotspotAnchor'

import useStore from '../../../modules/store'
import { incPositionAction } from '../../../modules/sceneHotspotStore'
import {
  setCurrentIdAction,
  hoverIdSelector,
  setHoverIdAction,
  currentIdSelector
} from '../../../modules/sceneHotspotListStore'

import { getCurrentOption } from '../../../modules/lateralMenuStore'
import { MENU_OPTIONS, LATERAL_PANELS } from '../../../settings/menuSettings'
import { setCurrentOptionPanelAction } from '../../../modules/lateralMenuStore'
import { getCurrentCubeHotspotMemo } from '../../../modules/sceneDrawDataStore'

import { HALF_BORDER_WIDTH } from '../../../modules/positioning2D'

const Root = styled(HotspotContainer, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hover'
})(({ theme, selected, hover }) => ({
  touchAction: 'none',
  cursor: 'pointer',
  // boxSizing: 'border-box',
  border: `${2 * HALF_BORDER_WIDTH}px solid ${
    hover
      ? 'rgb(68, 152, 179)'
      : selected
      ? 'rgb(106, 107, 246)'
      : 'transparent'
  }`,

  '&:hover': { border: `${2 * HALF_BORDER_WIDTH}px solid rgb(68, 152, 179)` }
}))

const HotspotContainerEditor = ({ id }) => {
  const gHotspot = useMemo(getCurrentCubeHotspotMemo, [])
  const hotspot = useStore((state) => gHotspot(state, id))

  const incPosition = useStore(incPositionAction)
  const currentOption = useStore(getCurrentOption)
  const currentHotspotId = useStore(currentIdSelector)
  const setCurrentHotspotId = useStore(setCurrentIdAction)
  const hoverHotspotId = useStore(hoverIdSelector)
  const setHoverHotspotId = useStore(setHoverIdAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)
  const selected = id === currentHotspotId
  const hover = id === hoverHotspotId

  const bind = useGesture(
    {
      onHover: ({ hovering }) => {
        setHoverHotspotId(hovering ? id : '')
      },
      onDrag: ({ xy, delta, tap, event }) => {
        if (tap) {
          setCurrentHotspotId(id)
          setCurrentOptionPanel(LATERAL_PANELS.HOTSPOT_EDITOR)
          //Bloque el evento para que no se cambie de cubo
          event.stopPropagation()
        } else {
          incPosition(id, delta)
        }
      },
      onDragEnd: () => {}
    },
    { filterTaps: true }
  )
  if (hotspot) {
    if (
      currentOption &&
      ((currentOption.id === MENU_OPTIONS.HOTSPOTS &&
        currentOption.lateral === LATERAL_PANELS.HOTSPOTS_LIST) ||
        (currentOption.lateral === LATERAL_PANELS.HOTSPOT_EDITOR && selected))
    ) {
      return (
        <Root
          {...bind()}
          id={id}
          twoD={hotspot.position2D}
          selected={selected}
          hover={hover}
          show
        >
          {(id === currentHotspotId || id === hoverHotspotId) && (
            <HotspotAnchor
              anchorId={hotspot.anchor}
              referenceId={hotspot.position2D?.reference}
            />
          )}
        </Root>
      )
    }
    return <HotspotContainer id={id} />
  }
  return null
}
export default HotspotContainerEditor
