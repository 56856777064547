// import { createSelector } from 'reselect'
import { cloneObject } from '../utils/utils'
import { getHotspotCubes, getHotspot } from './sceneDescriptionStore'
import { currentIdSelector } from './sceneHotspotListStore'
import { setHotspotAction, setCubesAction } from './sceneHotspotStore'
import { saveAction as savePolygonAction } from './polygonEditorStore'
export const hotspotEditorStore = (set, get) => ({
  hotspotEditor: {
    hotspotBackup: null,
    cubesBackup: [],

    init: () => {
      const hotspot = getHotspot(get())(currentIdSelector(get()))
      set((st) => ({
        hotspotEditor: {
          ...st.hotspotEditor,
          hotspotBackup: hotspot ? cloneObject(hotspot) : null,
          cubesBackup: hotspot
            ? [...getHotspotCubes(get())(hotspot.id).cubesIds]
            : []
        }
      }))
    },
    restore: () => {
      const hs = hotspotBackupSelector(get())
      if (hs) {
        setHotspotAction(get())(cloneObject(hs))
        setCubesAction(get())(hs.id, cubesBackupSelector(get()))
      }
    },
    save: () => {
      console.log('save')
      savePolygonAction(get())()
    }
  }
})
export default hotspotEditorStore

export const initAction = (state) => state.hotspotEditor.init
export const restoreAction = (state) => state.hotspotEditor.restore
export const saveAction = (state) => state.hotspotEditor.save

const hotspotBackupSelector = (state) => state.hotspotEditor.hotspotBackup
const cubesBackupSelector = (state) => state.hotspotEditor.cubesBackup
