export const SCHEMA_URIS = {
  MULTILANGUAGE_TEXT: 'http://myserver/multilanguage_text',
  POINT2D: 'http://myserver/point2D',
  POINT3D: 'http://myserver/point3D',
  POINT_OF_VIEW: 'http://myserver/pov',
  STRING_LIST: 'http://myserver/string_list',
  UNIQUE_STRING_LIST: 'http://myserver/unique_string_list',
  NOT_EMPTY_STRING_LIST: 'http://myserver/no_empty_string_list',
  NOT_EMPTY_UNIQUE_STRING_LIST: 'http://myserver/no_empty_unique_string_list',
  SIZE_IN_PIXELS: 'http://myserver/size_in_pixels',
  LANGUAGES_LIST: 'http://myserver/languages',
  CUBE_IMAGES: 'http://myserver/cube_images',
  CUBE: 'http://myserver/cube',
  FINISH: 'http://myserver/finish',
  OPTION: 'http://myserver/option',
  SCENE_DEFAULT_CONFIGURATION: 'http://myserver/scene_default_cfg',
  SCENE: 'http://myserver/scene',
  POSITION2D_PROPERTY: 'http://myserver/position2d_property',
  POSITION2D: 'http://myserver/position2d',
  HOTSPOT: 'http://myserver/hotspot',
  HOTSPOT_EVENT: 'http://myserver/hotspot_event',
  HOTSPOT_POLYGON: 'http://myserver/hotspot_polygon',
  HOTSPOT_IMAGE: 'http://myserver/hotspot_image',
  HOTSPOT_BLINKY: 'http://myserver/hotspot_blinky',
  POLYGON_DATA: 'http://myserver/polygon_data',
  POLYGON_EMPTY: 'http://myserver/hotspot_polygon_empty_fill',
  POLYGON_COLOR: 'http://myserver/hotspot_polygon_color_fill',
  POLYGON_IMAGE: 'http://myserver/hotspot_polygon_image_fill',
  POLYGON_VIDEO: 'http://myserver/hotspot_polygon_video_fill',
  POLYGON_FILL: 'http://myserver/hotspot_polygon_fill',
  POLYGON_BORDER: 'http://myserver/hotspot_polygon_border',
  IMAGE_MAPPER_FINISH: 'http://myserver/finish',
  IMAGE_MAPPER_OPTION: 'http://myserver/option',
  IMAGE_MAPPER_PAIR_FINISH_OPTION: 'http://myserver/pair',
  IMAGE_MAPPER_AUTO_FILL: 'http://myserver/auto',
  IMAGE_MAPPER_LINK: 'http://myserver/link',
  IMAGE_MAPPER_COMBINATION: 'http://myserver/combination',
  IMAGE_MAPPER_EQUIRECTANGULAR_IMAGES:
    'http://myserver/equirectangularImageMapper',
  HOTSPOT_STYLE: 'http://myserver/hotspot_style',
  HOTSPOT_STYLES: 'http://myserver/hotspot_styles'
}

export const multilanguageTextSchema = {
  id: SCHEMA_URIS.MULTILANGUAGE_TEXT,
  type: 'object',
  properties: {
    es: { type: 'string', minLength: 1 }
  },
  required: ['es'],
  additionalProperties: { type: 'string' },
  default: { es: 'Texto', en: 'Text' }
}

export const point3DSchema = {
  id: SCHEMA_URIS.POINT3D,
  type: 'array',
  items: { type: 'number' },
  maxItems: 3,
  minItems: 3,
  default: [0, 0, 0]
}

export const point2DSchema = {
  id: SCHEMA_URIS.POINT2D,
  type: 'array',
  items: { type: 'number' },
  maxItems: 2,
  minItems: 2,
  default: [0, 0]
}

export const povSchema = {
  id: SCHEMA_URIS.POINT_OF_VIEW,
  type: 'object',
  properties: {
    fov: { type: 'number', minimum: 10, maximum: 100 },
    polar: { type: 'number', minimum: 0, maximum: 180 },
    azimuth: { type: 'number', minimum: -180, maximum: 180 }
  },
  required: ['fov', 'polar', 'azimuth'],
  additionalProperties: false,
  default: { fov: 50, polar: 0, azimuth: 0 }
}

export const stringListSchema = {
  id: SCHEMA_URIS.STRING_LIST,
  type: 'array',
  items: { type: 'string' },
  default: []
}

export const uniqueStringListSchema = {
  id: SCHEMA_URIS.UNIQUE_STRING_LIST,
  type: 'array',
  items: { type: 'string' },
  uniqueItems: true,
  default: []
}

export const noEmptyStringListSchema = {
  id: SCHEMA_URIS.NOT_EMPTY_STRING_LIST,
  type: 'array',
  items: { type: 'string' },
  minItems: 1,
  default: []
}

export const noEmptyUniqueStringListSchema = {
  id: SCHEMA_URIS.NOT_EMPTY_UNIQUE_STRING_LIST,
  type: 'array',
  items: { type: 'string' },
  minItems: 1,
  uniqueItems: true,
  default: []
}

export const sizeInPixelsSchema = {
  id: SCHEMA_URIS.SIZE_IN_PIXELS,
  type: 'object',
  properties: {
    width: { type: 'integer', minimum: 0 },
    height: { type: 'integer', minimum: 0 }
  },
  required: ['width', 'height'],
  additionalProperties: false,
  default: { width: 80, height: 80 }
}
