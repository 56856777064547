import { useEffect } from 'react'
import { styled } from '@mui/material/styles'

import Item from './Item'
import useStore from '../../../../modules/store'
import {
  getClassifiedCombinations,
  recreateAction,
  setCurrentCombinationAction,
  currentCombinationSelector
} from '../../../../modules/equirectangularImagesMapperStore'

const Root = styled('div')(({ theme, animate }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  width: '100%',
  overflow: 'auto',

  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'start',
  alignItems: 'start'
}))

const Group = styled('div')(({ theme, animate }) => ({
  // height: '100%',
  width: '100%',

  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'start'
}))

const Title = styled('div')(({ theme }) => ({
  fontSize: 24,
  boxSizing: 'border-box',
  padding: 8,
  width: '100%',
  textAlign: 'center'
}))

const List = () => {
  const combinations = useStore(getClassifiedCombinations)
  const currentCombination = useStore(currentCombinationSelector)
  const setCurrentCombination = useStore(setCurrentCombinationAction)
  const recreate = useStore(recreateAction)

  useEffect(() => {
    recreate()
    setCurrentCombination()
  }, [recreate, setCurrentCombination])

  const getName = (group) => {
    return group.finishs
      .map((finish) => {
        return finish.name
      })
      .join(' - ')
  }

  return currentCombination ? null : (
    <Root>
      {combinations.map((group) => (
        <>
          <Title>{getName(group)}</Title>
          <Group key={group.id}>
            {group.items.map((c) => (
              <Item key={c.id} id={c.id} />
            ))}
          </Group>
        </>
      ))}
      }
    </Root>
  )
}
export default List
//
