// import { useCallback } from 'react'
// import { useDispatch } from 'react-redux'

import { SelectAllIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const SelectAllHotspotsButton = (props) => {
  return (
    <STButton
      tooltip={'Seleccionar todo'}
      tooltipEnabled
      {...props}
      icon={<SelectAllIcon />}
    />
  )
}
export default SelectAllHotspotsButton
