import { useCallback, memo } from 'react'

import CubeSelectNode from '../../../Common/Nodes/CubeSelectNode'

import { useStore } from '../../../../modules/store'
import { getCubes, setCubesAction } from '../../../../modules/finishEditorStore'

const FECubesSelectNode = () => {
  const { cubesIds, disabledCubesIds } = useStore(getCubes)
  const setCubes = useStore(setCubesAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setCubes(value)
    },
    [setCubes]
  )

  return (
    <CubeSelectNode
      id={'cubes'}
      label="Cubos"
      multiple
      value={cubesIds}
      disabledItems={disabledCubesIds}
      onChange={handleChange}
    />
  )
}
export default memo(FECubesSelectNode)
