import { useCallback } from 'react'

import HotspotTypeSelectNode from '../../../Common/Nodes/HotspotTypeSelectNode'

import { useStore } from '../../../../modules/store'
import {
  setTypeAction,
  setDataAction
} from '../../../../modules/sceneHotspotStore'
import {
  currentIdSelector,
  getCurrentHotspot
} from '../../../../modules/sceneHotspotListStore'

const HETypeSelectNode = () => {
  const currentId = useStore(currentIdSelector)
  const hs = useStore(getCurrentHotspot)
  const setType = useStore(setTypeAction)
  const setData = useStore(setDataAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setType(currentId, value)
    },
    [currentId, setType]
  )

  const handlePropertyChanged = useCallback(
    (propertyName, value) => {
      console.log(propertyName, value)
      setData(currentId, { ...hs.data, [propertyName]: value })
    },
    [setData, hs.data, currentId]
  )

  return hs ? (
    <HotspotTypeSelectNode
      id={'type'}
      hotspotId={currentId}
      label="Tipo"
      value={hs.type}
      data={hs.data}
      onChange={handleChange}
      onPropertyChanged={handlePropertyChanged}
    />
  ) : null
}
export default HETypeSelectNode
