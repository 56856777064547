import { createSelector } from 'reselect'
import { sceneFile, imageMapperFile, stylesFile } from '../schemas/files'
import { findById } from '../utils/utils'

export const MODEL_ID = {
  SCENE: 'scene',
  STYLES: 'styles',
  MAPPER: 'mapper'
}

const textEditorModelsStore = (set, get) => ({
  textEditor: {
    currentModel: '',
    models: [
      {
        id: MODEL_ID.SCENE,
        name: 'scene.json',
        language: 'json',
        readOnly: false,
        schemas: sceneFile,
        value: '',
        valid: false,
        update: false
      },
      {
        id: MODEL_ID.STYLES,
        name: 'styles.json',
        language: 'json',
        readOnly: false,
        schemas: stylesFile,
        value: '',
        valid: false,
        update: false
      },
      {
        id: MODEL_ID.MAPPER,
        name: 'mapper.json',
        language: 'json',
        readOnly: true,
        schemas: imageMapperFile,
        value: '',
        valid: false,
        update: false
      }
    ],
    setCurrentModel: (id) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          currentModel: id
        }
      }))
    },
    updateValue: (id, value) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          models: stateUpdateModelProperty(
            stateUpdateModelProperty(modelsSelector(get()), id, 'value', value),
            id,
            'update',
            true
          )
        }
      }))
    },
    updated: (id) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          models: stateUpdateModelProperty(
            modelsSelector(get()),
            id,
            'update',
            false
          )
        }
      }))
    },
    setValue: (id, value) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          models: stateUpdateModelProperty(
            modelsSelector(get()),
            id,
            'value',
            value
          )
        }
      }))
    },

    setSchemas: (id, schemas) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          models: stateUpdateModelProperty(
            modelsSelector(get()),
            id,
            'schemas',
            schemas
          )
        }
      }))
    },
    setModelOk: (id, ok) => {
      set((st) => ({
        textEditor: {
          ...st.textEditor,
          models: stateUpdateModelProperty(
            modelsSelector(get()),
            id,
            'valid',
            ok
          )
        }
      }))
    }
  }
})
export default textEditorModelsStore

const stateUpdateModelProperty = (models, modelId, propertyName, value) => {
  return models.map((model) => {
    if (model.id === modelId) {
      const newModel = { ...model }
      newModel[propertyName] = value
      return newModel
    }
    return model
  })
}

export const setCurrentModelAction = (state) => state.textEditor.setCurrentModel
export const setSchemasAction = (state) => state.textEditor.setSchemas
export const setModelOkAction = (state) => state.textEditor.setModelOk
export const updatedAction = (state) => state.textEditor.updated
export const setValueAction = (state) => state.textEditor.setValue
export const updateValueAction = (state) => state.textEditor.updateValue

export const modelsSelector = (state) => state.textEditor.models
export const currentModelSelector = (state) => state.textEditor.currentModel

export const getModelMemo = () =>
  createSelector([modelsSelector, (_, id) => id], (models, id) => {
    return findById(models, id)
  })

export const getCurrentModel = createSelector(
  [modelsSelector, currentModelSelector],
  (models, currentModel) => {
    return findById(models, currentModel)
  }
)
