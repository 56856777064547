import { styled } from '@mui/material/styles'

import { getAnchor, getReference } from '../../../modules/positioning2D'

const ANCHOR_WIDTH = 24
const ANCHOR_RADIUS = 4
const ARROW_WIDTH = 8

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning'
})(({ theme, positioning }) => ({
  color: 'blue',
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  width: ANCHOR_WIDTH,
  height: ANCHOR_WIDTH,
  border: `2px solid currentColor`,
  borderRadius: ANCHOR_RADIUS,

  position: 'absolute',
  top: positioning.top,
  left: positioning.left,
  bottom: positioning.bottom,
  right: positioning.right,
  transform: positioning.transform
}))
const Top = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning'
})(({ theme, positioning }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  border: `${ARROW_WIDTH}px solid`,
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'currentColor',
  transform: 'translate( -50%, -100%)',
  top: '0%',
  left: '50%'
}))
const Left = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning'
})(({ theme, positioning }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  border: `${ARROW_WIDTH}px solid`,
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'currentColor',
  borderBottomColor: 'transparent',
  transform: 'translate( -100%, -50%)',
  top: '50%',
  left: '0%'
}))
const Right = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning'
})(({ theme, positioning }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  border: `${ARROW_WIDTH}px solid`,
  borderTopColor: 'transparent',
  borderLeftColor: 'currentColor',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  transform: 'translate( 100%, -50%)',
  top: '50%',
  right: '0%'
}))
const Bottom = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning'
})(({ theme, positioning }) => ({
  position: 'absolute',
  width: 0,
  height: 0,
  border: `${ARROW_WIDTH}px solid`,
  borderTopColor: 'currentColor',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  transform: 'translate( -50%, 100%)',
  bottom: '0%',
  left: '50%'
}))

const HotspotAnchor = ({ anchorId, referenceId }) => {
  const anchor = getAnchor(anchorId)
  const reference = referenceId ? getReference(referenceId) : null
  return (
    <Root positioning={anchor.anchorMark}>
      {reference?.anchorMark.right && <Right />}
      {reference?.anchorMark.top && <Top />}
      {reference?.anchorMark.left && <Left />}
      {reference?.anchorMark.bottom && <Bottom />}
    </Root>
  )
}
export default HotspotAnchor
