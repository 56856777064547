import { useCallback } from 'react'

import PropertyTreeEmptyNode from '../../../Common/PropertyTree/Nodes/PropertyTreeEmptyNode'
import ShowHotspotsActionNode from '../../../Common/Nodes/Action/ShowHotspotsActionNode'
import HideHotspotsActionNode from '../../../Common/Nodes/Action/HideHotspotsActionNode'
import GotoActionNode from '../../../Common/Nodes/Action/GotoActionNode'
import LookAtActionNode from '../../../Common/Nodes/Action/LookAtActionNode'
import SelectOptionsActionNode from '../../../Common/Nodes/Action/SelectOptionsActionNode'

import { useStore } from '../../../../modules/store'
import { setEventAction } from '../../../../modules/sceneHotspotStore'
import { getCurrentHotspot } from '../../../../modules/sceneHotspotListStore'
import { povSchema } from '../../../../schemas/schemas'
import { getDefaultObjectFromSchema } from '../../../../utils/utils'

const HEEventNode = ({ id, eventName, cubeId, value, ...props }) => {
  const setEvent = useStore(setEventAction)

  const hs = useStore(getCurrentHotspot)

  const handleChange = useCallback(
    (nodeId, newValue) => {
      switch (nodeId) {
        case `${id}:showHotspots`:
          setEvent(hs.id, eventName, { ...value, showHotspots: newValue })
          break
        case `${id}:hideHotspots`:
          setEvent(hs.id, eventName, { ...value, hideHotspots: newValue })
          break
        case `${id}:goto`:
          setEvent(hs.id, eventName, { ...value, goto: { id: newValue } })
          break
        case `${id}:lookAt`:
          setEvent(hs.id, eventName, { ...value, lookAt: newValue })
          break
        case `${id}:selectOptions`:
          setEvent(hs.id, eventName, { ...value, selectOptions: newValue })
          break

        default:
      }
    },
    [hs, eventName, setEvent, id, value]
  )
  const handleActivateLookAt = useCallback(
    (nodeId, newValue) => {
      console.log(nodeId, newValue)
      if (!newValue) {
        const newEvent = { ...value }
        delete newEvent.lookAt
        setEvent(hs.id, eventName, newEvent)
      } else {
        setEvent(hs.id, eventName, {
          ...value,
          lookAt: getDefaultObjectFromSchema(povSchema)
        })
      }
    },
    [eventName, value, setEvent, hs]
  )

  return hs ? (
    <PropertyTreeEmptyNode id={id} {...props}>
      <ShowHotspotsActionNode
        id={'showHotspots'}
        exclude={[hs.id]}
        cubeId={cubeId}
        value={value.showHotspots ? value.showHotspots : []}
        onChange={handleChange}
      />
      <HideHotspotsActionNode
        id={'hideHotspots'}
        exclude={[hs.id]}
        cubeId={cubeId}
        value={value.hideHotspots ? value.hideHotspots : []}
        onChange={handleChange}
      />
      <GotoActionNode
        id={'goto'}
        value={value.goto.id}
        onChange={handleChange}
      />
      <LookAtActionNode
        id={'lookAt'}
        value={value.lookAt}
        onChange={handleChange}
        onActivate={handleActivateLookAt}
      />
      <SelectOptionsActionNode
        id={'selectOptions'}
        value={value.selectOptions}
        onChange={handleChange}
      />
    </PropertyTreeEmptyNode>
  ) : null
}
export default HEEventNode
