import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'

const Root = styled('div')(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  width: '100%',
  cursor: 'row-resize',
  color: theme.palette.primary.main,
  '&:hover': { color: theme.palette.secondary.main },
  touchAction: 'none'
}))

const SplitterBar = styled('div')(({ theme }) => ({
  height: 4,
  minHeight: 4,
  width: '100%',
  backgroundColor: 'currentColor'
}))

const VericalSplitter = ({ top, setTop, height }) => {
  const [initialTop, setInitialTop] = useState(0)

  const bind = useGesture({
    onDrag: ({ movement, first }) => {
      if (first) {
        setInitialTop(top)
      }
      setTop(
        Math.min(
          height - 160,
          Math.max(160, first ? top : initialTop + movement[1])
        )
      )
    }
  })

  return (
    <Root {...bind()}>
      <SplitterBar />
    </Root>
  )
}
export default VericalSplitter
