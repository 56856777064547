import { SCHEMA_URIS } from './schemas'
export const finishSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_FINISH,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^a[0-9]+$' },
    name: { type: 'string', minLength: 1 }
  },
  required: ['id', 'name'],
  additionalProperties: false,
  default: { id: '', name: '' }
}

export const optionSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_OPTION,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^op[0-9]+$' },
    name: { type: 'string', minLength: 1 }
  },
  required: ['id', 'name'],
  additionalProperties: false,
  default: { id: '', name: '' }
}

export const pairSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_PAIR_FINISH_OPTION,
  type: 'object',
  properties: {
    finish: { $ref: SCHEMA_URIS.IMAGE_MAPPER_FINISH },
    option: { $ref: SCHEMA_URIS.IMAGE_MAPPER_OPTION }
  },
  required: ['finish', 'option'],
  additionalProperties: false,
  default: { finish: finishSchema.default, option: finishSchema.default }
}

export const autoSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_AUTO_FILL,
  type: 'object',
  properties: {
    folder: { type: 'string' },
    prefix: { type: 'string' },
    suffix: { type: 'string' },
    numberLength: { type: 'integer', minimum: 1 }
  },
  required: ['folder', 'prefix', 'suffix', 'numberLength'],
  additionalProperties: false,
  default: { folder: '', prefix: '', suffix: '', numberLength: 4 }
}

export const linkSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_LINK,
  type: 'object',
  properties: {
    cubeId: { type: 'string', minLength: 1, pattern: '^p[0-9]+$' },
    imageSrc: { type: 'string', minLength: 1 }
  },
  required: ['cubeId', 'imageSrc'],
  additionalProperties: false,
  default: { cubeId: '', imageSrc: '' }
}

export const combinationSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_COMBINATION,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1 },
    pairs: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.IMAGE_MAPPER_PAIR_FINISH_OPTION },
      uniqueItems: true
    },
    auto: { $ref: SCHEMA_URIS.IMAGE_MAPPER_AUTO_FILL },
    links: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.IMAGE_MAPPER_LINK },
      uniqueItems: true
    }
  },
  required: ['id', 'pairs', 'links', 'auto'],
  additionalProperties: false,
  default: {
    id: '',
    pairs: [pairSchema.default],
    auto: autoSchema.default,
    links: [linkSchema.default]
  }
}

export const equirectangularImagesMapperSchema = {
  id: SCHEMA_URIS.IMAGE_MAPPER_EQUIRECTANGULAR_IMAGES,
  type: 'object',
  properties: {
    scene: { type: 'string', minLength: 1, pattern: '^s[0-9]+$' },
    combinations: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.IMAGE_MAPPER_COMBINATION },
      uniqueItems: true
    }
  },
  required: ['scene', 'combinations'],
  additionalProperties: false,
  default: {
    scene: '',
    combinations: [combinationSchema.default]
  }
}

export const schemas = [
  { uri: finishSchema.id, schema: finishSchema },
  { uri: finishSchema.id, schema: optionSchema },
  { uri: pairSchema.id, schema: pairSchema },
  { uri: autoSchema.id, schema: autoSchema },
  { uri: linkSchema.id, schema: linkSchema },
  { uri: combinationSchema.id, schema: combinationSchema },
  {
    fileMatch: ['*'],
    uri: equirectangularImagesMapperSchema.id,
    schema: equirectangularImagesMapperSchema
  }
]
