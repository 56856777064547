import { useEffect, useState, useCallback } from 'react'

import BaseTextField from './BaseTextField'

const IntegerField = ({
  value,
  min,
  max,
  validateValue,
  onChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(0)
  const [localMin, setLocalMin] = useState(0)
  const [localMax, setLocalMax] = useState(0)
  const [editing, setEditing] = useState(false)

  const getIntValue = useCallback(
    (v) => {
      let intValue = parseInt(v)
      if (isNaN(intValue)) {
        intValue = parseInt(value)
      }
      intValue = Math.max(localMin, intValue)
      intValue = Math.min(localMax, intValue)
      return intValue
    },
    [localMin, localMax, value]
  )

  useEffect(() => {
    setLocalMin(isNaN(min) || min === null ? Number.MIN_SAFE_INTEGER : min)
    setLocalMax(isNaN(max) || max === null ? Number.MAX_SAFE_INTEGER : max)
    setLocalValue(getIntValue(value))
  }, [value, min, max, getIntValue, localValue])

  const handleChange = useCallback(
    (id, value) => {
      setLocalValue(value)
      onChange && onChange(id, value)
    },
    [onChange]
  )

  const handleEditingChange = useCallback((editing) => {
    setEditing(editing)
  }, [])

  return (
    <>
      {editing ? (
        <BaseTextField
          type={'number'}
          value={localValue}
          inputProps={{
            min: 0,
            sx: {
              textAlign: 'right',
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
              },
              appearance: 'textfield'
            }
          }}
          validateValue={getIntValue}
          onChange={handleChange}
          onEditingChange={handleEditingChange}
          {...props}
        />
      ) : (
        <BaseTextField
          type={'text'}
          value={Intl.NumberFormat().format(localValue)}
          inputProps={{ min: 0, style: { textAlign: 'right' } }}
          readOnly
          onChange={onChange}
          onEditingChange={handleEditingChange}
          {...props}
        />
      )}
    </>
  )
}

export default IntegerField
//
/*
'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
  WebkitAppearance: 'none',
  margin: 0
},
*/
