import create from 'zustand'
import { devtools } from 'zustand/middleware'

import mainStore from './mainStore'
import texturesStore from './texturesStore'
import cameraStore from './cameraStore'
import textEditorModelsStore from './textEditorModelsStore'
import projectFolderStore from './projectFolderStore'
import equirectangularImagesMapperStore from './equirectangularImagesMapperStore'
import lateralMenuStore from './lateralMenuStore'
import propertyTreeStore from './propertyTreeStore'
import sceneDescriptionStore from './sceneDescriptionStore'
import sceneDrawDataStore from './sceneDrawDataStore'
import sceneCubeStore from './sceneCubeStore'
import sceneCubeListStore from './sceneCubeListStore'
import sceneFinishsStore from './sceneFinishsStore'
import finishEditorStore from './finishEditorStore'
import sceneEditorStore from './sceneEditorStore'
import hotspotTypesStore from './hotspotTypesStore'
import transformStore from './transformStore'
import sceneHotspotStore from './sceneHotspotStore'
import sceneHotspotListStore from './sceneHotspotListStore'
import hotspotEditorStore from './hotspotEditorStore'
import polygonEditorStore from './polygonEditorStore'
import cubeEditorStore from './cubeEditorStore'
import pointerEventsStore from './pointerEventsStore'
import sceneTextEditorStore from './sceneTextEditorStore'
import openSaveStore from './openSaveStore'

export const useStore = create(
  devtools(
    (set, get) => ({
      ...openSaveStore(set, get),
      ...mainStore(set, get),
      ...lateralMenuStore(set, get),
      ...propertyTreeStore(set, get),
      ...textEditorModelsStore(set, get),

      ...sceneDescriptionStore(set, get),
      ...sceneDrawDataStore(set, get),
      ...pointerEventsStore(set, get),
      // ...eventEditorStore(set, get),

      ...cameraStore(set, get),

      ...projectFolderStore(set, get),
      ...equirectangularImagesMapperStore(set, get),
      ...texturesStore(set, get),
      ...sceneEditorStore(set, get),
      ...sceneCubeStore(set, get),
      ...sceneCubeListStore(set, get),
      ...sceneFinishsStore(set, get),
      ...cubeEditorStore(set, get),
      ...finishEditorStore(set, get),
      // ...sceneEditorPolygonsStore(set, get),

      ...transformStore(set, get),
      ...hotspotTypesStore(set, get),
      ...sceneHotspotStore(set, get),
      ...sceneHotspotListStore(set, get),
      ...hotspotEditorStore(set, get),
      ...polygonEditorStore(set, get),
      ...sceneTextEditorStore(set, get)
    }),
    'Store'
  )
)
export default useStore
