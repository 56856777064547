import { useCallback, useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'

import BaseTextField from '../../../Common/Inputs/BaseTextField'
import useStore from '../../../../modules/store'

import { getEquirectangularFolderMemo } from '../../../../modules/projectFolderStore'
import { setImageIdAction } from '../../../../modules/equirectangularImagesMapperStore'

const ImageSelect = ({
  combId,
  cubeId,
  folderId,
  imageId,
  imageSrc,
  ...props
}) => {
  const gEquirectangularFolderMemo = useMemo(getEquirectangularFolderMemo, [])
  const folder = useStore((state) =>
    gEquirectangularFolderMemo(state, folderId)
  )
  const setImageId = useStore(setImageIdAction)

  const handleChange = useCallback(
    (id, value) => {
      console.log(value)
      setImageId(combId, cubeId, value)
    },
    [setImageId, combId, cubeId]
  )

  return (
    <BaseTextField
      select
      value={imageId}
      disabled={!folder}
      onChange={handleChange}
      {...props}
    >
      {folder
        ? folder.images.map((image, idx) => (
            <MenuItem value={image.id} key={idx}>
              {image.name}
            </MenuItem>
          ))
        : []}
    </BaseTextField>
  )
}

export default ImageSelect
//
