// import { useState, useEffect } from 'react'
import { DoubleSide } from 'three'

import Geometry from './Geometry'

const ColorGeometry = ({ contour, holes, color, opacity, ...props }) => {
  return (
    <Geometry
      contour={contour}
      holes={holes}
      material={
        <meshBasicMaterial
          attach="material"
          color={color}
          transparent={opacity < 1}
          opacity={opacity}
          wireframe={false}
          side={DoubleSide}
        />
      }
      {...props}
    />
  )
}

export default ColorGeometry
