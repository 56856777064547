import { useRef, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import useStore from '../../modules/store'
import { loadingTextureSelector } from '../../modules/texturesStore'

const PROGRESS_RADIUS = 40

const Loading = () => {
  const [show, setShow] = useState(false)
  const loadingTexture = useStore(loadingTextureSelector)
  const timerId = useRef()

  useEffect(() => {
    if (loadingTexture) {
      timerId.current = setTimeout(() => {
        setShow(true)
        timerId.current = null
      }, 300)
    } else {
      setShow(false)
    }
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }
  }, [loadingTexture])

  return show ? (
    <CircularProgress
      size={2 * PROGRESS_RADIUS}
      sx={{
        position: 'absolute',
        top: `calc(50% - ${PROGRESS_RADIUS}px)`,
        left: `calc(50% - ${PROGRESS_RADIUS}px)`,
        transform: 'translate(-50%)',
        zIndex: 100
      }}
    />
  ) : null
  // return show ? (
  //   <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 100 }}>
  //     Cargando...
  //   </div>
  // ) : null
}

export default Loading
