// import { styled } from '@mui/material/styles'
import PropertyTreeMediaSelectNode from '../PropertyTree/Nodes/MediaSelect/PropertyTreeMediaSelectNode'

import useStore from '../../../modules/store'
import { videosSelector } from '../../../modules/projectFolderStore'
import { getVideoSrc } from '../../../modules/sceneDrawDataStore'

const VideoSelectNode = ({ value, ...props }) => {
  const videos = useStore(videosSelector)
  const gVideoSrc = useStore(getVideoSrc)
  return (
    <PropertyTreeMediaSelectNode
      value={value}
      medias={videos.map((video) => ({
        name: video.name,
        src: video.url
      }))}
      currentMediaSrc={gVideoSrc(value)}
      {...props}
    />
  )
}
export default VideoSelectNode
