import { createSelector } from 'reselect'
import { findById, clamp } from '../utils/utils'
import { menuSettings } from '../settings/menuSettings'

const lateralMenuStore = (set, get) => ({
  lateralMenu: {
    width: 0, //Ancho del lateral
    options: menuSettings.options,
    panels: menuSettings.panels,
    currentOption: '',
    disabledOptions: [],
    hiddenOptions: [],

    setWidth: (width) => {
      const st = get()
      const { panel } = optionPanel(
        optionsSelector(st),
        panelsSelector(st),
        currentOptionSelector(st)
      )
      if (panel) {
        let newWidth = width
        if (newWidth < panel.minWidth - 120 || newWidth <= 0) {
          //Cierra si se quiere un ancho muy pequeño
          newWidth = 0
        } else {
          newWidth = clamp(newWidth, panel.minWidth, panel.maxWidth)
        }
        set({ lateralMenu: { ...st.lateralMenu, width: newWidth } })
      }
    },
    expand: () => {
      const st = get()
      if (widthSelector(st) === 0) {
        const panel = local_getCurrentPanel(
          currentOptionSelector(st),
          panelsSelector(st)
        )
        if (panel) {
          setWidthAction(st)(panel.defaultWidth)
        }
      }
    },

    setMenuOption: (optionId) => {
      const st = get()
      const currentOP = optionPanel(
        optionsSelector(st),
        panelsSelector(st),
        currentOptionSelector(st)
      )
      const newOP = optionPanel(
        optionsSelector(st),
        panelsSelector(st),
        optionId
      )
      let width = widthSelector(st)
      if (
        currentOP.option === newOP.option &&
        currentOP.option &&
        currentOP.panel
      ) {
        //Misma opcion. Cambio desplegado-recogido
        set({
          lateralMenu: {
            ...st.lateralMenu,
            width: width === 0 ? currentOP.panel.defaultWidth : 0
          }
        })
      } else if (currentOP.option !== newOP.option) {
        //Cambio de opcion
        if (currentOP.panel && !currentOP.panel.closeButton) {
          //El panel actual no se puede cerrar directamente. Hay que hacer algo
          //antes. El panel es el que se encarga.
          return
        }
        if (newOP.panel) {
          //La nueva opcion tiene panel. Mostrarlo
          width =
            width === 0
              ? newOP.panel.defaultWidth
              : clamp(width, newOP.panel.minWidth, newOP.panel.maxWidth)
          set({
            lateralMenu: { ...st.lateralMenu, width, currentOption: optionId }
          })
        } else {
          //No hay panel. Cerrar el actual y marcar nueva opcion si la hay
          set({
            lateralMenu: {
              ...st.lateralMenu,
              currentOption: newOP.option ? newOP.option.id : ''
            }
          })
        }
      }
    },
    setLateralPanel: (optionId, lateralId) => {
      const st = get()
      const currentOP = optionPanel(
        optionsSelector(st),
        panelsSelector(st),
        currentOptionSelector(st)
      )
      const newPanel = findById(panelsSelector(st), lateralId)
      if (!currentOP || currentOP.panel === newPanel) {
        return
      }
      const newOptions = optionsSelector(st).map((o) => {
        if (o.id === optionId) {
          return { ...o, lateral: newPanel ? newPanel.id : '' }
        }
        return o
      })
      set({
        lateralMenu: {
          ...st.lateralMenu,
          options: newOptions,
          width:
            newPanel && optionId === currentOptionSelector(st)
              ? widthSelector(st) === 0
                ? currentOP.panel.defaultWidth
                : clamp(widthSelector(st), newPanel.minWidth, newPanel.maxWidth)
              : 0
        }
      })
    },
    setCurrentOptionPanel: (panelId) => {
      setLateralPanelAction(get())(currentOptionSelector(get()), panelId)
    },

    setDisabledOptions: (ids) => {
      set((st) => ({
        lateralMenu: { ...st.lateralMenu, disabledOptions: ids }
      }))
    },
    setHiddenOptions: (ids) => {
      set((st) => ({
        lateralMenu: { ...st.lateralMenu, hiddenOptions: ids }
      }))
    },
    setEnabledOptions: (ids) => {
      setDisabledOptionsAction(get())(
        optionsSelector(get())
          .filter((option) => ids.indexOf(option.id) < 0)
          .map((option) => option.id)
      )
    },
    setVisibleOptions: (ids) => {
      setHiddenOptionsAction(get())(
        optionsSelector(get())
          .filter((option) => ids.indexOf(option.id) < 0)
          .map((option) => option.id)
      )
    },

    setBadgeValue: (optionId, badge, value) => {
      set((st) => ({
        lateralMenu: {
          ...st.lateralMenu,
          options: optionsSelector(get()).map((option) => {
            if (option.id === optionId) {
              const newOption = { ...option }
              if (!newOption.badges) {
                newOption.badges = {}
              }
              if (newOption.badges[badge]) {
                newOption.badges[badge].value = value
              } else {
                newOption.badges[badge] = {
                  value: value,
                  type: 'info'
                }
              }
              return newOption
            }
            return option
          })
        }
      }))
    },
    setBadgeType: (optionId, badge, badgeType) => {
      set((st) => ({
        lateralMenu: {
          ...st.lateralMenu,
          options: optionsSelector(get()).map((option) => {
            if (option.id === optionId) {
              const newOption = { ...option }
              if (!newOption.badges) {
                newOption.badges = {}
              }
              if (newOption.badges[badge]) {
                newOption.badges[badge].type = badgeType
              } else {
                newOption.badges[badge] = {
                  value: 0,
                  type: badgeType
                }
              }
              return newOption
            }
            return option
          })
        }
      }))
    }
  }
})
export default lateralMenuStore

const optionPanel = (options, panels, optionId) => {
  const option = options.find((mo) => mo.id === optionId)
  return {
    option,
    panel: option ? findById(panels, option.lateral) : null
  }
}

const local_getCurrentPanel = (option, panels) => {
  return option && findById(panels, option.lateral)
}

export const setWidthAction = (state) => state.lateralMenu.setWidth
export const expandAction = (state) => state.lateralMenu.expand
export const setMenuOptionAction = (state) => state.lateralMenu.setMenuOption
export const setLateralPanelAction = (state) =>
  state.lateralMenu.setLateralPanel
export const setCurrentOptionPanelAction = (state) =>
  state.lateralMenu.setCurrentOptionPanel
export const setDisabledOptionsAction = (state) =>
  state.lateralMenu.setDisabledOptions
export const setEnabledOptionsAction = (state) =>
  state.lateralMenu.setEnabledOptions
export const setHiddenOptionsAction = (state) =>
  state.lateralMenu.setHiddenOptions
export const setVisibleOptionsAction = (state) =>
  state.lateralMenu.setVisibleOptions
export const setBadgeValueAction = (state) => state.lateralMenu.setBadgeValue
export const setBadgeTypeAction = (state) => state.lateralMenu.setBadgeType

export const widthSelector = (state) => state.lateralMenu.width
export const optionsSelector = (state) => state.lateralMenu.options
export const panelsSelector = (state) => state.lateralMenu.panels
export const currentOptionSelector = (state) => state.lateralMenu.currentOption
export const disabledOptionsSelector = (state) =>
  state.lateralMenu.disabledOptions
export const hiddenOptionsSelector = (state) => state.lateralMenu.hiddenOptions

export const getOptions = createSelector(
  [optionsSelector, hiddenOptionsSelector],
  (options, hidden) => {
    return options.filter((o) => hidden.indexOf(o.id) < 0)
  }
)

export const getOption = () =>
  createSelector(
    [getOptions, disabledOptionsSelector, hiddenOptionsSelector, (_, id) => id],
    (options, disabledOptions, hiddenOptions, id) => {
      const option = options.find((o) => o.id === id)
      return option
        ? {
            ...option,
            disabled: disabledOptions.indexOf(id) >= 0,
            hidden: hiddenOptions.indexOf(id) >= 0
          }
        : null
    }
  )

export const getCurrentOption = createSelector(
  [getOptions, currentOptionSelector],
  (options, currentOption) => {
    return findById(options, currentOption)
  }
)

export const getCurrentPanel = createSelector(
  [getCurrentOption, panelsSelector],
  (option, panels) => {
    return local_getCurrentPanel(option, panels)
  }
)
