import { memo, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../PropertyTree/Nodes/PropertyTreeSelectNode'

import useStore from '../../../modules/store'
import {
  getCubeHotspots,
  hotspotsSelector
} from '../../../modules/sceneDescriptionStore'

const HotspotsSelectNode = ({
  cubeId, //Si no se pone, se pueden elegir todos
  exclude = [],
  disabledItems,
  multiple,
  allowEmpty,
  onChange,
  ...props
}) => {
  let hotspots = useStore(hotspotsSelector)
  const gCubeHotspots = useStore(getCubeHotspots)
  if (cubeId) {
    const { hotspots: cubeHotspots } = gCubeHotspots(cubeId)
    hotspots = cubeHotspots
  }

  const getItems = () => {
    let items = hotspots
      .filter((hs) => exclude.indexOf(hs.id) < 0)
      .map((hs) => {
        return (
          <MenuItem
            key={hs.id}
            value={hs.id}
            disabled={disabledItems?.indexOf(hs.id) >= 0}
          >
            {hs.name.es}
          </MenuItem>
        )
      })
    if (items.length > 1) {
      if (multiple) {
        items = [
          <MenuItem key={-1} value={-1}>
            Marcar todos
          </MenuItem>,
          <MenuItem key={-2} value={-2}>
            Quitar todos
          </MenuItem>,
          ...items
        ]
      } else if (allowEmpty) {
        items = [
          <MenuItem key={-2} value={-2}>
            Ninguno
          </MenuItem>,
          ...items
        ]
      }
    }

    return items
  }

  const handleChange = useCallback(
    (nodeId, value) => {
      if (onChange) {
        if (multiple) {
          if (value.indexOf(-1) >= 0) {
            onChange(
              nodeId,
              hotspots.map((hs) => hs.id)
            )
          } else if (value.indexOf(-2) >= 0) {
            onChange(nodeId, [])
          } else {
            onChange(nodeId, value)
          }
        } else {
          if (value === -2) {
            onChange(nodeId, '')
          } else {
            onChange(nodeId, value)
          }
        }
      }
    },
    [onChange, hotspots, multiple]
  )

  return (
    <PropertyTreeSelectNode
      options={getItems()}
      multiple={multiple}
      onChange={handleChange}
      {...props}
    />
  )
}
export default memo(HotspotsSelectNode)
