import { useCallback, memo } from 'react'

import Position2DNode from '../../../Common/Nodes/Position2D/Position2DNode'
import { useStore } from '../../../../modules/store'
import {
  setPosition2DAction,
  setPosition2DReferenceAction
} from '../../../../modules/sceneHotspotStore'
import {
  getCurrentHotspot,
  currentIdSelector
} from '../../../../modules/sceneHotspotListStore'

import { threeBoundsSelector } from '../../../../modules/mainStore'

const HEPosition2DNode = () => {
  const currentId = useStore(currentIdSelector)
  const hotspot = useStore(getCurrentHotspot)
  const setPosition2D = useStore(setPosition2DAction)
  const setPosition2DReference = useStore(setPosition2DReferenceAction)
  const threeBounds = useStore(threeBoundsSelector)

  const handleChange = useCallback(
    (nodeId, value) => {
      setPosition2D(currentId, value)
    },
    [currentId, setPosition2D]
  )

  const handleReferenceChange = useCallback(
    (nodeId, value) => {
      setPosition2DReference(currentId, value)
    },
    [currentId, setPosition2DReference]
  )

  return hotspot?.position2D ? (
    <Position2DNode
      id={'position2D'}
      label="Referencia"
      value={hotspot.position2D}
      onReferenceTypeChange={handleReferenceChange}
      onChange={handleChange}
      bounds={threeBounds}
    />
  ) : null
}
export default memo(HEPosition2DNode)
