import { useCallback, memo } from 'react'

import HotspotStyleSelectNode from '../../../Common/Nodes/StyleSelect/HotspotStyleSelectNode'

import { useStore } from '../../../../modules/store'
import { setStyleAction } from '../../../../modules/sceneHotspotStore'
import { getCurrentHotspot } from '../../../../modules/sceneHotspotListStore'

const HEStyleSelectNode = () => {
  const hotspot = useStore(getCurrentHotspot)
  const setStyle = useStore(setStyleAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setStyle(hotspot.id, value)
    },
    [hotspot, setStyle]
  )

  return hotspot ? (
    <HotspotStyleSelectNode
      id={'style'}
      hotspotId={hotspot.id}
      hotspotType={hotspot.type}
      label="Estilo"
      value={hotspot.style}
      onChange={handleChange}
    />
  ) : null
}
export default memo(HEStyleSelectNode)
