import { useState, useCallback } from 'react'

const useSelectFolder = () => {
  const [fileList, setFiles] = useState(null)

  const openFolderDialog = useCallback(() => {
    setFiles(null)

    const input = document.createElement('input')
    input.type = 'file'
    input.webkitdirectory = true
    // input.multiple = true

    input.onchange = (event) => {
      const { files } = event.target
      setFiles(files)
    }
    input.click()
    input.remove()
  }, [])

  return { fileList, openFolderDialog }
}

export default useSelectFolder
