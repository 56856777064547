import { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useSpring, animated, config } from 'react-spring'
import { useGesture } from 'react-use-gesture'

import { ScrollToolbarContext } from './ScrollToolbarContext'

import STItem from './STItem'
import STScrollButton from './STScrollButton'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical' && prop !== 'mainDimension',
  name: 'ScrollToolbar',
  slot: 'Root'
})(({ theme, mainDimension, vertical }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.light,

  minHeight: vertical ? 0 : mainDimension,
  height: vertical ? '100%' : mainDimension,

  minWidth: vertical ? mainDimension : 0,
  width: vertical ? mainDimension : '100%',

  display: 'flex',
  flexFlow: vertical ? 'column' : 'row'
}))

const DraggingMask = styled('div')(({ theme }) => ({
  backgroundColor: '#00000040',
  position: 'absolute',
  zIndex: 1000,
  width: '100%',
  height: '100%'
}))

const ItemsWindow = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative'
}))

const Spacer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'length' && prop !== 'vertical'
})(({ theme, length, vertical }) => ({
  width: vertical ? null : length,
  height: vertical ? length : null
}))

const ItemsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical'
})(({ theme, vertical }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexFlow: vertical ? 'column' : 'row',
  justifyContent: 'flex-start',
  touchAction: 'none'
}))

const SideItemsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical'
})(({ theme, vertical }) => ({
  height: vertical ? null : '100%',
  width: vertical ? '100%' : null,
  display: 'flex',
  flexFlow: vertical ? 'column' : 'row'
}))

const ScrollToolbar = ({
  className,
  vertical,
  mainDimension,
  firstItems,
  secondItems,
  ...props
}) => {
  const [toolbarBoundsRef, toolbarBounds] = useMeasure({
    polyfill: ResizeObserver
  })
  const [firstItemsBoundsRef, firstItemsBounds] = useMeasure({
    polyfill: ResizeObserver
  })
  const [secondItemsBoundsRef, secondItemsBounds] = useMeasure({
    polyfill: ResizeObserver
  })

  const [itemsLength, setItemsLength] = useState(0)
  const [offset, setOffset] = useState(0)
  const [centerLength, setCenterLength] = useState(0)
  const [arrows, setArrows] = useState(false)
  const [draggingOffset, setDraggingOffset] = useState(0)
  const toolbarLength = toolbarBounds
    ? vertical
      ? toolbarBounds.height
      : toolbarBounds.width
    : 0

  const trucanteOffset = (toolbarLength, itemsLength, offset) => {
    return Math.min(0, Math.max(toolbarLength - itemsLength, offset))
  }

  const getOffset = useCallback(
    (inc) => {
      return trucanteOffset(toolbarLength, itemsLength, offset + inc)
    },
    [offset, toolbarLength, itemsLength]
  )

  useEffect(() => {
    if (firstItemsBounds && secondItemsBounds) {
      const lenght = vertical
        ? firstItemsBounds.height + secondItemsBounds.height
        : firstItemsBounds.width + secondItemsBounds.width
      setItemsLength(lenght)
      setOffset(getOffset(0))
      setCenterLength(Math.max(0, toolbarLength - lenght))
      setArrows(toolbarLength < lenght)
    }
  }, [toolbarLength, vertical, firstItemsBounds, secondItemsBounds, getOffset])

  const handleNext = useCallback(() => {
    setOffset(getOffset((-toolbarLength * 2) / 3))
  }, [getOffset, toolbarLength])

  const handlePrev = useCallback(() => {
    setOffset(getOffset((toolbarLength * 2) / 3))
  }, [toolbarLength, getOffset])

  const leftAnimation = useSpring({
    left: vertical
      ? null
      : trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset),
    top: vertical
      ? trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset)
      : null,
    config: config.default
  })

  const bind = useGesture({
    onDrag: ({ movement }) => {
      setDraggingOffset(vertical ? movement[1] : movement[0])
    },
    onDragEnd: () => {
      setDraggingOffset(0)
      setOffset(
        trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset)
      )
    }
  })

  const AnimatedItemsWrapper = animated(ItemsWrapper)
  return (
    <ScrollToolbarContext.Provider value={{ vertical, mainDimension }}>
      <Root
        className={className}
        vertical={vertical}
        mainDimension={mainDimension}
        {...props}
      >
        {draggingOffset !== 0 && centerLength === 0 && <DraggingMask />}
        {arrows && (
          <STScrollButton
            itemsLength={itemsLength}
            toolbarLength={
              vertical ? toolbarBounds.height : toolbarBounds.width
            }
            onClick={handlePrev}
            offset={offset}
          />
        )}
        <ItemsWindow ref={toolbarBoundsRef}>
          <AnimatedItemsWrapper
            {...bind()}
            style={leftAnimation}
            vertical={vertical}
          >
            {firstItems && (
              <SideItemsWrapper ref={firstItemsBoundsRef} vertical={vertical}>
                {firstItems.map((item, idx) => (
                  <STItem key={idx}>{item}</STItem>
                ))}
              </SideItemsWrapper>
            )}
            <Spacer length={centerLength} vertical={vertical} />
            {secondItems && (
              <SideItemsWrapper ref={secondItemsBoundsRef} vertical={vertical}>
                {secondItems.map((item, idx) => (
                  <STItem key={idx}>{item}</STItem>
                ))}
              </SideItemsWrapper>
            )}
          </AnimatedItemsWrapper>
        </ItemsWindow>
        {arrows && (
          <STScrollButton
            itemsLength={itemsLength}
            toolbarLength={
              vertical ? toolbarBounds.height : toolbarBounds.width
            }
            onClick={handleNext}
            offset={offset}
            next
          />
        )}
      </Root>
    </ScrollToolbarContext.Provider>
  )
}
export default ScrollToolbar
