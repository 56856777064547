import { memo, useCallback } from 'react'
import PropertyTreeEmptyNode from '../../../Common/PropertyTree/Nodes/PropertyTreeEmptyNode'
import HideHotspotsActionNode from '../../../Common/Nodes/Action/HideHotspotsActionNode'
import ShowHotspotsActionNode from '../../../Common/Nodes/Action/ShowHotspotsActionNode'
import LookAtActionNode from '../../../Common/Nodes/Action/LookAtActionNode'
import SelectOptionsActionNode from '../../../Common/Nodes/Action/SelectOptionsActionNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setOnStartEventAction } from '../../../../modules/sceneCubeStore'
import { povSchema } from '../../../../schemas/schemas'
import { getDefaultObjectFromSchema } from '../../../../utils/utils'

const CEStartEventNode = () => {
  const onStart = useStore(
    useCallback((state) => {
      const { cube } = getFirstSelected(state)
      return cube ? cube.onStart : null
    }, [])
  )
  const selected = useStore(selectedSelector)
  const setOnStartEvent = useStore(setOnStartEventAction)

  const handleChange = useCallback(
    (nodeId, newValue) => {
      switch (nodeId) {
        case `onStart:selectOptions`:
          setOnStartEvent(selected[0], { ...onStart, selectOptions: newValue })
          break
        case `onStart:hideHotspots`:
          setOnStartEvent(selected[0], { ...onStart, hideHotspots: newValue })
          break
        case `onStart:showHotspots`:
          setOnStartEvent(selected[0], { ...onStart, showHotspots: newValue })
          break
        case `onStart:lookAt`:
          setOnStartEvent(selected[0], { ...onStart, lookAt: newValue })
          break
        default:
      }
    },
    [selected, setOnStartEvent, onStart]
  )

  const handleActivateLookAt = useCallback(
    (nodeId, newValue) => {
      if (!newValue) {
        const newOnStart = { ...onStart }
        delete newOnStart.lookAt
        setOnStartEvent(selected[0], newOnStart)
      } else {
        setOnStartEvent(selected[0], {
          ...onStart,
          lookAt: getDefaultObjectFromSchema(povSchema)
        })
      }
    },
    [selected, setOnStartEvent, onStart]
  )

  return selected.length === 1 ? (
    <PropertyTreeEmptyNode id={'onStart'} label="Al empezar">
      <HideHotspotsActionNode
        id={'hideHotspots'}
        cubeId={selected[0]}
        value={onStart.hideHotspots}
        onChange={handleChange}
      />
      <ShowHotspotsActionNode
        id={'showHotspots'}
        cubeId={selected[0]}
        value={onStart.showHotspots}
        onChange={handleChange}
      />
      <LookAtActionNode
        id={'lookAt'}
        value={onStart.lookAt}
        onActivate={handleActivateLookAt}
        onChange={handleChange}
      />
      <SelectOptionsActionNode
        id={'selectOptions'}
        value={onStart.selectOptions}
        onChange={handleChange}
      />
    </PropertyTreeEmptyNode>
  ) : null
}
export default memo(CEStartEventNode)
