// import { useEffect } from 'react'
import { styled } from '@mui/material/styles'

import LateralMenuBar from './Bar/LateralMenuBar'
import LateralMenuPanel from './Panel/LateralMenuPanel'

const Root = styled('div')(({ theme, animate }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexFlow: 'row'
}))

const LateralMenu = ({
  barWidth = 48,
  zIndex = 100,
  panelContent,
  getIcon,
  onClose,
  onOptionClick
}) => {
  return (
    <Root>
      <LateralMenuBar
        zIndex={zIndex}
        barWidth={barWidth}
        getIcon={getIcon}
        onOptionClick={onOptionClick}
      />
      <LateralMenuPanel zIndex={zIndex} barWidth={barWidth} onClose={onClose}>
        {panelContent}
      </LateralMenuPanel>
    </Root>
  )
}
export default LateralMenu
