import { useCallback, memo } from 'react'

import PropertyTreeTextNode from '../PropertyTree/Nodes/PropertyTreeTextNode'
import { languagesSelector } from '../../../modules/sceneDescriptionStore'
import useStore from '../../../modules/store'

const MultilanguageTextNode = ({
  id,

  value = { es: '', en: '' },
  options,
  onChange,
  ...props
}) => {
  const languages = useStore(languagesSelector)

  const handleChange = useCallback(
    (language, newText) => {
      if (onChange) {
        onChange(id, { ...value, [language]: newText })
      }
    },
    [id, onChange, value]
  )
  return (
    <PropertyTreeTextNode
      id={id}
      value={`(es) ${value['es']}`}
      readOnly={true}
      onChange={handleChange}
      {...props}
    >
      {languages.map((language) => {
        return (
          <PropertyTreeTextNode
            key={language}
            id={language}
            label={language}
            value={value[language]}
            onChange={(id, value) => handleChange(language, value)}
          />
        )
      })}
    </PropertyTreeTextNode>
  )
}
export default memo(MultilanguageTextNode)
