import { useContext } from 'react'
import { styled } from '@mui/material/styles'

import { PropertyTreeContext } from '../PropertyTreeContext'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBarWidth'
})(({ theme, transparentBarWidth }) => ({
  display: 'flex',
  flexFlow: 'row',
  paddingLeft: transparentBarWidth,
  paddingRight: transparentBarWidth - 4,
  width: '100%',
  overflow: 'hidden'
}))

const PropertyTreeNodeContent = ({ input }) => {
  const context = useContext(PropertyTreeContext)
  return (
    <Root transparentBarWidth={context.splitterTransparentBarWidth}>
      {input}
    </Root>
  )
}
export default PropertyTreeNodeContent
