import { useCallback } from 'react'
import { styled } from '@mui/material/styles'

import ScrollToolbar from '../../ScrollToolbar/ScrollToolbar'
import STButton from '../../ScrollToolbar/STButton'

import { useStore } from '../../../../modules/store'
import {
  getOptions,
  currentOptionSelector,
  disabledOptionsSelector
} from '../../../../modules/lateralMenuStore'

const Root = styled(ScrollToolbar, {
  shouldForwardProp: (prop) => prop !== 'zIndex'
})(({ theme, zIndex }) => ({
  zIndex: zIndex,
  borderRight: `1px solid ${theme.palette.primary.main}`
  // '& .ScrollToolbar-button': { color: 'red' }
  // '& .ScrollToolbarItem-root': { backgroundColor: 'red' }

  // boxSizing: 'border-box'
  // '& .scroll_button': {
  //   color: 'red'
  //   // '&:hover': { color: 'blue' }
  // }
}))

const LateralMenuBar = ({ zIndex, barWidth, getIcon, onOptionClick }) => {
  // const dispatch = useDispatch()
  const options = useStore(getOptions)
  const currentMenuOption = useStore(currentOptionSelector)
  const disabledOptions = useStore(disabledOptionsSelector)

  const handleClick = useCallback(
    (optionId) => {
      onOptionClick && onOptionClick(optionId)
    },
    [onOptionClick]
  )

  return (
    <Root
      zIndex={zIndex}
      vertical
      mainDimension={barWidth}
      firstItems={options
        .filter((option) => option.top)
        .map((option) => (
          <STButton
            id={option.id}
            tooltip={option.name}
            icon={getIcon(option.id)}
            disabled={disabledOptions.indexOf(option.id) >= 0}
            selected={currentMenuOption === option.id}
            onClick={handleClick}
          />
        ))}
      secondItems={options
        .filter((option) => !option.top)
        .map((option) => (
          <STButton
            id={option.id}
            tooltip={option.name}
            icon={getIcon(option.id)}
            disabled={disabledOptions.indexOf(option.id) >= 0}
            selected={currentMenuOption === option.id}
            onClick={handleClick}
          />
        ))}
    />
  )
}
export default LateralMenuBar
