import { useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'

import {
  EditIcon,
  AddDuplicateIcon,
  RemoveSelectedIcon
} from '../../Icons/Icons'
import DraggableListItem from '../../Common/DraggableList/DraggableListItem'
import DraggableListItemButton from '../../Common/DraggableList/DraggableListItemButton'

import { useStore } from '../../../modules/store'
import {
  currentCubeSelector,
  setCurrentCubeAction
} from '../../../modules/sceneDrawDataStore'
import { getCubeMemo } from '../../../modules/sceneDescriptionStore'
import {
  selectedSelector,
  setSelectedAction,
  selectAction,
  insertAction,
  removeAction
} from '../../../modules/sceneCubeListStore'

import { setCurrentOptionPanelAction } from '../../../modules/lateralMenuStore'
import { LATERAL_PANELS } from '../../../settings/menuSettings'

const DraggableCubeItem = styled(DraggableListItem)(({ theme }) => ({
  // border: '1px solid red',
  // borderTop: 'none',
  '& .aza-draggable-item-title': {
    // border: `1px solid ${theme.palette.primary.contrastText}`,
    // borderTop: `1px solid ${theme.palette.primary.contrastText}`,
    // borderBottom: `1px solid ${theme.palette.primary.contrastText}`
  }
}))

const CubeItem = ({ id, index }) => {
  // const dispatch = useDispatch()
  const gCube = useMemo(getCubeMemo, [])
  const cube = useStore((state) => gCube(state, id))
  // const gSceneErrors = useMemo(getSceneErrors, [])
  // const errors = useSelector((state) => gSceneErrors(state, id))
  const currentCube = useStore(currentCubeSelector)
  const selectedCubes = useStore(selectedSelector)
  const setCurrentCube = useStore(setCurrentCubeAction)
  const selectCube = useStore(selectAction)
  const setSelectedCubes = useStore(setSelectedAction)
  const insertCube = useStore(insertAction)
  const removeCubes = useStore(removeAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)

  const handleClick = useCallback(() => {
    setCurrentCube(id)
  }, [setCurrentCube, id])

  const handleCheck = useCallback(() => {
    selectCube(id, selectedCubes.indexOf(id) < 0)
  }, [selectCube, id, selectedCubes])

  const handleEdit = useCallback(() => {
    setSelectedCubes([id])
    setCurrentCube(id)
    setCurrentOptionPanel(LATERAL_PANELS.CUBE_EDITOR)
  }, [id, setCurrentCube, setCurrentOptionPanel, setSelectedCubes])

  const handleAdd = useCallback(() => {
    insertCube(index + 1, id)
  }, [insertCube, id, index])

  const handleRemove = useCallback(() => {
    removeCubes([id])
  }, [removeCubes, id])

  return cube ? (
    <DraggableCubeItem
      primaryText={cube.name.es}
      secondaryText={id}
      key={id}
      id={id}
      index={index}
      checkbox
      checked={selectedCubes.indexOf(id) >= 0}
      onCheck={handleCheck}
      selected={currentCube === id}
      onClick={handleClick}
      error={false}
      hoverButtons={[
        <DraggableListItemButton key={0} tooltip="Editar" onClick={handleEdit}>
          <EditIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton
          key={1}
          tooltip="Eliminar"
          onClick={handleRemove}
        >
          <RemoveSelectedIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton key={2} tooltip="Añadir" onClick={handleAdd}>
          <AddDuplicateIcon />
        </DraggableListItemButton>
      ]}
    />
  ) : null
}
export default CubeItem
