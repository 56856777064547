import { useCallback } from 'react'

import MultilanguageTextNode from '../../../Common/Nodes/MultilanguageTextNode'
import { useStore } from '../../../../modules/store'
import { setNameAction } from '../../../../modules/sceneHotspotStore'
import {
  getCurrentHotspot,
  currentIdSelector
} from '../../../../modules/sceneHotspotListStore'

const HENameNode = () => {
  const currentId = useStore(currentIdSelector)
  const hs = useStore(getCurrentHotspot)
  const setName = useStore(setNameAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setName(currentId, value)
    },
    [currentId, setName]
  )

  return hs ? (
    <MultilanguageTextNode
      id={'name'}
      label="Nombre"
      tooltipDisabled
      value={hs.name}
      onChange={handleChange}
    />
  ) : null
}
export default HENameNode
