// import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

const Root = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '80%',
    maxHeight: '80%',
    backgroundColor: theme.palette.primary.light,
    border: '1px solid white',
    color: theme.palette.primary.contrastText
  }
}))

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderBottom: '1px solid white',
  fontSize: 20
}))

const PropertyTreeDialog = ({ title, content, actions, open, ...props }) => {
  // const [localValue, setLocalValue] = useState(value)
  //
  // useEffect(() => {
  //   if (!open) {
  //     setLocalValue(value)
  //   }
  // }, [value, open])

  return (
    <Root open={open} {...props}>
      <Title>{title}</Title>
      <DialogContent sx={{ padding: 0 }}>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Root>
  )
}

export default PropertyTreeDialog
