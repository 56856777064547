import { cloneObject, getDefaultObjectFromSchema } from '../utils/utils'
// import { createSelector } from 'reselect'
import { threeBoundsSelector } from './mainStore'
import { getHotspot, getHotspotCubes } from './sceneDescriptionStore'

import {
  insertHotspotsAction as insertHotspotsIntoCubeAction,
  removeHotspotsAction as removeHotspotsFromCubeAction
} from './sceneCubeStore'
import {
  getReference,
  validatePosition2D,
  ANCHORS_IDS,
  getAnchorPosition,
  incrementPropertyValue
} from './positioning2D'
import { getType, HOTSPOTS_TYPES_IDS } from './hotspotTypesStore'
import { polygonSchema } from '../schemas/hotspotPolygonSchema'
import {
  position2DPropertySchema,
  position2DSchema
} from '../schemas/hotspotSchema'
import { point3DSchema } from '../schemas/schemas'
import { addHotspotAction } from './transformStore'

export const sceneHotspotStore = (set, get) => ({
  sceneHotspot: {
    setHotspots: (hotspots) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, hotspots }
      }))
    },
    setHotspot: (hotspot) => {
      if (hotspot) {
        set((st) => ({
          sceneDescription: {
            ...st.sceneDescription,
            hotspots: st.sceneDescription.hotspots.map((hs) => {
              if (hs.id === hotspot.id) {
                return hotspot
              }
              return hs
            })
          }
        }))
      }
    },
    setName: (id, name) => {
      setHotspotProperty(set, id, 'name', name)
    },
    setAnchor: (id, anchor) => {
      setHotspotProperty(set, id, 'anchor', anchor)
    },
    setPositionMode: (id, mode) => {
      const hotspot = getHotspot(get())(id)
      if (hotspot) {
        const newHotspot = { ...hotspot }
        if (hotspot.position2D) {
          //A modo 3D
          delete newHotspot.position2D
          newHotspot.position3D = getDefaultObjectFromSchema(point3DSchema)
          setHotspotAction(get())(newHotspot)
          addHotspotAction(get())(
            id,
            getAnchorPosition(hotspot.position2D, threeBoundsSelector(get())),
            null,
            false
          )
        } else {
          //A modo 2D
          delete newHotspot.position3D
          newHotspot.position2D = getDefaultObjectFromSchema(position2DSchema)
          setHotspotAction(get())(newHotspot)
          addHotspotAction(get())(id, hotspot.position3D, null, true)
        }
      }
    },
    setPosition3D: (id, position3D) => {
      const hotspot = getHotspot(get())(id)
      if (hotspot?.position3D && position3D) {
        setHotspotProperty(set, id, 'position3D', position3D)
      }
    },
    setPosition2D: (id, position2D) => {
      const hotspot = getHotspot(get())(id)
      if (hotspot?.position2D && position2D) {
        setHotspotProperty(
          set,
          id,
          'position2D',
          validatePosition2D(position2D, threeBoundsSelector(get()))
        )
      }
    },
    incPosition: (id, inc) => {
      const hotspot = getHotspot(get())(id)
      if (hotspot && inc && (inc[0] !== 0 || inc[1] !== 0)) {
        if (hotspot.position2D) {
          const bounds = threeBoundsSelector(get())
          const reference = getReference(hotspot.position2D.reference)
          const newPosition = { ...hotspot.position2D }
          reference.properties.forEach((prop) => {
            newPosition[prop.id] = incrementPropertyValue(
              hotspot.position2D[prop.id],
              prop.length === 'height'
                ? inc[1] * reference.incY
                : inc[0] * reference.incX,
              bounds[prop.length]
            )
          })
          setHotspotProperty(
            set,
            id,
            'position2D',
            validatePosition2D(newPosition, threeBoundsSelector(get()))
          )
        } else {
          addHotspotAction(get())(id, null, inc, false)
        }
      }
    },
    setPosition2DReference: (id, referenceId) => {
      const hotspot = getHotspot(get())(id)
      const newReference = getReference(referenceId)
      if (hotspot?.position2D?.reference !== referenceId) {
        const position2D = { reference: referenceId }
        position2D[newReference.properties[0].id] = hotspot.position2D[
          newReference.properties[0].id
        ]
          ? hotspot.position2D[newReference.properties[0].id]
          : getDefaultObjectFromSchema(position2DPropertySchema)
        position2D[newReference.properties[1].id] = hotspot.position2D[
          newReference.properties[1].id
        ]
          ? hotspot.position2D[newReference.properties[1].id]
          : getDefaultObjectFromSchema(position2DPropertySchema)
        setHotspotProperty(set, id, 'position2D', position2D)
      }
    },
    setPosition2DPixelsMode: (id, propertyId, pixels) => {
      //Ajustar para que se quede en el mismo sitio
      const hotspot = getHotspot(get())(id)
      if (hotspot?.position2D) {
        const reference = getReference(hotspot.position2D.reference)
        const property = reference[propertyId]
        const length = threeBoundsSelector(get())[property.length]
        const newProperty = {
          ...property,
          pixels,
          value: pixels
            ? Math.round((property.value * length) / 100)
            : (property.value * 100) / length
        }
        const newPosition = { ...hotspot.position2D }
        newPosition[propertyId] = newProperty
        setHotspotProperty(set, id, 'position2D', newPosition)
      }
    },
    setCubes: (id, newCubesIds) => {
      if (newCubesIds?.length > 0) {
        const { cubesIds } = getHotspotCubes(get())(id)
        const remove = cubesIds.filter((id) => newCubesIds.indexOf(id) < 0)
        const add = newCubesIds.filter((id) => cubesIds.indexOf(id) < 0)
        add.forEach((cubeId) => {
          insertHotspotsIntoCubeAction(cubeId, [id], 0)
        })
        remove.forEach((cubeId) => {
          removeHotspotsFromCubeAction(cubeId, [id])
        })
      }
    },
    setType: (id, typeId) => {
      const type = getType(get())(typeId)
      const hotspot = getHotspot(get())(id)
      if (hotspot && type && typeId !== hotspot.type) {
        const newHotspot = { ...hotspot }
        if (hotspot.type === HOTSPOTS_TYPES_IDS.POLYGON) {
          //Se esta saliendo de poligono. Hay que poner posicion
          newHotspot.position2D = getDefaultObjectFromSchema(position2DSchema)
          newHotspot.anchor = ANCHORS_IDS.CENTER
          //Quitar poligono
          delete newHotspot.polygonData
        } else if (typeId === HOTSPOTS_TYPES_IDS.POLYGON) {
          //Se quiere convertir en poligono. Quitar posicion
          delete newHotspot.position2D
          delete newHotspot.position3D
          delete newHotspot.anchor
          //Añadir propiedades poligono
          newHotspot.polygonData = getDefaultObjectFromSchema(polygonSchema)
        }
        const newData = cloneObject(type.schema.default)
        for (const prop in newData) {
          //Intenta recuperar valores del data actual
          if (hotspot.data[prop]) {
            newData[prop] = hotspot.data[prop]
          }
        }
        setHotspotAction(get())({ ...newHotspot, type: typeId, data: newData })
      }
    },
    setData: (id, data) => {
      setHotspotProperty(set, id, 'data', data)
    },
    setStyle: (id, style) => {
      setHotspotProperty(set, id, 'style', style)
    },
    setGoto: (id, cubeId) => {
      setHotspotProperty(set, id, 'goto', cubeId)
    },
    setPolygonData: (id, data) => {
      const hs = getHotspot(get())(id)
      if (hs?.polygonData) {
        setHotspotAction(get())({ ...hs, polygonData: data })
      }
    },
    setEvent: (id, eventName, event) => {
      setHotspotProperty(set, id, eventName, event)
    }
  }
})
export default sceneHotspotStore

const setHotspotProperty = (set, id, propertyName, value) => {
  set((st) => ({
    sceneDescription: {
      ...st.sceneDescription,
      hotspots: st.sceneDescription.hotspots.map((hs) => {
        if (hs.id === id) {
          return { ...hs, [propertyName]: value }
        }
        return hs
      })
    }
  }))
}

export const setHotspotsAction = (state) => state.sceneHotspot.setHotspots
export const setHotspotAction = (state) => state.sceneHotspot.setHotspot
export const setNameAction = (state) => state.sceneHotspot.setName
export const setStyleAction = (state) => state.sceneHotspot.setStyle
export const setAnchorAction = (state) => state.sceneHotspot.setAnchor
export const setPositionModeAction = (state) =>
  state.sceneHotspot.setPositionMode
export const setPosition3DAction = (state) => state.sceneHotspot.setPosition3D
export const setPosition2DAction = (state) => state.sceneHotspot.setPosition2D
export const incPositionAction = (state) => state.sceneHotspot.incPosition
export const setPosition2DReferenceAction = (state) =>
  state.sceneHotspot.setPosition2DReference
export const setPosition2DPixelsModeAction = (state) =>
  state.sceneHotspot.setPosition2DPixelsMode
export const setCubesAction = (state) => state.sceneHotspot.setCubes
export const setTypeAction = (state) => state.sceneHotspot.setType
export const setDataAction = (state) => state.sceneHotspot.setData
export const setGotoAction = (state) => state.sceneHotspot.setGoto
export const setPolygonDataAction = (state) => state.sceneHotspot.setPolygonData
export const setPolygonFillTypeAction = (state) =>
  state.sceneHotspot.setPolygonFillType
export const setEventAction = (state) => state.sceneHotspot.setEvent
