import { useMemo, useCallback } from 'react'

import {
  EditIcon,
  AddDuplicateIcon,
  RemoveSelectedIcon
} from '../../../Icons/Icons'
import DraggableListItem from '../../../Common/DraggableList/DraggableListItem'
import DraggableListItemButton from '../../../Common/DraggableList/DraggableListItemButton'

import { useStore } from '../../../../modules/store'
import { getFinishMemo } from '../../../../modules/sceneDescriptionStore'
import {
  selectedSelector,
  setSelectedAction,
  selectAction,
  insertAction,
  removeAction,
  setCurrentIdAction,
  currentIdSelector
} from '../../../../modules/sceneFinishsStore'

import { setCurrentOptionPanelAction } from '../../../../modules/lateralMenuStore'
import { LATERAL_PANELS } from '../../../../settings/menuSettings'

const FinishItem = ({ id, index }) => {
  // const dispatch = useDispatch()
  const gFinishMemo = useMemo(getFinishMemo, [])
  const { finish } = useStore((state) => gFinishMemo(state, id))
  // const gSceneErrors = useMemo(getSceneErrors, [])
  // const errors = useSelector((state) => gSceneErrors(state, id))
  const currentFinishId = useStore(currentIdSelector)
  const setCurrentFinishId = useStore(setCurrentIdAction)
  const selectedFinishs = useStore(selectedSelector)
  const selectFinish = useStore(selectAction)
  const setSelectedFinishs = useStore(setSelectedAction)
  const insertFinish = useStore(insertAction)
  const removeFinishs = useStore(removeAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)

  const handleClick = useCallback(() => {
    setCurrentFinishId(id)
  }, [setCurrentFinishId, id])

  const handleCheck = useCallback(() => {
    selectFinish(id, selectedFinishs.indexOf(id) < 0)
  }, [selectFinish, id, selectedFinishs])

  const handleEdit = useCallback(() => {
    setSelectedFinishs([id])
    setCurrentFinishId(id)
    setCurrentOptionPanel(LATERAL_PANELS.FINISH_EDITOR)
  }, [id, setCurrentFinishId, setCurrentOptionPanel, setSelectedFinishs])

  const handleAdd = useCallback(() => {
    insertFinish(index + 1, id)
  }, [insertFinish, id, index])

  const handleRemove = useCallback(() => {
    removeFinishs([id])
  }, [removeFinishs, id])

  return finish ? (
    <DraggableListItem
      primaryText={finish.name.es}
      secondaryText={id}
      key={id}
      id={id}
      index={index}
      checkbox
      checked={selectedFinishs.indexOf(id) >= 0}
      onCheck={handleCheck}
      selected={currentFinishId === id}
      onClick={handleClick}
      error={false}
      hoverButtons={[
        <DraggableListItemButton key={0} tooltip="Editar" onClick={handleEdit}>
          <EditIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton
          key={1}
          tooltip="Eliminar"
          onClick={handleRemove}
        >
          <RemoveSelectedIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton key={2} tooltip="Añadir" onClick={handleAdd}>
          <AddDuplicateIcon />
        </DraggableListItemButton>
      ]}
    />
  ) : null
}
export default FinishItem
