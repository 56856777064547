import { memo, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'

const Root = styled(List)(({ theme }) => ({
  padding: 0
}))

const InnerList = forwardRef(({ children, ...props }, ref) => {
  return <Root {...props}>{children}</Root>
})
export default memo(InnerList)
