import { createSelector } from 'reselect'
import { cloneObject } from '../utils/utils'
import { selectedSelector } from './sceneCubeListStore'
import { getCube } from './sceneDescriptionStore'
import { setAction } from './sceneCubeStore'
import {
  cubesSelector,
  getHotspotCubes,
  getCubeHotspots
} from './sceneDescriptionStore'
export const cubeEditorStore = (set, get) => ({
  cubeEditor: {
    cubeBackup: null,

    init: () => {
      const cube = getCube(get())(selectedSelector(get())[0])
      set((st) => ({
        cubeEditor: {
          ...st.cubeEditor,
          cubeBackup: cube ? cloneObject(cube) : null
        }
      }))
    },
    restore: () => {
      const cube = cubeBackupSelector(get())
      if (cube) {
        setAction(get())(cube.id, cube)
      }
    },
    save: () => {
      const selected = selectedSelector(get())
      if (selected.length > 1) {
        //Hay que actualizar el resto de cubos
        const modelCube = getCube(get())(selectedSelector(get())[0])
        selected.forEach((id) => {
          const cube = getCube(get())(id)
          setAction(get())(id, {
            ...cube,
            //Solo se copian estas propiedades
            defaultView: modelCube.defaultView,
            finishs: modelCube.finishs,
            hotspots: modelCube.hotspots
          })
        })
      }
    }
  }
})
export default cubeEditorStore

export const initAction = (state) => state.cubeEditor.init
export const restoreAction = (state) => state.cubeEditor.restore
export const saveAction = (state) => state.cubeEditor.save

const cubeBackupSelector = (state) => state.cubeEditor.cubeBackup

export const getFirstSelected = createSelector(
  [selectedSelector, cubesSelector, getCubeHotspots, getHotspotCubes],
  (selected, cubes, gCubeHotspots, gHotspotCubes) => {
    const { cube, hotspotsIds } = gCubeHotspots(
      selected.length ? selected[0] : ''
    )
    let disabledHotspotsIds = []
    if (selected.length < 2) {
      disabledHotspotsIds = cube
        ? hotspotsIds.filter((hsId) => {
            const { cubesIds } = gHotspotCubes(hsId)
            return cubesIds.length === 1 && cubesIds[0] === cube.id
          })
        : []
    } else {
      //Deshabilitar hotspots que solo esten el los cubos seleccionados
      //Busca hotspots en los cubos seleccionados
      disabledHotspotsIds = selected.reduce((acc, cubeId) => {
        const { hotspotsIds } = gCubeHotspots(cubeId)
        hotspotsIds.forEach((hsId) => {
          if (acc.indexOf(hsId) < 0) {
            acc.push(hsId)
          }
        })
        return acc
      }, [])

      //Deja los que esten solo en los cubos seleccionados
      disabledHotspotsIds = disabledHotspotsIds.filter((hsId) => {
        const { cubesIds } = gHotspotCubes(hsId)
        if (cubesIds.length <= selected.length) {
          return cubesIds.filter((id) => selected.indexOf(id) < 0).length === 0
        }
        return false
      })
    }
    return { cube, disabledHotspotsIds }
  }
)
