import { useEffect } from 'react'

import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import MainTheme from '../settings/Theme'
import MainEditor from './MainEditor'
import MainScene from './MainScene'
import { useStore } from '../modules/store'
import { setRootBoundsAction } from '../modules/mainStore'

const Root = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden'
}))

const Main = () => {
  const setRootBounds = useStore(setRootBoundsAction)
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })

  //Tamaño
  useEffect(() => {
    if (rootBounds) {
      setRootBounds(rootBounds)
    }
  }, [rootBounds, setRootBounds])

  // return (
  //   <ThemeProvider theme={MainTheme}>
  //     <Root ref={rootBoundsRef}>
  //       <MainScene />
  //     </Root>
  //   </ThemeProvider>
  // )
  return (
    <ThemeProvider theme={MainTheme}>
      <Root ref={rootBoundsRef}>
        <MainEditor />
      </Root>
    </ThemeProvider>
  )
}
export default Main
