import { createSelector } from 'reselect'
import {
  findById,
  selectItem,
  getFirstNotUsedName,
  getDefaultObjectFromSchema,
  initMultilaguageProperty
} from '../utils/utils'
import { threeBoundsSelector } from './mainStore'
import {
  cubesSelector,
  hotspotsSelector,
  languagesSelector
} from './sceneDescriptionStore'
import { removeHotspotsAction as removeHotspotsFromCubeAction } from './sceneCubeStore'
import { setHotspotsAction, updateEventsAction } from './sceneEditorStore'
import { insertHotspotsAction as insertHotspotsIntoCubeAction } from './sceneCubeStore'

import { currentCubeSelector } from './sceneDrawDataStore'
import { addHotspotAction } from './transformStore'
import { initAction as initPolygonEditorAction } from './polygonEditorStore'
import { HOTSPOTS_TYPES_IDS, getType } from './hotspotTypesStore'
import { hotspotSchema } from '../schemas/hotspotSchema'
import { polygonSchema } from '../schemas/hotspotPolygonSchema'

const resetStore = () => ({
  selected: [],
  currentId: '',
  hoverId: ''
})

export const sceneHotspotListStore = (set, get) => ({
  sceneHotspotList: {
    ...resetStore(),
    reset: () => {
      set((st) => ({
        sceneHotspotList: {
          ...st.sceneHotspotList,
          ...resetStore()
        }
      }))
    },
    setHoverId: (id) => {
      set((st) => ({
        sceneHotspotList: {
          ...st.sceneHotspotList,
          hoverId: id
        }
      }))
    },
    setCurrentId: (id) => {
      set((st) => ({
        sceneHotspotList: {
          ...st.sceneHotspotList,
          currentId: id
        }
      }))
      initPolygonEditorAction(get())()
    },
    setSelected: (selection) => {
      set((st) => ({
        sceneHotspotList: {
          ...st.sceneHotspotList,
          selected: selection
        }
      }))
    },
    selectAll: () => {
      setSelectedAction(get())(hotspotsSelector(get()).map((hs) => hs.id))
    },
    select: (id, select) => {
      setSelectedAction(get())(selectItem(selectedSelector(get()), id, select))
    },
    insert: (idx, hotspot) => {
      setHotspotsAction(get())([...hotspotsSelector(get()), hotspot])
      insertHotspotsIntoCubeAction(get())(
        currentCubeSelector(get()),
        [hotspot.id],
        idx
      )
      // Current
      if (!currentIdSelector(get())) {
        setCurrentIdAction(get())(hotspot.id)
      }
    },
    insertByType: (idx, typeId) => {
      const hotspot = createHotspot(get, typeId)
      insertAction(get())(idx, hotspot)
    },
    duplicate: (idx, modelId) => {
      const hotspot = duplicateHotspot(get, modelId)
      if (hotspot.position3D) {
        const bounds = threeBoundsSelector(get())
        addHotspotAction(get())(
          hotspot.id,
          [bounds.width / 2, bounds.height / 2],
          null,
          false
        )
      }
      insertAction(get())(idx, hotspot)
    },
    remove: (ids) => {
      const hotspots = hotspotsSelector(get())
      if (ids.length > 0) {
        const newHotspots = hotspots.filter((hs) => ids.indexOf(hs.id) < 0)
        setHotspotsAction(get())(newHotspots)
        //Cubes
        cubesSelector(get()).forEach((cube) =>
          removeHotspotsFromCubeAction(get())(cube.id, ids)
        )
        //Current
        if (newHotspots.length === 0) {
          setCurrentIdAction(get())('')
        } else if (ids.indexOf(currentIdSelector(get())) >= 0) {
          setCurrentIdAction(get())(newHotspots[0].id)
        }
        //Selected
        setSelectedAction(get())(
          selectedSelector(get()).filter((id) => ids.indexOf(id) < 0)
        )
        //Events
        updateEventsAction(get())(null, newHotspots, null)
      }
    }
  }
})
export default sceneHotspotListStore

const createHotspot = (get, type) => {
  let hotspot = null
  const htype = getType(get())(type)
  if (htype) {
    hotspot = getDefaultObjectFromSchema(hotspotSchema)
    hotspot.id = getFirstNotUsedName(hotspotsSelector(get()), 'id', 'h', '')
    hotspot.type = type
    hotspot.data = getDefaultObjectFromSchema(htype.schema)
    if (type === HOTSPOTS_TYPES_IDS.POLYGON) {
      hotspot.name = initMultilaguageProperty(
        languagesSelector(get()),
        `Nuevo polígono`
      )
      hotspot.polygonData = getDefaultObjectFromSchema(polygonSchema)
      delete hotspot.position2D
      delete hotspot.anchor
    } else {
      hotspot.name = initMultilaguageProperty(
        languagesSelector(get()),
        `Nuevo hotspot`
      )
    }
  }
  return hotspot
}

const duplicateHotspot = (get, modelId) => {
  const hotspots = hotspotsSelector(get())
  let hotspot = findById(hotspots, modelId)
  hotspot = hotspot ? hotspot : hotspotSchema.default
  hotspot = JSON.parse(JSON.stringify(hotspot))

  hotspot.id = getFirstNotUsedName(hotspots, 'id', 'h', '')

  if (hotspot.position2D) {
    hotspot.name = initMultilaguageProperty(
      languagesSelector(get()),
      `Nuevo hotspot`
    )
    hotspot.anchor = 'cc'
    hotspot.position2D.reference = 'tl'
    hotspot.position2D.top = { value: 50, pixels: false }
    hotspot.position2D.left = { value: 50, pixels: false }
  } else if (hotspot.position3D) {
    hotspot.name = initMultilaguageProperty(
      languagesSelector(get()),
      `Nuevo hotspot`
    )
    hotspot.anchor = 'cc'
    hotspot.position3D = [0, 0, 0]
  } else if (hotspot.polygonData) {
    hotspot.name = initMultilaguageProperty(
      languagesSelector(get()),
      `Nuevo polígono`
    )
    hotspot.polygonData = getDefaultObjectFromSchema(polygonSchema)
  }
  return hotspot
}

export const resetAction = (state) => state.sceneHotspotList.reset
export const setHoverIdAction = (state) => state.sceneHotspotList.setHoverId
export const setCurrentIdAction = (state) => state.sceneHotspotList.setCurrentId
export const setSelectedAction = (state) => state.sceneHotspotList.setSelected
export const selectAllAction = (state) => state.sceneHotspotList.selectAll
export const selectAction = (state) => state.sceneHotspotList.select
export const insertAction = (state) => state.sceneHotspotList.insert
export const insertByTypeAction = (state) => state.sceneHotspotList.insertByType
export const duplicateAction = (state) => state.sceneHotspotList.duplicate
export const removeAction = (state) => state.sceneHotspotList.remove
export const updateAction = (state) => state.sceneHotspotList.update

export const backupSelector = (state) => state.sceneHotspotList.backup
export const cubesBackupSelector = (state) => state.sceneHotspotList.cubesBackup
export const currentIdSelector = (state) => state.sceneHotspotList.currentId
export const hoverIdSelector = (state) => state.sceneHotspotList.hoverId
export const selectedSelector = (state) => state.sceneHotspotList.selected

export const getCurrentHotspot = createSelector(
  [currentIdSelector, hotspotsSelector],
  (currentId, hotspots) => {
    return findById(hotspots, currentId)
  }
)
