import { HotspotIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const AddHotspotButton = (props) => {
  return (
    <STButton
      tooltip={'Añadir hotspot'}
      tooltipEnabled
      icon={<HotspotIcon />}
      {...props}
    />
  )
}
export default AddHotspotButton
