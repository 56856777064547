import { useCallback, memo } from 'react'
import PointOfViewNode from '../../../Common/Nodes/PointOfView/PointOfViewNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setDefaultViewAction } from '../../../../modules/sceneCubeStore'

const CEPOVNode = () => {
  const defaultView = useStore(
    useCallback((state) => {
      const { cube } = getFirstSelected(state)
      return cube ? cube.defaultView : null
    }, [])
  )
  const selected = useStore(selectedSelector)
  const setDefaultView = useStore(setDefaultViewAction)

  const handleChange = useCallback(
    (id, newValue) => {
      console.log(selected[0], newValue)
      setDefaultView(selected[0], newValue)
    },
    [selected, setDefaultView]
  )

  return selected.length > 0 ? (
    <PointOfViewNode
      id={'defaultView'}
      label="Punto de vista por defecto"
      tooltipDisabled
      value={defaultView}
      onChange={handleChange}
    />
  ) : null
}
export default memo(CEPOVNode)
