// import { useCallback } from 'react'
// import { useDispatch } from 'react-redux'

import { ReloadIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'
const ReloadButton = (props) => {
  return (
    <STButton
      tooltip={'Leer'}
      tooltipEnabled
      icon={<ReloadIcon />}
      {...props}
    />
  )
}
export default ReloadButton
