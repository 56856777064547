import { SCHEMA_URIS, sizeInPixelsSchema } from './schemas'

export const hotspotImageSchema = {
  id: SCHEMA_URIS.HOTSPOT_IMAGE,
  type: 'object',
  properties: {
    tooltip: {
      type: 'string',
      myData: {
        type: 'string',
        name: 'Tooltip',
        description: 'Poco mas que decir'
      }
    },
    image: {
      type: 'string',
      minLength: '1',
      myData: { type: 'image', name: 'Imagen', description: 'Imagen' }
    },
    size: {
      $ref: SCHEMA_URIS.SIZE_IN_PIXELS,
      myData: { type: 'size', name: 'Tamaño', description: 'Tamaño' }
    },
    scale: {
      type: 'number',
      minimum: 0.1,
      maximum: 10,
      myData: {
        type: 'number',
        name: 'Escala',
        description:
          'Escala que se aplica cuando el puntero está sobre la imagen'
      }
    }
  },
  required: ['tooltip', 'image', 'size'],
  additionalProperties: false,
  default: {
    tooltip: 'Tooltip',
    image: '',
    size: sizeInPixelsSchema.default,
    scale: 1.5
  }
}
