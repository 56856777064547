import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderTop: 'none'
}))

const PropertyTreeRow = ({ children, ...props }) => {
  // const dispatch = useDispatch()
  return <Root {...props}>{children}</Root>
}
export default PropertyTreeRow
