import { useEffect } from 'react'
// import { styled } from '@mui/material/styles'
import { Globals } from '@react-spring/shared'

import FinishsMenu from './Scene/Cosas/FinishsMenu'
import CubesMenu from './Scene/Cosas/CubesMenu'
import Scene from './Scene/Scene'
import { useStore } from '../modules/store'
import { readSceneFileAction } from '../modules/openSaveStore'
// import useLoadScript from '../utils/useLoadScript'

Globals.assign({
  frameLoop: 'always'
})

const MainScene = () => {
  const readSceneFile = useStore(readSceneFileAction)

  useEffect(() => {
    readSceneFile('settings.json')
  }, [readSceneFile])

  return (
    <>
      <Scene />
      <FinishsMenu />
      <CubesMenu />
    </>
  )
}
export default MainScene
