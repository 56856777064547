export const MENU_OPTIONS = {
  OPEN: 'OPEN',
  SAVE: 'SAVE',
  CUBES: 'CUBES',
  FINISHS: 'FINISHS',
  TEXT_EDITOR: 'TEXT_EDITOR',
  IMAGE_MAPPER: 'IMAGE_MAPPER',
  HOTSPOTS: 'HOTSPOTS',
  SCENE: 'SCENE',
  SELECT: 'SELECT',
  POLYGONS: 'POLYGONS'
}

export const LATERAL_PANELS = {
  SCENE: 'SCENE',
  CUBES_LIST: 'CUBES_LIST',
  CUBE_EDITOR: 'CUBE_EDITOR',
  FINISHS_LIST: 'FINISHS_LIST',
  FINISH_EDITOR: 'FINISH_EDITOR',
  OPTION_EDITOR: 'OPTION_EDITOR',
  HOTSPOTS_LIST: 'HOTSPOTS_LIST',
  HOTSPOT_EDITOR: 'HOTSPOT_EDITOR',
  SELECT: 'SELECT',
  POLYGONS_LIST: 'POLYGONS_LIST',
  POLYGON_EDITOR: 'POLYGON_EDITOR'
}

export const menuSettings = {
  options: [
    {
      id: MENU_OPTIONS.OPEN,
      name: 'Abrir',
      lateral: '',
      top: true
    },
    {
      id: MENU_OPTIONS.SAVE,
      name: 'Guardar',
      lateral: '',
      top: true
    },
    {
      id: MENU_OPTIONS.SCENE,
      name: 'Cubos',
      lateral: LATERAL_PANELS.SCENE,
      top: true
    },
    {
      id: MENU_OPTIONS.CUBES,
      name: 'Cubos',
      lateral: LATERAL_PANELS.CUBES_LIST,
      top: true
    },
    {
      id: MENU_OPTIONS.FINISHS,
      name: 'Acabados',
      lateral: LATERAL_PANELS.FINISHS_LIST,
      top: true
    },
    {
      id: MENU_OPTIONS.IMAGE_MAPPER,
      name: 'Asignar imagenes a acabados',
      lateral: '',
      top: true
    },
    {
      id: MENU_OPTIONS.HOTSPOTS,
      name: 'Hotspots',
      lateral: LATERAL_PANELS.HOTSPOTS_LIST,
      top: true
    },
    // {
    //   id: MENU_OPTIONS.POLYGONS,
    //   name: 'Polígonos',
    //   lateral: LATERAL_PANELS.POLYGONS_LIST,
    //   top: true
    // },
    {
      id: MENU_OPTIONS.SELECT,
      name: 'Selección rápida',
      lateral: LATERAL_PANELS.SELECT,
      top: true
    },
    {
      id: MENU_OPTIONS.TEXT_EDITOR,
      name: 'Editor texto',
      lateral: LATERAL_PANELS.TEXT_EDITOR,
      top: false
    }
  ],
  panels: [
    {
      id: LATERAL_PANELS.CUBES_LIST,
      title: 'Lista de cubos',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_PANELS.CUBE_EDITOR,
      title: 'Editor cubo',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_PANELS.FINISHS_LIST,
      title: 'Lista de acabados',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_PANELS.FINISH_EDITOR,
      title: 'Editor acabado',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_PANELS.OPTION_EDITOR,
      title: 'Editor opción',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_PANELS.SELECT,
      title: 'Selección rápida',
      minWidth: 200,
      maxWidth: 400,
      defaultWidth: 300,
      closeButton: true
    },
    // {
    //   id: LATERAL_PANELS.HOTSPOTS_3D,
    //   title: 'Lista de hotspots 3D',
    //   minWidth: 200,
    //   maxWidth: 600,
    //   defaultWidth: 400,
    //   closeButton: true
    // },
    {
      id: LATERAL_PANELS.SCENE,
      title: 'Escena',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_PANELS.HOTSPOTS_LIST,
      title: 'Hotspots del cubo actual',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_PANELS.HOTSPOT_EDITOR,
      title: 'Hotspot',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    },
    {
      id: LATERAL_PANELS.POLYGONS_LIST,
      title: 'Polígonos',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: true
    },
    {
      id: LATERAL_PANELS.POLYGON_EDITOR,
      title: 'Editor polígono',
      minWidth: 200,
      maxWidth: 600,
      defaultWidth: 400,
      closeButton: false
    }
  ]
}
