import { SCHEMA_URIS } from './schemas'
// import { hotspotImageSchema } from './hotspotImageSchema'

export const hotspotStyleSchema = {
  id: SCHEMA_URIS.HOTSPOT_STYLE,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^(?!\\s*$).+' },
    name: { type: 'string', minLength: 1, pattern: '^(?!\\s*$).+' },
    description: { type: 'string' },
    hotspotTypes: { type: 'array' },
    styles: { type: 'object' }
  },
  required: ['id', 'name', 'description', 'hotspotTypes', 'styles'],
  additionalProperties: false,
  default: {
    id: '',
    name: '',
    description: '',
    hotspotTypes: [],
    styles: {}
  }
}

export const hotspotStylesSchema = {
  id: SCHEMA_URIS.HOTSPOT_STYLES,
  type: 'array',
  items: {
    $ref: SCHEMA_URIS.HOTSPOT_STYLE
  },
  default: []
}
