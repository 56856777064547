// import { createSelector } from 'reselect'
import { cloneObject, findById } from '../utils/utils'
import {
  defaultSelector,
  hotspotsSelector,
  cubesSelector
} from './sceneDescriptionStore'

export const sceneEditorStore = (set, get) => ({
  sceneEditor: {
    //****************************************************************************
    setName: (name) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, name: cloneObject(name) }
      }))
    },
    setCubes: (cubes) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, cubes }
      }))
    },
    setFinishs: (finishs) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, finishs }
      }))
    },
    setOptions: (options) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, options }
      }))
    },
    setHotspots: (hotspots) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, hotspots }
      }))
    },
    setPolygons: (polygons) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, polygons }
      }))
    },
    setDefault: (defaultData) => {
      set((st) => ({
        sceneDescription: { ...st.sceneDescription, default: defaultData }
      }))
    },
    setDefaultOptions: (options) => {
      set((st) => ({
        sceneDescription: {
          ...st.sceneDescription,
          default: { ...defaultSelector(get()), options }
        }
      }))
    },
    setDefaultCube: (cube) => {
      set((st) => ({
        sceneDescription: {
          ...st.sceneDescription,
          default: { ...defaultSelector(get()), cube }
        }
      }))
    },
    updateEvents: (cubes, hotspots, options) => {
      setHotspotsAction(get())(
        hotspotsSelector(get()).map((hs) => ({
          ...hs,
          onStart: updateEvent(hs.onStart, cubes, hotspots, options),
          onHover: updateEvent(hs.onHover, cubes, hotspots, options),
          onOut: updateEvent(hs.onOut, cubes, hotspots, options),
          onClick: updateEvent(hs.onClick, cubes, hotspots, options)
        }))
      )
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => ({
          ...cube,
          onStart: updateEvent(cube.onStart, cubes, hotspots, options)
        }))
      )
    }
  }
})
export default sceneEditorStore

export const updateEvent = (event, cubes, hotspots, options) => {
  if (event) {
    const newEvent = cloneObject(event)
    if (cubes && newEvent.goto) {
      const cube = findById(cubes, newEvent.goto.id)
      newEvent.goto.id = cube ? cube.id : ''
    }
    if (hotspots && newEvent.hideHotspots) {
      newEvent.hideHotspots = newEvent.hideHotspots.filter((hsId) =>
        findById(hotspots, hsId)
      )
    }
    if (hotspots && newEvent.showHotspots) {
      newEvent.showHotspots = newEvent.showHotspots.filter((hsId) =>
        findById(hotspots, hsId)
      )
    }
    if (options && newEvent.selectOptions) {
      newEvent.selectOptions = newEvent.selectOptions.filter((oId) =>
        findById(options, oId)
      )
    }
    return newEvent
  }
  return null
}

export const setNameAction = (state) => state.sceneEditor.setName
export const setCubesAction = (state) => state.sceneEditor.setCubes
export const setFinishsAction = (state) => state.sceneEditor.setFinishs
export const setOptionsAction = (state) => state.sceneEditor.setOptions
export const setHotspotsAction = (state) => state.sceneEditor.setHotspots
export const setPolygonsAction = (state) => state.sceneEditor.setPolygons
export const setDefaultAction = (state) => state.sceneEditor.setDefault
export const setDefaultOptionsAction = (state) =>
  state.sceneEditor.setDefaultOptions
export const setDefaultCubeAction = (state) => state.sceneEditor.setDefaultCube
export const updateEventsAction = (state) => state.sceneEditor.updateEvents
