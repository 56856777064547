import { Html } from '@react-three/drei'
import HotspotContainerEditor from './HotspotContainerEditor'

import useStore from '../../../modules/store'
import { getCurrentCubeHotspots } from '../../../modules/sceneDrawDataStore'

const EditorHotspots3D = () => {
  const { hotspots3D } = useStore(getCurrentCubeHotspots)
  return hotspots3D.map((hs) => (
    <Html
      key={hs.id}
      sprite
      transform
      distanceFactor={750}
      position={hs.position3D}
    >
      <HotspotContainerEditor id={hs.id} />
    </Html>
  ))
}

export default EditorHotspots3D
