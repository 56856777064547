import { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

import { PropertyTreeContext } from '../PropertyTreeContext'
import { ExpandIcon, CollapseIcon } from '../../../Icons/Icons'

import useStore from '../../../../modules/store'
import {
  setNodeExpandedAction,
  getTree
} from '../../../../modules/propertyTreeStore'
const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'hasChildren' &&
    prop !== 'hasInput' &&
    prop !== 'level' &&
    prop !== 'labelWidth' &&
    prop !== 'transparentBarWidth'
})(
  ({
    theme,
    hasChildren,
    hasInput,
    level,
    labelWidth,
    transparentBarWidth
  }) => ({
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: hasChildren ? level * 8 : 24 + level * 8,
    paddingRight: transparentBarWidth,
    width: hasInput ? labelWidth - 3 : '100%',
    minWidth: labelWidth - 3,
    cursor: hasChildren ? 'pointer' : 'default'
  })
)

const Label = styled('div')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

const ExpandIconWrapper = styled('div')(({ theme }) => ({
  padding: 2,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const PropertyTreeNodeLabel = ({
  id,
  hover,
  hasChildren,
  hasInput,
  label,
  level,
  tooltip,
  tooltipDisabled
}) => {
  const context = useContext(PropertyTreeContext)
  const [localLabelWidth, setLocalLabelWidth] = useState(0)
  const gTree = useMemo(getTree, [])
  const tree = useStore((state) => gTree(state, context.treeId))
  const setNodeExpanded = useStore(setNodeExpandedAction)
  const expanded = tree ? tree.expanded.indexOf(id) >= 0 : false
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (localLabelWidth === 0 && tree && tree.labelWidth) {
      setLocalLabelWidth(tree.labelWidth)
    }
  }, [tree, localLabelWidth])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleExpand = useCallback(() => {
    setOpen(false)
    hasChildren && setNodeExpanded(context.treeId, id, !expanded)
  }, [setNodeExpanded, hasChildren, expanded, context.treeId, id])

  return (
    <Tooltip
      title={tooltip ? tooltip : label}
      open={open && !tooltipDisabled}
      onClose={handleClose}
      onOpen={handleOpen}
      disableInteractive
    >
      <Root
        level={level}
        hasChildren={hasChildren}
        hasInput={hasInput}
        labelWidth={tree ? tree.labelWidth : 0}
        onClick={handleExpand}
        transparentBarWidth={context.splitterTransparentBarWidth}
      >
        {hasChildren ? (
          expanded ? (
            <ExpandIconWrapper>
              <CollapseIcon sx={{ width: 20 }} />
            </ExpandIconWrapper>
          ) : (
            <ExpandIconWrapper>
              <ExpandIcon sx={{ width: 20 }} />
            </ExpandIconWrapper>
          )
        ) : null}
        <Label>{label}</Label>
      </Root>
    </Tooltip>
  )
}
export default PropertyTreeNodeLabel
