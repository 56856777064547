import { useEffect } from 'react'

import List from './List/List'
import Editor from './Editor/Editor'
import useStore from '../../../modules/store'
import { recreateAction } from '../../../modules/equirectangularImagesMapperStore'

const ImageMapper = () => {
  // const setCurrentCombination = useStore(setCurrentCombinationAction)
  const recreate = useStore(recreateAction)

  useEffect(() => {
    recreate()
  }, [recreate])

  return (
    <>
      <List />
      <Editor />
    </>
  )
}
export default ImageMapper
//
