import SaveMaterialIcon from '@mui/icons-material/Save'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import GetAppIcon from '@mui/icons-material/GetApp'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import EditIconMaterial from '@mui/icons-material/Edit'
import RemoveIconMaterial from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import SettingsIcon from '@mui/icons-material/Settings'
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline'
import CachedIcon from '@mui/icons-material/Cached'
import PublishIcon from '@mui/icons-material/Publish'
import ErrorIconMaterial from '@mui/icons-material/Error'
import BuildIcon from '@mui/icons-material/Build'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import TextureIconMaterial from '@mui/icons-material/Texture'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import PlaceIcon from '@mui/icons-material/Place'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import TocIcon from '@mui/icons-material/Toc'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import CloseIconMaterial from '@mui/icons-material/Close'

export const CloseIcon = (props) => {
  return <CloseIconMaterial {...props} />
}

export const ReadPositionIcon = (props) => {
  return <GpsNotFixedIcon {...props} />
}

export const PanIcon = (props) => {
  return <PanToolOutlinedIcon {...props} />
}

export const RemovePolygonPointIcon = (props) => {
  return <IndeterminateCheckBoxOutlinedIcon {...props} />
}

export const AddPolygonPointIcon = (props) => {
  return <AddBoxOutlinedIcon {...props} />
}

export const QuickSelectIcon = (props) => {
  return <TocIcon {...props} />
}

export const HotspotIcon = (props) => {
  return <PlaceIcon {...props} />
}

export const SceneIcon = (props) => {
  return <BurstModeIcon {...props} />
}

export const ImageMapIcon = (props) => {
  return <InsertLinkIcon {...props} />
}

export const FinishIcon = (props) => {
  return <TextureIconMaterial {...props} />
}

export const NewIcon = (props) => {
  return <InsertDriveFileIcon {...props} />
}

export const AddDuplicateIcon = (props) => {
  return <AddIcon {...props} />
}

export const RemoveSelectedIcon = (props) => {
  return <DeleteIcon {...props} />
}

export const CustomActionIcon = (props) => {
  return <ChangeHistoryIcon {...props} />
}

export const KrpanoActionIcon = (props) => {
  return <RadioButtonUncheckedIcon {...props} />
}

export const ActionIcon = (props) => {
  return <BuildIcon {...props} />
}

export const ErrorIcon = (props) => {
  return <ErrorIconMaterial {...props} />
}

export const ReloadIcon = (props) => {
  return <CachedIcon {...props} />
}

export const UploadIcon = (props) => {
  return <PublishIcon {...props} />
}

export const TextIcon = (props) => {
  return <ViewHeadlineIcon {...props} />
}

export const KrpanoCfgIcon = (props) => {
  return <SettingsIcon {...props} />
}

export const SelectAllIcon = (props) => {
  return <CheckBoxIcon {...props} />
}

export const UnselectAllIcon = (props) => {
  return <CheckBoxOutlineBlankIcon {...props} />
}

export const ExpandIcon = (props) => {
  return <AddIcon {...props} />
}

export const CollapseIcon = (props) => {
  return <RemoveIconMaterial {...props} />
}

export const FilterIcon = (props) => {
  return <FilterListIcon {...props} />
}

export const EditIcon = (props) => {
  return <EditIconMaterial {...props} />
}

export const ImportIcon = (props) => {
  return <GetAppIcon {...props} />
}

export const ImportUnpairedIcon = (props) => {
  return <MenuOpenIcon {...props} />
}

export const RemoveUnpairedIcon = (props) => {
  return <DeleteIcon {...props} />
}

export const OpenIcon = (props) => {
  return <FolderOpenIcon {...props} />
}

export const SaveIcon = (props) => {
  return <SaveMaterialIcon {...props} />
}
