import HotspotContainerEditor from './HotspotContainerEditor'

import useStore from '../../../modules/store'
import { getCurrentCubeHotspots } from '../../../modules/sceneDrawDataStore'

const EditorHotspots2D = () => {
  const { hotspots2D } = useStore(getCurrentCubeHotspots)
  return hotspots2D.map((hs) => {
    return <HotspotContainerEditor twoD key={hs.id} id={hs.id} />
  })
}

export default EditorHotspots2D
