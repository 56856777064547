import { useCallback, memo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import PropertyTreeTextNode from '../PropertyTree/Nodes/PropertyTreeTextNode'
import PropertyTreeSelectNode from '../PropertyTree/Nodes/PropertyTreeSelectNode'

import useStore from '../../../modules/store'
import {
  getFinishs,
  getFinishsOptionsSummary
} from '../../../modules/sceneDescriptionStore'

const SelectOptionsNode = ({
  id,
  label,
  absolute,
  value = [],
  onChange,
  ...props
}) => {
  const finishs = useStore(getFinishs)
  const gFinishsOptionsSummary = useStore(getFinishsOptionsSummary)

  const getFinishSelectedOption = useCallback(
    (finish) => {
      const id = finish.options.find((optionId) => value.indexOf(optionId) >= 0)
      return id ? id : -1
    },
    [value]
  )

  const getFinishItems = (finish) => {
    const items = []
    if (!absolute) {
      items.push(
        <MenuItem key={-1} value={-1}>
          Actual
        </MenuItem>
      )
    }
    return [
      ...items,
      ...finish.optionsObjects.map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.name.es}
          </MenuItem>
        )
      })
    ]
  }

  const handleChange = useCallback(
    (nodeId, newOptionId, finishId) => {
      const finish = finishs.find((finish) => finish.id === finishId)
      const optionId = getFinishSelectedOption(finish)
      const options = value.filter((o) => o !== optionId)
      options.push(newOptionId)
      onChange && onChange(id, options)
    },
    [finishs, getFinishSelectedOption, onChange, value, id]
  )

  return (
    <PropertyTreeTextNode
      id={'selectOptions'}
      label={label}
      value={gFinishsOptionsSummary(value)}
      readOnly
      onChange={null}
      {...props}
    >
      {finishs.map((finish) => {
        return (
          <PropertyTreeSelectNode
            key={finish.id}
            id={'option'}
            label={finish.name.es}
            value={getFinishSelectedOption(finish)}
            onChange={(nodeId, value) => {
              handleChange(nodeId, value, finish.id)
            }}
            options={getFinishItems(finish)}
          />
        )
      })}
    </PropertyTreeTextNode>
  )
}
export default memo(SelectOptionsNode)
