import { useEffect, useState, useCallback } from 'react'
import TextField from '@mui/material/TextField'

const BaseTextField = ({
  id,
  value,
  validateValue,
  onChange,
  onEditingChange,
  select,
  ...props
}) => {
  const [localValue, setLocalValue] = useState('')

  useEffect(() => {
    setLocalValue(validateValue ? validateValue(value) : value)
  }, [value, validateValue])

  const updateExternalValue = useCallback(() => {
    let newValue = validateValue ? validateValue(localValue) : localValue
    onChange && onChange(id, newValue)
    setLocalValue(value) //Dejar esto
  }, [id, onChange, localValue, validateValue, value])

  const handleBlur = useCallback(
    (e) => {
      updateExternalValue()
      onEditingChange && onEditingChange(false)
    },
    [updateExternalValue, onEditingChange]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateExternalValue()
      }
    },
    [updateExternalValue]
  )

  const handleChange = useCallback((e) => {
    setLocalValue(e.target.value)
  }, [])

  const handleFocus = useCallback(
    (e) => {
      onEditingChange && onEditingChange(true)
    },
    [onEditingChange]
  )

  const handleSelectChange = useCallback(
    (e) => {
      onChange && onChange(id, e.target.value)
    },
    [onChange, id]
  )

  return select ? (
    <TextField
      value={localValue}
      onChange={handleSelectChange}
      select
      {...props}
    />
  ) : (
    <TextField
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      onFocus={handleFocus}
      {...props}
    />
  )
}

export default BaseTextField
