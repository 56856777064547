// import { createSelector } from 'reselect'
import { findById, selectItem, getLastNotUsedName, swap } from '../utils/utils'
import {
  finishsSelector,
  optionsSelector,
  defaultOptionsSelector,
  cubesSelector,
  getFinishCubes
} from './sceneDescriptionStore'
import {
  setFinishsAction,
  setOptionsAction,
  setDefaultOptionsAction
} from './sceneEditorStore'
import {
  setFinishsAction as setCubeFinishsAction,
  insertFinishsAction as insertFinishsIntoCubeAction,
  removeFinishsAction as removeFinishsFromCubeAction
} from './sceneCubeStore'
import {
  selectedOptionsSelector as selectedOptionsSelectorScene,
  setSelectedOptionsAction
} from './sceneDrawDataStore'
import { finishSchema, optionSchema } from '../schemas/sceneSchema'

export const sceneFinishsStore = (set, get) => ({
  finishsEditor: {
    selected: [],
    finishSeed: 2,
    optionSeed: 2,
    currentId: '',

    reset: (scene) => {
      let finishSeed = 2
      let optionSeed = 2
      if (scene && scene.finishs) {
        const { number } = getLastNotUsedName(scene.finishs, 'id', 'a', '')
        finishSeed = number
      }
      if (scene && scene.options) {
        const { number } = getLastNotUsedName(scene.options, 'id', 'op', '')
        optionSeed = number
      }
      set((st) => ({
        finishsEditor: {
          ...st.finishsEditor,
          selected: [],
          currentId: '',
          finishSeed,
          optionSeed
        }
      }))
    },

    setFinishSeed: (value) => {
      set((st) => ({
        finishsEditor: { ...st.finishsEditor, finishSeed: value }
      }))
    },
    setOptionSeed: (value) => {
      set((st) => ({
        finishsEditor: { ...st.finishsEditor, optionSeed: value }
      }))
    },
    setCurrentId: (finishId) => {
      set((st) => ({
        finishsEditor: { ...st.finishsEditor, currentId: finishId }
      }))
    },

    setSelected: (selection) => {
      set((st) => ({
        finishsEditor: { ...st.finishsEditor, selected: selection }
      }))
    },
    selectAll: () => {
      setSelectedAction(get())(finishsSelector(get()).map((f) => f.id))
    },
    select: (id, select) => {
      setSelectedAction(get())(selectItem(selectedSelector(get()), id, select))
    },
    setFinish: (finishId, newFinish) => {
      setFinishsAction(get())(
        finishsSelector(get()).map((f) => {
          if (f.id === finishId) {
            return JSON.parse(JSON.stringify(newFinish))
          }
          return f
        })
      )
    },
    insert: (idx, modelFinishId) => {
      const finishs = [...finishsSelector(get())]
      const options = optionsSelector(get())

      const { finish, option, finishSeed, optionSeed } = createFinish(
        modelFinishId,
        finishs,
        options,
        finishSeedSelector(get()),
        optionSeedSelector(get())
      )
      finishs.splice(idx, 0, finish)
      setFinishsAction(get())(finishs)
      setOptionsAction(get())([...options, option])
      setDefaultOptionsAction(get())([
        ...defaultOptionsSelector(get()),
        option.id
      ])
      setSelectedOptionsAction(get())([
        ...selectedOptionsSelectorScene(get()),
        option.id
      ])
      set((st) => ({
        finishsEditor: { ...st.finishsEditor, finishSeed, optionSeed }
      }))
    },
    remove: (ids) => {
      let finishs = finishsSelector(get())
      let options = optionsSelector(get())
      let selectedOptions = selectedOptionsSelectorScene(get())
      let defaultOptions = defaultOptionsSelector(get())
      if (ids.length > 0) {
        const {
          newFinishs,
          newOptions,
          newSelectedOptions,
          newDefaultOptions
        } = finishs.reduce(
          (acc, f) => {
            if (ids.indexOf(f.id) >= 0) {
              // Quitar opciones del acabado
              acc.newOptions = acc.newOptions.filter(
                (option) => f.options.indexOf(option.id) < 0
              )
              // Quitar opcion de la seleccion
              acc.newSelectedOptions = acc.newSelectedOptions.filter(
                (optionId) => f.options.indexOf(optionId) < 0
              )
              //Quitar opcion de default
              acc.newDefaultOptions = acc.newDefaultOptions.filter(
                (optionId) => f.options.indexOf(optionId) < 0
              )
              return acc
            }
            acc.newFinishs.push(f)
            return acc
          },
          {
            newFinishs: [],
            newOptions: options,
            newSelectedOptions: selectedOptions,
            newDefaultOptions: defaultOptions
          }
        )

        if (newFinishs.length === 0) {
          //Se han quitado todos los acabados. Crear uno por defecto
          const { finish, option, finishSeed, optionSeed } = createFinish(
            '',
            [],
            newOptions,
            finishSeedSelector(get()),
            optionSeedSelector(get())
          )
          newFinishs.push(finish)
          newOptions.push(option)
          newSelectedOptions.push(newOptions[0].id)
          newDefaultOptions.push(newOptions[0].id)
          set((st) => ({
            finishsEditor: { ...st.finishsEditor, finishSeed, optionSeed }
          }))
        }
        setFinishsAction(get())(newFinishs)
        setOptionsAction(get())(newOptions)
        setDefaultOptionsAction(get())(newDefaultOptions)
        setSelectedOptionsAction(get())(newSelectedOptions)
        // Seleccion del editor
        setSelectedAction(get())(
          selectedSelector(get()).filter((id) => ids.indexOf(id) < 0)
        )
        //Cubos
        cubesSelector(get()).forEach((cube) => {
          if (newFinishs.length === 1) {
            setCubeFinishsAction(get())(cube.id, [newFinishs[0].id])
          } else {
            removeFinishsFromCubeAction(get())(cube.id, ids)
          }
        })
      }
    },
    swapById: (fromId, toId) => {
      const finishs = finishsSelector(get())
      const fromIdx = finishs.findIndex((cube) => cube.id === fromId)
      const toIdx = finishs.findIndex((cube) => cube.id === toId)
      setFinishsAction(get())(swap([...finishs], fromIdx, toIdx))
    },
    setFinishCubes: (id, newCubesIds) => {
      if (newCubesIds?.length > 0) {
        const { finishCubesIds } = getFinishCubes(get())(id)
        const remove = finishCubesIds.filter(
          (id) => newCubesIds.indexOf(id) < 0
        )
        const add = newCubesIds.filter((id) => finishCubesIds.indexOf(id) < 0)
        add.forEach((cubeId) => {
          insertFinishsIntoCubeAction(get())(cubeId, [id], 0)
        })
        remove.forEach((cubeId) => {
          removeFinishsFromCubeAction(get())(cubeId, [id])
        })
      }
    }
  }
})
export default sceneFinishsStore

const createFinish = (
  modelFinishId,
  finishs,
  options,
  finishSeed,
  optionSeed
) => {
  let finish = findById(finishs, modelFinishId)
  finish = finish ? finish : finishSchema.default
  finish = JSON.parse(JSON.stringify(finish))
  //Id
  finish.id = `a${finishSeed}`
  //Añadirle una opcion por defecto
  const { option, seed: newOptionSeed } = createOption('', options, optionSeed)
  finish.options = [option.id]
  return {
    finish,
    option,
    finishSeed: finishSeed + 1,
    optionSeed: newOptionSeed
  }
}

export const createOption = (modelOptionId, options, seed) => {
  let option = findById(options, modelOptionId)
  option = option ? option : optionSchema.default
  option = JSON.parse(JSON.stringify(option))
  //Id
  option.id = `op${seed}`
  option.name = { ...option.name, es: 'Nombre' }
  return { option, seed: seed + 1 }
}

export const resetAction = (state) => state.finishsEditor.reset
export const setCurrentIdAction = (state) => {
  return state.finishsEditor.setCurrentId
}
export const setSelectedAction = (state) => state.finishsEditor.setSelected
export const selectAllAction = (state) => state.finishsEditor.selectAll
export const selectAction = (state) => state.finishsEditor.select
export const setFinishAction = (state) => state.finishsEditor.setFinish
export const insertAction = (state) => state.finishsEditor.insert
export const removeAction = (state) => state.finishsEditor.remove
export const swapByIdAction = (state) => state.finishsEditor.swapById
export const setFinishSeedAction = (state) => state.finishsEditor.setFinishSeed
export const setOptionSeedAction = (state) => state.finishsEditor.setOptionSeed
export const setFinishCubesAction = (state) =>
  state.finishsEditor.setFinishCubes

export const currentIdSelector = (state) => state.finishsEditor.currentId
export const selectedSelector = (state) => state.finishsEditor.selected
export const finishSeedSelector = (state) => state.finishsEditor.finishSeed
export const optionSeedSelector = (state) => state.finishsEditor.optionSeed
