import { useCallback } from 'react'
import IconButton from '@mui/material/IconButton'

import { ReadPositionIcon } from '../../../Icons/Icons'
import { useStore } from '../../../../modules/store'
import {
  fovSelector,
  polarAngleSelector,
  azimuthSelector
} from '../../../../modules/cameraStore'

// import useStore from '../../../../modules/store'

const ReadButton = ({ id, value, onChange }) => {
  const fov = useStore(fovSelector)
  const polar = useStore(polarAngleSelector)
  const azimuth = useStore(azimuthSelector)

  const handleReadPosition = useCallback(() => {
    onChange && onChange(id, { ...value, fov, azimuth, polar })
  }, [id, onChange, value, fov, azimuth, polar])

  return (
    <IconButton
      sx={{
        padding: 0,
        color: 'primary.contrastText',
        '&:hover': { color: 'secondary.main' }
      }}
      onClick={handleReadPosition}
    >
      <ReadPositionIcon />
    </IconButton>
  )
}
export default ReadButton
