import { useCallback, memo } from 'react'
import PropertyTreeIntegerNode from '../../../Common/PropertyTree/Nodes/PropertyTreeIntegerNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setImageIdxAction } from '../../../../modules/sceneCubeStore'

const CEImageIndexNode = () => {
  const selected = useStore(selectedSelector)
  const imageIdx = useStore(
    useCallback((state) => {
      const { cube } = getFirstSelected(state)
      return cube ? cube.imageIdx : null
    }, [])
  )
  const setImageIdx = useStore(setImageIdxAction)

  const handleChange = useCallback(
    (id, newValue) => {
      setImageIdx(selected[0], newValue)
    },
    [selected, setImageIdx]
  )

  return selected.length === 1 ? (
    <PropertyTreeIntegerNode
      id={'imageIdx'}
      label="Indice imagen"
      tooltipDisabled
      value={imageIdx}
      onChange={handleChange}
    />
  ) : null
}
export default memo(CEImageIndexNode)
