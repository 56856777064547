import { memo, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from './PropertyTreeSelectNode'

const PropertyMultipleSelectNode = ({
  options,
  onChange,
  onSelectAll,
  onClearSelection,
  ...props
}) => {
  const handleChange = useCallback(
    (nodeId, value) => {
      if (value.indexOf(-1) >= 0) {
        onSelectAll && onSelectAll(nodeId)
      } else if (value.indexOf(-2) >= 0) {
        onClearSelection && onClearSelection(nodeId, [])
      } else {
        onChange && onChange(nodeId, value)
      }
    },
    [onChange, onSelectAll, onClearSelection]
  )

  return (
    <PropertyTreeSelectNode
      options={[
        <MenuItem key={-1} value={-1}>
          Marcar todos
        </MenuItem>,
        <MenuItem key={-2} value={-2}>
          Quitar todos
        </MenuItem>,
        ...options
      ]}
      multiple
      onChange={handleChange}
      {...props}
    />
  )
}
export default memo(PropertyMultipleSelectNode)
