// import { styled } from '@mui/material/styles'
import PropertyTreeMediaSelectNode from '../PropertyTree/Nodes/MediaSelect/PropertyTreeMediaSelectNode'

import useStore from '../../../modules/store'
import { imagesSelector } from '../../../modules/projectFolderStore'
import { getImageSrc } from '../../../modules/sceneDrawDataStore'

const ImageSelectNode = ({ value, ...props }) => {
  const images = useStore(imagesSelector)
  const gImageSrc = useStore(getImageSrc)

  return (
    <PropertyTreeMediaSelectNode
      value={value}
      imageMode
      medias={images.map((img) => ({
        name: img.name,
        src: img.url
      }))}
      currentMediaSrc={gImageSrc(value)}
      {...props}
    />
  )
}
export default ImageSelectNode
