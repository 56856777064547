import { useCallback, memo } from 'react'

import PropertyTreeTwoStatesNode from '../../../Common/PropertyTree/Nodes/PropertyTreeTwoStatesNode'

import { useStore } from '../../../../modules/store'
import { setPositionModeAction } from '../../../../modules/sceneHotspotStore'
import {
  getCurrentHotspot,
  currentIdSelector
} from '../../../../modules/sceneHotspotListStore'

const HEPositionModeNode = () => {
  const currentId = useStore(currentIdSelector)
  const hotspot = useStore(getCurrentHotspot)
  const setPositionMode = useStore(setPositionModeAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setPositionMode(currentId, value)
    },
    [currentId, setPositionMode]
  )
  return hotspot?.position2D || hotspot?.position3D ? (
    <PropertyTreeTwoStatesNode
      id={'mode2D'}
      label="Modo"
      value={Boolean(hotspot.position2D)}
      values={['3D', '2D']}
      onChange={handleChange}
    />
  ) : null
}
export default memo(HEPositionModeNode)
