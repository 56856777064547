import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import ScrollToolbar from '../../../Common/ScrollToolbar/ScrollToolbar'
import AddPolygonButton from '../../../Common/ToolbarButtons/AddPolygonButton'
import AddPolygonPointButton from '../../../Common/ToolbarButtons/AddPolygonPointButton'
import PanButton from '../../../Common/ToolbarButtons/PanButton'
import RemovePolygonPointButton from '../../../Common/ToolbarButtons/RemovePolygonPointButton'
import { useStore } from '../../../../modules/store'
import { getCurrentHotspot } from '../../../../modules/sceneHotspotListStore'

import { HOTSPOTS_TYPES_IDS } from '../../../../modules/hotspotTypesStore'
import {
  modeSelector,
  setModeAction,
  EDITOR_MODE_IDS,
  currentPointSelector,
  getDrawData,
  pointsSelector
} from '../../../../modules/polygonEditorStore'

const Root = styled(ScrollToolbar)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderBottom: 'none' // `1px solid ${theme.palette.primary.contrastText}`
}))

const HEPolygonToolbar = () => {
  const { POINTER, ADD_POINT, REMOVE_POINT, ADD_HOLE } = EDITOR_MODE_IDS
  const editorMode = useStore(modeSelector)
  const setMode = useStore(setModeAction)
  const hotspot = useStore(getCurrentHotspot)
  const { contour } = useStore(getDrawData)
  const currentPoint = useStore(currentPointSelector)
  const points = useStore(pointsSelector)

  const handleChange = useCallback(
    (mode) => {
      setMode(mode)
    },
    [setMode]
  )

  return hotspot?.type === HOTSPOTS_TYPES_IDS.POLYGON ? (
    <Root
      mainDimension={48}
      firstItems={[
        <PanButton
          id={POINTER}
          selected={editorMode === POINTER}
          onClick={handleChange}
        />,
        <AddPolygonPointButton
          id={ADD_POINT}
          selected={editorMode === ADD_POINT}
          disabled={!currentPoint && contour.length > 0}
          onClick={handleChange}
        />,
        <RemovePolygonPointButton
          id={REMOVE_POINT}
          selected={editorMode === REMOVE_POINT}
          disabled={!points.length}
          onClick={handleChange}
        />,
        <AddPolygonButton
          id={ADD_HOLE}
          selected={editorMode === ADD_HOLE}
          disabled={contour.length < 3}
          onClick={handleChange}
        />
      ]}
      secondItems={[]}
    />
  ) : null
}
export default HEPolygonToolbar
