import { memo, useCallback, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'

import PropertyTreeDialogNode from '../PropertyTreeDialogNode'
import MediaSelectDialogContent from './MediaSelectDialogContent'
import MediaSelectDialogActions from './MediaSelectDialogActions'

const MediaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderTop: 'none',
  backgroundColor: theme.palette.primary.light,
  padding: 8,
  cursor: 'pointer'
}))

const Image = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: 100,
  height: 100
}))

const Video = styled('video')(({ theme }) => ({
  width: 100,
  height: 100
}))

const PropertyTreeMediaSelectNode = ({
  id,
  imageMode,
  medias, //[{name,src}]
  currentMediaSrc,
  onChange,
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [localMedias, setLocalMedias] = useState([])
  const [locaMediaSrc, setLocaMediaSrc] = useState(currentMediaSrc)

  useEffect(() => {
    setLocalMedias(medias.map((media, idx) => ({ ...media, id: idx })))
  }, [medias])

  useEffect(() => {
    setLocaMediaSrc(currentMediaSrc)
  }, [currentMediaSrc])

  const handleOpen = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleClose = useCallback(
    (ok) => {
      setDialogOpen(false)
      if (ok && onChange) {
        const media = localMedias.find((img) => img.src === locaMediaSrc)
        onChange(id, media ? media.name : locaMediaSrc)
      }
    },
    [onChange, id, locaMediaSrc, localMedias]
  )

  const handleChange = useCallback(
    (value) => {
      setLocaMediaSrc(localMedias[value].src)
    },
    [localMedias]
  )

  const handleMediaUrlChange = useCallback((id, value) => {
    setLocaMediaSrc(value)
  }, [])

  return (
    <>
      <PropertyTreeDialogNode
        id={id}
        text={currentMediaSrc}
        open={dialogOpen}
        onOpen={handleOpen}
        dialogTitle={imageMode ? 'Elige imagen' : 'Elige vídeo'}
        dialogContent={
          <MediaSelectDialogContent
            imageMode={imageMode}
            mediaSrc={locaMediaSrc}
            currentMediaSrc={currentMediaSrc}
            medias={localMedias}
            onChange={handleChange}
          />
        }
        dialogActions={
          <MediaSelectDialogActions
            mediaSrc={locaMediaSrc}
            onChange={handleMediaUrlChange}
            onClose={handleClose}
          />
        }
        {...props}
      />
      {currentMediaSrc && (
        <MediaContainer onClick={handleOpen}>
          {imageMode ? (
            <Image src={currentMediaSrc} alt={currentMediaSrc} />
          ) : (
            <Video src={currentMediaSrc} />
          )}
        </MediaContainer>
      )}
    </>
  )
}
export default memo(PropertyTreeMediaSelectNode)
