import PointerIcon from '../../Icons/PointerIcon'
import STButton from '../ScrollToolbar/STButton'

const PanButton = (props) => {
  return (
    <STButton
      tooltip={'Añadir hueco'}
      tooltipEnabled
      icon={<PointerIcon />}
      {...props}
    />
  )
}
export default PanButton
