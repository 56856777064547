import { memo, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../PropertyTree/Nodes/PropertyTreeSelectNode'

import useStore from '../../../modules/store'
import { cubesSelector } from '../../../modules/sceneDescriptionStore'

const CubeSelectNode = ({
  multiple,
  disabledItems,
  allowEmpty,
  onChange,
  ...props
}) => {
  const cubes = useStore(cubesSelector)

  const getItems = () => {
    let items = []
    if (multiple) {
      items = [
        <MenuItem key={-1} value={-1}>
          Marcar todos
        </MenuItem>,
        <MenuItem key={-2} value={-2}>
          Quitar todos
        </MenuItem>
      ]
    } else if (allowEmpty) {
      items = [
        <MenuItem key={-2} value={-2}>
          Ninguno
        </MenuItem>
      ]
    }
    items = [
      ...items,
      cubes.map((cube) => {
        return (
          <MenuItem
            key={cube.id}
            value={cube.id}
            disabled={disabledItems?.indexOf(cube.id) >= 0}
          >
            {cube.name.es}
          </MenuItem>
        )
      })
    ]
    return items
  }

  const handleChange = useCallback(
    (nodeId, value) => {
      if (onChange) {
        if (multiple) {
          if (value.indexOf(-1) >= 0) {
            onChange(
              nodeId,
              cubes.map((cube) => cube.id)
            )
          } else if (value.indexOf(-2) >= 0) {
            onChange(nodeId, [])
          } else {
            onChange(nodeId, value)
          }
        } else {
          if (value === -2) {
            onChange(nodeId, '')
          } else {
            onChange(nodeId, value)
          }
        }
      }
    },
    [onChange, cubes, multiple]
  )

  return (
    <PropertyTreeSelectNode
      options={getItems()}
      multiple={multiple}
      onChange={handleChange}
      {...props}
    />
  )
}
export default memo(CubeSelectNode)
