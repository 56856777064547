import {
  clamp
  // normalizeAngle
} from '../utils/utils'
import { Matrix4, Frustum } from 'three'
const FOV = { DEFAULT: 60, MIN: 20, MAX: 120 }

const m4 = new Matrix4()
const frustum = new Frustum()

const cameraStore = (set, get) => ({
  camera: {
    // camera: null,
    fov: FOV.DEFAULT,
    polarAngle: Math.PI / 2,
    azimuth: 0,
    enabled: true,

    wantedAzimuth: 0,
    wantendPolarAngle: 0,
    moveCamera: false,
    reset: () => {
      setWantedPovAction(get())({
        fov: FOV.DEFAULT,
        polar: Math.PI / 2,
        azimuth: 0
      })
    },
    setPolarAngle: (value) => {
      set((prev) => ({
        camera: { ...prev.camera, polarAngle: value }
      }))
    },
    setAzimuthalAngle: (value) => {
      set((prev) => ({
        camera: { ...prev.camera, azimuth: value }
      }))
    },

    setWantedPov: (pov) => {
      setFovAction(get())(pov.fov)
      setWantedPolarAngleAction(get())(pov.polar)
      setWantedAzimuthalAngleAction(get())(pov.azimuth)
    },

    setFov: (value) => {
      set((prev) => ({
        camera: { ...prev.camera, fov: clamp(value, FOV.MIN, FOV.MAX) }
      }))
    },
    zoomIn: (value) => setFovAction(get())(fovSelector(get()) - 1),
    zoomOut: (value) => setFovAction(get())(fovSelector(get()) + 1),
    resetZoom: (value) => setFovAction(get())(FOV.DEFAULT),

    setWantedPolarAngle: (value) => {
      set((prev) => ({
        camera: { ...prev.camera, wantedPolarAngle: value, moveCamera: true }
      }))
    },
    setWantedAzimuthalAngle: (value) => {
      set((prev) => ({
        camera: { ...prev.camera, wantedAzimuth: value, moveCamera: true }
      }))
    },
    cameraMoved: () => {
      set((prev) => ({
        camera: { ...prev.camera, moveCamera: false }
      }))
    },
    setEnabled: (enabled) => {
      set((prev) => ({
        camera: { ...prev.camera, enabled }
      }))
    }

    // setTheta: (value) =>
    //   set((prev) => ({
    //     camera: {
    //       ...prev.theta,
    //       theta: normalizeAngle(value, 0, Math.PI)
    //     }
    //   })),
    // setPhi: (value) =>
    //   set((prev) => ({
    //     camera: {
    //       ...prev.phi,
    //       phi: normalizeAngle(value, 0, 2 * Math.PI)
    //     }
    //   }))
  }
})
export default cameraStore

export const getCameraFrustum = (camera) => {
  if (camera === null) {
    return null
  }
  frustum.setFromProjectionMatrix(
    m4.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse)
  )
  return frustum
}

// export const setCameraAction = (state) => state.camera.setCamera
export const resetAction = (state) => state.camera.reset
export const setSceneAction = (state) => state.camera.setScene
export const setFovAction = (state) => state.camera.setFov
export const zoomInAction = (state) => state.camera.zoomIn
export const zoomOutAction = (state) => state.camera.zoomOut
export const resetZoomAction = (state) => state.camera.resetZoom
export const setAzimuthalAngleAction = (state) => state.camera.setAzimuthalAngle
export const setPolarAngleAction = (state) => state.camera.setPolarAngle
export const setWantedPovAction = (state) => state.camera.setWantedPov
export const setWantedAzimuthalAngleAction = (state) =>
  state.camera.setWantedAzimuthalAngle
export const setWantedPolarAngleAction = (state) =>
  state.camera.setWantedPolarAngle
export const cameraMovedAction = (state) => state.camera.cameraMoved
export const setEnabledAction = (state) => state.camera.setEnabled

// export const cameraSelector = (state) => state.camera.camera
export const enabledSelector = (state) => state.camera.enabled
export const fovSelector = (state) => state.camera.fov
export const polarAngleSelector = (state) => state.camera.polarAngle
export const azimuthSelector = (state) => state.camera.azimuth
export const wantedPolarAngleSelector = (state) => state.camera.wantedPolarAngle
export const wantedAzimuthSelector = (state) => state.camera.wantedAzimuth
export const moveCameraSelector = (state) => state.camera.moveCamera
