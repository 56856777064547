import { Line } from '@react-three/drei'

const Contour = ({
  contour, //[[x0,y0],[x1,y1],...]
  holes, //[[[x0,y0],[x1,y1],...],[[x,y],...]]
  color = 'black',
  width = 1
}) => {
  return contour?.length > 1 ? (
    <>
      <Line
        points={[...contour.map((point) => [...point, 0]), [...contour[0], 0]]}
        color={color}
        lineWidth={width}
        dashed={false}
        renderOrder={0}
      />
      {holes.map((hole, idx) => (
        <Line
          key={idx}
          points={[...hole.map((point) => [...point, 0]), [...hole[0], 0]]}
          color={color}
          lineWidth={width}
          dashed={false}
        />
      ))}
    </>
  ) : null
}

export default Contour
