import { useContext } from 'react'
import { styled } from '@mui/material/styles'

import { ScrollToolbarContext } from './ScrollToolbarContext'
const Root = styled('div', {
  name: 'ScrollToolbarItem',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    return styles.root
  },
  shouldForwardProp: (prop) => prop !== 'vertical' && prop !== 'mainDimension'
})(({ theme, vertical, mainDimension }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  boxSizing: 'border-box',
  padding: 4,
  //
  height: vertical ? null : mainDimension,
  minWidth: vertical ? null : mainDimension,
  //
  width: vertical ? mainDimension : null,
  minHeight: vertical ? mainDimension : null
}))

const STItem = ({ children }) => {
  const context = useContext(ScrollToolbarContext)

  return (
    <Root
      className={'ScrollToolbarItem-root'}
      vertical={context.vertical}
      mainDimension={context.mainDimension}
    >
      {children}
    </Root>
  )
}

export default STItem
