// import { useCallback } from 'react'

import { UnselectAllIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const UnselectAllHotspotsButton = (props) => {
  return (
    <STButton
      tooltip={'Deseleccionar todo'}
      tooltipEnabled
      {...props}
      icon={<UnselectAllIcon />}
    />
  )
}
export default UnselectAllHotspotsButton
