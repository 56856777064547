import { useMemo, useState, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'

import { PropertyTreeContext } from '../PropertyTreeContext'
import useStore from '../../../../modules/store'
import {
  setLabelWidthAction,
  getTree
} from '../../../../modules/propertyTreeStore'
const SPLITTER_BAR_WIDTH = 1

const Splitter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'labelWidth'
})(({ theme, labelWidth }) => ({
  position: 'absolute',
  top: 0,
  left: labelWidth,
  cursor: 'col-resize',
  height: '100%',
  touchAction: 'none'
}))

const SplitterBar = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  width: SPLITTER_BAR_WIDTH,
  minWidth: SPLITTER_BAR_WIDTH,
  height: '100%',
  cursor: 'col-resize',
  backgroundColor: theme.palette.primary.contrastText
}))

const SplitterTransparentBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'left' && prop !== 'transparentBarWidth'
})(({ theme, left, transparentBarWidth }) => ({
  position: 'absolute',
  left: left ? -transparentBarWidth : SPLITTER_BAR_WIDTH,
  top: 0,
  width: transparentBarWidth,
  minWidth: transparentBarWidth,
  height: '100%',
  cursor: 'col-resize',
  backgroundColor: 'transparent'
}))

const PropertyTreeSplitter = () => {
  const context = useContext(PropertyTreeContext)
  const [localLabelWidth, setLocalLabelWidth] = useState(0)
  const [first, setFirst] = useState(true)
  const gTree = useMemo(getTree, [])
  const tree = useStore((state) => gTree(state, context.treeId))
  const setLabelWidth = useStore(setLabelWidthAction)
  const bind = useGesture({
    onDrag: ({ movement, tap, down }) => {
      if (first) {
        setFirst(false)
        setLocalLabelWidth(tree.labelWidth)
        setLabelWidth(context.treeId, tree.labelWidth + movement[0])
      } else {
        setLabelWidth(context.treeId, localLabelWidth + movement[0])
      }
    },
    onDragEnd: () => {
      setFirst(true)
    }
  })

  return tree ? (
    <Splitter {...bind()} labelWidth={tree.labelWidth}>
      <SplitterTransparentBar
        left
        transparentBarWidth={context.splitterTransparentBarWidth}
      />
      <SplitterBar />
      <SplitterTransparentBar
        transparentBarWidth={context.splitterTransparentBarWidth}
      />
    </Splitter>
  ) : null
}
export default PropertyTreeSplitter
