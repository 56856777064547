import { createTheme, lighten } from '@mui/material/styles'

export const getPropertyTreeTheme = (baseTheme) => {
  const theme = createTheme({ ...baseTheme })

  theme.components = {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
        fullWidth: true,
        InputProps: { style: { margin: 0 } },
        hiddenLabel: true
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        disableUnderline: true,
        fullWidth: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }
      },
      styleOverrides: {
        root: {},
        icon: { color: 'inherit' },
        nativeInput: { boxSizing: 'border-box' }
      }
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 0,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            boxSizing: 'border-box'
          }
        },
        list: { padding: 0 }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: lighten(theme.palette.primary.light, 0.1)
          },
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            '&.Mui-selected': { backgroundColor: theme.palette.secondary.main }
          }
        }
      }
    },
    MuiInputLabel: { defaultProps: { shrink: false } },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          visibility: 'hidden'
        }
      }
    },
    MuiInput: {
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: { color: theme.palette.primary.contrastText },
        input: {
          padding: 0
          // '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          //   WebkitAppearance: 'none',
          //   margin: 0
          // },
          // appearance: 'textfield'
        }
      }
    }
  }
  return theme
}
