import { cloneObject } from '../utils/utils'
import { MODEL_ID, updateValueAction } from './textEditorModelsStore'
import { getSceneObject } from './sceneDescriptionStore'
import { getOutput as getEquirectangularImagesMapperOutput } from './equirectangularImagesMapperStore'
import { stylesSelector as getStyles } from './hotspotTypesStore'

const stringify = require('json-stringify-pretty-compact')

export const sceneTextEditorStore = (set, get) => ({
  sceneTextEditor: {
    modeJson: true,
    [MODEL_ID.SCENE]: null,
    [MODEL_ID.STYLES]: null,
    [MODEL_ID.MAPPER]: null,

    // init: (scene, mapper, styles) => {
    //   setModelAction(get())(MODEL_ID.SCENE, scene)
    //   setModelAction(get())(MODEL_ID.MAPPER, mapper)
    //   setModelAction(get())(MODEL_ID.STYLES, styles)
    // },
    setModel: (id, model) => {
      if (model) {
        updateValueAction(get())(
          id,
          stringify(model, { maxLength: 100, indent: 1 })
        )
        set((prev) => ({
          sceneTextEditor: {
            ...prev.sceneTextEditor,
            [id]: cloneObject(model)
          }
        }))
      }
    },
    readModel: (id) => {
      let model = null
      switch (id) {
        case MODEL_ID.SCENE:
          model = getSceneObject(get())
          break
        case MODEL_ID.STYLES:
          model = getStyles(get())
          break
        case MODEL_ID.MAPPER:
          model = getEquirectangularImagesMapperOutput(get())
          break
        default:
      }
      model && setModelAction(get())(id, model)
    }
  }
})
export default sceneTextEditorStore

// export const initAction = (state) => state.sceneTextEditor.init
export const setModelAction = (state) => state.sceneTextEditor.setModel
export const readModelAction = (state) => state.sceneTextEditor.readModel

export const modeJsonSelector = (state) => state.sceneTextEditor.modeJson
export const mapperSelector = (state) => state.sceneTextEditor.mapper
export const stylesSelector = (state) => state.sceneTextEditor.styles
