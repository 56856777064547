// import { useCallback } from 'react'
// import { useDispatch } from 'react-redux'

import { RemoveSelectedIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const RemoveButton = (props) => {
  return (
    <STButton
      tooltip={'Eliminar'}
      tooltipEnabled
      icon={<RemoveSelectedIcon />}
      {...props}
    />
  )
}
export default RemoveButton
