import { useEffect, useRef } from 'react'
import { useThree } from '@react-three/fiber'

import useStore from '../../modules/store'
import {
  hotspotsSelector,
  updateHotspotsAction
} from '../../modules/transformStore'

const Transforms = () => {
  const camera = useThree((state) => state.camera)
  const scene = useThree((state) => state.scene)
  const sphere = useRef()
  const hotspots = useStore(hotspotsSelector)
  const updateHotspots = useStore(updateHotspotsAction)

  useEffect(() => {
    sphere.current = scene.children.find(
      (o) => o.type === 'Mesh' && o.geometry.type === 'SphereGeometry'
    )
  }, [scene])
  useEffect(() => {
    if (hotspots.length) {
      updateHotspots(sphere.current, camera)
    }
  }, [hotspots, sphere, camera, updateHotspots])

  return null
}
export default Transforms
