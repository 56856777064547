// import AddPolygonPointIcon from '../../Icons/AddPolygonPointIcon'
import { AddPolygonPointIcon } from '../../Icons/Icons'

import STButton from '../ScrollToolbar/STButton'

const AddPolygonPointButton = (props) => {
  return (
    <STButton
      tooltip={'Añadir punto'}
      tooltipEnabled
      icon={<AddPolygonPointIcon />}
      {...props}
    />
  )
}
export default AddPolygonPointButton
