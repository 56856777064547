import { useMemo } from 'react'
import { styled } from '@mui/material/styles'

import useStore from '../../../../modules/store'
import { getFinishOptionPairMemo } from '../../../../modules/sceneDescriptionStore'
import { getImageSrc } from '../../../../modules/sceneDrawDataStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 8,
  color: 'white'
}))

const Image = styled('img')(({ theme }) => ({
  boxSizing: 'border-box',
  width: 100,
  height: 100,
  padding: 4,
  objectFit: 'contain'
}))

const Pair = ({ finishId, optionId }) => {
  const gImageSrc = useStore(getImageSrc)
  const gFinishOptionPairMemo = useMemo(getFinishOptionPairMemo, [])
  const { finish, option } = useStore((state) =>
    gFinishOptionPairMemo(state, optionId)
  )

  return finish && option ? (
    <Root>
      {option.imageSrc ? (
        <>
          {finish.name.es}
          <Image src={gImageSrc(option.imageSrc)} alt={option.name.es} />
          {option.name.es}
        </>
      ) : (
        `(${finish.name.es} - ${option.name.es})`
      )}
    </Root>
  ) : null
}

export default Pair
//
