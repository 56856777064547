import { multilanguageTextSchema, SCHEMA_URIS } from './schemas'
import { hotspotImageSchema } from './hotspotImageSchema'

export const position2DPropertySchema = {
  id: SCHEMA_URIS.POSITION2D_PROPERTY,
  type: 'object',
  properties: {
    value: { type: 'number' },
    pixels: { type: 'boolean' }
  },
  required: ['value', 'pixels'],
  additionalProperties: false,
  default: {
    value: 50,
    pixels: false
  }
}

export const position2DSchema = {
  id: SCHEMA_URIS.POSITION2D,
  type: 'object',
  properties: {
    reference: { type: 'string' },
    top: { $ref: SCHEMA_URIS.POSITION2D_PROPERTY },
    left: { $ref: SCHEMA_URIS.POSITION2D_PROPERTY },
    bottom: { $ref: SCHEMA_URIS.POSITION2D_PROPERTY },
    right: { $ref: SCHEMA_URIS.POSITION2D_PROPERTY }
  },

  default: {
    reference: 'tl',
    top: position2DPropertySchema.default,
    left: position2DPropertySchema.default
    // bottom: position2DPropertySchema.default,
    // right: position2DPropertySchema.default
  }
}

export const hotspotEventSchema = {
  id: SCHEMA_URIS.HOTSPOT_EVENT,
  type: 'object',
  properties: {
    hideHotspots: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
    showHotspots: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
    goto: {
      type: 'object',
      properties: { id: { type: 'string', pattern: '^$|p[0-9]+$' } }
    },
    selectOptions: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST }
  },
  // required: ['hideHotspots', 'showHotspots', 'goto', 'selectOptions'],
  required: [],
  default: {
    hideHotspots: [],
    showHotspots: [],
    goto: { id: '' },
    selectOptions: []
  }
}

export const hotspotSchema = {
  id: SCHEMA_URIS.HOTSPOT,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^h[0-9]+$' },
    name: { $ref: SCHEMA_URIS.MULTILANGUAGE_TEXT },
    type: { type: 'string', minLength: 1 },
    style: { type: 'string' },
    anchor: { type: 'string', minLength: 1 },
    position2D: { $ref: SCHEMA_URIS.POSITION2D },
    position3D: { $ref: SCHEMA_URIS.POINT3D },
    polygonData: { $ref: SCHEMA_URIS.POLYGON_DATA },
    data: { type: 'object' },
    onStart: { $ref: SCHEMA_URIS.HOTSPOT_EVENT },
    onHover: { $ref: SCHEMA_URIS.HOTSPOT_EVENT },
    onOut: { $ref: SCHEMA_URIS.HOTSPOT_EVENT },
    onClick: { $ref: SCHEMA_URIS.HOTSPOT_EVENT }
  },
  required: [
    'id',
    'name',
    'type',
    'style',
    'data',
    'onStart',
    'onHover',
    'onOut',
    'onClick'
  ],
  additionalProperties: false,
  default: {
    id: 'h0',
    name: multilanguageTextSchema.default,
    type: 'IMAGE',
    style: '',
    data: hotspotImageSchema.default,
    anchor: 'cc',
    position2D: position2DSchema.default,
    onStart: hotspotEventSchema.default,
    onHover: hotspotEventSchema.default,
    onOut: hotspotEventSchema.default,
    onClick: hotspotEventSchema.default
  }
}
