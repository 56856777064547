import { createSelector } from 'reselect'
import { findById, getLastNotUsedName } from '../utils/utils'
import { validateScene } from './sceneDescriptionStore'
import { validateMapper } from './equirectangularImagesMapperStore'

export const EQUIRECTANGULAR_FOLDER_NAME = 'equirectangular_images'
const CUBE_FOLDER_NAME = 'cube_images'
const MEDIA_FOLDER_NAME = 'media'
export const SETTINGS_FILE_NAME = 'settings.json'
export const IMAGE_MAP_FILE_NAME = 'equirectangularImagesMapper.json'
export const STYLES_FILE_NAME = 'styles.json'

const defaultData = {
  equirectangularFolders: [], //[{id,name:'folder/folder/...', path:[folder,folder,...]}]
  equirectangularImages: [], //[{id,file,name,folderId:''}]
  cubeImages: [],
  images: [],
  videos: []
}
export const projectFolderStore = (set, get) => ({
  projectFolder: {
    loaded: false,
    ...defaultData,
    setData: (project) => {
      set((st) => ({
        projectFolder: {
          ...st.projectFolder,
          ...(project ? { ...project } : { ...defaultData })
        }
      }))
    },
    setLoaded: (value) => {
      set((st) => ({
        projectFolder: {
          ...st.projectFolder,
          loaded: value
        }
      }))
    },
    setFileList: (fileList) => {
      set((st) => ({
        projectFolder: {
          ...st.projectFolder,
          loaded: true,
          ...readProject(fileList)
        }
      }))
    }
  }
})
export default projectFolderStore

export const readProject = async (fileList) => {
  const files = Array.from(fileList)
  let { images: imgs, videos, jsons } = filterFiles(files)
  //Jsons
  const {
    settingsFile,
    equirectangularImagesMapperFile,
    stylesFile
  } = filterJsons(jsons)
  const scene = await validateFile(settingsFile?.file, validateScene)
  const mapper = await validateFile(
    equirectangularImagesMapperFile?.file,
    validateMapper
  )
  const styles = await validateFile(stylesFile?.file, null)
  const errorsCount =
    getFileErrorsCount(scene.errors) + getFileErrorsCount(mapper.errors)
  if (!errorsCount) {
    //Images
    const {
      equirectangularFolders,
      equirectangularImages,
      cubeImages,
      images
    } = filterImages(imgs)
    //Videos
    videos = filterVideos(videos)

    return {
      files: {
        equirectangularFolders,
        equirectangularImages,
        cubeImages,
        images,
        videos
      },
      scene,
      mapper,
      styles
    }
  } else {
    return {
      files: {
        equirectangularFolders: [],
        equirectangularImages: [],
        cubeImages: [],
        images: [],
        videos: []
      },
      scene,
      mapper
    }
  }
}

const filterFiles = (files) => {
  const jsonTypes = ['application/json']
  const imageTypes = ['image/jpeg', 'image/png']
  const videoTypes = ['video/mp4', 'video/avi', 'video/mpeg']
  return files.reduce(
    (acc, file) => {
      if (jsonTypes.indexOf(file.type) >= 0) {
        acc.jsons.push(file)
      } else if (imageTypes.indexOf(file.type) >= 0) {
        acc.images.push(file)
      } else if (videoTypes.indexOf(file.type) >= 0) {
        acc.videos.push(file)
      }

      return acc
    },
    { images: [], videos: [], jsons: [] }
  )
}

const filterJsons = (jsons) => {
  return jsons
    .map((jsonFile, idx) => getFileObj('j', idx, jsonFile))
    .reduce(
      (acc, json) => {
        if (json.path.length === 0) {
          //Deja json que no esten en una carpeta y clasifica
          if (json.name === SETTINGS_FILE_NAME) {
            acc.settingsFile = json
          } else if (json.name === IMAGE_MAP_FILE_NAME) {
            acc.equirectangularImagesMapperFile = json
          } else if (json.name === STYLES_FILE_NAME) {
            acc.stylesFile = json
          }
        }
        return acc
      },
      {
        settingsFile: null,
        equirectangularImagesMapperFile: null,
        stylesFile: null
      }
    )
}
const filterImages = (images) => {
  return images
    .map((imageFile, idx) => getFileObj('i', idx, imageFile))
    .reduce(
      (acc, image) => {
        if (image.path.length >= 1) {
          //Deja imagenes que esten en una carpeta y clasifica
          if (image.path[0] === EQUIRECTANGULAR_FOLDER_NAME) {
            const path = image.path.slice(1)
            let pathName = path.join('/')
            //Busca carpeta de la imagen
            let folder = acc.equirectangularFolders.find(
              (folder) => folder.name === pathName
            )
            if (!folder) {
              //No estaba todavia. Crear
              const { name: id } = getLastNotUsedName(
                acc.equirectangularFolders,
                'id',
                'f',
                ''
              )
              folder = { id, name: pathName, path: [...path] }
              acc.equirectangularFolders.push(folder)
            }
            //Añadir imagen
            acc.equirectangularImages.push({
              id: image.id,
              name: image.name,
              path: image.path,
              folderId: folder.id,
              url: URL.createObjectURL(image.file)
            })
          } else if (
            image.path.length === 1 &&
            image.path[0] === CUBE_FOLDER_NAME
          ) {
            //No se permiten subcarpetas
            acc.cubeImages.push({
              id: image.id,
              name: image.name,
              path: image.path,
              url: URL.createObjectURL(image.file)
            })
          } else if (image.path[0] === MEDIA_FOLDER_NAME) {
            acc.images.push({
              id: image.id,
              name: image.name,
              path: image.path,
              url: URL.createObjectURL(image.file)
            })
          } else {
          }
        }
        return acc
      },
      {
        equirectangularFolders: [],
        equirectangularImages: [],
        cubeImages: [],
        images: []
      }
    )
}

const filterVideos = (videos) => {
  return videos
    .map((videoFile, idx) => getFileObj('v', idx, videoFile))
    .reduce((acc, video) => {
      if (video.path[0] === MEDIA_FOLDER_NAME) {
        acc.push({
          id: video.id,
          name: video.name,
          path: video.path,
          url: URL.createObjectURL(video.file)
        })
      }
      return acc
    }, [])
}

const getFileObj = (prefix, idx, file) => {
  return {
    id: `${prefix}${idx}`,
    file,
    name: file.name,
    path: getFilePath(file)
  }
}

const getFilePath = (file) => {
  const path = file.webkitRelativePath.split('/')
  path.pop()
  path.shift()
  return path
}

const validateFile = async (file, validator) => {
  try {
    return validator
      ? validator(await readFile(file))
      : {
          data: await readFile(file),
          errors: { errors: [], schemaErrors: [], relationalErrors: [] }
        }
  } catch (e) {
    return {
      errors: { errors: [e], schemaErrors: [], relationalErrors: [] },
      data: null
    }
  }
}

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file'))
    }
    const fileReader = new FileReader()
    fileReader.onloadend = (e) => {
      const content = fileReader.result
      resolve(JSON.parse(content))
    }
    fileReader.onerror = () => {
      reject(fileReader.error)
    }
    fileReader.onabort = () => {
      reject(new Error('Abort'))
    }
    fileReader.readAsText(file)
  })
}

export const getFileErrorsCount = (errors) => {
  return (
    errors.errors.length +
    errors.relationalErrors.length +
    errors.schemaErrors.length
  )
}

export const setDataAction = (state) => state.projectFolder.setData
export const setLoadedAction = (state) => state.projectFolder.setLoaded
export const setFileListAction = (state) => state.projectFolder.setFileList

export const loadedSelector = (state) => state.projectFolder.loaded
export const equirectangularFoldersSelector = (state) =>
  state.projectFolder.equirectangularFolders
export const equirectangularImagesSelector = (state) =>
  state.projectFolder.equirectangularImages
export const cubeImagesSelector = (state) => state.projectFolder.cubeImages
export const imagesSelector = (state) => state.projectFolder.images
export const videosSelector = (state) => state.projectFolder.videos

export const getCubeFaceImage = createSelector(
  [cubeImagesSelector],
  (cubeImages) => (name) => {
    return cubeImages.find((image) => image.name === name)
  }
)

export const getEquirectangularFolder = (state) => (id) => {
  return findById(equirectangularFoldersSelector(state), id)
}

export const getEquirectangularImageMemo = () =>
  createSelector(
    [equirectangularImagesSelector, (_, id) => id],
    (equirectangularImages, id) => {
      return findById(equirectangularImages, id)
    }
  )

export const getEquirectangularImageById = (state) => (id) => {
  const image = findById(equirectangularImagesSelector(state), id)
  if (image) {
    const folder = getEquirectangularFolder(state)(image.folderId)
    if (folder) {
      const relative = `${folder.name}/${image.name}`
      return {
        image,
        relative,
        absolute: `${EQUIRECTANGULAR_FOLDER_NAME}/${relative}`
      }
    }
  }
  return { image, relative: '', absolute: '' }
}

export const getEquirectangularImageBySrc = (state) => (src) => {
  if (!src) {
    return null
  }
  const path = src.split('/')
  const name = path.pop()
  return equirectangularImagesSelector(state).find(
    (image) => image.name === name
  )
}

export const getEquirectangularFolderByName = (state) => (name) => {
  return equirectangularFoldersSelector(state).find(
    (folder) => folder.name === name
  )
}

export const getEquirectangularFolderMemo = () =>
  createSelector(
    [
      equirectangularFoldersSelector,
      equirectangularImagesSelector,
      (_, id) => id
    ],
    (items, equirectangularImages, id) => {
      const folder = findById(items, id)
      return folder
        ? {
            folder,
            images: equirectangularImages.filter(
              (image) => image.folderId === folder.id
            )
          }
        : null
    }
  )

export const getEquirectangularFolders = createSelector(
  [equirectangularFoldersSelector, equirectangularImagesSelector],
  (equirectangularFolders, equirectangularImages) => {
    return equirectangularFolders.map((folder) => {
      return {
        folder,
        images: equirectangularImages.filter(
          (image) => image.folderId === folder.id
        )
      }
    })
  }
)

export const getEquirectangularFolderImages = createSelector(
  [equirectangularFoldersSelector, equirectangularImagesSelector],
  (equirectangularFolders, equirectangularImages) => {
    return equirectangularFolders.map((folder) => {
      return {
        folder,
        images: equirectangularImages.filter(
          (image) => image.folderId === folder.id
        )
      }
    })
  }
)

export const getProjectFolderErrors = createSelector(
  [equirectangularImagesSelector, cubeImagesSelector],
  (equirectangularImages, cubeImages) => {
    const errors = []
    if (equirectangularImages.length === 0 && cubeImages.length < 6) {
      //¿Puedo trabajar con una escena sin images?
      // errors.push('No hay imagenes')
    }
    if (cubeImages.length % 6 !== 0) {
      errors.push(`Nº imagenes en ${CUBE_FOLDER_NAME} debe ser multiplo de 6.`)
    }
    return errors
  }
)

export const getImageByName = createSelector(
  [imagesSelector],
  (images) => (name) => {
    return images.find((img) => img.name === name)
  }
)

export const getVideoByName = createSelector(
  [videosSelector],
  (videos) => (name) => {
    return videos.find((v) => v.name === name)
  }
)
