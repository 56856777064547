import { useRef, useEffect } from 'react'
import { useGesture } from 'react-use-gesture'
import * as THREE from 'three'

import useStore from '../../../modules/store'
import {
  currentTextureSelector,
  getTexture0,
  getTexture1
} from '../../../modules/texturesStore'
import { reloadTextureAction } from '../../../modules/sceneDrawDataStore'
import { setDraggingAction } from '../../../modules/pointerEventsStore'

function Sphere(props) {
  const ref = useRef()
  const currentTexture = useStore(currentTextureSelector)
  const setDragging = useStore(setDraggingAction)
  const reloadTexture = useStore(reloadTextureAction)
  const texture0 = useStore(getTexture0)
  const texture1 = useStore(getTexture1)
  const texture = currentTexture === 0 ? texture0 : texture1

  console.log('Sphere')

  useEffect(() => {
    reloadTexture()
  }, [reloadTexture])

  const bind = useGesture(
    {
      onDragStart: () => {
        setDragging(true)
      },
      onDrag: ({ tap }) => {},
      onDragEnd: ({ tap }) => {
        // if (tap) {
        //   console.log('click')
        // } else {
        //   console.log('end')
        // }
        setDragging(false)
      }
    },
    { filterTaps: true }
  )

  return (
    <mesh ref={ref} {...bind()}>
      <sphereGeometry args={[1500]} />
      <meshBasicMaterial
        wireframe={!Boolean(texture?.texture)}
        map={texture?.texture}
        args={[
          {
            side: THREE.BackSide,
            depthTest: true,
            depthWrite: false,
            fog: false
          }
        ]}
      />
    </mesh>
  )
}
export default Sphere
