import { SCHEMA_URIS } from './schemas'
export const POLYGON_FILL_TYPE_IDS = {
  EMPTY: 'EMPTY',
  COLOR: 'COLOR',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
}

export const polygonSchema = {
  id: SCHEMA_URIS.POLYGON_DATA,
  type: 'object',
  properties: {
    rotation: {
      $ref: SCHEMA_URIS.POINT3D
    },
    position: {
      $ref: SCHEMA_URIS.POINT3D
    },
    contour: {
      type: 'array',
      items: { type: 'number' }
    }, //[x0,y0,x1,y1,...]
    holes: {
      type: 'array',
      items: {
        type: 'array',
        items: { type: 'number' }
      } //[[x0,y0,x1,y1,...],[x,y,...]]
    }
  },
  required: ['rotation', 'position', 'contour', 'holes'],
  additionalProperties: false,
  default: {
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    contour: [],
    holes: []
  }
}

export const hotspotPolygonEmptyFillSchema = {
  id: SCHEMA_URIS.POLYGON_EMPTY,
  type: 'object',
  properties: {},
  required: [],
  additionalProperties: false,
  default: {}
}

export const hotspotPolygonColorFillSchema = {
  id: SCHEMA_URIS.POLYGON_COLOR,
  type: 'object',
  properties: {
    color: { type: 'string', minLength: 1 },
    hover: { type: 'string', minLength: 1 }
  },
  required: ['color', 'hover'],
  additionalProperties: false,
  default: { color: '#FF000080', hover: '#FF0000A0' }
}

export const hotspotPolygonVideoFillSchema = {
  id: SCHEMA_URIS.POLYGON_VIDEO,
  type: 'object',
  properties: { src: { type: 'string' } },
  required: ['src', 'hover'],
  additionalProperties: false,
  default: { src: '' }
}

export const hotspotPolygonFillSchema = {
  id: SCHEMA_URIS.POLYGON_FILL,
  type: 'object',
  properties: {
    type: {
      type: 'string',
      enum: [
        POLYGON_FILL_TYPE_IDS.EMPTY,
        POLYGON_FILL_TYPE_IDS.COLOR,
        POLYGON_FILL_TYPE_IDS.IMAGE,
        POLYGON_FILL_TYPE_IDS.VIDEO
      ]
    },
    data: {
      type: 'object',
      properties: { value: { type: 'string' } },
      required: ['value']
    }
  },
  required: ['type', 'data'],
  additionalProperties: false,
  default: {
    type: POLYGON_FILL_TYPE_IDS.COLOR,
    data: hotspotPolygonColorFillSchema.default
  }
}

export const hotspotPolygonBorderSchema = {
  id: SCHEMA_URIS.POLYGON_BORDER,
  type: 'object',
  properties: {
    color: {
      type: 'string'
    },
    width: {
      type: 'number',
      minimum: 0.5,
      maximum: 5
    }
  },
  required: ['color', 'width'],
  additionalProperties: false,
  default: {
    color: '#0000FF',
    width: 1
  }
}

export const hotspotPolygonSchema = {
  id: SCHEMA_URIS.HOTSPOT_POLYGON,
  type: 'object',
  properties: {
    fill: {
      $ref: SCHEMA_URIS.POLYGON_FILL,
      myData: {
        type: 'fill',
        name: 'Relleno',
        description: 'Relleno del polígono'
      }
    },
    border: {
      $ref: SCHEMA_URIS.POLYGON_BORDER,
      myData: {
        type: 'border',
        name: 'Borde',
        description: 'Borde del polígono'
      }
    }
  },
  required: ['fill', 'border'],
  additionalProperties: false,
  default: {
    fill: hotspotPolygonFillSchema.default,
    border: hotspotPolygonBorderSchema.default
  }
}

export const POLYGON_FILL_TYPES = [
  {
    id: POLYGON_FILL_TYPE_IDS.EMPTY,
    name: 'Vacío',
    schema: hotspotPolygonEmptyFillSchema
  },
  {
    id: POLYGON_FILL_TYPE_IDS.COLOR,
    name: 'Color',
    schema: hotspotPolygonColorFillSchema
  },
  {
    id: POLYGON_FILL_TYPE_IDS.IMAGE,
    name: 'Imagen',
    schema: hotspotPolygonEmptyFillSchema
  },
  {
    id: POLYGON_FILL_TYPE_IDS.VIDEO,
    name: 'Vídeo',
    schema: hotspotPolygonVideoFillSchema
  }
]

export const getFillType = (id) => {
  return POLYGON_FILL_TYPES.find((type) => type.id === id)
}
