import { point3DSchema, povSchema, SCHEMA_URIS } from './schemas'

export const languagesSchema = {
  id: SCHEMA_URIS.LANGUAGES_LIST,
  type: 'array',
  items: { type: 'string' },
  minItems: 1,
  default: ['es', 'en']
}

export const cubeImagesSchema = {
  id: SCHEMA_URIS.CUBE_IMAGES,
  type: 'object',
  properties: {
    prefix: { type: 'string' },
    suffix: { type: 'string' }
  },
  required: ['prefix', 'suffix'],
  additionalProperties: false,
  default: {
    prefix: 'cube_images/',
    suffix: '.jpg'
  }
}

export const cubeSchema = {
  id: SCHEMA_URIS.CUBE,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^p[0-9]+$' },
    imageIdx: { type: 'integer', minimum: 0 },
    name: { $ref: SCHEMA_URIS.MULTILANGUAGE_TEXT },
    defaultView: { $ref: SCHEMA_URIS.POINT_OF_VIEW },
    point: { $ref: SCHEMA_URIS.POINT3D },
    hotspots: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
    finishs: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
    onStart: {
      type: 'object',
      properties: {
        hideHotspots: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
        showHotspots: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
        selectOptions: { $ref: SCHEMA_URIS.UNIQUE_STRING_LIST },
        lookAt: { $ref: SCHEMA_URIS.POINT_OF_VIEW }
      },
      additionalProperties: false
    }
  },
  required: [
    'id',
    'imageIdx',
    'name',
    'defaultView',
    'point',
    'hotspots',
    'finishs',
    'onStart'
  ],
  additionalProperties: false,
  default: {
    id: 'p1',
    imageIdx: 1,
    name: { es: 'Nombre del cubo', en: 'Cube name' },
    defaultView: povSchema.default,
    point: point3DSchema.default,
    hotspots: [],
    finishs: ['a1'],
    onStart: { hideHotspots: [], showHotspots: [], selectOptions: [] }
  }
}

export const finishSchema = {
  id: SCHEMA_URIS.FINISH,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^a[0-9]+$' },
    name: { $ref: SCHEMA_URIS.MULTILANGUAGE_TEXT },
    options: { $ref: SCHEMA_URIS.NOT_EMPTY_UNIQUE_STRING_LIST }
  },
  required: ['id', 'name', 'options'],
  additionalProperties: false,
  default: {
    id: 'a1',
    name: { es: 'Acabado', en: 'Finish' },
    options: ['op1']
  }
}

export const optionSchema = {
  id: SCHEMA_URIS.OPTION,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^op[0-9]+$' },
    name: { $ref: SCHEMA_URIS.MULTILANGUAGE_TEXT },
    imageSrc: { type: 'string' }
  },
  required: ['id', 'name', 'imageSrc'],
  additionalProperties: false,
  default: {
    id: 'op1',
    name: { es: 'Opción', en: 'Option' },
    imageSrc: ''
  }
}

export const sceneDefaultCfgSchema = {
  id: SCHEMA_URIS.SCENE_DEFAULT_CONFIGURATION,
  type: 'object',
  properties: {
    cube: { type: 'string', minLength: 1 },
    options: { $ref: SCHEMA_URIS.NOT_EMPTY_UNIQUE_STRING_LIST }
  },
  required: ['cube', 'options'],
  additionalProperties: false,
  default: {
    cube: 'p1',
    options: ['op1']
  }
}

export const sceneSchema = {
  id: SCHEMA_URIS.SCENE,
  type: 'object',
  properties: {
    id: { type: 'string', minLength: 1, pattern: '^s[0-9]+$' },
    languages: { $ref: SCHEMA_URIS.LANGUAGES_LIST },
    name: { $ref: SCHEMA_URIS.MULTILANGUAGE_TEXT },
    cubeImages: { $ref: SCHEMA_URIS.CUBE_IMAGES },
    cubes: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.CUBE },
      uniqueItems: true,
      minItems: 1
    },
    hotspots: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.HOTSPOT },
      uniqueItems: true
    },
    finishs: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.FINISH },
      uniqueItems: true,
      minItems: 1
    },
    options: {
      type: 'array',
      items: { $ref: SCHEMA_URIS.OPTION },
      uniqueItems: true,
      minItems: 1
    },
    default: { $ref: SCHEMA_URIS.SCENE_DEFAULT_CONFIGURATION }
  },
  required: [
    'id',
    'languages',
    'name',
    'cubeImages',
    'cubes',
    'hotspots',
    'finishs',
    'options',
    'default'
  ],
  additionalProperties: false,
  default: {
    id: 's1',
    languages: languagesSchema.default,
    name: { es: 'Escena', en: 'Scene' },
    cubeImages: cubeImagesSchema.default,
    cubes: [cubeSchema.default],
    hotspots: [],
    finishs: [finishSchema.default],
    options: [optionSchema.default],
    default: sceneDefaultCfgSchema.default
  }
}
