import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import ScrollToolbar from '../../../Common/ScrollToolbar/ScrollToolbar'
import DraggableList from '../../../Common/DraggableList/DraggableList'

import SelectAllButton from '../../../Common/ToolbarButtons/SelectAllButton'
import UnselectAllButton from '../../../Common/ToolbarButtons/UnselectAllButton'
import RemoveButton from '../../../Common/ToolbarButtons/RemoveButton'
import OptionItem from './OptionItem'

import { useStore } from '../../../../modules/store'
import {
  getFinishOptions,
  selectedOptionsSelector,
  swapOptionsByIdAction,
  selectAllOptionsAction,
  setSelectedOptionsAction,
  removeOptionsAction
} from '../../../../modules/finishEditorStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
  width: '100%',
  minHeight: 0,
  boxSizing: 'border-box',
  // border: `1px solid ${theme.palette.primary.contrastText}`,
  borderTop: 'none'
}))

const SScrollToolbar = styled(ScrollToolbar)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderTop: 'none',
  borderBottom: 'none'
}))

const OptionList = () => {
  const options = useStore(getFinishOptions)
  const selectedOptions = useStore(selectedOptionsSelector)
  const swapOptionsById = useStore(swapOptionsByIdAction)
  const selectAllOptions = useStore(selectAllOptionsAction)
  const setSelectedOptions = useStore(setSelectedOptionsAction)
  const removeOptions = useStore(removeOptionsAction)

  const handleSwap = useCallback(
    (fromIdx, toIdx) => {
      swapOptionsById(options[fromIdx].id, options[toIdx].id)
    },
    [swapOptionsById, options]
  )

  const handleSelectAll = useCallback(
    (e) => {
      selectAllOptions()
    },
    [selectAllOptions]
  )

  const handleUnselectAll = useCallback(
    (e) => {
      setSelectedOptions([])
    },
    [setSelectedOptions]
  )

  const handleRemoveSelected = useCallback(
    (e) => {
      removeOptions(selectedOptions)
    },
    [removeOptions, selectedOptions]
  )

  return (
    <Root>
      <SScrollToolbar
        mainDimension={32}
        firstItems={[
          <RemoveButton
            disabled={options.length === 0 || selectedOptions.length === 0}
            onClick={handleRemoveSelected}
          />
        ]}
        secondItems={[
          <SelectAllButton
            disabled={
              options.length === 0 || selectedOptions.length === options.length
            }
            onClick={handleSelectAll}
          />,
          <UnselectAllButton
            disabled={options.length === 0 || selectedOptions.length === 0}
            onClick={handleUnselectAll}
          />
        ]}
      />
      <DraggableList onSwap={handleSwap}>
        {options.map((option, index) => (
          <OptionItem key={option.id} id={option.id} index={index} />
        ))}
      </DraggableList>
    </Root>
  )
}
export default OptionList
