import * as THREE from 'three'

export const ShaderCube = {
  uniforms: {
    progress: {
      type: 'f',
      value: 1.0
    },
    opacity: {
      type: 'f',
      value: 1
    },
    renderTransparent: {
      type: 'f',
      value: 0
    },
    initialTextureCube: {
      type: 't',
      value: null
    },
    initialPositionCube: {
      type: 'v3',
      value: new THREE.Vector3()
    },
    initialMatrixCube: {
      type: 'm4',
      value: new THREE.Matrix4()
    },
    lastTextureCube: {
      type: 't',
      value: null
    },
    lastPositionCube: {
      type: 'v3',
      value: new THREE.Vector3()
    },
    lastMatrixCube: {
      type: 'm4',
      value: new THREE.Matrix4()
    },
    numCapasInitial: {
      type: 'f',
      value: 0
    },
    numCapasLast: {
      type: 'f',
      value: 0
    }
  },
  vertexShader: `uniform vec3 initialPositionCube;
    uniform mat4 initialMatrixCube;
    varying vec3 initialPositionWorld;

    uniform vec3 lastPositionCube;
    uniform mat4 lastMatrixCube;
    varying vec3 lastPositionWorld;

    varying vec2 vUv;

    void main() {
      vUv = uv;

      vec4 worldPosition = modelMatrix * vec4(position, 1.0);

      vec3 initialPositionCenter = worldPosition.xyz - initialPositionCube;
      initialPositionWorld = (vec4(initialPositionCenter, 1.0) * initialMatrixCube).xyz;
      initialPositionWorld.x *= -1.0;

      vec3 lastPositionCenter = worldPosition.xyz - lastPositionCube;
      lastPositionWorld = (vec4(lastPositionCenter, 1.0) * lastMatrixCube).xyz;
      lastPositionWorld.x *= -1.0;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
  fragmentShader: `uniform float opacity;
    varying vec2 vUv;
    uniform float renderTransparent;

    uniform float progress;

    uniform samplerCube initialTextureCube;
    varying vec3 initialPositionWorld;

    uniform samplerCube lastTextureCube;
    varying vec3 lastPositionWorld;

    uniform float numCapasInitial;

    uniform float numCapasLast;

    void colorCapa(samplerCube capaTextureCube,vec3 position, out vec4 color, out float colorSetted) {
      vec4 overlayColor = textureCube(capaTextureCube, position);
      if (overlayColor.a >= 0.9) {
        color = overlayColor;
        colorSetted = 1.0;
      }
    }

    void setColorCapa(
      float numCapas,
      vec3 position,
      samplerCube textureCubeBase,
      out vec4 color
    ) {
      float colorSetted = 0.0;

      if (colorSetted == 0.0) {
        color = textureCube(textureCubeBase, position);
      }
    }

    void main() {
      vec4 initialColor;
      vec3 initialPosition = initialPositionWorld.xyz;

      setColorCapa(
        numCapasInitial,
        initialPosition,
        initialTextureCube,
        initialColor
      );

      vec4 lastColor;
      vec3 lastPosition = lastPositionWorld.xyz;

      setColorCapa(
        numCapasLast,
        lastPosition,
        lastTextureCube,
        lastColor
      );

      vec4 color = mix(initialColor, lastColor, progress);

      gl_FragColor = vec4(color.rgb, opacity);
    }`
}
/*
float colorSetted = 0.0;
vec4 initialColor;
vec3 initialPosition = initialPositionWorld.xyz;

if (colorSetted == 0.0 && numCapaInitial >= 1.0) {
  colorCapa(capaInitialTextureCube0,initialPosition, initialColor, colorSetted);
}

if (colorSetted == 0.0 && numCapaInitial >= 2.0) {
  colorCapa(capaInitialTextureCube1,initialPosition, initialColor, colorSetted);
}

if (colorSetted == 0.0 && numCapaInitial >= 3.0) {
  colorCapa(capaInitialTextureCube2,initialPosition, initialColor, colorSetted);
}

if (colorSetted == 0.0 && numCapaInitial >= 4.0) {
  colorCapa(capaInitialTextureCube3,initialPosition, initialColor, colorSetted);
}

if (numCapaInitial == 5.0) {
  colorCapa(capaInitialTextureCube4,initialPosition, initialColor, colorSetted);
}

if (colorSetted == 0.0) {
  initialColor = textureCube(initialTextureCube, initialPosition);
}
*/
export default ShaderCube
