import { useCallback, memo } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../../PropertyTree/Nodes/PropertyTreeSelectNode'
import PixelPorcentageNode from './PixelPorcentageNode'
import { REFERENCES, getReference } from '../../../../modules/positioning2D'

const Position2DNode = ({
  id,
  value,
  onReferenceTypeChange,
  onChange,
  bounds,
  ...props
}) => {
  const currentReference = getReference(value?.reference)

  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === `${id}:top`) {
          onChange(id, { ...value, top: newValue })
        } else if (nodeId === `${id}:left`) {
          onChange(id, { ...value, left: newValue })
        } else if (nodeId === `${id}:bottom`) {
          onChange(id, { ...value, bottom: newValue })
        } else if (nodeId === `${id}:right`) {
          onChange(id, { ...value, right: newValue })
        }
      }
    },
    [id, onChange, value]
  )

  return (
    <PropertyTreeSelectNode
      id={id}
      label={'Posición 2D'}
      value={value.reference}
      onChange={onReferenceTypeChange}
      options={[
        REFERENCES.map((reference) => {
          return (
            <MenuItem key={reference.id} value={reference.id}>
              {reference.name}
            </MenuItem>
          )
        })
      ]}
      {...props}
    >
      {currentReference.properties.map((property) => (
        <PixelPorcentageNode
          key={property.id}
          id={property.id}
          label={property.name}
          value={value[property.id]}
          length={bounds[property.length]}
          onChange={handleChange}
        />
      ))}
    </PropertyTreeSelectNode>
  )
}
export default memo(Position2DNode)
