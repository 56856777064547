import { memo, useCallback } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyMultipleSelectNode from '../PropertyTree/Nodes/PropertyMultipleSelectNode'
import useStore from '../../../modules/store'
import { finishsSelector } from '../../../modules/sceneDescriptionStore'

const FinishSelectNode = ({ onChange, ...props }) => {
  const finishs = useStore(finishsSelector)

  const handleChange = useCallback(
    (nodeId, value) => {
      onChange && onChange(nodeId, value)
    },
    [onChange]
  )

  const handleSelectAll = useCallback(
    (nodeId, value) => {
      onChange &&
        onChange(
          nodeId,
          finishs.map((f) => f.id)
        )
    },
    [onChange, finishs]
  )

  const handleClear = useCallback(
    (nodeId, value) => {
      console.log(nodeId)
      onChange && onChange(nodeId, [])
    },
    [onChange]
  )
  return (
    <PropertyMultipleSelectNode
      options={[
        finishs.map((finish) => {
          return (
            <MenuItem key={finish.id} value={finish.id}>
              {finish.name.es}
            </MenuItem>
          )
        })
      ]}
      onChange={handleChange}
      onSelectAll={handleSelectAll}
      onClearSelection={handleClear}
      {...props}
    />
  )
}
export default memo(FinishSelectNode)
