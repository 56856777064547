import { styled, lighten } from '@mui/material/styles'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import InnerList from './InnerList'

const ListContainer = styled('div')(({ theme }) => ({
  minHeight: 0,
  overflow: 'auto',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.primary.contrastText}`,
  backgroundColor: lighten(theme.palette.primary.light, 0.3)
}))

const DraggableList = ({ children, onSwap }) => {
  const onDragEnd = (result) => {
    if (!result.destination || !onSwap) {
      return
    }
    onSwap(result.source.index, result.destination.index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <ListContainer ref={provided.innerRef} {...provided.droppableProps}>
            <InnerList ref={provided.innerRef}>{children}</InnerList>
            {provided.placeholder}
          </ListContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default DraggableList
