import { useMemo, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from 'react-use-gesture'

import CircleSequenceHotspot from './CircleSequence/CircleSequenceHotspot'
import FilledCircleSequenceHotspot from './FilledCircleSequence/FilledCircleSequenceHotspot'
import ImageHotspot from './Image/ImageHotspot'
import useStore from '../../modules/store'
import {
  getCurrentCubeHotspotMemo,
  hotspotHoverAction,
  hotspotClickAction,
  hiddenHotspotsSelector
} from '../../modules/sceneDrawDataStore'
import { HOTSPOTS_TYPES_IDS } from '../../modules/hotspotTypesStore'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positioning' && prop !== 'twoD'
})(({ theme, positioning, twoD }) => {
  if (twoD) {
    return {
      position: 'absolute',
      top: positioning.top,
      left: positioning.left,
      bottom: positioning.bottom,
      right: positioning.right,
      transform: positioning.transform,
      zIndex: 101,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  } else {
    return {
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: positioning.transform
    }
  }
})

const HotspotContainer = ({ id, show, children, ...props }) => {
  const gHotspot = useMemo(getCurrentCubeHotspotMemo, [])
  const hotspot = useStore((state) => gHotspot(state, id))
  const hotspotHover = useStore(hotspotHoverAction)
  const hotspotClick = useStore(hotspotClickAction)
  const hiddenHotspots = useStore(hiddenHotspotsSelector)

  useEffect(() => {
    console.log('hidden', hiddenHotspots)
  }, [hiddenHotspots])

  const bind = useGesture(
    {
      onDragEnd: ({ tap }) => {
        if (tap) {
          hotspotClick(id)
        }
      },
      onHover: ({ hovering }) => {
        hotspotHover(id, hovering)
      }
    },
    { filterTaps: true }
  )

  return hotspot &&
    (!hotspot.hidden || show) &&
    (hotspot.position2D || hotspot.position3D) ? (
    <Root
      {...bind()}
      twoD={hotspot.position2D}
      className={`${props.className} ${hotspot.type}-${hotspot.style}`}
      positioning={hotspot.positioning}
      {...props}
    >
      {children}
      {hotspot.type === HOTSPOTS_TYPES_IDS.CIRCLE_SEQUENCE && (
        <CircleSequenceHotspot />
      )}
      {hotspot.type === HOTSPOTS_TYPES_IDS.FILLED_CIRCLE_SEQUENCE && (
        <FilledCircleSequenceHotspot />
      )}
      {hotspot.type === HOTSPOTS_TYPES_IDS.IMAGE && (
        <ImageHotspot
          styleId={hotspot.style}
          anchorId={hotspot.anchor}
          data={hotspot.data}
        />
      )}
    </Root>
  ) : null
}

export default HotspotContainer
