import { createSelector } from 'reselect'
import {
  findById,
  selectItem,
  getLastNotUsedName,
  swap,
  stringArrayEquals
} from '../utils/utils'
import {
  defaultCubeSelector,
  cubesSelector,
  finishsSelector,
  getHotspotCubes
} from './sceneDescriptionStore'
import {
  setCubesAction,
  setDefaultCubeAction,
  updateEventsAction
} from './sceneEditorStore'
import { removeAction as removeHotspotsAction } from './sceneHotspotListStore'

import { currentCubeSelector, setCurrentCubeAction } from './sceneDrawDataStore'
import { cubeSchema } from '../schemas/sceneSchema'

export const sceneCubeListStore = (set, get) => ({
  sceneCubeList: {
    selected: [],
    seed: 2,
    //****************************************************************************
    reset: (scene) => {
      let seed = 2
      if (scene && scene.cubes) {
        const { number } = getLastNotUsedName(scene.cubes, 'id', 'p', '')
        seed = number
      }
      set((st) => ({
        sceneCubeList: {
          ...st.sceneCubeList,
          selected: [],
          seed
        }
      }))
    },

    setSelected: (selection) => {
      set((st) => ({
        sceneCubeList: {
          ...st.sceneCubeList,
          selected: selection
        }
      }))
    },
    selectAll: () => {
      setSelectedAction(get())(cubesSelector(get()).map((cube) => cube.id))
    },
    select: (id, select) => {
      setSelectedAction(get())(selectItem(selectedSelector(get()), id, select))
    },

    insert: (idx, modelCubeId) => {
      const cubes = [...cubesSelector(get())]
      const { cube, seed } = createCube(
        modelCubeId,
        cubes,
        finishsSelector(get()),
        seedSelector(get())
      )
      cubes.splice(idx, 0, cube)
      setCubesAction(get())(cubes)
      set((st) => ({
        sceneCubeList: { ...st.sceneCubeList, seed }
      }))
    },
    remove: (ids) => {
      const currentCubes = cubesSelector(get())
      if (ids.length > 0) {
        const { cubes, removedCubes, removedCubesIds } = currentCubes.reduce(
          (acc, cube) => {
            if (ids.indexOf(cube.id) < 0) {
              acc.cubes.push(cube)
            } else {
              acc.removedCubes.push(cube)
              acc.removedCubesIds.push(cube.id)
            }
            return acc
          },
          { cubes: [], removedCubes: [], removedCubesIds: [] }
        )
        // Eliminar-Añadir hotspots
        const { hotspotsToRemove, hotspotsToInsert } = removedCubes.reduce(
          (acc, cube) => {
            const nCubes = currentCubes.length
            cube.hotspots.forEach((hsId) => {
              const { cubesIds } = getHotspotCubes(get())(hsId)
              if (
                nCubes === cubesIds.length &&
                acc.hotspotsToInsert.indexOf(hsId) < 0
              ) {
                //El hotspot esta en todos los cubos. Añadirlo al nuevo cubo si
                //se crea
                acc.hotspotsToInsert.push(hsId)
              } else if (stringArrayEquals(removedCubesIds, cubesIds)) {
                //El hotspot solo esta en los cubos eliminados. Quitarlo
                acc.hotspotsToRemove.push(hsId)
              }
            })
            return acc
          },
          { hotspotsToRemove: [], hotspotsToInsert: [] }
        )
        removeHotspotsAction(get())(hotspotsToRemove)
        //Eventos
        updateEventsAction(get())(cubes, null, null)
        //Siempre tiene que haber un cubo
        if (cubes.length === 0) {
          const { cube, seed } = createCube(
            '',
            [],
            finishsSelector(get()),
            seedSelector(get())
          )
          hotspotsToInsert.forEach((hsId) => cube.hotspots.push(hsId))
          cubes.push(cube)
          set((st) => ({
            sceneCubeList: { ...st.sceneCubeList, seed }
          }))
        }
        setCubesAction(get())(cubes)
        // Cubo inicial de la escena
        if (removedCubesIds.indexOf(defaultCubeSelector(get())) >= 0) {
          setDefaultCubeAction(get())(cubes[0].id)
        }

        // Current cube
        if (ids.indexOf(currentCubeSelector(get())) >= 0) {
          setCurrentCubeAction(get())(cubes[0].id)
        }
        // Selected cubes
        setSelectedAction(get())(
          selectedSelector(get()).filter((id) => ids.indexOf(id) < 0)
        )
      }
    },
    swapById: (fromId, toId) => {
      const cubes = cubesSelector(get())
      const fromIdx = cubes.findIndex((cube) => cube.id === fromId)
      const toIdx = cubes.findIndex((cube) => cube.id === toId)
      setCubesAction(get())(swap([...cubes], fromIdx, toIdx))
    }
  }
})
export default sceneCubeListStore

const createCube = (modelCubeId, cubes, finishs, seed) => {
  let cube = findById(cubes, modelCubeId)
  cube = cube ? cube : cubeSchema.default
  cube = JSON.parse(JSON.stringify(cube))
  //Id
  cube.id = `p${seed}`
  cube.name.es = `Cubo_${seed}`
  //Acabado
  if (cube.finishs.length === 0) {
    cube.finishs.push(finishs[0].id)
  }
  return { cube, seed: seed + 1 }
}

export const resetAction = (state) => state.sceneCubeList.reset
export const setSelectedAction = (state) => state.sceneCubeList.setSelected
export const selectAllAction = (state) => state.sceneCubeList.selectAll
export const selectAction = (state) => state.sceneCubeList.select
export const insertAction = (state) => state.sceneCubeList.insert
export const removeAction = (state) => state.sceneCubeList.remove
export const updateAction = (state) => state.sceneCubeList.update
export const swapByIdAction = (state) => state.sceneCubeList.swapById

export const selectedSelector = (state) => state.sceneCubeList.selected
export const seedSelector = (state) => state.sceneCubeList.seed

export const getSelected = createSelector(
  [selectedSelector, cubesSelector],
  (selectedCubes, cubes) => {
    return cubes.filter((cube) => selectedCubes.indexOf(cube.id) >= 0)
  }
)
