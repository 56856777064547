import { useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'

import OKCancelButtons from '../../../Common/OKCancelButtons'
import PropertyTree from '../../../Common/PropertyTree/Tree/PropertyTree'
import PropertyTreeHeader from '../../../Common/PropertyTree/Header/PropertyTreeHeader'

import HENameNode from './HENameNode'
import HECubesSelectNode from './HECubesSelectNode'
import HEPositionModeNode from './HEPositionModeNode'
import HEAnchorSelectNode from './HEAnchorSelectNode'
import HEStyleSelectNode from './HEStyleSelectNode'
import HETypeSelectNode from './HETypeSelectNode'
import HEPosition2DNode from './HEPosition2DNode'
import HEPosition3DNode from './HEPosition3DNode'
import HEPolygonToolbar from './HEPolygonToolbar'
import HEEventsNode from './HEEventsNode'

import { useStore } from '../../../../modules/store'
import {
  initAction,
  restoreAction,
  saveAction
} from '../../../../modules/hotspotEditorStore'

import {
  setCurrentOptionPanelAction,
  setDisabledOptionsAction,
  setEnabledOptionsAction
} from '../../../../modules/lateralMenuStore'
import { LATERAL_PANELS, MENU_OPTIONS } from '../../../../settings/menuSettings'
import { TREE_IDS } from '../../../../modules/propertyTreeStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  minHeight: 0,
  overflow: 'auto',
  flex: '0 1 auto'
}))

const HotspotEditor = () => {
  const init = useStore(initAction)
  const restore = useStore(restoreAction)
  const save = useStore(saveAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)
  const setEnabledOptions = useStore(setEnabledOptionsAction)
  const setDisabledOptions = useStore(setDisabledOptionsAction)

  console.log('editor')

  useEffect(() => {
    init()
    setEnabledOptions([MENU_OPTIONS.HOTSPOTS])
    return () => {
      setDisabledOptions([])
    }
  }, [setDisabledOptions, setEnabledOptions, init])

  const handleCancel = useCallback(() => {
    restore()
    setCurrentOptionPanel(LATERAL_PANELS.HOTSPOTS_LIST)
    setDisabledOptions([])
  }, [restore, setCurrentOptionPanel, setDisabledOptions])

  const handleOK = useCallback(() => {
    save()
    setCurrentOptionPanel(LATERAL_PANELS.HOTSPOTS_LIST)
    setDisabledOptions([])
  }, [setCurrentOptionPanel, setDisabledOptions, save])

  return (
    <Root>
      <ContentWrapper>
        <HEPolygonToolbar />
        <PropertyTree treeId={TREE_IDS.HOTSPOT}>
          <PropertyTreeHeader />
          <HENameNode />
          <HECubesSelectNode />
          <HEPositionModeNode />
          <HEAnchorSelectNode />
          <HEPosition2DNode />
          {false && <HEPosition3DNode />}
          <HETypeSelectNode />
          <HEStyleSelectNode />
          <HEEventsNode />
        </PropertyTree>
      </ContentWrapper>
      <OKCancelButtons onCancel={handleCancel} onOk={handleOK} />
    </Root>
  )
}
export default HotspotEditor
