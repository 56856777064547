import { memo, useCallback } from 'react'

import PointOfViewNode from '../PointOfView/PointOfViewNode'
// import StyleSelectDialogActions from './StyleSelectDialogActions'
// import StyleSelectDialogContent from './StyleSelectDialogContent'
//
import PropertyTreeTwoStatesNode from '../../PropertyTree/Nodes/PropertyTreeTwoStatesNode'
// import useStore from '../../../../modules/store'

const LookAtActionNode = ({ id, value, onActivate, onChange, ...props }) => {
  // const events = useStore(eventsSelector)
  // const [dialogOpen, setDialogOpen] = useState(false)
  // const gTypeMemo = useMemo(getTypeMemo, [])
  // const type = useStore((state) => gTypeMemo(state, hotspotType))
  // const gStyleMemo = useMemo(getStyleMemo, [])
  // const style = useStore((state) => gStyleMemo(state, value))
  // const gTypeStylesMemo = useMemo(getTypeStylesMemo, [])
  // const styles = useStore((state) => gTypeStylesMemo(state, hotspotType))
  //
  // const [localStyle, setLocalStyle] = useState(style ? style.id : '')
  // useEffect(() => {
  //   setLocalStyle(style ? style.id : '')
  // }, [style])
  //
  // const handleOpen = useCallback(() => {
  //   setDialogOpen(true)
  // }, [])
  //
  // const handleClose = useCallback(
  //   (ok) => {
  //     setDialogOpen(false)
  //     if (ok) {
  //       onChange && onChange(id, localStyle)
  //     } else {
  //       setLocalStyle(style ? style.id : '')
  //     }
  //   },
  //   [onChange, id, localStyle, style]
  // )
  //
  const handleChange = useCallback(
    (nodeId, newValue) => {
      // console.log(nodeId, newValue)
      switch (nodeId) {
        case id:
          onActivate && onActivate(id, newValue)
          break
        case `${id}:pov`:
          onChange && onChange(id, newValue)
          break
        default:
      }
    },
    [onActivate, onChange, id]
  )

  return (
    <PropertyTreeTwoStatesNode
      id={id}
      label={'Mirar a'}
      value={Boolean(value)}
      values={['Desactivado', 'Activado']}
      onChange={handleChange}
      {...props}
    >
      {value && (
        <PointOfViewNode
          id={'pov'}
          label={'Punto de vista'}
          value={value}
          onChange={handleChange}
          {...props}
        />
      )}
    </PropertyTreeTwoStatesNode>
  )
}
export default memo(LookAtActionNode)
