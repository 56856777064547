import { useRef } from 'react'
import { useGesture } from 'react-use-gesture'

import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import { Plane } from '@react-three/drei'

import Point from './Point'
// import PolygonPlaneGeometry from '../../Three/PolygonPlaneGeometry'
import PolygonContour from '../../Three/FlatPolygon/Contour'
import PolygonGeometry from '../../Hotspots/Polygon/PolygonGeometry'

import { useStore } from '../../../modules/store'
import {
  getDrawData,
  insertPointAction,
  rotationSelector,
  positionSelector,
  pointsSelector
} from '../../../modules/polygonEditorStore'
import { getCurrentHotspot } from '../../../modules/sceneHotspotListStore'

const PolygonEditor = () => {
  const planeRef = useRef()
  const insertPoint = useStore(insertPointAction)
  const { contour, holes } = useStore(getDrawData)
  const hotspot = useStore(getCurrentHotspot)
  const points = useStore(pointsSelector)

  const rotation = useStore(rotationSelector)
  const position = useStore(positionSelector)

  useFrame(({ camera }) => {
    if (planeRef.current) {
      if (!contour?.length) {
        planeRef.current.position.copy(camera.position)
        planeRef.current.quaternion.copy(camera.quaternion)
        planeRef.current.translateZ(-2)
      }
    }
  })

  const bind = useGesture(
    {
      onDragStart: () => {
        console.log('plane start')
      },
      onDrag: ({ xy, tap, event }) => {
        const intersection = event.intersections.find(
          (i) => i.object === planeRef.current
        )
        if (tap && intersection) {
          console.log('plane click')
          const v = planeRef.current.worldToLocal(intersection.point)
          insertPoint(planeRef.current, v.x, v.y)
        } else {
        }
      },
      onDragEnd: ({ tap, timeStamp, event }) => {
        if (tap) {
        }
        console.log('plane end')
      }
    },
    { filterTaps: true }
  )

  return (
    <Plane
      {...bind()}
      ref={planeRef}
      args={[10, 10]}
      rotation={rotation}
      position={position}
    >
      <meshBasicMaterial
        attach="material"
        color="#5243aa"
        transparent={true}
        opacity={0.1}
        wireframe={false}
        side={THREE.DoubleSide}
      />
      <group position={[0, 0, 0.0001]}>
        {points.map((point) => (
          <Point key={point.id} id={point.id} />
        ))}
        {hotspot.data.border.color && (
          <PolygonContour
            contour={contour}
            holes={holes}
            color={hotspot.data.border.color}
            width={hotspot.data.border.width}
          />
        )}
        <PolygonGeometry contour={contour} holes={holes} id={hotspot.id} />
      </group>
    </Plane>
  )
}
export default PolygonEditor
