import { styled } from '@mui/material/styles'

import StylesList from './StylesList'
import HotspotWrapper from './HotspotWrapper'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row'
}))

const StyleSelectDialogContent = ({
  hotspotId,
  styleId,
  onChange,
  ...props
}) => {
  return (
    <Root>
      <StylesList hotspotId={hotspotId} styleId={styleId} onChange={onChange} />
      <HotspotWrapper hotspotId={hotspotId} styleId={styleId} />
    </Root>
  )
}
export default StyleSelectDialogContent
