import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import Button from '@mui/material/Button'

const ButtonsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'end'
}))

const SButton = styled(Button)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  textTransform: 'none',
  boxSizing: 'border-box',
  width: 100,
  padding: 0,
  marginLeft: 8,
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.1)
  }
}))

const StyleSelectDialogActions = ({ onClose, ...props }) => {
  const handleCancel = useCallback(() => {
    onClose(false)
  }, [onClose])

  const handleOk = useCallback(() => {
    onClose(true)
  }, [onClose])

  return (
    <ButtonsWrapper>
      <SButton variant="outlined" onClick={handleCancel}>
        Cancelar
      </SButton>
      <SButton variant="outlined" onClick={handleOk}>
        OK
      </SButton>
    </ButtonsWrapper>
  )
}
export default StyleSelectDialogActions
