// import { useCallback } from 'react'
// import { useSelector } from 'react-redux'

import { ErrorIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const ErrorsButton = (props) => {
  return (
    <STButton
      tooltip={'Guardar'}
      tooltipEnabled
      {...props}
      icon={<ErrorIcon />}
    />
  )
}
export default ErrorsButton
