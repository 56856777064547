import { createSelector } from 'reselect'
import { findById, isValidUrl } from '../utils/utils'

import {
  getCubeHotspots,
  finishsSelector,
  optionsSelector,
  cubesSelector,
  cubeImagesSelector,
  getHotspot
} from './sceneDescriptionStore'
import { setCurrentTextureAction, clearAction } from './texturesStore'
import { setWantedPovAction } from './cameraStore'

import { getEquirectangularImageId } from './equirectangularImagesMapperStore'
import { getPositioning2D, getPositioning3D } from './positioning2D'
import {
  getEquirectangularImageById,
  getImageByName,
  getVideoByName,
  getCubeFaceImage
} from './projectFolderStore'

export const CUBE_FACES = ['px', 'nx', 'py', 'ny', 'pz', 'nz']
export const NOT_FOUND_IMAGE_SRC = 'media/image-not-found.png'

export const sceneDrawDataStore = (set, get) => ({
  sceneDrawData: {
    sphereMode: false,
    localMode: true,
    currentCube: '',
    selectedOptions: [],
    hiddenHotspots: [],
    hoverHotspot: '',
    //****************************************************************************
    //****************************************************************************
    reset: (scene) => {
      set((st) => ({
        sceneDrawData: {
          ...st.sceneDrawData,
          currentCube: scene?.default?.cube ? scene.default.cube : '',
          selectedOptions: scene?.default?.options ? scene.default.options : []
        }
      }))
      setCurrentCubeAction(get())(
        scene?.default?.cube ? scene.default.cube : ''
      )
    },
    reloadTextureAction: () => {
      clearAction(get())()
      const { cube, combination } = getCubeData(get())(
        currentCubeSelector(get())
      )
      if (cube) {
        changeTexture(set, get, cube.id, combination)
      }
    },
    setCurrentCube: async (cubeId) => {
      const { cube, combination } = getCubeData(get())(cubeId)
      if (cube) {
        changeTexture(set, get, cube.id, combination)
        executeEventAction(get())(cube.onStart)
        set((st) => ({
          sceneDrawData: { ...st.sceneDrawData, currentCube: cubeId }
        }))
      }
    },
    setSelectedOptions: async (selection) => {
      const { cube, finishs } = getCurrentCubeData(get())
      const { combination } = calculateCubeTextureId(
        cube.id,
        finishs,
        selection
      )
      if (cube) {
        changeTexture(set, get, cube.id, combination)
        set((st) => ({
          sceneDrawData: {
            ...st.sceneDrawData,
            selectedOptions: selection
          }
        }))
      }
    },
    selectOptions: (optionIds) => {
      if (optionIds && optionIds.length) {
        let newSelection = optionIds.reduce(
          (acc, optionId) => {
            const option = findById(optionsSelector(get()), optionId)
            const finish = getOptionFinish(finishsSelector(get()), optionId)
            if (option && finish) {
              acc = acc.filter(
                (optionId) => finish.options.indexOf(optionId) < 0
              )
              acc.push(optionId)
            }
            return acc
          },
          [...selectedOptionsSelector(get())]
        )
        setSelectedOptionsAction(get())(newSelection)
      }
    },
    selectOption: (optionId) => {
      selectOptionsAction(get())([optionId])
    },

    setSphereMode: (sphere) => {
      set((st) => ({
        sceneDrawData: {
          ...st.sceneDrawData,
          sphereMode: sphere
        }
      }))
    },
    setLocalMode: (local) => {
      set((st) => ({
        sceneDrawData: {
          ...st.sceneDrawData,
          localMode: local
        }
      }))
    },
    setHiddenHotspots: (ids) => {
      set((st) => ({
        sceneDrawData: {
          ...st.sceneDrawData,
          hiddenHotspots: ids
        }
      }))
    },
    hideHotspots: (ids) => {
      const hidden = ids.reduce(
        (acc, id) => {
          if (acc.indexOf(id) < 0) {
            acc.push(id)
          }
          return acc
        },
        [...hiddenHotspotsSelector(get())]
      )
      setHiddenHotspotsAction(get())(hidden)
    },
    showHotspots: (ids) => {
      const hidden = hiddenHotspotsSelector(get())
      setHiddenHotspotsAction(get())(hidden.filter((id) => ids.indexOf(id) < 0))
    },
    hotspotClick: (id) => {
      const hs = getHotspot(get())(id)
      hs && executeEventAction(get())(hs.onClick)
    },
    hotspotHover: (id, hover) => {
      const hs = getHotspot(get())(id)
      if (hs) {
        const currentHover = hoverHotspotSelector(get())
        if (!hover) {
          if (currentHover === id) {
            //Saliendo de hotspot
            executeEventAction(get())(hs.onOut)
          }
        } else {
          executeEventAction(get())(hs.onHover)
        }
        set((st) => ({
          sceneDrawData: {
            ...st.sceneDrawData,
            hoverHotspot: hover ? id : ''
          }
        }))
      }
    },
    executeEvent: (event) => {
      if (event) {
        if (event.hideHotspots?.length) {
          console.log('hide')
          hideHotspotsAction(get())(event.hideHotspots)
        }
        if (event.showHotspots?.length) {
          console.log('show')
          showHotspotsAction(get())(event.showHotspots)
        }
        if (event.goto?.id) {
          console.log('goto')
          setCurrentCubeAction(get())(event.goto.id)
        }
        if (event.selectOptions?.length) {
          console.log('selectOptions')
          selectOptionsAction(get())(event.selectOptions)
        }
        if (event.lookAt) {
          console.log('lookAt')
          setWantedPovAction(get())(event.lookAt)
        }
      }
      /*


      */
    }
  }
})
export default sceneDrawDataStore

const changeTexture = async (set, get, cubeId, combinationId) => {
  const textureId = `${cubeId}_${combinationId}`
  if (sphereModeSelector(get())) {
    const { url } = getEquirectangularImage(get())(cubeId, combinationId)
    await setCurrentTextureAction(get())(`sphere_${textureId}`, url, true)
  } else {
    await setCurrentTextureAction(get())(
      textureId,
      getCubeFaceImages(get())(cubeId, combinationId).map((image) => image.url),
      false
    )
  }
}

const calculateCubeTextureId = (cubeId, cubeFinishs, optionIds) => {
  const combination = cubeFinishs.reduce((acc, finish) => {
    for (const optionId of optionIds) {
      if (finish.options.indexOf(optionId) >= 0) {
        acc = `${acc}_${finish.id}_${optionId}`
        break
      }
    }
    return acc
  }, '')
  return {
    textureId: `${cubeId}${combination}`,
    combination: combination.slice(1)
  }
}

const getOptionFinish = (finishs, optionId) => {
  return finishs.find((finish) => finish.options.indexOf(optionId) >= 0)
}

export const resetAction = (state) => state.sceneDrawData.reset
export const reloadTextureAction = (state) =>
  state.sceneDrawData.reloadTextureAction
export const setCurrentCubeAction = (state) =>
  state.sceneDrawData.setCurrentCube
export const selectOptionAction = (state) => state.sceneDrawData.selectOption
export const selectOptionsAction = (state) => state.sceneDrawData.selectOptions
export const setSelectedOptionsAction = (state) =>
  state.sceneDrawData.setSelectedOptions
export const setSphereModeAction = (state) => state.sceneDrawData.setSphereMode
export const setLocalModeAction = (state) => state.sceneDrawData.setLocalMode
export const setHiddenHotspotsAction = (state) =>
  state.sceneDrawData.setHiddenHotspots
export const hideHotspotsAction = (state) => state.sceneDrawData.hideHotspots
export const showHotspotsAction = (state) => state.sceneDrawData.showHotspots
export const hotspotHoverAction = (state) => state.sceneDrawData.hotspotHover
export const hotspotClickAction = (state) => state.sceneDrawData.hotspotClick
const executeEventAction = (state) => state.sceneDrawData.executeEvent

export const currentCubeSelector = (state) => state.sceneDrawData.currentCube
export const selectedOptionsSelector = (state) =>
  state.sceneDrawData.selectedOptions
export const sphereModeSelector = (state) => state.sceneDrawData.sphereMode
export const localModeSelector = (state) => state.sceneDrawData.localMode
export const hiddenHotspotsSelector = (state) =>
  state.sceneDrawData.hiddenHotspots
export const hoverHotspotSelector = (state) => state.sceneDrawData.hoverHotspot

export const getCubeData = createSelector(
  [cubesSelector, finishsSelector, selectedOptionsSelector],
  (cubes, finishs, selectedOptions) => (cubeId) => {
    const cube = findById(cubes, cubeId)
    const cubeFinishs = cube
      ? finishs.filter((finish) => cube.finishs.indexOf(finish.id) >= 0)
      : []
    const { combination } = calculateCubeTextureId(
      cubeId,
      cubeFinishs,
      selectedOptions
    )
    return { cube, finishs: cubeFinishs, combination }
  }
)

export const getCurrentCubeData = createSelector(
  [currentCubeSelector, getCubeData],
  (currentCube, gCubeData) => {
    return gCubeData(currentCube)
  }
)

const getAumentedHotspot = (hs, hiddenHotspots) => {
  if (hs.position2D) {
    return {
      ...hs,
      positioning: getPositioning2D(hs.anchor, hs.position2D),
      hidden: hiddenHotspots.indexOf(hs.id) >= 0
    }
  } else if (hs.position3D) {
    return {
      ...hs,
      positioning: getPositioning3D(hs.anchor),
      hidden: hiddenHotspots.indexOf(hs.id) >= 0
    }
  } else if (hs.polygonData) {
    return {
      ...hs,
      hidden: hiddenHotspots.indexOf(hs.id) >= 0
    }
  }
}

export const getCurrentCubeHotspots = createSelector(
  [getCubeHotspots, currentCubeSelector, hiddenHotspotsSelector],
  (gCubeHotspots, currentCube, hiddenHotspots) => {
    const { cube, hotspots } = gCubeHotspots(currentCube)
    const { allHotspots, hotspots2D, hotspots3D, polygons } = hotspots.reduce(
      (acc, hs) => {
        const h = getAumentedHotspot(hs, hiddenHotspots)
        if (h.position2D) {
          acc.hotspots2D.push(h)
          acc.allHotspots.push(h)
        } else if (h.position3D) {
          acc.hotspots3D.push(h)
          acc.allHotspots.push(h)
        } else if (h.polygonData) {
          acc.polygons.push(h)
          acc.allHotspots.push(h)
        }
        return acc
      },
      { allHotspots: [], hotspots2D: [], hotspots3D: [], polygons: [] }
    )

    return {
      cube,
      hotspots: allHotspots,
      hotspots2D,
      hotspots3D,
      polygons
    }
  }
)

export const getCurrentCubeHotspotMemo = () =>
  createSelector(
    [
      getCubeHotspots,
      currentCubeSelector,
      hiddenHotspotsSelector,
      (_, id) => id
    ],
    (gCubeHotspots, currentCube, hiddenHotspots, id) => {
      const { hotspots } = gCubeHotspots(currentCube)
      const hotspot = findById(hotspots, id)
      return hotspot ? getAumentedHotspot(hotspot, hiddenHotspots) : null
    }
  )

export const getEquirectangularImage = createSelector(
  [localModeSelector, getEquirectangularImageId, getEquirectangularImageById],
  (local, gEquirectangularImageId, gEquirectangularImageById) => (
    cubeId,
    combinationId
  ) => {
    let equirectangularImageId = gEquirectangularImageId(cubeId, combinationId)
    const { image, absolute } = gEquirectangularImageById(
      equirectangularImageId
    )
    return {
      id: equirectangularImageId,
      url: local ? (image ? image.url : null) : absolute
    }
  }
)
export const getCubeFaceImages = createSelector(
  [localModeSelector, cubeImagesSelector, getCubeFaceImage],
  (local, cubeImages, gCubeFaceImage) => (cubeId, combinationId) => {
    return CUBE_FACES.map((face) => {
      const imageName = `${cubeId}_${combinationId}_${face}`
      let url = null
      if (local) {
        const image = gCubeFaceImage(`${imageName}.jpg`)
        url = image ? image.url : NOT_FOUND_IMAGE_SRC
      } else {
        url = `${cubeImages.prefix}$${imageName}${cubeImages.suffix}`
      }
      return { id: face, name: imageName, url }
    })
  }
)

export const getImageSrc = createSelector(
  [localModeSelector, getImageByName],
  (localMode, gImageByName) => (src) => {
    if (isValidUrl(src)) {
      return src
    }
    if (localMode) {
      const image = gImageByName(src)
      return image ? image.url : NOT_FOUND_IMAGE_SRC
    } else {
      return `media/${src}`
    }
  }
)

export const getVideoSrc = createSelector(
  [localModeSelector, getVideoByName],
  (localMode, gVideoByName) => (src) => {
    if (isValidUrl(src)) {
      return src
    }
    if (localMode) {
      const video = gVideoByName(src)
      return video ? video.url : ''
    } else {
      return `media/${src}`
    }
  }
)
