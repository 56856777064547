import { useCallback, useMemo, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import { SelectorGroup, SelectorItem, SelectorTitle } from './SelectorGroup'
import VericalSplitter from './VericalSplitter'
import { cubesSelector } from '../../../modules/sceneDescriptionStore'
import {
  currentCubeSelector,
  setCurrentCubeAction
} from '../../../modules/sceneDrawDataStore'
import { getFinishMemo } from '../../../modules/sceneDescriptionStore'
import {
  selectedOptionsSelector,
  selectOptionAction,
  getCurrentCubeData
} from '../../../modules/sceneDrawDataStore'
import { selectorVisibleSelector } from '../../../modules/mainStore'
import useStore from '../../../modules/store'
import {
  getCurrentPanel,
  currentOptionSelector
} from '../../../modules/lateralMenuStore'
import { LATERAL_PANELS, MENU_OPTIONS } from '../../../settings/menuSettings'

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  padding: 0,
  height: '100%',
  width: 200,
  minWidth: 200,
  minHeight: 0,

  backgroundColor: theme.palette.primary.light,
  borderLeft: `4px solid ${theme.palette.primary.main}`
}))

const SPLITTER_HEIGHT = 20

const FinishGroup = ({ id }) => {
  const gFinishMemo = useMemo(getFinishMemo, [])
  const { finish, options } = useStore((state) => gFinishMemo(state, id))
  const selectOption = useStore(selectOptionAction)
  const selectedOptions = useStore(selectedOptionsSelector)
  const currentPanel = useStore(getCurrentPanel)
  const optionsDisabled = currentPanel?.id === LATERAL_PANELS.FINISH_EDITOR

  const handleClick = useCallback(
    (optionId) => {
      selectOption(optionId)
    },
    [selectOption]
  )

  return finish ? (
    <>
      <SelectorTitle>{finish.name.es}</SelectorTitle>

      {options.map((option) => (
        <SelectorItem
          key={option.id}
          id={option.id}
          title={option.name.es}
          selected={selectedOptions.indexOf(option.id) >= 0}
          disabled={optionsDisabled}
          onClick={() => handleClick(option.id)}
        />
      ))}
    </>
  ) : null
}
const Selector = () => {
  const [top, setTop] = useState(0)
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const currentCube = useStore(currentCubeSelector)
  const cubes = useStore(cubesSelector)
  const { finishs } = useStore(getCurrentCubeData)
  const setCurrentCube = useStore(setCurrentCubeAction)
  const selectorVisible = useStore(selectorVisibleSelector)
  const currentPanel = useStore(getCurrentPanel)
  const currentOption = useStore(currentOptionSelector)
  const visible =
    selectorVisible &&
    cubes &&
    finishs &&
    currentOption !== MENU_OPTIONS.TEXT_EDITOR &&
    currentOption !== MENU_OPTIONS.IMAGE_MAPPER
  const cubesDisabled =
    currentPanel?.id === LATERAL_PANELS.HOTSPOT_EDITOR ||
    currentPanel?.id === LATERAL_PANELS.CUBE_EDITOR ||
    currentPanel?.id === LATERAL_PANELS.FINISH_EDITOR

  useEffect(() => {
    if (rootBounds) {
      setTop(rootBounds.height / 2)
    }
  }, [rootBounds])

  const handleCubeClick = useCallback(
    (id) => {
      setCurrentCube(id)
    },
    [setCurrentCube]
  )

  return visible ? (
    <Root ref={rootBoundsRef}>
      <SelectorGroup height={top} title={'Cubos'}>
        {cubes.map((cube) => (
          <SelectorItem
            key={cube.id}
            id={cube.id}
            title={cube.name.es}
            disabled={cubesDisabled}
            selected={currentCube === cube.id}
            onClick={handleCubeClick}
          />
        ))}
      </SelectorGroup>
      <VericalSplitter
        top={top}
        setTop={setTop}
        height={rootBounds ? rootBounds.height : 0}
      />
      <SelectorGroup
        title={'Acabados'}
        height={rootBounds ? rootBounds.height - top - SPLITTER_HEIGHT : 0}
      >
        {finishs.map((finish) => (
          <FinishGroup key={finish.id} id={finish.id} />
        ))}
      </SelectorGroup>
    </Root>
  ) : null
}
export default Selector
