import { swap, cloneObject } from '../utils/utils'
import {
  cubesSelector,
  getCubeFinishs,
  getCubeHotspots
} from './sceneDescriptionStore'
import { setCubesAction } from './sceneEditorStore'

export const sceneCubeStore = (set, get) => ({
  sceneCube: {
    setImageIdx: (id, idx) => {
      setCubeProperty(set, id, 'imageIdx', idx)
    },
    setName: (id, name) => {
      setCubeProperty(set, id, 'name', name)
    },
    setDefaultView: (id, defaultView) => {
      setCubeProperty(set, id, 'defaultView', defaultView)
    },
    setPoint: (id, point) => {
      setCubeProperty(set, id, 'point', point)
    },
    setFinishs: (id, finishsIds) => {
      setCubeProperty(
        set,
        id,
        'finishs',
        calculateCubeFinishs(getCubeFinishs(get())(id).finishsIds, finishsIds)
      )
    },
    insertFinishs: (id, finishsIds, idx) => {
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => {
          if (cube.id === id) {
            //Busca acabados que no esten en el cubo
            const cubeFinishsIds = getCubeFinishs(get())(id).finishsIds
            const newFinishsIds = finishsIds.filter(
              (id) => cubeFinishsIds.indexOf(id) < 0
            )
            //Añade
            if (newFinishsIds.length > 0) {
              const finishs = [...cube.finishs]
              finishs.splice(idx, 0, ...newFinishsIds)
              return { ...cube, finishs }
            }
          }
          return cube
        })
      )
    },
    removeFinishs: (id, finishsIds) => {
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => {
          if (cube.id === id) {
            const finishs = cube.finishs.filter(
              (fId) => finishsIds.indexOf(fId) < 0
            )
            return {
              ...cube,
              //Hay que dejar un acabado
              finishs: finishs.length > 0 ? finishs : [cube.finishs[0]]
            }
          }
          return cube
        })
      )
    },
    setHotspots: (id, hotspotsIds) => {
      setCubeProperty(set, id, 'hotspots', hotspotsIds)
    },
    insertHotspots: (id, hotspotsIds, idx) => {
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => {
          if (cube.id === id) {
            //Busca hotspots que no esten en el cubo
            const cubeHotspotsIds = getCubeHotspots(get())(id).hotspotsIds
            const newHotspotsIds = hotspotsIds.filter(
              (id) => cubeHotspotsIds.indexOf(id) < 0
            )
            //Añade
            if (newHotspotsIds.length > 0) {
              const hotspots = [...cube.hotspots]
              hotspots.splice(idx, 0, ...newHotspotsIds)
              return { ...cube, hotspots }
            }
          }
          return cube
        })
      )
    },
    removeHotspots: (id, hotspotsIds) => {
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => {
          if (cube.id === id) {
            const hotspots = cube.hotspots.filter(
              (hsId) => hotspotsIds.indexOf(hsId) < 0
            )
            return {
              ...cube,
              hotspots
              // onStart: {
              //   ...cube.onStart,
              //   hideHotspots: cube.onStart.hideHotspots.filter(
              //     (hsId) => hotspotsIds.indexOf(hsId) < 0
              //   ),
              //   showHotspots: cube.onStart.showHotspots.filter(
              //     (hsId) => hotspotsIds.indexOf(hsId) < 0
              //   )
              // }
            }
          }
          return cube
        })
      )
    },
    set: (id, data) => {
      if (data) {
        setCubesAction(get())(
          cubesSelector(get()).map((cube) => {
            if (id === cube.id) {
              return {
                ...cube,
                ...cloneObject(data),
                finishs: calculateCubeFinishs(
                  getCubeFinishs(get())(cube.id).finishsIds,
                  data.finishs
                )
              }
            }
            return cube
          })
        )
      }
    },
    swapHotspotsById: (cubeId, fromId, toId) => {
      setCubesAction(get())(
        cubesSelector(get()).map((cube) => {
          if (cubeId === cube.id) {
            const fromIdx = cube.hotspots.findIndex((hsId) => hsId === fromId)
            const toIdx = cube.hotspots.findIndex((hsId) => hsId === toId)

            return {
              ...cube,
              hotspots: swap([...cube.hotspots], fromIdx, toIdx)
            }
          }
          return cube
        })
      )
    },
    setOnStartEvent: (id, value) => {
      setCubeProperty(set, id, 'onStart', value)
    }
  }
})
export default sceneCubeStore

const setCubeProperty = (set, id, propertyName, value) => {
  set((st) => ({
    sceneDescription: {
      ...st.sceneDescription,
      cubes: st.sceneDescription.cubes.map((cube) => {
        if (cube.id === id) {
          const newCube = { ...cube }
          newCube[propertyName] = value
          return newCube
        }
        return cube
      })
    }
  }))
}

const calculateCubeFinishs = (cubeFinishsIds, newFinishsIds) => {
  //Quitar
  let ids = cubeFinishsIds.filter((fId) => newFinishsIds.indexOf(fId) >= 0)
  //Poner
  ids = [...ids, ...newFinishsIds.filter((id) => ids.indexOf(id) < 0)]
  return ids.length > 0 ? ids : [cubeFinishsIds[0]]
}

export const setAction = (state) => state.sceneCube.set
export const setImageIdxAction = (state) => state.sceneCube.setImageIdx
export const setNameAction = (state) => state.sceneCube.setName
export const setDefaultViewAction = (state) => state.sceneCube.setDefaultView
export const setPointAction = (state) => state.sceneCube.setPoint
export const setFinishsAction = (state) => state.sceneCube.setFinishs
export const insertFinishsAction = (state) => state.sceneCube.insertFinishs
export const removeFinishsAction = (state) => state.sceneCube.removeFinishs

export const setHotspotsAction = (state) => state.sceneCube.setHotspots
export const insertHotspotsAction = (state) => state.sceneCube.insertHotspots
export const removeHotspotsAction = (state) => state.sceneCube.removeHotspots
export const swapHotspotsByIdAction = (state) =>
  state.sceneCube.swapHotspotsById
export const setOnStartEventAction = (state) => state.sceneCube.setOnStartEvent
