import { useCallback, memo } from 'react'

import PropertyTreeTextNode from '../PropertyTree/Nodes/PropertyTreeTextNode'
import PropertyTreeIntegerNode from '../PropertyTree/Nodes/PropertyTreeIntegerNode'

const SizeInPixelsNode = ({
  id,
  readOnly,
  value = { width: 0, height: 0 },
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === `${id}:width`) {
          onChange(id, { ...value, width: newValue })
        } else if (nodeId === `${id}:height`) {
          onChange(id, { ...value, height: newValue })
        }
      }
    },
    [id, onChange, value]
  )

  return (
    <PropertyTreeTextNode
      id={id}
      value={`(${value.width}  ${value.height})`}
      readOnly={true}
      onChange={handleChange}
      {...props}
    >
      <PropertyTreeIntegerNode
        id={'width'}
        label="Ancho"
        value={value.width}
        readOnly={readOnly}
        onChange={handleChange}
        min={0}
      />
      <PropertyTreeIntegerNode
        id={'height'}
        label="Alto"
        value={value.height}
        readOnly={readOnly}
        onChange={handleChange}
        min={0}
      />
    </PropertyTreeTextNode>
  )
}
export default memo(SizeInPixelsNode)
