import { useCallback, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'

import ListItem from '@mui/material/ListItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'

const SListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'showIcons'
})(({ theme, error, showIcons }) => ({
  boxSizing: 'border-box',
  paddingRight: 16,
  height: 56,
  zIndex: 1000,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  border: '2px solid transparent',
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    border: `2px solid ${theme.palette.secondary.main}`,
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      border: `2px solid ${theme.palette.secondary.main}`
    }
  },
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    border: `2px solid ${theme.palette.secondary.main}`
  }
}))

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  height: '100%'
}))

const SListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary, .MuiListItemText-secondary': {
    color: 'currentColor', //theme.palette.primary.contrastText,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'none'
  }
}))

const SCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'currentColor',
  '&.Mui-checked': {
    color: 'currentColor'
  }
}))

const DraggableListItem = ({
  id,
  icon,
  checkbox,
  primaryText,
  secondaryText,
  hoverButtons,
  buttons,
  checked,
  selected,
  error,
  onClick,
  onCheck,
  onHover,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  const showIcons = selected || hover

  const handleMouseOver = useCallback(() => {
    setHover(true)
    !hover && onHover && onHover(id, true)
  }, [id, hover, onHover])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
    hover && onHover && onHover(id, false)
  }, [id, hover, onHover])

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(id)
      }
    },
    [onClick, id]
  )

  return (
    <SListItem
      button
      showIcons={showIcons}
      error={error}
      selected={selected}
      autoFocus={selected}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {checkbox && (
        <SCheckbox
          size="small"
          edge="start"
          checked={checked}
          disableRipple
          onClick={(e) => e.stopPropagation()}
          onChange={onCheck}
        />
      )}
      {icon}
      <SListItemText primary={primaryText} secondary={secondaryText} />
      <ButtonsWrapper onClick={(e) => e.stopPropagation()}>
        {showIcons && hoverButtons}
        {buttons}
      </ButtonsWrapper>
    </SListItem>
  )
}
export default DraggableListItem
