import { useCallback } from 'react'

import SelectOptionsNode from '../../Common/Nodes/SelectOptionsNode'

import { useStore } from '../../../modules/store'
import { defaultSelector } from '../../../modules/sceneDescriptionStore'
import { setDefaultOptionsAction } from '../../../modules/sceneEditorStore'

const SEOptionsNode = () => {
  const setDefaultOptions = useStore(setDefaultOptionsAction)
  const defaultValues = useStore(defaultSelector)

  const handleChange = useCallback(
    (nodeId, value) => {
      setDefaultOptions(value)
    },
    [setDefaultOptions]
  )

  return (
    <SelectOptionsNode
      id={'options'}
      label="Opciones iniciales"
      absolute
      value={defaultValues.options}
      onChange={handleChange}
    />
  )
}
export default SEOptionsNode
