import { useContext } from 'react'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { ScrollToolbarContext } from './ScrollToolbarContext'

const ScrollButton = styled(Button, {
  name: 'ScrollToolbar',
  slot: 'ScrollButton',
  shouldForwardProp: (prop) => prop !== 'vertical'
})(({ theme, vertical }) => ({
  boxSizing: 'border-box',
  padding: 0,
  height: vertical ? null : '100%',
  minWidth: vertical ? null : 0,
  width: vertical ? '100%' : null,
  minHeight: vertical ? 0 : null,

  borderRadius: 0,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 2px 20px -2px currentColor`
  },
  '&:disabled': {
    color: theme.palette.action.disabled
  },
  // '&:disabled': { color: theme.scrollToolbar.disabled },
  boxShadow: `0 2px 20px -2px currentColor`
}))

const STScrollButton = ({
  next,
  itemsLength,
  toolbarLength,
  offset,
  onClick
}) => {
  const context = useContext(ScrollToolbarContext)

  return (
    <ScrollButton
      className={'scroll_button'}
      vertical={context.vertical}
      disabled={next ? offset <= toolbarLength - itemsLength : offset >= 0}
      variant="contained"
      onClick={onClick}
    >
      {next ? (
        <NavigateNextIcon
          sx={{ transform: context.vertical ? 'rotate(90deg)' : 'none' }}
        />
      ) : (
        <NavigateBeforeIcon
          sx={{ transform: context.vertical ? 'rotate(90deg)' : 'none' }}
        />
      )}
    </ScrollButton>
  )
}

export default STScrollButton
