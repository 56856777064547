// import RemovePolygonPointIcon from '../../Icons/RemovePolygonPointIcon'
import { RemovePolygonPointIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const RemovePolygonPointButton = (props) => {
  return (
    <STButton
      tooltip={'Eliminar punto'}
      tooltipEnabled
      icon={<RemovePolygonPointIcon />}
      {...props}
    />
  )
}
export default RemovePolygonPointButton
