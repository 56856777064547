import { useState, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import { SketchPicker } from 'react-color'

import { hexToRgb, hexToRgba2, rgbToHex, rgbaToHex } from '../../../utils/color'

const STextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected, value }) => ({
  backgroundColor: value,
  '& .MuiInput-input': { textAlign: 'center', textTransform: 'uppercase' }
}))

const ColorField = ({
  id,
  value,
  readOnly,
  validateValue,
  onChange,
  onEditingChange,
  disabled,
  disableAlpha,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(disabled ? null : event.currentTarget)
    },
    [disabled]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChangeComplete = (color) => {
    if (!readOnly && onChange) {
      onChange(
        id,
        disableAlpha
          ? rgbToHex(color.rgb.r, color.rgb.g, color.rgb.b)
          : rgbaToHex({
              r: color.rgb.r,
              g: color.rgb.g,
              b: color.rgb.b,
              a: color.rgb.a * 255
            })
      )
    }
  }

  return (
    <>
      <STextField
        value={value}
        disabled={disabled}
        onClick={handleClick}
        {...props}
      />
      {!readOnly && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <SketchPicker
            disableAlpha={disableAlpha}
            color={
              disableAlpha
                ? hexToRgb(value ? value : 'black')
                : hexToRgba2(value ? value : 'black')
            }
            onChangeComplete={handleChangeComplete}
          />
        </Popover>
      )}
    </>
  )
}

export default ColorField
