import { createSelector } from 'reselect'
import { resetAction as resetCameraAction } from './cameraStore'
import {
  resetAction as resetSceneDescriptionAction,
  getSceneObject
} from './sceneDescriptionStore'
import {
  resetAction as resetEquirectangularImagesMapperAction,
  getOutput as getEquirectangularImagesMapperOutput
} from './equirectangularImagesMapperStore'

import { resetAction as resetEditorCubeListAction } from './sceneCubeListStore'
import { resetAction as resetEditorFinishAction } from './sceneFinishsStore'
import { resetAction as resetSceneDrawDataAction } from './sceneDrawDataStore'
import { resetAction as resetEditorHotspotListAction } from './sceneHotspotListStore'
import { addStylesAction, stylesSelector } from './hotspotTypesStore'
import {
  readProject,
  setDataAction,
  SETTINGS_FILE_NAME,
  IMAGE_MAP_FILE_NAME,
  STYLES_FILE_NAME
} from './projectFolderStore'

import { resetAction as resetTexturesAction } from './texturesStore'
import { cloneObject } from '../utils/utils'
import { sceneSchema } from '../schemas/sceneSchema'
const stringify = require('json-stringify-pretty-compact')

export const openSaveStore = (set, get) => ({
  openSave: {
    sceneErrors: { errors: [], relationalErrors: [], schemaErrors: [] },
    mapperErrors: { errors: [], relationalErrors: [], schemaErrors: [] },
    open: async (fileList) => {
      let newScene = null
      let newMapper = null
      let newStyles = null
      let project = null
      clearErrorsAction(get())
      if (fileList?.length) {
        //Leer archivos
        const { files, scene, mapper, styles } = await readProject(fileList)
        console.log(scene, mapper, styles)

        set((st) => ({
          ...st,
          openSave: {
            ...st.openSave,
            sceneErrors: scene.errors,
            mapperErrors: mapper.errors
          }
        }))
        if (!scene.data || !mapper.data) {
          newScene = null
          newMapper = null
          newStyles = null
          project = null
        } else {
          newScene = scene.data
          newMapper = mapper.data
          newStyles = styles.data
          project = files
        }
      }
      if (!newScene) {
        newScene = cloneObject(sceneSchema.default)
      }
      //Cargar datos
      setDataAction(get())(project)
      addStylesAction(get())(newStyles)
      resetCameraAction(get())(newScene, newMapper, newStyles)
      resetEquirectangularImagesMapperAction(get())(
        newScene,
        newMapper,
        newStyles
      )
      resetSceneDescriptionAction(get())(newScene, newMapper, newStyles)
      resetSceneDrawDataAction(get())(newScene, newMapper, newStyles)
      resetTexturesAction(get())(newScene, newMapper, newStyles)
      resetEditorCubeListAction(get())(newScene, newMapper, newStyles)
      resetEditorFinishAction(get())(newScene, newMapper, newStyles)
      resetEditorHotspotListAction(get())(newScene, newMapper, newStyles)
    },
    clearErrors: () => {
      set((st) => ({
        ...st,
        openSave: {
          ...st.openSave,
          sceneErrors: { errors: [], relationalErrors: [], schemaErrors: [] },
          mapperErrors: { errors: [], relationalErrors: [], schemaErrors: [] }
        }
      }))
    },

    readSceneFile: async (src) => {
      const response = await fetch(src)
      if (response.ok) {
        const scene = await response.json()
        resetSceneDescriptionAction(get())(scene)
        resetSceneDrawDataAction(get())(scene)
        resetTexturesAction(get())()
      }
    },
    save: () => {
      save(getSceneObject(get()), SETTINGS_FILE_NAME)
      save(getEquirectangularImagesMapperOutput(get()), IMAGE_MAP_FILE_NAME)
      save(stylesSelector(get()), STYLES_FILE_NAME)
    }
  }
})
export default openSaveStore

const save = (jsonObject, name) => {
  const file = new Blob(
    [
      stringify(jsonObject, {
        maxLength: 100,
        indent: 1
      })
    ],
    {
      type: 'application/json'
    }
  )
  const a = document.createElement('a')
  a.href = URL.createObjectURL(file)
  a.download = name
  a.click()

  a.remove()
}

export const openAction = (state) => state.openSave.open
export const clearErrorsAction = (state) => state.openSave.clearErrors
export const readSceneFileAction = (state) => state.openSave.readSceneFile
export const saveAction = (state) => state.openSave.save

export const sceneErrorsSelector = (state) => state.openSave.sceneErrors
export const mapperErrorsSelector = (state) => state.openSave.mapperErrors

export const getOpenErrors = createSelector(
  [sceneErrorsSelector, mapperErrorsSelector],
  (sceneErrors, mapperErrors) => {
    const errors = [sceneErrors, mapperErrors]
    const n = errors.reduce((acc, e) => {
      acc += e.errors.length + e.relationalErrors.length + e.schemaErrors.length
      return acc
    }, 0)
    return { sceneErrors, mapperErrors, n }
  }
)
