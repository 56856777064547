import { findById, selectItem, swap } from '../utils/utils'
import { createSelector } from 'reselect'

import {
  optionsSelector as sceneOptionsSelector,
  getFinish,
  defaultSelector,
  getFinishCubes,
  getCube
} from './sceneDescriptionStore'

import {
  setDefaultOptionsAction,
  setOptionsAction,
  updateEventsAction
} from './sceneEditorStore'
import { currentCubeSelector } from './sceneDrawDataStore'
import {
  currentIdSelector as currentFinishIdSelector,
  setFinishAction,
  createOption,
  optionSeedSelector as currentOptionSeedSelector,
  setOptionSeedAction,
  setFinishCubesAction
} from './sceneFinishsStore'

export const finishEditorStore = (set, get) => ({
  finishEditor: {
    finish: null,
    cubes: [],
    options: [],
    optionSeed: 2,

    selectedOptions: [],
    defaultOptions: [],
    currentOption: '',

    init: () => {
      const finish = getFinish(get())(currentFinishIdSelector(get()))
      const options = sceneOptionsSelector(get())
      const optionSeed = currentOptionSeedSelector(get())
      const defaultOptions = defaultSelector(get()).options
      if (finish && options) {
        set((st) => ({
          finishEditor: {
            ...st.finishEditor,
            selectedOptions: [],
            currentOption: '',
            // Guarda una copia de acabado y opciones para trabajar sobre ellos
            finish: JSON.parse(JSON.stringify(finish)),
            cubes: getFinishCubes(get())(currentFinishIdSelector(get()))
              .finishCubesIds,
            options: JSON.parse(JSON.stringify(options)),
            optionSeed,
            defaultOptions: [...defaultOptions]
          }
        }))
      }
    },
    save: () => {
      setFinishAction(get())(
        currentFinishIdSelector(get()),
        finishSelector(get())
      )
      setOptionsAction(get())(optionsSelector(get()))
      setDefaultOptionsAction(get())(defaultOptionsSelector(get()))
      setOptionSeedAction(get())(optionSeedSelector(get()))
      setFinishCubesAction(get())(
        finishSelector(get()).id,
        cubesSelector(get())
      )
      //Events
      updateEventsAction(get())(null, null, optionsSelector(get()))
    },
    updateFinish: (newData) => {
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          finish: JSON.parse(JSON.stringify(newData))
        }
      }))
    },
    setCubes: (ids) => {
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          cubes: ids.length === 0 ? [currentCubeSelector(get())] : ids
        }
      }))
    },

    setCurrentOption: (id) => {
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          currentOption: id
        }
      }))
    },
    setSelectedOptions: (selection) => {
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          selectedOptions: selection
        }
      }))
    },
    selectAllOptions: () => {
      setSelectedOptionsAction(get())(getFinishOptions(get()).map((f) => f.id))
    },
    selectOption: (id, select) => {
      setSelectedOptionsAction(get())(
        selectItem(selectedOptionsSelector(get()), id, select)
      )
    },
    updateOption: (id, newData) => {
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          options: optionsSelector(get()).map((o) => {
            if (o.id === id) {
              return JSON.parse(JSON.stringify(newData))
            }
            return o
          })
        }
      }))
    },
    insertOption: (idx, modelOptionId) => {
      const options = optionsSelector(get())
      const { option, seed } = createOption(
        modelOptionId,
        options,
        optionSeedSelector(get())
      )
      const finish = finishSelector(get())
      const finishOptions = [...finish.options]
      finishOptions.splice(idx, 0, option.id)

      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          options: [...options, option],
          finish: { ...finish, options: finishOptions },
          optionSeed: seed
        }
      }))
    },

    removeOptions: (ids) => {
      const finish = finishSelector(get())
      const idsToRemove = ids.filter((id) => finish.options.indexOf(id) >= 0)
      const newFinish = { ...finish }
      let options = optionsSelector(get())
      let defaultOptions = defaultOptionsSelector(get())
      //Quitar de opciones
      options = options.filter((o) => idsToRemove.indexOf(o.id) < 0)
      //Quitar de acabado
      newFinish.options = newFinish.options.filter(
        (optionId) => idsToRemove.indexOf(optionId) < 0
      )
      //Quitar de default
      defaultOptions = defaultOptions.filter(
        (optionId) => idsToRemove.indexOf(optionId) < 0
      )
      if (newFinish.options.length > 0) {
        //Añadir nueva opcion a defecto
        defaultOptions.push(newFinish.options[0])
      } else if (newFinish.options.length === 0) {
        //Añadir opcion por defecto a acabado si se ha quedado vacio
        const { option, seed } = createOption(
          '',
          options,
          optionSeedSelector(get())
        )
        options.push(option)
        newFinish.options = [option.id]
        defaultOptions = [...defaultOptions, option.id]
        set((st) => ({
          finishEditor: { ...st.finishEditor, optionSeed: seed }
        }))
      }
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          options,
          finish: newFinish,
          defaultOptions
        }
      }))
    },
    swapOptionsById: (fromId, toId) => {
      const finish = finishSelector(get())
      const fromIdx = finish.options.findIndex(
        (optionId) => optionId === fromId
      )
      const toIdx = finish.options.findIndex((optionId) => optionId === toId)
      set((st) => ({
        finishEditor: {
          ...st.finishEditor,
          finish: {
            ...finish,
            options: swap([...finish.options], fromIdx, toIdx)
          }
        }
      }))

      // set((st) => ({
      //   finishEditor: {
      //     ...st.finishEditor,
      //     options: swap([...options], fromIdx, toIdx)
      //   }
      // }))
    }
  }
})
export default finishEditorStore

export const setCurrentOptionAction = (state) =>
  state.finishEditor.setCurrentOption
export const initAction = (state) => state.finishEditor.init
export const saveAction = (state) => state.finishEditor.save
export const updateFinishAction = (state) => state.finishEditor.updateFinish
export const setCubesAction = (state) => state.finishEditor.setCubes
export const updateOptionAction = (state) => state.finishEditor.updateOption
export const setSelectedOptionsAction = (state) =>
  state.finishEditor.setSelectedOptions
export const selectAllOptionsAction = (state) =>
  state.finishEditor.selectAllOptions
export const selectOptionAction = (state) => state.finishEditor.selectOption
export const insertOptionAction = (state) => state.finishEditor.insertOption
export const removeOptionsAction = (state) => state.finishEditor.removeOptions
export const swapOptionsByIdAction = (state) =>
  state.finishEditor.swapOptionsById

export const finishSelector = (state) => state.finishEditor.finish
export const cubesSelector = (state) => state.finishEditor.cubes
export const optionsSelector = (state) => state.finishEditor.options
export const defaultOptionsSelector = (state) =>
  state.finishEditor.defaultOptions
export const currentOptionSelector = (state) => state.finishEditor.currentOption
export const selectedOptionsSelector = (state) =>
  state.finishEditor.selectedOptions
export const optionSeedSelector = (state) => state.finishEditor.optionSeed

export const getCubes = createSelector(
  [finishSelector, cubesSelector, getCube],
  (finish, cubesIds, gCube) => {
    return cubesIds.reduce(
      (acc, cubeId) => {
        const cube = gCube(cubeId)
        if (cube) {
          acc.cubes.push(cube)
          acc.cubesIds.push(cube.id)
          if (cube.finishs.length === 1 && cube.finishs[0] === finish.id) {
            acc.disabledCubesIds.push(cube.id)
          }
        }
        return acc
      },
      { cubes: [], cubesIds: [], disabledCubesIds: [] }
    )
  }
)

export const getFinishOptions = createSelector(
  [optionsSelector, finishSelector],
  (options, finish) => {
    return finish.options.reduce((acc, optionId) => {
      const option = findById(options, optionId)
      if (option) {
        acc.push(option)
      }
      return acc
    }, [])
  }
)

export const getOptionMemo = () =>
  createSelector([getFinishOptions, (_, id) => id], (options, id) => {
    return findById(options, id)
  })

export const getOption = (state) => (optionId) =>
  findById(getFinishOptions(state), optionId)

export const getCurrentOption = createSelector(
  [currentOptionSelector, getFinishOptions],
  (current, items) => {
    return findById(items, current)
  }
)
