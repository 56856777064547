import { useCallback, useMemo, memo } from 'react'

import CubeSelectNode from '../../../Common/Nodes/CubeSelectNode'

import { useStore } from '../../../../modules/store'
import { setCubesAction } from '../../../../modules/sceneHotspotStore'
import { currentIdSelector } from '../../../../modules/sceneHotspotListStore'

import { currentCubeSelector } from '../../../../modules/sceneDrawDataStore'
import { getHotspotCubesMemo } from '../../../../modules/sceneDescriptionStore'

const HECubesSelectNode = () => {
  const id = useStore(currentIdSelector)
  const gHotspotCubes = useMemo(getHotspotCubesMemo, [])
  const { cubesIds } = useStore((state) => gHotspotCubes(state, id))
  const currentCube = useStore(currentCubeSelector)
  const setHotspotCubes = useStore(setCubesAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      if (value.length === 0) {
        setHotspotCubes(id, [currentCube])
      } else {
        setHotspotCubes(id, value)
      }
    },
    [id, setHotspotCubes, currentCube]
  )

  return (
    <CubeSelectNode
      id={'cubes'}
      label="Visible en"
      multiple
      value={cubesIds}
      onChange={handleChange}
    />
  )
}
export default memo(HECubesSelectNode)

// import { useCallback, useMemo, memo } from 'react'
//
// import CubeSelectNode from '../../../Common/Nodes/CubeSelectNode'
//
// import { useStore } from '../../../../modules/store'
// import {
//   setHotspotCubesAction,
//   currentIdSelector
// } from '../../../../modules/sceneHotspotStore'
// import { currentCubeSelector } from '../../../../modules/sceneDrawDataStore'
// import { getHotspotCubesMemo } from '../../../../modules/sceneDescriptionStore'
//
// const HEGotoCubeNode = () => {
//   const id = useStore(currentIdSelector)
//   const gHotspotCubes = useMemo(getHotspotCubesMemo, [])
//   const { cubesIds } = useStore((state) => gHotspotCubes(state, id))
//   const currentCube = useStore(currentCubeSelector)
//   // const { cubesIds } = gHotspotCubes(id)
//
//   const setHotspotCubes = useStore(setHotspotCubesAction)
//
//   const handleChange = useCallback(
//     (nodeId, value) => {
//       if (value.length === 0) {
//         setHotspotCubes(id, [currentCube])
//       } else {
//         setHotspotCubes(id, value)
//       }
//     },
//     [id, setHotspotCubes, currentCube]
//   )
//
//   return (
//     <CubeSelectNode
//       id={'cubes'}
//       label="Cubos"
//       multiple
//       value={cubesIds}
//       onChange={handleChange}
//     />
//   )
// }
// export default memo(HEGotoCubeNode)
