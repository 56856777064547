import { useEffect, useCallback, useState } from 'react'
import { styled } from '@mui/material/styles'

import TextEditor from '../../Common/TextEditor/TextEditor'
import Bar from './Bar'

import useStore from '../../../modules/store'
import { readModelAction } from '../../../modules/sceneTextEditorStore'
import {
  currentModelSelector,
  setCurrentModelAction,
  MODEL_ID
} from '../../../modules/textEditorModelsStore'
import {
  setEnabledOptionsAction,
  setDisabledOptionsAction
} from '../../../modules/lateralMenuStore'
import {} from '../../../schemas/files'
import { MENU_OPTIONS } from '../../../settings/menuSettings'
const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: 'white'
}))

const Main = () => {
  const [modeJson, setModeJson] = useState(false)

  const readModel = useStore(readModelAction)
  const setEnabledOptions = useStore(setEnabledOptionsAction)
  const setDisabledOptions = useStore(setDisabledOptionsAction)
  const currentModel = useStore(currentModelSelector)
  const setCurrentModel = useStore(setCurrentModelAction)

  // const loadModel = useCallback(
  //   (modelId) => {
  //     switch (modelId) {
  //       case MODEL_ID.SCENE:
  //         console.log(scene)
  //         updateModelValue(
  //           MODEL_ID.SCENE,
  //           stringify(scene, { maxLength: 100, indent: 1 })
  //         )
  //         break
  //       case MODEL_ID.MAPPER:
  //         updateModelValue(
  //           MODEL_ID.MAPPER,
  //           stringify(combinations, { maxLength: 100, indent: 1 })
  //         )
  //         break
  //       default:
  //     }
  //   },
  //   [updateModelValue, scene, combinations]
  // )
  // Carga modelos
  useEffect(() => {
    readModel(MODEL_ID.SCENE)
    readModel(MODEL_ID.MAPPER)
    readModel(MODEL_ID.STYLES)
    setCurrentModel(MODEL_ID.SCENE)
  }, [readModel, setCurrentModel])
  //Configura menu
  useEffect(() => {
    setEnabledOptions([MENU_OPTIONS.TEXT_EDITOR])
    return () => {
      setDisabledOptions([])
    }
  }, [setEnabledOptions, setDisabledOptions])

  const handleReload = useCallback(() => {
    readModel(currentModel)
  }, [readModel, currentModel])

  const handleUpload = useCallback((e) => {
    // dispatch(setKrpanoData(JSON.parse(model.value)))
  }, [])

  return (
    <Root>
      <Bar
        onUpload={handleUpload}
        onReload={handleReload}
        modeJson={modeJson}
        onSetJsonMode={setModeJson}
      />
      {modeJson ? <TextEditor /> : null}
    </Root>
  )
}
export default Main
