// import { useCallback } from 'react'
// import { useDispatch } from 'react-redux'

import { EditIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const MultipleEditButton = (props) => {
  return (
    <STButton
      tooltip={'Edicion múltiple'}
      tooltipEnabled
      icon={<EditIcon />}
      {...props}
    />
  )
}
export default MultipleEditButton
