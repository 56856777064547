import { useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { useSpring, useTransition, animated } from 'react-spring'

import { getAnchor } from '../../../modules/positioning2D'
import useStore from '../../../modules/store'
import { getImageSrc } from '../../../modules/sceneDrawDataStore'
import { getStyleMemo } from '../../../modules/hotspotTypesStore'
const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transformOrigin'
})(({ theme, transformOrigin }) => ({
  // transformOrigin
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
}))

const Image = styled('img', {
  shouldForwardProp: (prop) => prop !== 'customStyle'
})(({ theme, customStyle }) => ({ ...customStyle, objectFit: 'contain' }))

const Text = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customStyle'
})(({ theme, customStyle }) => ({
  textAlign: 'center',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -100%)',
  width: 'max-content',
  ...customStyle
}))

const ImageHotspot = ({ className, styleId, anchorId, data }) => {
  const [hover, setHover] = useState(false)
  const anchor = getAnchor(anchorId)
  const gImageSrc = useStore(getImageSrc)
  const gStyleMemo = useMemo(getStyleMemo, [])
  const customStyle = useStore((state) => gStyleMemo(state, styleId))

  const imageAnimation = useSpring({
    width: hover ? data.scale * data.size.width : data.size.width,
    height: hover ? data.scale * data.size.height : data.size.height
  })
  const textAnimation = useTransition(hover, {
    from: { opacity: 0, fontSize: 16 },
    enter: { opacity: 1, fontSize: data.scale * 16 },
    leave: { opacity: 0, fontSize: 16 }
  })

  const AnimatedImage = animated(Image)
  const AnimatedText = animated(Text)
  return data ? (
    <Root
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}
      transformOrigin={anchor.scaleOrigin}
    >
      {textAnimation(
        (styles, item) =>
          item && (
            <AnimatedText
              style={styles}
              customStyle={
                customStyle?.styles ? customStyle.styles.tooltip : null
              }
            >
              {data.tooltip}
            </AnimatedText>
          )
      )}
      <AnimatedImage
        style={imageAnimation}
        customStyle={customStyle?.styles ? customStyle.styles.image : null}
        src={gImageSrc(data.image)}
        draggable={false}
      />
    </Root>
  ) : null
}
export default ImageHotspot
