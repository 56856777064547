import { createSelector } from 'reselect'

import { findById, selectItem, clamp } from '../utils/utils'

export const TREE_NODE_TYPES = {
  TEXT: 'TEXT',
  MULTILINE_TEXT: 'MULTILINE_TEXT',
  BOOL: 'BOOL',
  INTEGER: 'INTEGER',
  FLOAT: 'FLOAT',
  COLOR: 'COLOR',
  IMAGE: 'IMAGE',
  IFRAME: 'IFRAME'
}

export const TREE_IDS = {
  CUBE: 'CUBE',
  FINISH: 'FINISH',
  OPTION: 'OPTION',
  HOTSPOT: 'HOTSPOT',
  POLYGON: 'POLYGON'
}
const propertyTreeStore = (set, get) => ({
  propertyTree: {
    trees: [
      // {
      //   id: ID,
      //   expanded: [],
      //   totalWidth: 0,
      //   labelWidth: 0
      // },
    ],
    addTree: (treeId) => {
      const tree = findById(treesSelector(get()), treeId)
      if (tree) {
        return
      }
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          trees: [
            ...treesSelector(st),
            {
              id: treeId,
              expanded: [],
              totalWidth: 0,
              labelWidth: 0
            }
          ]
        }
      }))
    },
    removeTree: (treeId) => {
      const tree = findById(treesSelector(get()), treeId)
      if (tree) {
        set((st) => ({
          propertyTree: {
            ...st.propertyTree,
            trees: treesSelector(st).filter((tree) => tree.id !== treeId)
          }
        }))
      }
    },
    setTotalWidth: (treeId, width) => {
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          trees: treesSelector(st).map((tree) => {
            if (tree.id === treeId) {
              return {
                ...tree,
                totalWidth: width,
                labelWidth: updateLabelWidth(width, tree.labelWidth)
              }
            }
            return tree
          })
        }
      }))
    },
    setLabelWidth: (treeId, width) => {
      const tree = findById(treesSelector(get()), treeId)
      if (tree) {
        const labelWidth = updateLabelWidth(tree.totalWidth, width)
        if (tree.labelWidth !== labelWidth) {
          set((st) => ({
            propertyTree: {
              ...st.propertyTree,
              trees: treesSelector(st).map((tree) => {
                if (tree.id === treeId) {
                  return {
                    ...tree,
                    labelWidth: updateLabelWidth(tree.totalWidth, width)
                  }
                }
                return tree
              })
            }
          }))
        }
      }
    },
    setNodeExpanded: (treeId, nodeId, expanded) => {
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          trees: treesSelector(st).map((tree) => {
            if (tree.id === treeId) {
              return {
                ...tree,
                expanded: selectItem(tree.expanded, nodeId, expanded)
              }
            }
            return tree
          })
        }
      }))
    },
    setExpandedNodes: (treeId, expanded) => {
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          trees: treesSelector(st).map((tree) => {
            if (tree.id === treeId) {
              return {
                ...tree,
                expanded
              }
            }
            return tree
          })
        }
      }))
    }
  }
})
export default propertyTreeStore

const updateLabelWidth = (treeWidth, labelWidth) => {
  if (treeWidth < 200) {
    return treeWidth / 2
  } else {
    return clamp(labelWidth, 100, treeWidth - 100)
  }
}

export const addTreeAction = (state) => state.propertyTree.addTree
export const removeTreeAction = (state) => state.propertyTree.removeTree
export const setTotalWidthAction = (state) => state.propertyTree.setTotalWidth
export const setLabelWidthAction = (state) => state.propertyTree.setLabelWidth
export const setNodeExpandedAction = (state) =>
  state.propertyTree.setNodeExpanded
export const setExpandedNodesAction = (state) =>
  state.propertyTree.setExpandedNodes

export const treesSelector = (state) => state.propertyTree.trees

export const getTree = () =>
  createSelector([treesSelector, (_, id) => id], (trees, id) => {
    return findById(trees, id)
  })
