import { createSelector } from 'reselect'
import { findById } from '../utils/utils'
import * as THREE from 'three'
import { get3DPosition } from './transformStore'
import { threeBoundsSelector } from './mainStore'
import { getCurrentHotspot } from './sceneHotspotListStore'
import { setPolygonDataAction } from './sceneHotspotStore'
const vector3 = new THREE.Vector3()

export const EDITOR_MODE_IDS = {
  ADD_POINT: 'ADD_POINT',
  REMOVE_POINT: 'REMOVE_POINT',
  ADD_HOLE: 'ADD_HOLE',
  POINTER: 'POINTER'
}

const resetStore = () => ({
  rotation: [0, 0, 0],
  position: [0, 0, 0],
  points: [], //[{id,position:[x,y]}]
  polygon: [], //[pointId,pointId]
  holes: [], //[{id,points:[]}]
  currentPoint: '',
  pointSeed: 0,
  holeSeed: 0,
  mode: EDITOR_MODE_IDS.ADD_POINT
})

export const polygonEditorStore = (set, get) => ({
  polygonEditor: {
    ...resetStore(),
    init: () => {
      //Guarda hotspot actual
      const hs = getCurrentHotspot(get())
      if (!hs?.polygonData) {
        set((st) => ({
          polygonEditor: {
            ...st.polygonEditor,
            ...resetStore()
          }
        }))
        return
      }
      //Adapta polygonData al formato del editor
      const polygon = []
      const points = []
      const holes = []
      for (let i = 0; i < hs.polygonData.contour.length; i += 2) {
        const point = createPoint(
          points.length,
          hs.polygonData.contour[i],
          hs.polygonData.contour[i + 1]
        ).point
        points.push(point)
        polygon.push(point.id)
      }
      hs.polygonData.holes.forEach((holePoints, n) => {
        const { hole } = createHole(n)
        holes.push(hole)
        for (let i = 0; i < holePoints.length; i += 2) {
          const point = createPoint(
            points.length,
            holePoints[i],
            holePoints[i + 1]
          ).point
          points.push(point)
          holes[n].points.push(point.id)
        }
      })

      set((st) => ({
        polygonEditor: {
          ...st.polygonEditor,
          ...resetStore(),
          rotation: hs.polygonData.rotation,
          position: hs.polygonData.position,
          points,
          polygon,
          holes,
          pointSeed: points.length,
          holeSeed: holes.length
        }
      }))
    },
    save: () => {
      const hotspot = getCurrentHotspot(get())
      if (!hotspot?.polygonData) {
        return
      }
      //Vuelve a cambiar de editor a polygonData
      const polygonData = {
        rotation: rotationSelector(get()),
        position: get().polygonEditor.position,
        contour: [],
        holes: []
      }
      const points = pointsSelector(get())
      const polygon = polygonSelector(get())
      if (polygon.length > 2) {
        polygonData.contour = polygon.reduce((acc, pointId) => {
          const point = findById(points, pointId)
          return [...acc, point.position[0], point.position[1]]
        }, [])

        polygonData.holes = holesSelector(get()).reduce((acc, hole) => {
          if (hole.points.length > 2) {
            acc.push(
              hole.points.reduce((acc, pointId) => {
                const point = findById(points, pointId)
                return [...acc, point.position[0], point.position[1]]
              }, [])
            )
          }
          return acc
        }, [])
      }
      setPolygonDataAction(get())(hotspot.id, polygonData)
    },

    insertPoint: (plane, x, y) => {
      switch (modeSelector(get())) {
        case EDITOR_MODE_IDS.ADD_POINT: {
          if (!pointsSelector(get()).length) {
            //Primer punto. Guarda posicion-rotacion del plano
            set((st) => ({
              polygonEditor: {
                ...st.polygonEditor,
                rotation: [
                  plane.rotation.x,
                  plane.rotation.y,
                  plane.rotation.z
                ],
                position: [plane.position.x, plane.position.y, plane.position.z]
              }
            }))
          }
          const currentPointId = currentPointSelector(get())
          if (currentPointId || !pointsSelector(get()).length) {
            const { polygon, hole, idx } = getPointOwner(get())(currentPointId)

            if (polygon || !hole) {
              //El currentPoint es del contorno
              const newPolygon = [...polygon]
              const { point, pointSeed } = createPoint(
                pointSeedSelector(get()),
                x,
                y
              )
              newPolygon.splice(idx >= 0 ? idx + 1 : 0, 0, point.id)
              set((st) => ({
                polygonEditor: {
                  ...st.polygonEditor,
                  polygon: newPolygon,
                  points: [...pointsSelector(get()), point],
                  currentPoint: point.id,
                  pointSeed
                }
              }))
            } else if (hole) {
              //El currentPoint en de un hueco
              const contour = [...hole.points]
              const { point, pointSeed } = createPoint(
                pointSeedSelector(get()),
                x,
                y
              )
              contour.splice(idx >= 0 ? idx + 1 : 0, 0, point.id)
              set((st) => ({
                polygonEditor: {
                  ...st.polygonEditor,
                  holes: st.polygonEditor.holes.map((h) => {
                    if (h.id === hole.id) {
                      return { ...h, points: contour }
                    }
                    return h
                  }),
                  points: [...pointsSelector(get()), point],
                  currentPoint: point.id,
                  pointSeed
                }
              }))
            }
          }
          break
        }
        case EDITOR_MODE_IDS.ADD_HOLE:
          if (polygonSelector(get()).length < 3) {
            //Todavia no hay contorno. No se puede añadir hueco
            set((st) => ({
              polygonEditor: {
                ...st.polygonEditor,
                mode: EDITOR_MODE_IDS.ADD_POINT
              }
            }))
            return
          }
          //Crea hueco, le añade un punto y cambia de modo
          const { point, pointSeed } = createPoint(
            pointSeedSelector(get()),
            x,
            y
          )
          const { hole, holeSeed } = createHole(holeSeedSelector(get()))
          hole.points = [point.id]
          set((st) => ({
            polygonEditor: {
              ...st.polygonEditor,
              holes: [...st.polygonEditor.holes, hole],
              points: [...pointsSelector(get()), point],
              currentPoint: point.id,
              holeSeed,
              pointSeed,
              mode: EDITOR_MODE_IDS.ADD_POINT
            }
          }))
          break
        default:
      }
    },
    removePoints: (ids) => {
      const points = pointsSelector(get())
      if (ids.length > 0) {
        const newPoints = points.filter((point) => ids.indexOf(point.id) < 0)
        const newState = {
          ...get().polygonEditor,
          points: newPoints,
          polygon: polygonSelector(get()).filter(
            (pointId) => ids.indexOf(pointId) < 0
          ),
          holes: get().polygonEditor.holes.reduce((acc, hole) => {
            const points = hole.points.filter(
              (pointId) => ids.indexOf(pointId) < 0
            )
            if (points.length) {
              acc.push({ ...hole, points })
            }
            return acc
          }, [])
        }
        let currentPoint = ''
        if (newPoints.length && ids.indexOf(currentPointSelector(get())) >= 0) {
          //Hay que cambiar el currentPoint. Toma como referencia el ultimo punto
          //de la lista
          const { polygon, hole, idx } = getPointOwner(get())(ids.slice(-1)[0])
          if (polygon) {
            if (newState.polygon.length) {
              currentPoint =
                newState.polygon[
                  Math.max(Math.min(newState.polygon.length - 1, idx - 1), 0)
                ]
            }
          } else {
            const h = findById(newState.holes, hole.id)
            if (h?.points.length) {
              currentPoint =
                h.points[Math.max(Math.min(h.points.length - 1, idx - 1), 0)]
            } else {
              currentPoint = newState.points[0].id
            }
          }
        }
        set((st) => ({
          polygonEditor: {
            ...newState,
            currentPoint,
            mode:
              newState.points.length === 0
                ? EDITOR_MODE_IDS.POINTER
                : newState.mode
          }
        }))
      }
    },
    setMode: (mode) => {
      set((st) => ({
        polygonEditor: { ...st.polygonEditor, mode }
      }))
    },
    setPointPosition: (id, position) => {
      set((st) => ({
        polygonEditor: {
          ...st.polygonEditor,
          points: st.polygonEditor.points.map((point) => {
            if (point.id === id) {
              return { ...point, position }
            }
            return point
          })
        }
      }))
    },
    setPointPosition2D: (id, x, y, plane, camera) => {
      const bounds = threeBoundsSelector(get())
      const position = get3DPosition([plane], camera, bounds, [
        x - bounds.left,
        y - bounds.top
      ])
      if (position) {
        vector3.fromArray(position)
        const v = plane.worldToLocal(vector3)
        setPointPositionAction(get())(id, [v.x, v.y])
      }
    },
    setPoint: (id, point) => {},
    setCurrentPoint: (id) => {
      set((st) => ({
        polygonEditor: { ...st.polygonEditor, currentPoint: id }
      }))
    }
  }
})
export default polygonEditorStore

const createPoint = (pointSeed, x, y) => {
  return {
    pointSeed: pointSeed + 1,
    point: { id: `p_${pointSeed}`, position: [x, y] }
  }
}
const createHole = (holeSeed) => {
  return {
    holeSeed: holeSeed + 1,
    hole: { id: `hole_${holeSeed}`, points: [] }
  }
}

export const initAction = (state) => state.polygonEditor.init
export const saveAction = (state) => state.polygonEditor.save
export const setModeAction = (state) => state.polygonEditor.setMode
export const insertPointAction = (state) => state.polygonEditor.insertPoint
export const removePointsAction = (state) => state.polygonEditor.removePoints
export const setCurrentPointAction = (state) =>
  state.polygonEditor.setCurrentPoint
export const setPointPositionAction = (state) =>
  state.polygonEditor.setPointPosition
export const setPointPosition2DAction = (state) =>
  state.polygonEditor.setPointPosition2D

export const pointsSelector = (state) => state.polygonEditor.points
export const polygonSelector = (state) => state.polygonEditor.polygon
export const holesSelector = (state) => state.polygonEditor.holes

export const rotationSelector = (state) => state.polygonEditor.rotation
export const positionSelector = (state) => state.polygonEditor.position
export const currentPointSelector = (state) => state.polygonEditor.currentPoint
const pointSeedSelector = (state) => state.polygonEditor.pointSeed
const holeSeedSelector = (state) => state.polygonEditor.holeSeed
export const modeSelector = (state) => state.polygonEditor.mode

const getPolygon = (ids, points) => {
  return ids.reduce((acc, pointId) => {
    const point = findById(points, pointId)
    if (point) {
      acc.push(point.position)
    }
    return acc
  }, [])
}

export const getDrawData = createSelector(
  [polygonSelector, holesSelector, pointsSelector],
  (polygon, holes, points) => {
    return {
      contour: getPolygon(polygon, points),
      holes: holes.map((hole) => getPolygon(hole.points, points))
    }
  }
)

// const getPolygon = (ids, points) => {
//   return ids.reduce(
//     (acc, pointId) => {
//       const point = findById(points, pointId)
//       if (point) {
//         acc.pointIds.push(pointId)
//         acc.points.push(point.position)
//       }
//       return acc
//     },
//     { pointIds: [], points: [] }
//   )
// }
//
// export const getDrawData = createSelector(
//   [polygonSelector, holesSelector, pointsSelector],
//   (polygon, holes, points) => {
//     return {
//       contour: getPolygon(polygon, points),
//       holes: holes.map((hole) => {
//         return { hole, contour: getPolygon(hole.points, points) }
//       })
//     }
//   }
// )
// const getContour = (ids, points) => {
//   return ids.reduce(
//     (acc, pointId) => {
//       const point = findById(points, pointId)
//       if (point) {
//         acc.pointIds.push(pointId)
//         acc.points.push(point)
//       }
//       return acc
//     },
//     { pointIds: [], points: [] }
//   )
// }

export const getPointMemo = () =>
  createSelector([pointsSelector, (_, id) => id], (points, id) => {
    return findById(points, id)
  })

// export const getContour = createSelector(
//   [polygonSelector, pointsSelector],
//   (polygon, points) => {
//     return getPolygon(polygon, points)
//   }
// )
//
// export const getHoles = createSelector(
//   [holesSelector, pointsSelector],
//   (holes, points) => {
//     return holes.map((hole) => {
//       return { hole, contour: getPolygon(hole.points, points) }
//     })
//   }
// )

const getPointOwner = createSelector(
  [polygonSelector, holesSelector],
  (polygon, holes) => (pointId) => {
    let idx = polygon.findIndex((id) => id === pointId)
    if (idx >= 0) {
      return { polygon, idx, hole: null }
    }
    for (let hole of holes) {
      idx = hole.points.indexOf(pointId)
      if (idx >= 0) {
        return { polygon: null, idx, hole }
      }
    }
    return { polygon, idx: -1, hole: null }
  }
)

// export const getHoleMemo = () =>
//   createSelector(
//     [holesSelector, pointsSelector, (_, id) => id],
//     (holes, points, id) => {
//       const hole = findById(holes, id)
//       if (hole) {
//         return { hole, ...getContour(hole.points, points) }
//       }
//     }
//   )
