import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

const SIconButton = styled(IconButton)(({ theme }) => ({
  color: 'inherit'
}))

const DraggableListItemButton = ({
  hide,
  tooltip,
  disabled,
  children,
  onClick
}) => {
  return hide ? null : (
    <Tooltip title={tooltip}>
      <SIconButton disabled={disabled} onClick={onClick}>
        {children}
      </SIconButton>
    </Tooltip>
  )
}
export default DraggableListItemButton
