import { useCallback } from 'react'

import AnchorNode from '../../../Common/Nodes/AnchorNode'
import { useStore } from '../../../../modules/store'
import { setAnchorAction } from '../../../../modules/sceneHotspotStore'
import {
  getCurrentHotspot,
  currentIdSelector
} from '../../../../modules/sceneHotspotListStore'

const HEAnchorSelectNode = () => {
  const currentId = useStore(currentIdSelector)
  const hotspot = useStore(getCurrentHotspot)
  const setAnchor = useStore(setAnchorAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setAnchor(currentId, value)
    },
    [currentId, setAnchor]
  )

  return hotspot?.position2D || hotspot?.position3D ? (
    <AnchorNode
      id={'anchor'}
      label="Ancla"
      value={hotspot.anchor}
      onChange={handleChange}
    />
  ) : null
}
export default HEAnchorSelectNode
