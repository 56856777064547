import { useMemo, useEffect, memo } from 'react'
import { styled, ThemeProvider, useTheme } from '@mui/material/styles'

import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import { PropertyTreeContext } from '../PropertyTreeContext'
import { getPropertyTreeTheme } from '../PropertyTreeTheme'

import useStore from '../../../../modules/store'
import {
  addTreeAction,
  setTotalWidthAction,
  getTree
} from '../../../../modules/propertyTreeStore'
const SPLITTER_TRANSPARENT_BAR_WIDTH = 12

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'topBorder'
})(({ theme, topBorder }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  flexFlow: 'column',
  borderTop: topBorder
    ? `2px solid ${theme.palette.primary.contrastText}`
    : 'none'
}))

const PropertyTree = ({
  treeId,
  name,
  nodeHeight = 32,
  children,
  topBorder = true
}) => {
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const gTree = useMemo(getTree, [])
  const tree = useStore((state) => gTree(state, treeId))
  const setWidth = useStore(setTotalWidthAction)
  const addTree = useStore(addTreeAction)
  const theme = useTheme()

  console.log('Tree')

  useEffect(() => {
    if (rootBounds) {
      addTree(treeId)
      if (rootBounds.width > 0) {
        setWidth(treeId, rootBounds.width)
      }
    }
  }, [rootBounds, treeId, addTree, setWidth])

  return tree ? (
    <ThemeProvider theme={getPropertyTreeTheme(theme)}>
      <PropertyTreeContext.Provider
        value={{
          treeId,
          name,
          splitterTransparentBarWidth: SPLITTER_TRANSPARENT_BAR_WIDTH,
          nodeHeight: nodeHeight
        }}
      >
        <Root ref={rootBoundsRef} topBorder={topBorder}>
          {children}
        </Root>
      </PropertyTreeContext.Provider>
    </ThemeProvider>
  ) : null
}
export default memo(PropertyTree)
