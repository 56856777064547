import { createTheme, lighten } from '@mui/material/styles'
// import createBreakpoints from '@material-ui/styles/createBreakpoints'
// const breakpoints = createBreakpoints({})

const MainTheme = createTheme({
  zIndex: { krpano: 10 },

  typography: {
    fontFamily: `Metropolis-Regular ,Roboto, Helvetica, Arial, sans-serif`,
    h1: { fontSize: '28px' },
    h2: { fontSize: '24px' },
    h3: { fontSize: '20px' },
    h4: { fontSize: '16px' },
    h5: { fontSize: '12px' },
    h6: { fontSize: '8px' }
  },
  palette: {
    error: { main: '#eb5757' },
    primary: {
      main: '#000000',
      mainTransparent: 'rgba(0, 0, 0, 0.5)',
      light: '#333333',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#D3AC5E',
      contrastText: '#FFFFFF',
      gradient: 'linear-gradient(to top, #d3ac5e, #ead98a)',
      gradientHorizontal: 'linear-gradient(to right, #d3ac5e, #ead98a)',
      hover: '#575343E0'
    },
    action: { disabled: '#828282' },
    other: {
      background: '#000000'
      // background: '#000F31',
      // sendButton: '#e0e0e0'
    }
  }
})

MainTheme.components = {
  MuiTextField: {
    defaultProps: { size: 'small', variant: 'outlined' },
    styleOverrides: {
      root: {
        '& .MuiFormLabel-root': {
          color: MainTheme.palette.primary.contrastText,
          '&.Mui-focused': {
            color: MainTheme.palette.secondary.main
          }
        },
        '& .MuiOutlinedInput-root': {
          color: MainTheme.palette.primary.contrastText,
          '& fieldset': {
            borderColor: MainTheme.palette.primary.contrastText
          },
          '&:hover fieldset': {
            borderColor: MainTheme.palette.secondary.main
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme.palette.secondary.main
          }
        },
        '& .MuiSelect-icon': { color: MainTheme.palette.primary.contrastText }
      }
    }
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
      disableUnderline: true,
      fullWidth: true,
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }
    },
    styleOverrides: {
      root: {},
      icon: { color: 'inherit' },
      nativeInput: { boxSizing: 'border-box' }
    }
  },

  MuiMenu: {
    styleOverrides: {
      root: {
        '& .MuiMenu-paper': {
          color: MainTheme.palette.primary.contrastText,
          backgroundColor: MainTheme.palette.primary.light,
          borderRadius: 0,
          border: `1px solid ${MainTheme.palette.primary.contrastText}`,
          boxSizing: 'border-box'
        }
      },
      list: { padding: 0 }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        '&.Mui-selected': {
          backgroundColor: lighten(MainTheme.palette.primary.light, 0.1)
        },
        '&:hover': {
          backgroundColor: MainTheme.palette.secondary.main,
          '&.Mui-selected': {
            backgroundColor: MainTheme.palette.secondary.main
          }
        }
      }
    }
  }
  // ScrollToolbarItem: {
  //   styleOverrides: {
  //     root: { backgroundColor: 'green' }
  //   }
  // },
}

export default MainTheme
