import { createSelector } from 'reselect'
import { findById, getFirstNotUsedName } from '../utils/utils'
import { hotspotBlinkySchema } from '../schemas/hotspotBlinkySchema'
import { hotspotImageSchema } from '../schemas/hotspotImageSchema'
import { hotspotPolygonSchema } from '../schemas/hotspotPolygonSchema'

export const HOTSPOTS_TYPES_IDS = {
  CIRCLE_SEQUENCE: 'CIRCLE_SEQUENCE',
  FILLED_CIRCLE_SEQUENCE: 'FILLED_CIRCLE_SEQUENCE',
  IMAGE: 'IMAGE', //Si se cambia este, cambiar tambien en hotspotSchema de la escena
  POLYGON: 'POLYGON'
}

export const hotspotTypesStore = (set, get) => ({
  hotspotTypes: {
    styles: [],
    types: [
      {
        id: HOTSPOTS_TYPES_IDS.CIRCLE_SEQUENCE,
        name: 'Parpadeo círculo',
        description: '',
        schema: hotspotBlinkySchema
        // styles: []
      },
      {
        id: HOTSPOTS_TYPES_IDS.FILLED_CIRCLE_SEQUENCE,
        name: 'Parpadeo círculo relleno',
        description: '',
        schema: hotspotBlinkySchema
        // styles: []
      },
      {
        id: HOTSPOTS_TYPES_IDS.IMAGE,
        name: 'Imagen',
        description: '',
        schema: hotspotImageSchema
        // styles: []
      },
      {
        id: HOTSPOTS_TYPES_IDS.POLYGON,
        name: 'Polígono',
        description: '',
        schema: hotspotPolygonSchema
        // styles: []
      }
    ],

    readStyles: async () => {
      const res = await fetch('./styles/hotspotStyles.json')
      if (res.ok) {
        const styles = await res.json()
        set((st) => ({
          hotspotTypes: {
            ...st.hotspotTypes,
            styles
          }
        }))
      }
    },
    addStyles: (styles) => {
      if (styles?.length > 0) {
        //Si algun estilo del los nuevos tiene en mismo id que un estilo actual
        //gana el nuevo estilo y al actual de le cambia el id.
        const currentStyles = stylesSelector(get())
        const newStyles = currentStyles.reduce((acc, currentStyle) => {
          let style = acc.find((style) => style.id === currentStyle.id)
          if (style) {
            style = {
              ...currentStyle,
              id: getFirstNotUsedName(acc, 'id', 'st', '')
            }
          } else {
            style = currentStyle
          }

          acc.push(style)
          return acc
        }, styles)

        set((st) => ({
          hotspotTypes: {
            ...st.hotspotTypes,
            styles: newStyles
            //  [
            //   ...st.hotspotTypes.styles,
            //   ...styles.filter(
            //     (newStyle) =>
            //       !currentStyles.find(
            //         (currentStyle) => currentStyle.id === newStyle.id
            //       )
            //   )
            // ]
          }
        }))
      }
    }
    // readStyles: async () => {
    //   const res = await fetch('./styles/prueba.css')
    //   if (res.ok) {
    //     const styles = await res.text()
    //     const lines = styles.split(/\r\n|\n/)
    //     const hotspotStyleLines = lines.filter((line) =>
    //       line.match(/^\s*[.]\S+-\S+\s+{$/)
    //     )
    //     const hotspotStyles = hotspotStyleLines.reduce((acc, line) => {
    //       const pair = line.trim().split('-')
    //       acc.push({
    //         type: pair[0].slice(1),
    //         style: pair[1].slice(0, -1).trim() //Quita llave final y espacios
    //       })
    //       return acc
    //     }, [])
    //     set((st) => ({
    //       hotspotTypes: {
    //         ...st.hotspotTypes,
    //         styles: hotspotStyles
    //       }
    //     }))
    //   }
    // }
  }
})
export default hotspotTypesStore

export const readStylesAction = (state) => state.hotspotTypes.readStyles
export const addStylesAction = (state) => state.hotspotTypes.addStyles

export const typesSelector = (state) => state.hotspotTypes.types
export const stylesSelector = (state) => state.hotspotTypes.styles

export const getType = (state) => (id) => {
  return findById(typesSelector(state), id)
}
export const getTypeMemo = () =>
  createSelector([typesSelector, (_, id) => id], (types, id) => {
    return findById(types, id)
  })
export const getTypeStylesMemo = () =>
  createSelector([stylesSelector, (_, id) => id], (styles, typeId) => {
    return styles.filter((style) => style.hotspotTypes.indexOf(typeId) >= 0)
  })
export const getStyleMemo = () =>
  createSelector([stylesSelector, (_, id) => id], (styles, id) => {
    return findById(styles, id)
  })
