export const mainStore = (set, get) => ({
  main: {
    rootBounds: null,
    threeBounds: null,
    selectorVisible: false,
    setRootBounds: (bounds) =>
      set((prev) => ({ main: { ...prev.main, rootBounds: bounds } })),
    setThreeBounds: (bounds) =>
      set((prev) => ({ main: { ...prev.main, threeBounds: bounds } })),
    setSelectorVisible: (visible) => {
      set((prev) => ({ main: { ...prev.main, selectorVisible: visible } }))
    }
  }
})
export default mainStore

export const setRootBoundsAction = (state) => state.main.setRootBounds
export const setThreeBoundsAction = (state) => state.main.setThreeBounds
export const setSelectorVisibleAction = (state) => state.main.setSelectorVisible

export const mainSelector = (state) => state.main
export const rootBoundsSelector = (state) => state.main.rootBounds
export const threeBoundsSelector = (state) => state.main.threeBounds
export const selectorVisibleSelector = (state) => state.main.selectorVisible
