import {
  multilanguageTextSchema,
  point3DSchema,
  stringListSchema,
  uniqueStringListSchema,
  noEmptyStringListSchema,
  noEmptyUniqueStringListSchema,
  povSchema
} from './schemas'
import {
  finishSchema as imageMapperFinishSchema,
  optionSchema as imageMapperOptionSchema,
  pairSchema,
  autoSchema,
  linkSchema,
  combinationSchema,
  equirectangularImagesMapperSchema
} from './equirectangularImagesMapperSchema'
import {
  position2DPropertySchema,
  position2DSchema,
  hotspotSchema,
  hotspotEventSchema
} from './hotspotSchema'

import { hotspotPolygonSchema, polygonSchema } from './hotspotPolygonSchema'
import {
  languagesSchema,
  cubeSchema,
  cubeImagesSchema,
  sceneDefaultCfgSchema,
  sceneSchema,
  finishSchema,
  optionSchema
} from './sceneSchema'

import { hotspotStyleSchema, hotspotStylesSchema } from './hotspotStylesSchema'

export const sceneFile = [
  { uri: languagesSchema.id, schema: languagesSchema },
  { uri: multilanguageTextSchema.id, schema: multilanguageTextSchema },
  { uri: povSchema.id, schema: povSchema },
  { uri: point3DSchema.id, schema: point3DSchema },
  { uri: stringListSchema.id, schema: stringListSchema },
  { uri: uniqueStringListSchema.id, schema: uniqueStringListSchema },
  { uri: noEmptyStringListSchema.id, schema: noEmptyStringListSchema },
  {
    uri: noEmptyUniqueStringListSchema.id,
    schema: noEmptyUniqueStringListSchema
  },
  { uri: cubeSchema.id, schema: cubeSchema },
  { uri: hotspotSchema.id, schema: hotspotSchema },
  { uri: point3DSchema.id, schema: point3DSchema },
  { uri: position2DPropertySchema.id, schema: position2DPropertySchema },
  { uri: position2DSchema.id, schema: position2DSchema },
  { uri: finishSchema.id, schema: finishSchema },
  { uri: optionSchema.id, schema: optionSchema },
  { uri: cubeImagesSchema.id, schema: cubeImagesSchema },
  { uri: sceneDefaultCfgSchema.id, schema: sceneDefaultCfgSchema },
  { uri: hotspotPolygonSchema.id, schema: hotspotPolygonSchema },
  { uri: polygonSchema.id, schema: polygonSchema },
  { uri: hotspotEventSchema.id, schema: hotspotEventSchema },

  {
    fileMatch: ['*'],
    uri: sceneSchema.id,
    schema: sceneSchema
  }
]

export const imageMapperFile = [
  { uri: imageMapperFinishSchema.id, schema: imageMapperFinishSchema },
  { uri: imageMapperOptionSchema.id, schema: imageMapperOptionSchema },
  { uri: pairSchema.id, schema: pairSchema },
  { uri: autoSchema.id, schema: autoSchema },
  { uri: linkSchema.id, schema: linkSchema },
  { uri: combinationSchema.id, schema: combinationSchema },
  {
    fileMatch: ['*'],
    uri: equirectangularImagesMapperSchema.id,
    schema: equirectangularImagesMapperSchema
  }
]

export const stylesFile = [
  { uri: hotspotStyleSchema.id, schema: hotspotStyleSchema },
  {
    fileMatch: ['*'],
    uri: hotspotStylesSchema.id,
    schema: hotspotStylesSchema
  }
]
