import { useMemo, useContext } from 'react'
import { styled } from '@mui/material/styles'

import { PropertyTreeContext } from '../PropertyTreeContext'
import PropertyTreeRow from '../Tree/PropertyTreeRow'
import PropertyTreeSplitter from '../Tree/PropertyTreeSplitter'

import useStore from '../../../../modules/store'
import { getTree } from '../../../../modules/propertyTreeStore'

const Header = styled(PropertyTreeRow, {
  shouldForwardProp: (prop) => prop !== 'height'
})(({ theme, height }) => ({
  display: 'flex',
  flexFlow: 'row',
  boxSizing: 'border-box',
  width: '100%',
  height: height
}))

const Property = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'propertyWidth' && prop !== 'transparentBarWidth'
})(({ theme, propertyWidth, transparentBarWidth }) => ({
  boxSizing: 'border-box',
  width: propertyWidth,
  minWidth: propertyWidth,
  padding: 4,
  paddingRight: transparentBarWidth,
  display: 'flex',
  alignItems: 'center'
}))
const DefaultPropertyContent = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'propertyWidth' && prop !== 'transparentBarWidth'
})(({ theme, propertyWidth, transparentBarWidth }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%'
}))

const Value = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBarWidth'
})(({ theme, transparentBarWidth }) => ({
  padding: 4,
  paddingLeft: transparentBarWidth,
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}))

const DefaultValueContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBarWidth'
})(({ theme, transparentBarWidth }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%'
}))

const PropertyTreeHeader = ({ height = 40 }) => {
  // const dispatch = useDispatch()
  const context = useContext(PropertyTreeContext)
  const gTree = useMemo(getTree, [])
  const tree = useStore((state) => gTree(state, context.treeId))

  return tree ? (
    <Header height={height}>
      <Property
        propertyWidth={tree.labelWidth}
        transparentBarWidth={context.splitterTransparentBarWidth}
      >
        <DefaultPropertyContent>Property</DefaultPropertyContent>
      </Property>
      <PropertyTreeSplitter />
      <Value transparentBarWidth={context.splitterTransparentBarWidth}>
        <DefaultValueContent>Value</DefaultValueContent>
      </Value>
    </Header>
  ) : null
}
export default PropertyTreeHeader
