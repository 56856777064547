import { useCallback, memo } from 'react'
import MultilanguageTextNode from '../../../Common/Nodes/MultilanguageTextNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setNameAction } from '../../../../modules/sceneCubeStore'

const CENameNode = () => {
  const name = useStore(
    useCallback((state) => {
      const { cube } = getFirstSelected(state)
      return cube ? cube.name : null
    }, [])
  )
  const selected = useStore(selectedSelector)
  const setName = useStore(setNameAction)

  const handleChange = useCallback(
    (id, newValue) => {
      setName(selected[0], newValue)
    },
    [selected, setName]
  )

  return selected.length === 1 ? (
    <MultilanguageTextNode
      id={'name'}
      label="Nombre"
      tooltipDisabled
      value={name}
      onChange={handleChange}
    />
  ) : null
}
export default memo(CENameNode)
