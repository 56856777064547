// import { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles'

import MediaItem from './MediaItem'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row'
}))
const MediasWrapper = styled('div')(({ theme }) => ({
  maxHeight: 400,
  width: 160,
  overflow: 'auto',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}))

const Image = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  boxSizing: 'border-box',
  width: 400,
  height: 400,
  padding: 12
}))
const Video = styled('video')(({ theme }) => ({
  objectFit: 'contain',
  boxSizing: 'border-box',
  width: 400,
  height: 400,
  padding: 12
}))

const MediaSelectDialogContent = ({
  imageMode,
  mediaSrc,
  currentMediaSrc,
  medias,
  onChange,
  ...props
}) => {
  console.log(currentMediaSrc)
  return (
    <Root>
      <MediasWrapper>
        {medias.map((media, idx) => (
          <MediaItem
            key={idx}
            imageMode={imageMode}
            id={idx}
            src={media.src}
            selected={mediaSrc === media.src}
            onClick={onChange}
          />
        ))}
      </MediasWrapper>

      {imageMode ? (
        <Image src={mediaSrc} alt={''} />
      ) : (
        <Video src={mediaSrc} controls />
      )}
    </Root>
  )
}
export default MediaSelectDialogContent
