import { useCallback } from 'react'
import { styled } from '@mui/material/styles'

import MyButton from '../../Common/MyButton'

const Root = styled('div')(({ theme, height }) => ({
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  width: '100%',
  height: height,
  minHeight: height,
  maxHeight: height
}))

const ContentWrapper = styled('div')(({ theme, height }) => ({
  padding: '4px 6px 4px 4px',
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  height: '100%',
  overflowY: 'scroll'
}))

const SMyButton = styled(MyButton)(({ theme }) => ({
  margin: '4px 0',
  minWidth: 0,
  width: '100%'
}))

export const SelectorTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'center',
  alingItems: 'center',
  paddingBottom: 4
}))

export const SelectorItem = ({ id, selected, disabled, title, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(id)
  }, [onClick, id])

  return (
    <SMyButton selected={selected} disabled={disabled} onClick={handleClick}>
      {title}
    </SMyButton>
  )
}

export const SelectorGroup = ({ title, height, disabled, children }) => {
  return (
    <Root height={height}>
      <SelectorTitle style={{ paddingRight: 0 }}>{title}</SelectorTitle>
      <ContentWrapper>{children}</ContentWrapper>
    </Root>
  )
}
