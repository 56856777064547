import { styled, alpha } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { AddDuplicateIcon } from '../Icons/Icons'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.light,
  border: `2px solid ${theme.palette.primary.contrastText}`,
  padding: 1
}))

const SListItem = styled(ListItem)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '0 16px',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  border: '2px solid transparent',
  transition: 'none',
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    border: `2px solid ${theme.palette.secondary.main}`
  }
}))

const SListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  color: 'currentColor',
  paddingRight: 8
}))

const EditableListEmptyItem = ({ text, onAdd }) => {
  return (
    <Root>
      <SListItem button onClick={onAdd}>
        <ListItemText primary={text} />
        <SListItemIcon>
          <AddDuplicateIcon />
        </SListItemIcon>
      </SListItem>
    </Root>
  )
}
export default EditableListEmptyItem
