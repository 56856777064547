import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Globals } from '@react-spring/shared'

import ImageMapper from './Editor/ImageMapper/ImageMapper'
import CustomLateralMenu from './CustomLateralMenu/CustomLateralMenu'
import EditorScene from './Editor/Three/EditorScene'
import EditorHotspots2D from './Editor/Hotspots/EditorHotspots2D'
import Selector from './Editor/Selector/Selector'
import TextEditor from './Editor/TextEditor/Main'
import OpenErrorsDialog from './Editor/OpenErrorsDialog/OpenErrorsDialog'

import useStore from '../modules/store'
import { openAction } from '../modules/openSaveStore'
import { currentOptionSelector } from '../modules/lateralMenuStore'
import { setSphereModeAction } from '../modules/sceneDrawDataStore'
import { MENU_OPTIONS } from '../settings/menuSettings'
import { readStylesAction } from '../modules/hotspotTypesStore'

// import useLoadStyle from '../utils/useLoadStyle'

const Center = styled('div')(({ theme }) => ({
  minWidth: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.light,
  zIndex: 10,
  position: 'relative'
}))

Globals.assign({
  frameLoop: 'always'
})

const MainEditor = () => {
  const open = useStore(openAction)
  const currentOption = useStore(currentOptionSelector)
  const readHotspotsStyles = useStore(readStylesAction)
  const setSphereMode = useStore(setSphereModeAction)

  // const stylesLoaded = useLoadStyle('styles/prueba.css')
  // useEffect(() => {
  //   if (stylesLoaded) {
  //     console.log('stylesLoaded')
  //   }
  // }, [stylesLoaded])

  useEffect(() => {
    readHotspotsStyles()
    open([])
    setSphereMode(true)
  }, [open, readHotspotsStyles, setSphereMode])

  const getCenter = () => {
    switch (currentOption) {
      case MENU_OPTIONS.TEXT_EDITOR:
        return <TextEditor />
      case MENU_OPTIONS.IMAGE_MAPPER:
        return <ImageMapper />
      default:
        return (
          <>
            <EditorScene />
            <EditorHotspots2D />
          </>
        )
    }
  }
  return (
    <>
      <CustomLateralMenu />
      <Center>{getCenter()}</Center>
      <Selector />
      <OpenErrorsDialog />
    </>
  )
}
export default MainEditor
