import { useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'

import OKCancelButtons from '../../../Common/OKCancelButtons'
import PropertyTree from '../../../Common/PropertyTree/Tree/PropertyTree'
import PropertyTreeHeader from '../../../Common/PropertyTree/Header/PropertyTreeHeader'
import MultilanguageTextNode from '../../../Common/Nodes/MultilanguageTextNode'
import OptionList from './OptionList'
import FECubesSelectNode from './FECubesSelectNode'

import { useStore } from '../../../../modules/store'
import {
  initAction,
  saveAction,
  finishSelector,
  updateFinishAction
} from '../../../../modules/finishEditorStore'

import {
  setCurrentOptionPanelAction,
  setDisabledOptionsAction,
  setEnabledOptionsAction
} from '../../../../modules/lateralMenuStore'
import { LATERAL_PANELS, MENU_OPTIONS } from '../../../../settings/menuSettings'
import { TREE_IDS } from '../../../../modules/propertyTreeStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  minHeight: 0,
  // overflow: 'auto',
  flex: '1 1 auto'
}))

const FinishEditor = () => {
  // const dispatch = useDispatch()
  const finish = useStore(finishSelector)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)
  const init = useStore(initAction)
  const save = useStore(saveAction)
  const updateFinish = useStore(updateFinishAction)
  const setEnabledOptions = useStore(setEnabledOptionsAction)
  const setDisabledOptions = useStore(setDisabledOptionsAction)

  useEffect(() => {
    init()
    setEnabledOptions([MENU_OPTIONS.FINISHS])
    return () => {
      setDisabledOptions([])
    }
  }, [init, setDisabledOptions, setEnabledOptions])

  const handleCancel = useCallback(() => {
    setCurrentOptionPanel(LATERAL_PANELS.FINISHS_LIST)
  }, [setCurrentOptionPanel])

  const handleOK = useCallback(() => {
    save()
    setCurrentOptionPanel(LATERAL_PANELS.FINISHS_LIST)
  }, [setCurrentOptionPanel, save])

  const handleChange = useCallback(
    (nodeId, value) => {
      switch (nodeId) {
        // case 'finishs': {
        //   if (value.length === 0) {
        //     return
        //   }
        //   setLocalCube({ ...localCube, finishs: value })
        //   break
        // }
        default:
          const newFinish = { ...finish }
          newFinish[nodeId] = value
          updateFinish(newFinish)
      }
    },
    [finish, updateFinish]
  )

  return finish ? (
    <Root>
      <ContentWrapper>
        <PropertyTree treeId={TREE_IDS.FINISH}>
          <PropertyTreeHeader />
          <FECubesSelectNode />
          <MultilanguageTextNode
            id={'name'}
            label="Nombre"
            tooltipDisabled
            value={finish.name}
            onChange={handleChange}
          />
        </PropertyTree>
        <OptionList />
      </ContentWrapper>

      <OKCancelButtons onCancel={handleCancel} onOk={handleOK} />
    </Root>
  ) : null
}
export default FinishEditor
