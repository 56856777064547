import { useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import {
  getFinishMemo,
  getOptionMemo
} from '../../../modules/sceneDescriptionStore'
import {
  selectedOptionsSelector,
  selectOptionAction,
  getCurrentCubeData
} from '../../../modules/sceneDrawDataStore'
import useStore from '../../../modules/store'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 12,
  left: 12,
  zIndex: 100,
  width: 100,
  backgroundColor: 'grey'
}))

const FinishItemRoot = styled('div')(({ theme }) => ({
  padding: 4,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))
const OptionItemRoot = styled('button')(({ theme, selected }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: selected
    ? theme.palette.secondary.main
    : theme.palette.primary.main
}))

const OptionItem = ({ id }) => {
  const gOption = useMemo(getOptionMemo, [])
  const option = useStore((state) => gOption(state, id))
  const selectOption = useStore(selectOptionAction)
  const selectedOptions = useStore(selectedOptionsSelector)

  const handleClick = useCallback(() => {
    selectOption(id)
  }, [selectOption, id])

  return option ? (
    <OptionItemRoot
      selected={selectedOptions.indexOf(id) >= 0}
      onClick={handleClick}
    >
      {option.name.es}
    </OptionItemRoot>
  ) : null
}

const FinishItem = ({ id }) => {
  const gFinish = useMemo(getFinishMemo, [])
  const { finish } = useStore((state) => gFinish(state, id))

  return finish ? (
    <FinishItemRoot>
      {finish.name.es}
      {finish.options.map((optionId) => (
        <OptionItem key={optionId} id={optionId} />
      ))}
    </FinishItemRoot>
  ) : null
}

const FinishsMenu = () => {
  const { finishs } = useStore(getCurrentCubeData)

  return finishs ? (
    <Root>
      {finishs.map((finish) => (
        <FinishItem key={finish.id} id={finish.id} />
      ))}
    </Root>
  ) : null
}

export default FinishsMenu
