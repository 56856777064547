import { createSelector } from 'reselect'
import { findById, cloneObject } from '../utils/utils'
import { sceneSchema } from '../schemas/sceneSchema'
import { sceneFile } from '../schemas/files'

const Validator = require('jsonschema').Validator
const validator = new Validator()

export const sceneDescriptionStore = (set, get) => ({
  sceneDescription: {
    // ...cloneObject(sceneSchema.default),
    reset: (scene) => {
      set((st) => ({
        sceneDescription: {
          ...st.sceneDescription,
          ...(scene ? scene : cloneObject(sceneSchema.default))
        }
      }))
    },
    setSceneDescription: (scene) => {
      set((st) => ({
        sceneDescription: {
          ...st.sceneDescription,
          ...scene
        }
      }))
    },
    setSceneDescriptionFromSrc: async (src) => {
      const response = await fetch(src)
      if (response.ok) {
        const scene = await response.json()
        setSceneDescriptionAction(get())(scene)
      }
    }
  }
})
export default sceneDescriptionStore

export const validateScene = (scene) => {
  const relationalErrors = []
  const errors = []
  sceneFile.forEach((schema) =>
    validator.addSchema(schema.schema, schema.schema.id)
  )
  const { errors: schemaErrors } = validator.validate(scene, sceneSchema)
  // if (schemaErrors.length === 0) {
  //   //Scene default
  //   if (!scene.cubes.find((cube) => cube.id === scene.default.cube)) {
  //     //No se encuentra cubo
  //     return
  //   }
  //   for (const optionId of scene.default.options) {
  //     if (!scene.options.find((option) => option.id === optionId)) {
  //       //No se encuentra opcion
  //       return
  //     }
  //   }
  //   //Cubos
  //   if (!uniquesValues(scene.cubes, 'id')) {
  //     //Cube ids repetidos
  //     return
  //   }
  //   for (const cube of scene.cubes) {
  //     for (const hsId of cube.hotspots) {
  //       if (!scene.hotspots.find((hs) => hs.id === hsId)) {
  //         //No se encuentra hotspot
  //         return
  //       }
  //     }
  //     for (const finishId of cube.finishs) {
  //       if (!scene.finishs.find((finish) => finish.id === finishId)) {
  //         //No se encuentra acabado
  //         return
  //       }
  //     }
  //   }
  //   //Acabados
  //   if (!uniquesValues(scene.finishs, 'id')) {
  //     //Acabado ids repetidos
  //     return
  //   }
  //   for (const finish of scene.finishs) {
  //     for (const optionId of finish.options) {
  //       if (!scene.options.find((option) => option.id === optionId)) {
  //         //No se encuentra opcion
  //         return
  //       }
  //     }
  //   }
  //   //Opciones
  //   if (!uniquesValues(scene.options, 'id')) {
  //     //Option ids repetidos
  //     return
  //   }
  // }
  return {
    data:
      schemaErrors.length === 0 &&
      relationalErrors.length === 0 &&
      errors.length === 0
        ? cloneObject(scene)
        : null,
    errors: { errors, schemaErrors, relationalErrors }
  }
}

export const resetAction = (state) => state.sceneDescription.reset
export const saveAction = (state) => state.sceneDescription.save
export const setSceneDescriptionAction = (state) =>
  state.sceneDescription.setSceneDescription
export const setSceneDescriptionFromSrcAction = (state) =>
  state.sceneDescription.setSceneDescriptionFromSrc

export const idSelector = (state) => state.sceneDescription.id
export const languagesSelector = (state) => state.sceneDescription.languages
export const nameSelector = (state) => state.sceneDescription.name
export const cubeImagesSelector = (state) => state.sceneDescription.cubeImages
export const cubesSelector = (state) => state.sceneDescription.cubes
export const hotspotsSelector = (state) => state.sceneDescription.hotspots
export const polygonsSelector = (state) => state.sceneDescription.polygons
export const finishsSelector = (state) => state.sceneDescription.finishs
export const optionsSelector = (state) => state.sceneDescription.options
export const defaultSelector = (state) => state.sceneDescription.default
export const defaultOptionsSelector = (state) =>
  state.sceneDescription.default.options
export const defaultCubeSelector = (state) =>
  state.sceneDescription.default.cube
export const getSceneObject = (state) => {
  return state.sceneDescription
}

export const getCubeMemo = () =>
  createSelector([cubesSelector, (_, id) => id], (cubes, id) => {
    return findById(cubes, id)
  })

export const getCube = createSelector([cubesSelector], (cubes) => (cubeId) =>
  findById(cubes, cubeId)
)
// export const getCube = (state) => (cubeId) => {
//   return findById(cubesSelector(state), cubeId)
// }

export const getCubeFinishs = createSelector(
  [cubesSelector, finishsSelector],
  (cubes, finishs) => (cubeId) => {
    const cube = findById(cubes, cubeId)
    const cubeFinishs = cube
      ? finishs.filter((finish) => cube.finishs.indexOf(finish.id) >= 0)
      : []
    return {
      cube,
      finishs: cubeFinishs,
      finishsIds: cubeFinishs.map((f) => f.id)
    }
  }
)

export const getCubeTextureId = createSelector(
  [getCubeFinishs],
  (gCubeFinishs) => (cubeId, optionIds) => {
    const { finishs } = gCubeFinishs(cubeId)
    return finishs.reduce((acc, finish) => {
      for (const optionId of optionIds) {
        if (finish.options.indexOf(optionId) >= 0) {
          acc = `${acc}_${finish.id}_${optionId}`
          break
        }
      }
      return acc
    }, `${cubeId}`)
  }
)

//Hotspots de un cubo
export const getCubeHotspots = createSelector(
  [cubesSelector, hotspotsSelector],
  (cubes, hotspots) => (cubeId) => {
    let cube = null
    let cubeHotspots = []
    if (cubeId) {
      cube = findById(cubes, cubeId)
      if (cube) {
        cubeHotspots = cube.hotspots.reduce((acc, hsId) => {
          const hs = findById(hotspots, hsId)
          if (hs) {
            acc.push(hs)
          }
          return acc
        }, [])
        // cubeHotspots = hotspots.filter((hs) => cube.hotspots.indexOf(hs.id) >= 0)
      }
    }
    return {
      cube,
      hotspots: cubeHotspots,
      hotspotsIds: cubeHotspots.map((hs) => hs.id)
    }
  }
)
//***********************************************************************

export const getFinishs = createSelector(
  [finishsSelector, optionsSelector],
  (finishs, options) => {
    return finishs.map((finish) => {
      return {
        ...finish,
        optionsObjects: finish.options.map((optionId) => {
          return findById(options, optionId)
        })
      }
    })
  }
)

export const getFinishMemo = () =>
  createSelector(
    [finishsSelector, optionsSelector, (_, id) => id],
    (finishs, options, id) => {
      const finish = findById(finishs, id)
      if (finish) {
        const finishOptions = finish.options.reduce((acc, optionId) => {
          const option = findById(options, optionId)
          if (option) {
            acc.push(option)
          }
          return acc
        }, [])
        return { finish, options: finishOptions }
      }
      return { finish: null, options: null }
    }
  )

export const getFinish = (state) => (id) => findById(finishsSelector(state), id)

export const getOptionMemo = () =>
  createSelector([optionsSelector, (_, id) => id], (options, id) => {
    return findById(options, id)
  })

export const getOption = (state) => (optionId) =>
  findById(optionsSelector(state), optionId)

export const getFinishOptionPairMemo = () =>
  createSelector(
    [finishsSelector, optionsSelector, getOption, (_, optionId) => optionId],
    (finishs, options, gOption, optionId) => {
      const finish = finishs.find((f) => f.options.indexOf(optionId) >= 0)
      const option = gOption(optionId)
      return {
        finish: finish && option ? finish : null,
        option: finish && option ? option : null
      }
    }
  )

export const getFinishsOptionsSummary = createSelector(
  [finishsSelector, optionsSelector],
  (finishs, options) => (optionsIds) => {
    if (!optionsIds) {
      return ''
    }
    const pairs = optionsIds.reduce((acc, optionId) => {
      const finish = finishs.find(
        (finish) => finish.options.indexOf(optionId) >= 0
      )
      const option = findById(options, optionId)
      if (option && finish) {
        acc.push(`${finish.name.es}-${option.name.es}`)
      }
      return acc
    }, [])
    return pairs.join(', ')
  }
)

//Cubos de un acabado
export const getFinishCubesMemo = () =>
  createSelector(
    [cubesSelector, finishsSelector, (_, id) => id],
    (cubes, finishs, id) => {
      const finishCubes = cubes.filter((cube) => cube.finishs.indexOf(id) >= 0)
      return {
        finishCubes,
        finishCubesIds: finishCubes.map((cube) => cube.id)
      }
    }
  )
export const getFinishCubes = createSelector(
  [cubesSelector, finishsSelector],
  (cubes, finishs) => (id) => {
    const finishCubes = cubes.filter((cube) => cube.finishs.indexOf(id) >= 0)
    return {
      finishCubes,
      finishCubesIds: finishCubes.map((cube) => cube.id)
    }
  }
)

export const getHotspots = createSelector(
  [hotspotsSelector],
  (hotspots) => (ids) => {
    return hotspots.filter((hs) => ids.indexOf(hs.id) >= 0)
  }
)

export const getHotspot = (state) => (id) => {
  const hotspots = hotspotsSelector(state)
  return findById(hotspots, id)
}

export const getHotspotMemo = () =>
  createSelector([hotspotsSelector, (_, id) => id], (hotspots, id) => {
    return findById(hotspots, id)
  })

//Cubos de un hotspot
export const getHotspotCubesMemo = () =>
  createSelector([cubesSelector, (_, id) => id], (cubes, id) => {
    const hotspotCubes = cubes.filter((cube) => cube.hotspots.indexOf(id) >= 0)
    return {
      hotspotCubes,
      cubesIds: hotspotCubes.map((cube) => cube.id)
    }
  })

export const getHotspotCubes = createSelector(
  [cubesSelector, hotspotsSelector],
  (cubes, hotspots) => (hotspotId) => {
    let hotspot = null
    let hotspotCubes = []
    if (hotspotId) {
      hotspot = findById(hotspots, hotspotId)
      if (hotspot) {
        hotspotCubes = cubes.filter(
          (cube) => cube.hotspots.indexOf(hotspotId) >= 0
        )
      }
    }

    return {
      hotspot,
      hotspotCubes,
      cubesIds: hotspotCubes.map((cube) => cube.id)
    }
  }
)

export const getPolygonMemo = () =>
  createSelector([polygonsSelector, (_, id) => id], (polygons, id) => {
    return findById(polygons, id)
  })
