import { styled } from '@mui/material/styles'

import PropertyTree from '../../Common/PropertyTree/Tree/PropertyTree'
import PropertyTreeHeader from '../../Common/PropertyTree/Header/PropertyTreeHeader'

import SECubeSelectNode from './SECubeSelectNode'
import SENameNode from './SENameNode'
import SEOptionsNode from './SEOptionsNode'

import { TREE_IDS } from '../../../modules/propertyTreeStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  minHeight: 0,
  overflow: 'auto',
  flex: '0 1 auto'
}))

const SceneEditor = () => {
  console.log('Scene editor')

  return (
    <Root>
      <ContentWrapper>
        <PropertyTree treeId={TREE_IDS.CUBE}>
          <PropertyTreeHeader />
          <SENameNode />
          <SECubeSelectNode />
          <SEOptionsNode />
        </PropertyTree>
      </ContentWrapper>
    </Root>
  )
}
export default SceneEditor
