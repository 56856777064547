import { useMemo } from 'react'

import Contour from '../../Three/FlatPolygon/Contour'
import PolygonGeometry from './PolygonGeometry'
import { useStore } from '../../../modules/store'
import { getCurrentCubeHotspotMemo } from '../../../modules/sceneDrawDataStore'

const Polygon = ({ id }) => {
  const gHotspot = useMemo(getCurrentCubeHotspotMemo, [])
  const hotspot = useStore((state) => gHotspot(state, id))

  const contour = useMemo(() => {
    const points = []
    if (hotspot?.polygonData?.contour) {
      for (let i = 0; i < hotspot.polygonData?.contour.length; i += 2) {
        points.push([
          hotspot.polygonData.contour[i],
          hotspot.polygonData.contour[i + 1]
        ])
      }
    }
    return points
  }, [hotspot])

  const holes = useMemo(() => {
    if (hotspot?.polygonData?.holes) {
      return hotspot.polygonData.holes.map((hole) => {
        const holePoints = []
        for (let i = 0; i < hole.length; i += 2) {
          holePoints.push([hole[i], hole[i + 1]])
        }

        return holePoints
      })
    }
    return []
  }, [hotspot])

  console.log(hotspot)
  return hotspot && !hotspot.hidden && hotspot.polygonData ? (
    <group
      rotation={hotspot.polygonData.rotation}
      position={hotspot.polygonData.position}
    >
      {hotspot.data.border.color && (
        <Contour
          contour={contour}
          holes={holes}
          width={hotspot.data.border.width}
          color={hotspot.data.border.color}
        />
      )}
      <PolygonGeometry
        id={id}
        contour={contour}
        holes={holes}
        color={hotspot.data.fill.data.value}
      />
    </group>
  ) : null
}
export default Polygon
