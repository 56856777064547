import { useState, useContext, cloneElement } from 'react'
import { styled } from '@mui/material/styles'

import { ScrollToolbarContext } from './ScrollToolbarContext'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'

const Root = styled(Button, { name: 'ScrollToolbar', slot: 'Button' })(
  ({ theme, selected }) => ({
    padding: 0,
    minWidth: 0,
    width: '100%',
    minHeight: 0,
    height: '100%',
    color: selected
      ? theme.palette.secondary.main
      : theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.secondary.main
    },
    '&.Mui-disabled': { color: theme.palette.action.disabled }
  })
)

const ButtonBadge = styled(Badge)(({ theme, type }) => ({
  width: '100%',
  height: '100%',
  '& .MuiBadge-anchorOriginTopLeftRectangular': {
    top: 12,
    left: 8
  },
  '& .MuiBadge-anchorOriginTopRightRectangular': {
    top: 12,
    right: 8
  },
  '& .MuiBadge-anchorOriginBottomRightRectangular': {
    bottom: 12,
    right: 8
  },
  '& .MuiBadge-anchorOriginBottomLeftRectangular': {
    bottom: 12,
    left: 8
  }
}))

const STButton = ({
  id,
  icon,
  tooltip,
  tooltipEnabled,
  hidden,
  selected,
  disabled,
  topLeftBadge, //{content,color}
  topRightBadge,
  bottomRightBadge,
  bottomLeftBadge,
  onClick
}) => {
  const [open, setOpen] = useState(false)
  const context = useContext(ScrollToolbarContext)

  const handleClick = () => {
    onClick && onClick(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const getRoot = () => {
    return (
      <Root
        variant="text"
        component="span"
        disableRipple
        disableFocusRipple
        selected={selected}
        disabled={disabled}
        onClick={handleClick}
      >
        {icon &&
          cloneElement(icon, {
            style: {
              width: context.vertical ? '100%' : 'auto',
              height: context.vertical ? 'auto' : '100%',
              ...icon.props.style
            }
          })}
      </Root>
    )
  }

  const getBadge = (badge, top, left, children) => {
    return badge ? (
      <ButtonBadge
        badgeContent={badge.content}
        anchorOrigin={{
          vertical: top ? 'top' : 'bottom',
          horizontal: left ? 'left' : 'right'
        }}
        color={badge.color ? badge.color : 'primary'}
      >
        {children}
      </ButtonBadge>
    ) : (
      children
    )
  }

  return !hidden ? (
    <Tooltip
      title={tooltip ? tooltip : ''}
      open={tooltipEnabled && open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableInteractive
    >
      {getBadge(
        bottomLeftBadge,
        false,
        true,
        getBadge(
          bottomRightBadge,
          false,
          false,
          getBadge(
            topRightBadge,
            true,
            false,
            getBadge(topLeftBadge, true, true, getRoot())
          )
        )
      )}
    </Tooltip>
  ) : null
}
export default STButton
