import { useCallback, memo } from 'react'
import FinishSelectNode from '../../../Common/Nodes/FinishSelectNode'

import useStore from '../../../../modules/store'
import { selectedSelector } from '../../../../modules/sceneCubeListStore'
import { getFirstSelected } from '../../../../modules/cubeEditorStore'
import { setFinishsAction } from '../../../../modules/sceneCubeStore'

const CEFinishSelectNode = () => {
  const selected = useStore(selectedSelector)
  const finishs = useStore(
    useCallback((state) => {
      const { cube } = getFirstSelected(state)
      return cube ? cube.finishs : null
    }, [])
  )

  const setFinishs = useStore(setFinishsAction)

  const handleChange = useCallback(
    (id, newValue) => {
      console.log(selected[0], newValue)
      setFinishs(selected[0], newValue)
    },
    [selected, setFinishs]
  )

  return selected.length > 0 ? (
    <FinishSelectNode
      id={'finishs'}
      label="Acabados"
      value={finishs}
      onChange={handleChange}
    />
  ) : null
}
export default memo(CEFinishSelectNode)
