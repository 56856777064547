import { memo } from 'react'

import HotspotsSelectNode from '../HotspotsSelectNode'

const ShowHotspotsActionNode = ({ cubeId, ...props }) => {
  return (
    <HotspotsSelectNode
      label="Mostrar hotspots"
      cubeId={cubeId}
      allowEmpty
      multiple
      {...props}
    />
  )
}
export default memo(ShowHotspotsActionNode)
