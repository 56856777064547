import { useCallback, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import LateralMenu from '../Common/LateralMenu/LateralMenu'
import CubesList from '../Editor/Cubes/CubesList'
import CubeEditor from '../Editor/Cubes/Editor/CubeEditor'
import FinishsMenu from '../Editor/Finishs/List/FinishsMenu'
import FinishEditor from '../Editor/Finishs/Editor/FinishEditor'
import OptionEditor from '../Editor/Finishs/Editor/OptionEditor'
import SceneEditor from '../Editor/Scene/SceneEditor'
import HotspotsList from '../Editor/Hotspots/HotspotsList'
import HotspotEditor from '../Editor/Hotspots/Editor/HotspotEditor'

import Selector from '../Editor/Selector/Selector'
import {
  OpenIcon,
  SaveIcon,
  FinishIcon,
  TextIcon,
  ImageMapIcon,
  HotspotIcon,
  SceneIcon,
  QuickSelectIcon
} from '../Icons/Icons'
import CubeIcon from '../Icons/CubeIcon'
import PolygonIcon from '../Icons/PolygonIcon'

import useStore from '../../modules/store'
import {
  getCurrentOption,
  setMenuOptionAction
} from '../../modules/lateralMenuStore'
import { openAction, saveAction } from '../../modules/openSaveStore'

import {
  selectorVisibleSelector,
  setSelectorVisibleAction
} from '../../modules/mainStore'

import { LATERAL_PANELS, MENU_OPTIONS } from '../../settings/menuSettings'
import useSelectFolder from '../../utils/useSelectFolder'

const CustomLateralMenu = () => {
  const theme = useTheme()
  const currentMenuOption = useStore(getCurrentOption)
  const setMenuOption = useStore(setMenuOptionAction)
  const open = useStore(openAction)
  const save = useStore(saveAction)
  const setSelectorVisible = useStore(setSelectorVisibleAction)
  const selectorVisible = useStore(selectorVisibleSelector)
  const { fileList, openFolderDialog } = useSelectFolder()

  console.log('Lateral')

  useEffect(() => {
    if (fileList) {
      open(fileList)
    }
  }, [fileList, open])

  // useEffect(() => {
  //   if (folderLoaded) {
  //     setLoaded(false)
  //     reset()
  //   }
  // }, [folderLoaded, setLoaded, reset])

  const getIcon = (optionId) => {
    switch (optionId) {
      case MENU_OPTIONS.OPEN:
        return <OpenIcon />
      case MENU_OPTIONS.SAVE:
        return <SaveIcon />
      case MENU_OPTIONS.CUBES:
        return <CubeIcon />
      case MENU_OPTIONS.TEXT_EDITOR:
        return <TextIcon />
      case MENU_OPTIONS.FINISHS:
        return <FinishIcon />
      case MENU_OPTIONS.IMAGE_MAPPER:
        return <ImageMapIcon />
      case MENU_OPTIONS.HOTSPOTS:
        return <HotspotIcon />
      case MENU_OPTIONS.SCENE:
        return <SceneIcon />
      case MENU_OPTIONS.SELECT:
        return (
          <QuickSelectIcon
            style={{
              color: selectorVisible ? theme.palette.secondary.main : null
            }}
          />
        )
      case MENU_OPTIONS.POLYGONS:
        return <PolygonIcon />
      default:
        return null
    }
  }

  const getLateral = () => {
    // console.log('currentMenuOption', currentMenuOption.lateral)
    switch (currentMenuOption ? currentMenuOption.lateral : '') {
      case LATERAL_PANELS.CUBES_LIST:
        return <CubesList />
      case LATERAL_PANELS.CUBE_EDITOR:
        return <CubeEditor />
      case LATERAL_PANELS.FINISHS_LIST:
        return <FinishsMenu />
      case LATERAL_PANELS.FINISH_EDITOR:
        return <FinishEditor />
      case LATERAL_PANELS.OPTION_EDITOR:
        return <OptionEditor />
      case LATERAL_PANELS.SCENE:
        return <SceneEditor />
      case LATERAL_PANELS.HOTSPOTS_LIST:
        return <HotspotsList />
      case LATERAL_PANELS.HOTSPOT_EDITOR:
        return <HotspotEditor />
      case LATERAL_PANELS.SELECT:
        return <Selector />
      default:
        return null
    }
  }

  const handleClose = useCallback(() => {
    setMenuOption('')
  }, [setMenuOption])

  const handleOptionClick = useCallback(
    (optionId) => {
      switch (optionId) {
        case MENU_OPTIONS.OPEN:
          openFolderDialog()
          break
        case MENU_OPTIONS.SAVE:
          save()
          break
        case MENU_OPTIONS.TEXT_EDITOR:
          setMenuOption(
            currentMenuOption &&
              currentMenuOption.id === MENU_OPTIONS.TEXT_EDITOR
              ? ''
              : MENU_OPTIONS.TEXT_EDITOR
          )

          break
        case MENU_OPTIONS.SELECT:
          setSelectorVisible(!selectorVisible)
          break
        default:
          setMenuOption(optionId)
      }
    },
    [
      setMenuOption,
      openFolderDialog,
      save,
      selectorVisible,
      setSelectorVisible,
      currentMenuOption
    ]
  )

  return (
    <LateralMenu
      onOptionClick={handleOptionClick}
      onClose={handleClose}
      panelContent={getLateral()}
      getIcon={getIcon}
    />
  )
}
export default CustomLateralMenu
