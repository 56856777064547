import { useState, useEffect } from 'react'
import { DoubleSide } from 'three'

import Geometry from './Geometry'

const VideoGeometry = ({ contour, holes, src }) => {
  const [video, setVideo] = useState(null)

  useEffect(() => {
    const v = document.createElement('video')
    setVideo(v)
    v.src = src
    v.crossOrigin = 'Anonymous'
    v.loop = true
    v.muted = true
    v.play()
  }, [src])

  return (
    <Geometry
      contour={contour}
      holes={holes}
      material={
        video ? (
          <meshStandardMaterial emissive={'white'} side={DoubleSide}>
            <videoTexture attach="emissiveMap" args={[video]} />
          </meshStandardMaterial>
        ) : (
          <meshBasicMaterial color={'white'} side={DoubleSide} />
        )
      }
    />
  )
}

export default VideoGeometry
