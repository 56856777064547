import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import Button from '@mui/material/Button'

import BaseTextField from '../../../Inputs/BaseTextField'
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center'
}))
const UrlWrapper = styled('div')(({ theme }) => ({
  padding: '8px 0',
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center'
}))
const ButtonsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  justifyContent: 'end'
}))

const SButton = styled(Button)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  textTransform: 'none',
  boxSizing: 'border-box',
  width: 100,
  padding: 0,
  marginLeft: 8,
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.1)
  }
}))

const ImageSelectDialogActions = ({
  mediaSrc,
  onChange,
  onClose,
  ...props
}) => {
  const handleCancel = useCallback(() => {
    onClose(false)
  }, [onClose])

  const handleOk = useCallback(() => {
    onClose(true)
  }, [onClose])

  return (
    <Root>
      <UrlWrapper>
        <div style={{ paddingRight: 12 }}>Url:</div>
        <BaseTextField
          InputProps={{ style: { height: 32, padding: 8, marginTop: 0 } }}
          sx={{
            border: '1px solid white'
          }}
          value={mediaSrc}
          onChange={onChange}
        />
      </UrlWrapper>
      <ButtonsWrapper>
        <SButton variant="outlined" onClick={handleCancel}>
          Cancelar
        </SButton>
        <SButton variant="outlined" onClick={handleOk}>
          OK
        </SButton>
      </ButtonsWrapper>
    </Root>
  )
}
export default ImageSelectDialogActions
