import { cloneObject, clamp } from '../utils/utils'
export const HALF_BORDER_WIDTH = 2

export const PROPERTIES = [
  { id: 'top', name: 'Top', length: 'height' },
  { id: 'bottom', name: 'Bottom', length: 'height' },
  { id: 'left', name: 'Left', length: 'width' },
  { id: 'right', name: 'Right', length: 'width' }
]

export const REFERENCES = [
  {
    id: 'tl',
    name: 'Top-Left',
    properties: [PROPERTIES[2], PROPERTIES[0]], //x,y
    incX: 1,
    incY: 1,
    anchorMark: { top: true, left: true, right: false, bottom: false }
  },
  {
    id: 'tr',
    name: 'Top-Right',
    properties: [PROPERTIES[3], PROPERTIES[0]],
    incX: -1,
    incY: 1,
    anchorMark: { top: true, left: false, right: true, bottom: false }
  },
  {
    id: 'bl',
    name: 'Bottom-Left',
    properties: [PROPERTIES[2], PROPERTIES[1]],
    incX: 1,
    incY: -1,
    anchorMark: { top: false, left: true, right: false, bottom: true }
  },
  {
    id: 'br',
    name: 'Bottom-Right',
    properties: [PROPERTIES[3], PROPERTIES[1]],
    incX: -1,
    incY: -1,
    anchorMark: { top: false, left: false, right: true, bottom: true }
  }
]

export const ANCHORS_IDS = {
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  TOP_RIGHT: 'tr',
  CENTER_LEFT: 'cl',
  CENTER: 'cc',
  CENTER_RIGHT: 'cr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_CENTER: 'bc',
  BOTTOM_RIGHT: 'br'
}
export const ANCHORS = [
  {
    id: ANCHORS_IDS.TOP_LEFT,
    name: 'Top-Left',
    transforms: { tl: [0, 0], tr: [100, 0], bl: [0, 100], br: [100, 100] },
    transforms3D: [50, 50],
    anchorMark: {
      top: -HALF_BORDER_WIDTH,
      left: -HALF_BORDER_WIDTH,
      right: null,
      bottom: null,
      transform: 'translate(-50%, -50%)'
    },
    scaleOrigin: '0% 0%'
  },
  {
    id: ANCHORS_IDS.TOP_CENTER,
    name: 'Top-Center',
    transforms: { tl: [-50, 0], tr: [50, 0], bl: [-50, 100], br: [50, 100] },
    transforms3D: [0, 50],
    anchorMark: {
      top: -HALF_BORDER_WIDTH,
      left: '50%',
      right: null,
      bottom: null,
      transform: 'translate(-50%, -50%)'
    },
    scaleOrigin: '50% 0%'
  },
  {
    id: ANCHORS_IDS.TOP_RIGHT,
    name: 'Top-Right',
    transforms: { tl: [-100, 0], tr: [0, 0], bl: [-100, 100], br: [0, 100] },
    transforms3D: [-50, 50],
    anchorMark: {
      top: -HALF_BORDER_WIDTH,
      left: null,
      right: -HALF_BORDER_WIDTH,
      bottom: null,
      transform: 'translate(50%, -50%)'
    },
    scaleOrigin: '100% 0%'
  },
  {
    id: ANCHORS_IDS.CENTER_LEFT,
    name: 'Center-Left',
    transforms: { tl: [0, -50], tr: [100, -50], bl: [0, 50], br: [100, 50] },
    transforms3D: [50, 0],
    anchorMark: {
      top: '50%',
      left: -HALF_BORDER_WIDTH,
      right: null,
      bottom: null,
      transform: 'translate(-50%, -50%)'
    },
    scaleOrigin: '0% 50%'
  },
  {
    id: ANCHORS_IDS.CENTER,
    name: 'Center',
    transforms: {
      tl: [-50, -50],
      tr: [50, -50],
      bl: [-50, 50],
      br: [50, 50]
    },
    transforms3D: [0, 0],
    anchorMark: {
      top: '50%',
      left: '50%',
      right: null,
      bottom: null,
      transform: 'translate(-50%, -50%)'
    },
    scaleOrigin: '50% 50%'
  },
  {
    id: ANCHORS_IDS.CENTER_RIGHT,
    name: 'Center-Right',
    transforms: {
      tl: [-100, -50],
      tr: [0, -50],
      bl: [-100, 50],
      br: [0, 50]
    },
    transforms3D: [-50, 0],
    anchorMark: {
      top: '50%',
      left: null,
      right: -HALF_BORDER_WIDTH,
      bottom: null,
      transform: 'translate(50%, -50%)'
    },
    scaleOrigin: '100% 50%'
  },
  {
    id: ANCHORS_IDS.BOTTOM_LEFT,
    name: 'Bottom-Left',
    transforms: { tl: [0, -100], tr: [100, -100], bl: [0, 0], br: [100, 0] },
    transforms3D: [50, -50],
    anchorMark: {
      top: null,
      left: -HALF_BORDER_WIDTH,
      right: null,
      bottom: -HALF_BORDER_WIDTH,
      transform: 'translate(-50%, 50%)'
    },
    scaleOrigin: '0% 100%'
  },
  {
    id: ANCHORS_IDS.BOTTOM_CENTER,
    name: 'Bottom-Center',
    transforms: {
      tl: [-50, -100],
      tr: [50, -100],
      bl: [-50, 0],
      br: [50, 0]
    },
    transforms3D: [0, -50],
    anchorMark: {
      top: null,
      left: '50%',
      right: null,
      bottom: -HALF_BORDER_WIDTH,
      transform: 'translate(-50%, 50%)'
    },
    scaleOrigin: '50% 100%'
  },
  {
    id: ANCHORS_IDS.BOTTOM_RIGHT,
    name: 'Bottom-Right',
    transforms: {
      tl: [-100, -100],
      tr: [0, -100],
      bl: [-100, 0],
      br: [0, 0]
    },
    transforms3D: [-50, -50],
    anchorMark: {
      top: null,
      left: null,
      right: -HALF_BORDER_WIDTH,
      bottom: -HALF_BORDER_WIDTH,
      transform: 'translate(50%, 50%)'
    },
    scaleOrigin: '100% 100%'
  }
]

const position = {
  reference: 'tl',
  anchor: 'cc',
  top: { value: 0, pixels: false },
  left: { value: 0, pixels: false },
  bottom: { value: 0, pixels: false },
  right: { value: 0, pixels: false }
}

export const getReference = (id) => {
  switch (id) {
    case 'tr':
      return REFERENCES[1]
    case 'bl':
      return REFERENCES[2]
    case 'br':
      return REFERENCES[3]
    default:
      return REFERENCES[0]
  }
}

export const getAnchor = (id) => {
  switch (id) {
    case 'tc':
      return ANCHORS[1]
    case 'tr':
      return ANCHORS[2]
    case 'cl':
      return ANCHORS[3]
    case 'cc':
      return ANCHORS[4]
    case 'cr':
      return ANCHORS[5]
    case 'bl':
      return ANCHORS[6]
    case 'bc':
      return ANCHORS[7]
    case 'br':
      return ANCHORS[8]
    default:
      return ANCHORS[0]
  }
}

const propertyValue = (prop) => {
  return prop.pixels ? prop.value : `${prop.value}%`
}
const getTransform = (tX, tY) => {
  return !tX && !tY ? null : `translate( ${tX}% , ${tY}%)`
}

export const setPropertyValue = (property, pixels, length) => {
  return {
    ...property,
    value: property.pixels ? pixels : (pixels * 100) / length
  }
}

export const incrementPropertyValue = (property, inc, length) => {
  return {
    ...property,
    value: property.pixels
      ? property.value + inc
      : property.value + (inc * 100) / length
  }
}

export const setPosition2DFromScreenCoords = (position2D, bounds, x, y) => {
  if (position2D && bounds) {
    const reference = getReference(position.reference)
    const newPosition = { ...position }
    newPosition[reference.properties[0].id] = setPropertyValue(
      newPosition[reference.properties[0].id],
      reference.incX > 0 ? x : bounds.width - x,
      bounds.width
    )
    newPosition[reference.properties[1].id] = setPropertyValue(
      newPosition[reference.properties[1].id],
      reference.incY > 0 ? y : bounds.height - y,
      bounds.height
    )
    return newPosition
  }
}

const validatePosition2DProperty = (property, length) => {
  const newProperty = cloneObject(property)
  if (newProperty.pixels) {
    newProperty.value = clamp(Math.round(newProperty.value), 0, length)
  } else {
    newProperty.value = clamp(newProperty.value, 0, 100)
  }
  return newProperty
}
export const validatePosition2D = (position, bounds) => {
  if (position) {
    const reference = getReference(position.reference)
    const newPosition = { ...position }
    newPosition[reference.properties[0].id] = validatePosition2DProperty(
      position[reference.properties[0].id],
      bounds.width
    )
    newPosition[reference.properties[1].id] = validatePosition2DProperty(
      position[reference.properties[1].id],
      bounds.height
    )
    return newPosition
  }
  return null
}

export const getAnchorPosition = (position, parentSize) => {
  const reference = getReference(position.reference)
  const xProp = position[reference.properties[0].id]
  const yProp = position[reference.properties[1].id]

  const left = xProp.pixels
    ? reference.incX > 0
      ? xProp.value
      : parentSize.width - xProp.value
    : Math.round(
        reference.incX > 0
          ? (parentSize.width * xProp.value) / 100
          : parentSize.width * (1 - xProp.value / 100)
      )
  const top = yProp.pixels
    ? reference.incY > 0
      ? yProp.value
      : parentSize.height - yProp.value
    : Math.round(
        reference.incY > 0
          ? (parentSize.height * yProp.value) / 100
          : parentSize.height * (1 - yProp.value / 100)
      )
  return [left, top]
}

export const getPositioning2D = (anchorId, position2D) => {
  const values = {
    top: null,
    left: null,
    bottom: null,
    right: null,
    transform: null
  }
  if (position2D) {
    const reference = getReference(position2D.reference)
    const anchor = getAnchor(anchorId)

    reference.properties.forEach((prop) => {
      values[prop.id] = propertyValue(position2D[prop.id])
    })
    const transforms = anchor.transforms[reference.id]
    values.transform = getTransform(transforms[0], transforms[1])
  }
  return values
}

export const getPositioning3D = (anchorId) => {
  const values = { transform: null }
  const anchor = getAnchor(anchorId)
  const transforms = anchor.transforms3D
  values.transform = getTransform(transforms[0], transforms[1])
  return values
}
