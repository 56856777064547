import { styled, lighten } from '@mui/material/styles'
import MyButton from './MyButton'

const ButtonsWrapper = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '8px 0 8px 0',
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'flex-end'
}))

const SButton = styled(MyButton)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  margin: '0 0 0 8px',
  width: 100
}))

const OKCancelButtons = ({ onOk, onCancel }) => {
  return (
    <ButtonsWrapper>
      <SButton onClick={onCancel}>Cancelar</SButton>
      <SButton onClick={onOk}>OK</SButton>
    </ButtonsWrapper>
  )
}
export default OKCancelButtons
