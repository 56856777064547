import { useCallback, memo } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../../PropertyTree/Nodes/PropertyTreeSelectNode'
import PropertyTreeColorNode from '../../PropertyTree/Nodes/PropertyTreeColorNode'
import ImageSelectNode from '../ImageSelectNode'
import VideoSelectNode from '../VideoSelectNode'
import {
  POLYGON_FILL_TYPE_IDS,
  POLYGON_FILL_TYPES,
  getFillType
} from '../../../../schemas/hotspotPolygonSchema'
import { getDefaultObjectFromSchema } from '../../../../utils/utils'

const FillNode = ({ id, readOnly, value, onChange, ...props }) => {
  const { COLOR, IMAGE, VIDEO } = POLYGON_FILL_TYPE_IDS

  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === id) {
          if (newValue !== value.type) {
            //Cambio de tipo de relleno
            const type = getFillType(newValue)
            if (type) {
              onChange(id, {
                ...value,
                type: newValue,
                data: getDefaultObjectFromSchema(type.schema)
              })
            }
          }
        } else if (nodeId === `${id}:color`) {
          onChange(id, { ...value, data: { ...value.data, color: newValue } })
        } else if (nodeId === `${id}:hoverColor`) {
          onChange(id, { ...value, data: { ...value.data, hover: newValue } })
        } else if (nodeId === `${id}:image`) {
          onChange(id, { ...value, data: { ...value.data, src: newValue } })
        } else if (nodeId === `${id}:video`) {
          onChange(id, { ...value, data: { ...value.data, src: newValue } })
        }
      }
    },
    [id, onChange, value]
  )

  const getNodes = () => {
    switch (value.type) {
      case COLOR:
        return [
          <PropertyTreeColorNode
            key={0}
            id={'color'}
            label="Color"
            value={value.data.color}
            onChange={handleChange}
          />,
          <PropertyTreeColorNode
            key={1}
            id={'hoverColor'}
            label="Puntero sobre polígono"
            value={value.data.hover}
            onChange={handleChange}
          />
        ]
      case IMAGE:
        return (
          <ImageSelectNode
            key={0}
            id={'image'}
            label="Imagen"
            value={value.data.src}
            onChange={handleChange}
          />
        )
      case VIDEO:
        return (
          <VideoSelectNode
            key={0}
            id={'video'}
            label="Video"
            value={value.data.src}
            onChange={handleChange}
          />
        )

      default:
        return null
    }
  }
  return (
    <PropertyTreeSelectNode
      id={id}
      value={value.type}
      options={POLYGON_FILL_TYPES.map((type) => (
        <MenuItem key={type.id} value={type.id}>
          {type.name}
        </MenuItem>
      ))}
      onChange={handleChange}
      {...props}
    >
      {getNodes()}
    </PropertyTreeSelectNode>
  )
}
export default memo(FillNode)
