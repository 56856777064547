// import { useCallback } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

import './circleSequence.css'
const Root = styled('div')(({ theme, x, y }) => ({
  zIndex: 102,
  left: x,
  top: y,
  width: 64,
  height: 64,
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}))

const CircleSequenceHotspot = ({ x, y, onClick }) => {
  return (
    <Tooltip title="Cadena de texto">
      <Root x={x} y={y} onClick={onClick}>
        <div
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/hotspot_sequence_circle.png')`
          }}
          className="circle_sequence"
        ></div>
      </Root>
    </Tooltip>
  )
}
export default CircleSequenceHotspot
