import { useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'

import { AddDuplicateIcon, RemoveSelectedIcon } from '../../../Icons/Icons'
import DraggableListItem from '../../../Common/DraggableList/DraggableListItem'
import DraggableListItemButton from '../../../Common/DraggableList/DraggableListItemButton'
import OptionEditor from './OptionEditor'

import { useStore } from '../../../../modules/store'
import {
  setCurrentOptionAction,
  getOptionMemo,
  currentOptionSelector,
  selectedOptionsSelector,
  selectOptionAction,
  insertOptionAction,
  removeOptionsAction
} from '../../../../modules/finishEditorStore'

const DraggableOptionItem = styled(DraggableListItem)(({ theme }) => ({
  // border: '1px solid red',
  // borderTop: 'none',
  // '& .aza-draggable-item-title': {
  //   border: `1px solid ${theme.palette.primary.contrastText}`,
  //   borderTop: 'none'
  // }
}))

const OptionItem = ({ id, index }) => {
  // const dispatch = useDispatch()
  const gItemMemo = useMemo(getOptionMemo, [])
  const option = useStore((state) => gItemMemo(state, id))
  const currentOption = useStore(currentOptionSelector)
  const setCurrentOption = useStore(setCurrentOptionAction)
  const selectedOptions = useStore(selectedOptionsSelector)
  const selectOption = useStore(selectOptionAction)
  // const setSelectedOptions = useStore(setSelectedOptionsAction)
  const insertOption = useStore(insertOptionAction)
  const removeOptions = useStore(removeOptionsAction)
  // const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)

  const handleClick = useCallback(() => {
    setCurrentOption(currentOption === id ? '' : id)
  }, [setCurrentOption, currentOption, id])

  const handleCheck = useCallback(() => {
    selectOption(id, selectedOptions.indexOf(id) < 0)
  }, [selectOption, id, selectedOptions])

  // const handleEdit = useCallback(() => {
  //   setSelectedOptions([id])
  //   setCurrentOption(id)
  //   setCurrentOptionPanel(LATERAL_PANELS.OPTION_EDITOR)
  // }, [id, setCurrentOption, setCurrentOptionPanel, setSelectedOptions])

  const handleAdd = useCallback(() => {
    insertOption(index + 1, id)
  }, [insertOption, id, index])

  const handleRemove = useCallback(() => {
    removeOptions([id])
  }, [removeOptions, id])

  return option ? (
    <DraggableOptionItem
      primaryText={option.name.es}
      secondaryText={id}
      key={id}
      id={id}
      index={index}
      checkbox
      checked={selectedOptions.indexOf(id) >= 0}
      onCheck={handleCheck}
      selected={currentOption === id}
      onClick={handleClick}
      error={false}
      hoverButtons={[
        <DraggableListItemButton
          key={1}
          tooltip="Eliminar"
          onClick={handleRemove}
        >
          <RemoveSelectedIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton key={2} tooltip="Añadir" onClick={handleAdd}>
          <AddDuplicateIcon />
        </DraggableListItemButton>
      ]}
    >
      {currentOption === id && <OptionEditor />}
    </DraggableOptionItem>
  ) : null
}
export default OptionItem
