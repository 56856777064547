import { memo } from 'react'
import MenuItem from '@mui/material/MenuItem'

import PropertyTreeSelectNode from '../PropertyTree/Nodes/PropertyTreeSelectNode'

import { ANCHORS } from '../../../modules/positioning2D'

const AnchorNode = ({ value, ...props }) => {
  return (
    <PropertyTreeSelectNode
      value={value}
      options={[
        ANCHORS.map((anchor) => {
          return (
            <MenuItem key={anchor.id} value={anchor.id}>
              {anchor.name}
            </MenuItem>
          )
        })
      ]}
      {...props}
    />
  )
}
export default memo(AnchorNode)
