import { useRef } from 'react'
import { PerspectiveCamera } from '@react-three/drei'

import useStore from '../../modules/store'
import { fovSelector } from '../../modules/cameraStore'

const Camera = () => {
  const fov = useStore(fovSelector)
  const cameraRef = useRef()

  return (
    <PerspectiveCamera
      ref={cameraRef}
      position={[0, 0, 0.001]}
      near={0.5}
      far={10000}
      fov={fov}
      makeDefault
    />
  )
}

export default Camera
