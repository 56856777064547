import { useMemo, useCallback, memo } from 'react'
// import { styled } from '@mui/material/styles'

import {
  EditIcon,
  AddDuplicateIcon,
  RemoveSelectedIcon,
  HotspotIcon
} from '../../Icons/Icons'
import PolygonIcon from '../../Icons/PolygonIcon'
import DraggableListItem from '../../Common/DraggableList/DraggableListItem'
import DraggableListItemButton from '../../Common/DraggableList/DraggableListItemButton'
// import OptionEditor from './OptionEditor'

import { useStore } from '../../../modules/store'
import { getHotspotMemo } from '../../../modules/sceneDescriptionStore'
import {
  setCurrentIdAction,
  hoverIdSelector,
  selectedSelector,
  selectAction,
  removeAction,
  duplicateAction,
  currentIdSelector
} from '../../../modules/sceneHotspotListStore'
import { setCurrentOptionPanelAction } from '../../../modules/lateralMenuStore'
import { LATERAL_PANELS } from '../../../settings/menuSettings'

const HotspotItem = ({ id, index, onHover }) => {
  // const dispatch = useDispatch()
  const gHotspotMemo = useMemo(getHotspotMemo, [])
  const hotspot = useStore((state) => gHotspotMemo(state, id))
  const currentId = useStore(currentIdSelector)
  const setCurrent = useStore(setCurrentIdAction)
  const selected = useStore(selectedSelector)
  const select = useStore(selectAction)
  // const setSelectedOptions = useStore(setSelectedOptionsAction)
  const duplicate = useStore(duplicateAction)
  const remove = useStore(removeAction)
  const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)
  const hoverHotspotId = useStore(hoverIdSelector)

  const handleClick = useCallback(() => {
    setCurrent(id)
  }, [setCurrent, id])

  const handleCheck = useCallback(() => {
    select(id, selected.indexOf(id) < 0)
  }, [select, id, selected])

  const handleAdd = useCallback(() => {
    duplicate(index + 1, id)
  }, [duplicate, id, index])

  const handleRemove = useCallback(() => {
    remove([id])
  }, [remove, id])

  const handleEdit = useCallback(() => {
    setCurrent(id)
    setCurrentOptionPanel(LATERAL_PANELS.HOTSPOT_EDITOR)
    onHover && onHover(id, '')
  }, [setCurrentOptionPanel, setCurrent, id, onHover])

  return hotspot ? (
    <DraggableListItem
      primaryText={hotspot.name.es}
      secondaryText={id}
      key={id}
      id={id}
      index={index}
      icon={
        hotspot.polygonData ? (
          <PolygonIcon style={{ paddingRight: 12 }} />
        ) : (
          <HotspotIcon style={{ paddingRight: 12 }} />
        )
      }
      checkbox
      checked={selected.indexOf(id) >= 0}
      onCheck={handleCheck}
      selected={currentId === id || hoverHotspotId === id}
      onClick={handleClick}
      error={false}
      hoverButtons={[
        <DraggableListItemButton key={0} tooltip="Editar" onClick={handleEdit}>
          <EditIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton
          key={1}
          tooltip="Eliminar"
          onClick={handleRemove}
        >
          <RemoveSelectedIcon />
        </DraggableListItemButton>,
        <DraggableListItemButton key={2} tooltip="Añadir" onClick={handleAdd}>
          <AddDuplicateIcon />
        </DraggableListItemButton>
      ]}
      onHover={onHover}
    />
  ) : null
}
export default memo(HotspotItem)

// {currentOption === id && <OptionEditor />}
