import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import ScrollToolbar from '../../Common/ScrollToolbar/ScrollToolbar'
import DraggableList from '../../Common/DraggableList/DraggableList'

import SelectAllButton from '../../Common/ToolbarButtons/SelectAllButton'
import UnselectAllButton from '../../Common/ToolbarButtons/UnselectAllButton'
import RemoveButton from '../../Common/ToolbarButtons/RemoveButton'
import AddPolygonButton from '../../Common/ToolbarButtons/AddPolygonButton'
import AddHotspotButton from '../../Common/ToolbarButtons/AddHotspotButton'
import EditableListEmptyItem from '../../Common/EditableListEmptyItem'
import HotspotItem from './HotspotItem'

import { useStore } from '../../../modules/store'
import {
  getCurrentCubeHotspots,
  currentCubeSelector
} from '../../../modules/sceneDrawDataStore'

import {
  selectedSelector,
  selectAllAction,
  setSelectedAction,
  removeAction,
  insertByTypeAction,
  hoverIdSelector,
  setHoverIdAction
} from '../../../modules/sceneHotspotListStore'
import { swapHotspotsByIdAction } from '../../../modules/sceneCubeStore'
import { HOTSPOTS_TYPES_IDS } from '../../../modules/hotspotTypesStore'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const SScrollToolbar = styled(ScrollToolbar)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderBottom: 'none' // `1px solid ${theme.palette.primary.contrastText}`
}))

const HotspotsList = () => {
  const { hotspots } = useStore(getCurrentCubeHotspots)
  const selected = useStore(selectedSelector)
  const swapById = useStore(swapHotspotsByIdAction)
  const selectAll = useStore(selectAllAction)
  const setSelected = useStore(setSelectedAction)
  const remove = useStore(removeAction)
  const insert = useStore(insertByTypeAction)
  const currentCubeId = useStore(currentCubeSelector)
  const hoverHotspotId = useStore(hoverIdSelector)
  const setHoverHotspotId = useStore(setHoverIdAction)

  const handleSwap = useCallback(
    (fromIdx, toIdx) => {
      swapById(currentCubeId, hotspots[fromIdx].id, hotspots[toIdx].id)
    },
    [swapById, hotspots, currentCubeId]
  )

  const handleSelectAll = useCallback(
    (e) => {
      selectAll()
    },
    [selectAll]
  )

  const handleUnselectAll = useCallback(
    (e) => {
      setSelected([])
    },
    [setSelected]
  )

  const handleRemoveSelected = useCallback(
    (e) => {
      remove(selected)
    },
    [remove, selected]
  )

  const handleAdd = useCallback(
    (e) => {
      insert(0, HOTSPOTS_TYPES_IDS.IMAGE)
    },
    [insert]
  )

  const handleAddPolygon = useCallback(
    (e) => {
      insert(0, HOTSPOTS_TYPES_IDS.POLYGON)
    },
    [insert]
  )

  const handleHover = useCallback(
    (id, hover) => {
      if (!hover && hoverHotspotId === id) {
        setHoverHotspotId('')
      } else if (hover) {
        setHoverHotspotId(id)
      }
    },
    [setHoverHotspotId, hoverHotspotId]
  )

  return (
    <Root>
      <SScrollToolbar
        mainDimension={32}
        firstItems={[
          <AddHotspotButton onClick={handleAdd} />,
          <AddPolygonButton onClick={handleAddPolygon} />,
          <RemoveButton
            disabled={hotspots.length === 0 || selected.length === 0}
            onClick={handleRemoveSelected}
          />
        ]}
        secondItems={[
          <SelectAllButton
            disabled={
              hotspots.length === 0 || selected.length === hotspots.length
            }
            onClick={handleSelectAll}
          />,
          <UnselectAllButton
            disabled={hotspots.length === 0 || selected.length === 0}
            onClick={handleUnselectAll}
          />
        ]}
      />
      {hotspots.length ? (
        <DraggableList onSwap={handleSwap}>
          {hotspots.map((option, index) => (
            <HotspotItem
              key={option.id}
              id={option.id}
              index={index}
              onHover={handleHover}
            />
          ))}
        </DraggableList>
      ) : (
        <EditableListEmptyItem
          text={'Añadir nuevo Hotspot'}
          onAdd={handleAdd}
        />
      )}
    </Root>
  )
}
export default HotspotsList
