import Polygon from '../../Hotspots/Polygon/Polygon'
import PolygonEditor from './PolygonEditor'
// import PolygonEditor from '../Three/PolygonEditor/PolygonEditor'

import useStore from '../../../modules/store'
import { getCurrentCubeHotspots } from '../../../modules/sceneDrawDataStore'
import { currentIdSelector as currentPolygonIdSelector } from '../../../modules/sceneHotspotListStore'

import { getCurrentPanel } from '../../../modules/lateralMenuStore'
import { LATERAL_PANELS } from '../../../settings/menuSettings'
const Polygons = () => {
  const { polygons } = useStore(getCurrentCubeHotspots)
  const currentPolygonId = useStore(currentPolygonIdSelector)
  const currentPanel = useStore(getCurrentPanel)
  const { HOTSPOT_EDITOR } = LATERAL_PANELS

  return polygons.map((polygon) =>
    currentPolygonId === polygon.id && currentPanel?.id === HOTSPOT_EDITOR ? (
      <PolygonEditor key={polygon.id} />
    ) : (
      <Polygon key={polygon.id} id={polygon.id} />
    )
  )
}

export default Polygons
