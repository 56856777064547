// import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'

import { CloseIcon } from '../../Icons/Icons'
import useStore from '../../../modules/store'
import {
  getOpenErrors,
  clearErrorsAction
} from '../../../modules/openSaveStore'
const Root = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '80%',
    maxHeight: '80%',
    backgroundColor: theme.palette.primary.light,
    border: '1px solid white',
    color: theme.palette.primary.contrastText
  }
}))

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderBottom: '1px solid white',
  fontSize: 20
}))

const OpenErrorsDialog = ({ ...props }) => {
  const { n, sceneErrors, mapperErrors } = useStore(getOpenErrors)
  const clearErrors = useStore(clearErrorsAction)
  // const [localValue, setLocalValue] = useState(value)
  //
  // useEffect(() => {
  //   if (!open) {
  //     setLocalValue(value)
  //   }
  // }, [value, open])
  const handleClose = () => {
    clearErrors()
  }
  console.log(sceneErrors)
  return n > 0 ? (
    <Root open={n > 0} onClose={handleClose} {...props}>
      <Title>
        Errores{' '}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            padding: 0,
            right: 4,
            top: 4,
            color: (theme) => theme.palette.primary.contrastText
          }}
        >
          <CloseIcon />
        </IconButton>
      </Title>
      <DialogContent>
        {sceneErrors.schemaErrors.map((error, idx) => (
          <div key={idx}>{error.stack}</div>
        ))}
        {mapperErrors.schemaErrors.map((error, idx) => (
          <div key={idx}>{error.stack}</div>
        ))}
      </DialogContent>
    </Root>
  ) : null
}

export default OpenErrorsDialog
