import { memo } from 'react'
import { styled } from '@mui/material/styles'
import PropertyTreeNode from '../Node/PropertyTreeNode'
import PropertyTreeDialog from '../Dialog/PropertyTreeDialog'

const Input = styled('div')(({ theme }) => ({
  userSelect: 'none',
  cursor: 'pointer',
  width: '100%'
}))

const PropertyTreeDialogNode = ({
  id,
  open,
  text,
  value,
  dialogTitle,
  dialogContent,
  dialogActions,
  onOpen,
  ...props
}) => {
  return (
    <>
      <PropertyTreeNode
        id={id}
        input={<Input onClick={onOpen}>{text}</Input>}
        {...props}
      />
      <PropertyTreeDialog
        open={open}
        title={dialogTitle}
        content={dialogContent}
        actions={dialogActions}
      />
    </>
  )
}
export default memo(PropertyTreeDialogNode)
