// import { useCallback } from 'react'
// import { useSelector } from 'react-redux'

import { SaveIcon } from '../../Icons/Icons'
import STButton from '../ScrollToolbar/STButton'

const SaveButton = (props) => {
  return (
    <STButton
      tooltip={'Guardar'}
      tooltipEnabled
      {...props}
      icon={<SaveIcon />}
    />
  )
}
export default SaveButton
