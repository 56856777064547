import { useCallback } from 'react'

import CubeSelectNode from '../../Common/Nodes/CubeSelectNode'

import { useStore } from '../../../modules/store'
import { defaultSelector } from '../../../modules/sceneDescriptionStore'
import { setDefaultCubeAction } from '../../../modules/sceneEditorStore'

const SECubeSelectNode = () => {
  const setDefaultCube = useStore(setDefaultCubeAction)
  const defaultValues = useStore(defaultSelector)

  const handleChange = useCallback(
    (nodeId, value) => {
      setDefaultCube(value)
    },
    [setDefaultCube]
  )

  return (
    <CubeSelectNode
      id={'cube'}
      label="Cubo inicial"
      value={defaultValues.cube}
      onChange={handleChange}
    />
  )
}
export default SECubeSelectNode
