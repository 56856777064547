import { useCallback } from 'react'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme, selected }) => ({
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  width: 100,
  height: 100,
  margin: 12,
  border: `4px solid ${
    selected ? theme.palette.secondary.main : 'transparent'
  }`,
  '&:hover': { border: `4px solid ${theme.palette.secondary.main}` }
}))
const Image = styled('img')(({ theme, selected }) => ({
  width: 92, //'100%',
  height: 92, //'auto',
  objectFit: 'contain'
}))
const Video = styled('video')(({ theme }) => ({
  width: 92, //'100%',
  height: 92, //'auto',
  objectFit: 'contain'
}))

const ImageItem = ({ imageMode, id, src, selected, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(id)
  }, [onClick, id])

  return (
    <Root selected={selected}>
      {imageMode ? (
        <Image src={src} alt={src} onClick={handleClick} />
      ) : (
        <Video src={src} onClick={handleClick} />
      )}
    </Root>
  )
}
export default ImageItem
