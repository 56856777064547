import { useCallback } from 'react'
import { styled, lighten } from '@mui/material/styles'

import ScrollToolbar from '../../../Common/ScrollToolbar/ScrollToolbar'
import DraggableList from '../../../Common/DraggableList/DraggableList'

import SelectAllButton from '../../../Common/ToolbarButtons/SelectAllButton'
import UnselectAllButton from '../../../Common/ToolbarButtons/UnselectAllButton'
import RemoveButton from '../../../Common/ToolbarButtons/RemoveButton'

import FinishItem from './FinishItem'

import { useStore } from '../../../../modules/store'
import { finishsSelector } from '../../../../modules/sceneDescriptionStore'
import {
  selectedSelector,
  swapByIdAction,
  selectAllAction,
  setSelectedAction,
  removeAction
} from '../../../../modules/sceneFinishsStore'
// import { setCurrentOptionPanelAction } from '../../../modules/lateralMenuStore'
// import { LATERAL_PANELS } from '../../../settings/menuSettings'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  padding: 4,
  height: '100%',
  width: '100%',
  minHeight: 0
}))

const SScrollToolbar = styled(ScrollToolbar)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.primary.contrastText}`,
  borderBottom: 'none' // `1px solid ${theme.palette.primary.contrastText}`
}))

const FinishsMenu = () => {
  const finishs = useStore(finishsSelector)
  const selectedFinishs = useStore(selectedSelector)
  const swapFinishsById = useStore(swapByIdAction)
  const selectAllFinishs = useStore(selectAllAction)
  const setSelectedFinishs = useStore(setSelectedAction)
  const removeFinishs = useStore(removeAction)
  // const setCurrentOptionPanel = useStore(setCurrentOptionPanelAction)

  const handleSwap = useCallback(
    (fromIdx, toIdx) => {
      swapFinishsById(finishs[fromIdx].id, finishs[toIdx].id)
    },
    [swapFinishsById, finishs]
  )

  const handleSelectAll = useCallback(
    (e) => {
      selectAllFinishs()
    },
    [selectAllFinishs]
  )

  const handleUnselectAll = useCallback(
    (e) => {
      setSelectedFinishs([])
    },
    [setSelectedFinishs]
  )

  const handleRemoveSelected = useCallback(
    (e) => {
      removeFinishs(selectedFinishs)
    },
    [removeFinishs, selectedFinishs]
  )

  // const handleEditSelected = useCallback(
  //   (e) => {
  //     setCurrentOptionPanel(LATERAL_PANELS.FINISH_EDITOR)
  //   },
  //   [setCurrentOptionPanel]
  // )

  return (
    <Root>
      <SScrollToolbar
        mainDimension={32}
        firstItems={[
          <RemoveButton
            disabled={finishs.length === 0 || selectedFinishs.length === 0}
            onClick={handleRemoveSelected}
          />
        ]}
        secondItems={[
          <SelectAllButton
            disabled={
              finishs.length === 0 || selectedFinishs.length === finishs.length
            }
            onClick={handleSelectAll}
          />,
          <UnselectAllButton
            disabled={finishs.length === 0 || selectedFinishs.length === 0}
            onClick={handleUnselectAll}
          />
        ]}
      />
      <DraggableList onSwap={handleSwap}>
        {finishs.map((finish, index) => (
          <FinishItem key={finish.id} id={finish.id} index={index} />
        ))}
      </DraggableList>
    </Root>
  )
}
export default FinishsMenu
