import { useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import Pair from './Pair'

import useStore from '../../../../modules/store'
import {
  getCombinationMemo,
  setCurrentCombinationAction
} from '../../../../modules/equirectangularImagesMapperStore'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasEmptyLinks'
})(({ theme, hasEmptyLinks }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${
    hasEmptyLinks
      ? theme.palette.error.main
      : theme.palette.primary.contrastText
  }`,
  padding: 8,
  margin: 8,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': { border: `2px solid ${theme.palette.secondary.main}` }
}))

const Item = ({ id }) => {
  const gCombinationMemo = useMemo(getCombinationMemo, [])
  const combination = useStore((state) => gCombinationMemo(state, id))
  const setCurrentCombination = useStore(setCurrentCombinationAction)

  const handleClick = useCallback(() => {
    setCurrentCombination(id)
  }, [setCurrentCombination, id])

  return combination ? (
    <Root hasEmptyLinks={combination.emptyLinks > 0} onClick={handleClick}>
      {combination.pairs.map((pair, idx) => (
        <Pair key={idx} optionId={pair.option.id} />
      ))}
    </Root>
  ) : null
}

export default Item
//
