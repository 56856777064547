import { useState, useCallback, memo } from 'react'

import PropertyTreeTwoStatesNode from '../../PropertyTree/Nodes/PropertyTreeTwoStatesNode'
import PropertyTreeColorNode from '../../PropertyTree/Nodes/PropertyTreeColorNode'
import PropertyTreeFloatNode from '../../PropertyTree/Nodes/PropertyTreeFloatNode'

const BorderNode = ({ id, value, onChange, ...props }) => {
  const [backup, setBackup] = useState(value.color)

  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === id) {
          if (!newValue) {
            setBackup(value.color)
          }
          onChange(id, { ...value, color: newValue ? backup : '' })
        } else if (nodeId === `${id}:color`) {
          setBackup(newValue)
          onChange(id, { ...value, color: newValue })
        } else if (nodeId === `${id}:width`) {
          onChange(id, { ...value, width: newValue })
        }
      }
    },
    [id, onChange, value, backup]
  )

  return (
    <PropertyTreeTwoStatesNode
      id={id}
      value={value.color}
      values={['No', 'Si']}
      onChange={handleChange}
      {...props}
    >
      {value.color && (
        <PropertyTreeColorNode
          id={'color'}
          label="Color"
          value={value.color}
          onChange={handleChange}
          disableAlpha
        />
      )}
      {value.color && (
        <PropertyTreeFloatNode
          id={'width'}
          label="Ancho"
          value={value.width}
          onChange={handleChange}
        />
      )}
    </PropertyTreeTwoStatesNode>
  )
}
export default memo(BorderNode)
