import { styled, lighten } from '@mui/material/styles'
import Button from '@mui/material/Button'

const SButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected }) => ({
  backgroundColor: selected
    ? theme.palette.secondary.main
    : lighten(theme.palette.primary.light, 0.1),
  textTransform: 'none',
  boxSizing: 'border-box',
  padding: 0,
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.1)
  }
}))

const MyButton = ({ selected, ...props }) => {
  return <SButton variant="outlined" selected={selected} {...props} />
}
export default MyButton
