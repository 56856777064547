import { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import { useTransition, animated, config } from 'react-spring'
import LPMTopBar from './LPMTopBar'
import LPMResizeBar from './LPMResizeBar'

import { useStore } from '../../../../modules/store'
import {
  getCurrentPanel,
  widthSelector
} from '../../../../modules/lateralMenuStore'

const PanelPosition = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'position' && prop !== 'width' && prop !== 'visible'
})(({ theme, width, position, visible }) => ({
  position: position,
  height: '100%',
  width
}))

const PanelWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'zIndex'
})(({ theme, zIndex }) => ({
  backgroundColor: 'red',
  top: 0,
  position: 'absolute',
  height: '100%',
  maxHeight: '100%',
  minHeight: '100%',

  display: 'flex',
  flexFlow: 'row',

  zIndex: zIndex - 1
}))

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width'
})(({ theme, width }) => ({
  backgroundColor: theme.palette.primary.light,
  boxSizing: 'border-box',
  padding: 0,
  height: '100%',
  width: width,
  display: 'flex',
  flexFlow: 'column',
  overflow: 'hidden'
}))

const LateralMenuPanel = ({ zIndex, barWidth, children, onClose }) => {
  const [animating, setAnimating] = useState(true)
  const currentPanel = useStore(getCurrentPanel)
  const width = useStore(widthSelector)
  const visible =
    (currentPanel !== null && currentPanel !== undefined) ||
    currentPanel?.maxWidth > 0

  const visibleAnimation = useTransition(visible, {
    from: { left: -width, opacity: 0 },
    enter: { left: barWidth, opacity: 1 },
    leave: { left: -width, opacity: 0 },

    onRest: (_, __, item) => {
      if (item) {
        setAnimating(false)
      }
    },
    config: config.default
  })

  useEffect(() => {
    setAnimating(true)
  }, [visible])

  const AnimatedPanelWrapper = animated(PanelWrapper)
  //4 es el ancho del resizeBar
  return (
    <PanelPosition
      width={visible ? width + 4 : 0}
      position={animating ? 'absolute' : 'relative'}
    >
      {visibleAnimation((style, item) =>
        item ? (
          <AnimatedPanelWrapper
            style={{ ...style, left: animating ? style.left : 0 }}
            zIndex={zIndex}
          >
            <ContentWrapper width={width}>
              <LPMTopBar onClose={onClose} />
              {children}
            </ContentWrapper>
            {visible && <LPMResizeBar zIndex={zIndex} />}
          </AnimatedPanelWrapper>
        ) : null
      )}
    </PanelPosition>
  )
}
export default LateralMenuPanel
