import { useRef, useCallback } from 'react'
import * as THREE from 'three'

const MAX_POINTS = 100
const positions = new Float32Array(MAX_POINTS * 3)
const uvs = new Float32Array(MAX_POINTS * 2)
const size = new THREE.Vector3()

const Geometry = ({
  contour, //[[x0,y0],[x1,y1],...]
  holes, //[[[x0,y0],[x1,y1],...],[[x,y],...]]
  material,
  ...props
}) => {
  const ref = useRef()
  const geomRef = useRef()

  const handleUpdate = useCallback(
    (self) => {
      if (self) {
        let length = 0
        //Contorno
        const contourPoints = []
        contour.forEach((point) => {
          contourPoints.push(new THREE.Vector2(point[0], point[1]))
          positions[length] = point[0]
          positions[length + 1] = point[1]
          positions[length + 2] = 0
          length += 3
        })
        //Huecos
        const holesArray = []
        holes.forEach((hole) => {
          if (hole.length > 2) {
            const points = []
            holesArray.push(points)
            hole.forEach((point, n) => {
              points.push(new THREE.Vector2(point[0], point[1]))
              positions[length] = point[0]
              positions[length + 1] = point[1]
              positions[length + 2] = 0
              length += 3
            })
          }
        })

        //Calcula tiangulos
        const faces = THREE.ShapeUtils.triangulateShape(
          contourPoints,
          holesArray
        )
        const facesArray = faces.reduce((acc, face) => {
          return [...acc, ...face]
        }, [])

        //Positions-normals
        self.setIndex(facesArray)
        self.setDrawRange(0, facesArray.length)
        self.attributes.position.needsUpdate = true
        self.computeVertexNormals()
        self.computeBoundingBox()
        self.computeBoundingSphere()
        //UV
        const attUv = self.getAttribute('uv')
        self.boundingBox.getSize(size)
        for (let i = 0; i < length / 3; i++) {
          attUv.setXY(
            i,
            (positions[i * 3] - self.boundingBox.min.x) / size.x,
            (positions[i * 3 + 1] - self.boundingBox.min.y) / size.y
          )
        }
        attUv.needsUpdate = true
      }
    },
    [holes, contour]
  )

  return contour?.length > 2 ? (
    <mesh {...props} ref={ref} renderOrder={0}>
      <bufferGeometry ref={geomRef} onUpdate={handleUpdate}>
        <bufferAttribute
          attachObject={['attributes', 'position']}
          args={[positions, 3]}
        />
        <bufferAttribute attachObject={['attributes', 'uv']} args={[uvs, 2]} />
      </bufferGeometry>
      {material}
    </mesh>
  ) : null
}

export default Geometry
