import { createSelector } from 'reselect'
import {
  findById,
  cloneObject,
  getDefaultObjectFromSchema
} from '../utils/utils'
import {
  getEquirectangularFolder,
  getEquirectangularImageById,
  getEquirectangularImageBySrc,
  getEquirectangularFolderByName
} from './projectFolderStore'
import {
  schemas,
  equirectangularImagesMapperSchema,
  autoSchema
} from '../schemas/equirectangularImagesMapperSchema'
import {
  idSelector,
  getCube,
  cubesSelector,
  optionsSelector,
  finishsSelector
} from './sceneDescriptionStore'
import { clearAction } from './texturesStore'

const Validator = require('jsonschema').Validator
const validator = new Validator()

export const equirectangularImagesMapperStore = (set, get) => ({
  imageLinks: {
    combinations: [
      /*
      {
        id: 'com0',
        pairs: [
          {
            finish: { id: 'a1', name: '' },
            option: { id: 'o1', name: '' }
          }
        ],
        auto:{folderId,prefix,suffix,numberLength}
        links: [{ cubeId: 'c0', folderId:'',imageId:'' }]
      }
*/
    ],
    currentCombination: '',
    reset: (scene, mapper) => {
      let combinations = []
      if (scene) {
        combinations = generateCombinations(
          scene.cubes,
          scene.finishs,
          scene.options
        )
        if (mapper) {
          combinations = combinations.map((comb) => {
            const mapperCombination = mapper.combinations.find((mapperC) =>
              equalsCombinations(comb, mapperC)
            )
            if (mapperCombination) {
              // console.log(comb, mapperCombination)
              return copyCombinationFromMapperFile(
                get(),
                mapperCombination,
                comb
              )
            }
            return comb
          })
        }
      }

      set((st) => ({
        imageLinks: {
          ...st.imageLinks,
          currentCombination: '',
          combinations: combinations
        }
      }))
    },
    recreate: () => {
      let newCombinations = generateCombinations(
        cubesSelector(get()),
        finishsSelector(get()),
        optionsSelector(get())
      )

      newCombinations = newCombinations.map((comb) => {
        const oldCombination = combinationsSelector(get()).find((mapperC) =>
          equalsCombinations(comb, mapperC)
        )
        if (oldCombination) {
          //Esta combinacion ya estaba
          return {
            ...comb,
            links: comb.links.map((link) => {
              //Intenta recuperar los cubos antiguos
              const oldLink = oldCombination.links.find(
                (oldLink) => link.cubeId === oldLink.cubeId
              )
              // console.log(oldLink ? `recupera` : `nuevo`)
              return oldLink ? oldLink : link
            })
          }
        }
        return comb
      })

      set((st) => ({
        imageLinks: {
          ...st.imageLinks,
          currentCombination: '',
          combinations: newCombinations
        }
      }))
    },
    setCurrentCombination: (id) => {
      set((st) => ({
        imageLinks: { ...st.imageLinks, currentCombination: id }
      }))
    },
    generateCombinations: (cubes, finishs, options) => {
      set((st) => ({
        imageLinks: {
          ...st.imageLinks,
          combinations: generateCombinations(cubes, finishs, options)
        }
      }))
    },
    setFolderId: (combId, cubeId, folderId) => {
      const folder = getEquirectangularFolder(get())(folderId)
      if (folder) {
        set((st) => ({
          imageLinks: {
            ...st.imageLinks,
            combinations: setLinkValue(
              st.imageLinks.combinations,
              combId,
              cubeId,
              'folderId',
              folderId
            )
          }
        }))
      }
    },
    setImageId: (combId, cubeId, imageId) => {
      const { image } = getEquirectangularImageById(get())(imageId)
      if (image) {
        clearAction(get())()
        set((st) => ({
          imageLinks: {
            ...st.imageLinks,
            combinations: setLinkValue(
              st.imageLinks.combinations,
              combId,
              cubeId,
              'imageId',
              imageId
            )
          }
        }))
      }
    },
    autoFill: (combId) => {
      clearAction(get())()
      const comb = getCombination(get())(combId)

      if (comb && comb.auto && (comb.auto.prefix || comb.auto.suffix)) {
        const newLinks = comb.links.map((link) => {
          const cube = getCube(get())(link.cubeId)
          const folder = getEquirectangularFolder(get())(comb.auto.folderId)

          if (cube && folder) {
            const src = `${folder.name}/${
              comb.auto.prefix
            }${cube.imageIdx.toString().padStart(comb.auto.numberLength, '0')}${
              comb.auto.suffix
            }`
            const image = getEquirectangularImageBySrc(get())(src)
            if (image && image.folderId === comb.auto.folderId) {
              //Hay imagen. Rellenar
              return { ...link, folderId: image.folderId, imageId: image.id }
            } else {
              //No hay imagen. Borrar
              return { ...link, folderId: '', imageId: '' }
            }
          }
          return link
        })

        set((st) => ({
          imageLinks: {
            ...st.imageLinks,
            combinations: st.imageLinks.combinations.map((comb) => {
              if (comb.id === combId) {
                return { ...comb, links: newLinks }
              }
              return comb
            })
          }
        }))
      }
    },
    updateFill: (combId, data) => {
      if (data) {
        set((st) => ({
          imageLinks: {
            ...st.imageLinks,
            combinations: st.imageLinks.combinations.map((comb) => {
              if (comb.id === combId) {
                return { ...comb, auto: { ...comb.data, ...data } }
              }
              return comb
            })
          }
        }))
      }
    }
  }
})
export default equirectangularImagesMapperStore

const setLinkValue = (combinations, combId, cubeId, property, value) => {
  return combinations.map((comb) => {
    if (comb.id === combId) {
      return {
        ...comb,
        links: comb.links.map((link) => {
          if (link.cubeId === cubeId) {
            const newLink = { ...link }
            newLink[property] = value
            return newLink
          }
          return link
        })
      }
    }
    return comb
  })
}

const copyCombinationFromMapperFile = (state, from, to) => {
  if (!from || !to) {
    return
  }
  const newTo = JSON.parse(JSON.stringify(to))
  if (from.auto) {
    const folder = getEquirectangularFolderByName(state)(from.auto.folder)
    newTo.auto = {
      folderId: folder ? folder.id : '',
      prefix: from.auto.prefix,
      suffix: from.auto.suffix,
      numberLength: from.auto.numberLength
    }
  } else {
    newTo.auto = getDefaultObjectFromSchema(autoSchema)
  }
  from.links.forEach((fromLink) => {
    newTo.links = newTo.links.map((toLink) => {
      if (toLink.cubeId === fromLink.cubeId) {
        const image = getEquirectangularImageBySrc(state)(fromLink.imageSrc)
        return {
          cubeId: fromLink.cubeId,
          folderId: image ? image.folderId : '',
          imageId: image ? image.id : ''
        }
      }
      return toLink
    })
  })
  return newTo
}

const equalsCombinations = (c0, c1) => {
  //Los cubos no se comprueban. Solo los acabados
  if (!c0 || !c1 || c0.pairs.length !== c1.pairs.length) {
    return false
  }
  //Pares acabado opcion
  for (let i = 0; i < c0.pairs.length; ++i) {
    if (
      c0.pairs[i].finish.id !== c1.pairs[i].finish.id ||
      c0.pairs[i].option.id !== c1.pairs[i].option.id
    ) {
      return false
    }
  }
  return true
}

const generateCombinations = (cubes, finishs, options) => {
  const finishCombinations = getFinishsCombinations(cubes, finishs)
  return finishCombinations.reduce((acc, comb) => {
    acc = [...acc, ...getFinishOptionCombinations(comb, finishs, options)]
    return acc
  }, [])
}

const getFinishOptionCombinations = (finishComb, finishs, options) => {
  const finishOptionCombinations = finishComb.finishs.reduce(
    (acc, finishId) => {
      const finish = finishs.find((f) => f.id === finishId)
      if (finish) {
        if (acc.length === 0) {
          //Inicio
          acc.push({ id: '', pairs: [] })
        }
        acc = appendFinishOptions(acc, finish, options)
      }
      return acc
    },
    []
  )
  return finishOptionCombinations.map((comb) => ({
    id: comb.id,
    auto: getDefaultObjectFromSchema(autoSchema),
    links: finishComb.cubes.map((cubeId) => ({
      cubeId: cubeId,
      imageSrc: '',
      folderId: '',
      imageId: ''
    })),
    pairs: comb.pairs
  }))
}

const appendFinishOptions = (combinations, finish, options) => {
  // console.log(options)
  const newCombinations = combinations.reduce((acc, comb) => {
    finish.options.forEach((optionId) => {
      const option = options.find((option) => option.id === optionId)
      if (option) {
        acc.push({
          id: `${comb.id}${comb.id ? '_' : ''}${finish.id}_${optionId}`,
          pairs: [
            ...comb.pairs,
            {
              finish: { id: finish.id, name: finish.name.es },
              option: { id: optionId, name: option.name.es }
            }
          ]
        })
      }
    })
    return acc
  }, [])
  return newCombinations
}

export const getFinishsCombinations = (cubes, finishs) => {
  return cubes.reduce((acc, cube) => {
    const combination = acc.find((combination) =>
      equalCombinations(combination.finishs, cube.finishs)
    )
    if (combination) {
      combination.cubes.push(cube.id)
    } else {
      acc.push({ finishs: cube.finishs, cubes: [cube.id] })
    }
    return acc
  }, [])
}

export const validateMapper = (mapper) => {
  const relationalErrors = []
  const errors = []
  schemas.forEach((schema) => validator.addSchema(schema, schema.uri))
  const { errors: schemaErrors } = validator.validate(
    mapper,
    equirectangularImagesMapperSchema
  )
  return {
    data:
      schemaErrors.length === 0 &&
      relationalErrors.length === 0 &&
      errors.length === 0
        ? cloneObject(mapper)
        : null,
    errors: { errors, schemaErrors, relationalErrors }
  }
}

export const resetAction = (state) => state.imageLinks.reset
export const recreateAction = (state) => state.imageLinks.recreate
export const setFolderIdAction = (state) => state.imageLinks.setFolderId
export const setImageIdAction = (state) => state.imageLinks.setImageId
export const generateCombinationsAction = (state) =>
  state.imageLinks.generateCombinations
export const setCurrentCombinationAction = (state) =>
  state.imageLinks.setCurrentCombination
export const updateFillAction = (state) => state.imageLinks.updateFill
export const autoFillAction = (state) => state.imageLinks.autoFill

export const combinationsSelector = (state) => state.imageLinks.combinations
export const currentCombinationSelector = (state) =>
  state.imageLinks.currentCombination

export const getClassifiedCombinations = createSelector(
  [combinationsSelector],
  (combinations) => {
    const groups = combinations.reduce((acc, comb) => {
      const combFinish = comb.pairs.map((pair) => pair.finish)
      const finishsString = combFinish.map((finish) => finish.id).join('')
      const group = acc.find((group) => group.id === finishsString)
      if (group) {
        group.items.push(comb)
      } else {
        acc.push({ id: finishsString, finishs: combFinish, items: [comb] })
      }
      return acc
    }, [])
    // return groups
    return groups.sort((g0, g1) => g0.finishs.length - g1.finishs.length)
  }
)

export const getOutput = (state) => {
  const combinations = combinationsSelector(state)
  return {
    scene: idSelector(state),
    combinations: combinations.map((c) => {
      const folder = getEquirectangularFolder(state)(c.auto.folderId)
      return {
        ...c,
        auto: {
          folder: folder ? folder.name : '',
          prefix: c.auto ? c.auto.prefix : '',
          suffix: c.auto ? c.auto.suffix : '',
          numberLength: Math.max(c.auto.numberLength, 1)
        },
        links: c.links.map((link) => {
          return {
            cubeId: link.cubeId,
            imageSrc: getEquirectangularImageById(state)(link.imageId).relative
          }
        })
      }
    })
  }
}

const equalCombinations = (c0, c1) => {
  if (c0.sort().join(',') === c1.sort().join(',')) {
    return true
  } else {
    return false
  }
}

export const getCombination = (state) => (id) => {
  return findById(combinationsSelector(state), id)
}

export const getCombinationMemo = () =>
  createSelector([combinationsSelector, (_, id) => id], (combinations, id) => {
    const comb = findById(combinations, id)
    if (comb) {
      const emptyLinks = comb.links.reduce((acc, link) => {
        if (!link.folderId || !link.imageId) {
          acc++
        }
        return acc
      }, 0)
      return { ...comb, emptyLinks }
    }
    return null
  })

export const getCombinationEmptyLinks = createSelector(
  [combinationsSelector, (_, id) => id],
  (combinations, id) => {
    return findById(combinations, id)
  }
)

export const getEquirectangularImageId = createSelector(
  [combinationsSelector],
  (combinations) => (cubeId, combinationId) => {
    const combination = findById(combinations, combinationId)
    if (combination) {
      const link = combination.links.find((link) => link.cubeId === cubeId)
      return link ? link.imageId : null
    }
  }
)

// export const getFinishsCombinations = createSelector(
//   [cubesSelector, finishsSelector],
//   (cubes, finishs) => {
//     cubes.reduce((acc, cube) => {
//       const combination = acc.find((combination) =>
//         equalCombinations(combination.finishs, cube.finishs)
//       )
//       if (combination) {
//         combination.cubes.push(cube.id)
//       } else {
//         acc.push({ finishs: cube.finishs, cubes: [cube.id] })
//       }
//       return acc
//     }, [])
//   }
// )
