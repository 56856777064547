import { useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import MyButton from '../../../Common/MyButton'
import Filler from './Filler'
import CubeItem from './CubeItem'
import useStore from '../../../../modules/store'
import {
  getCombinationMemo,
  currentCombinationSelector,
  setCurrentCombinationAction
} from '../../../../modules/equirectangularImagesMapperStore'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  height: '100%',
  width: '100%',

  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'star',
  alignItems: 'center'
}))

const Title = styled('div')(({ theme }) => ({
  fontSize: 24,
  boxSizing: 'border-box',
  padding: 8,
  width: '100%',
  textAlign: 'center'
}))

const Header = styled('div')(({ theme }) => ({
  fontSize: 24,
  width: '100%',
  textAlign: 'center'
}))
const CubeWrapper = styled('div')(({ theme }) => ({
  overflow: 'auto',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'star',
  alignItems: 'center'
}))

const Footer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 8,
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'end',
  alignItems: 'center'
}))

const Editor = () => {
  const currentCombination = useStore(currentCombinationSelector)
  const setCurrentCombination = useStore(setCurrentCombinationAction)
  const gCombinationMemo = useMemo(getCombinationMemo, [])
  const combination = useStore((state) =>
    gCombinationMemo(state, currentCombination)
  )

  const getName = () => {
    return combination.pairs
      .map((pair) => {
        return `(${pair.finish.name}, ${pair.option.name})`
      })
      .join(' - ')
  }

  const handleBack = useCallback(() => {
    setCurrentCombination('')
  }, [setCurrentCombination])

  return combination ? (
    <Root>
      <Header>
        <Title>{getName()}</Title>
        <Filler combId={currentCombination} />
      </Header>
      <CubeWrapper style={{ width: '100%', height: '100%' }}>
        {combination.links.map((link) => (
          <CubeItem
            key={link.cubeId}
            combId={currentCombination}
            cubeId={link.cubeId}
            folderId={link.folderId}
            imageId={link.imageId}
            imageSrc={link.imageSrc}
          />
        ))}
      </CubeWrapper>
      <Footer>
        <MyButton style={{ width: 120, height: 40 }} onClick={handleBack}>
          Volver
        </MyButton>
      </Footer>
    </Root>
  ) : null
}
export default Editor
//
