import { useCallback, memo } from 'react'

import ReadButton from './ReadButton'
import PropertyTreeNode from '../../PropertyTree/Node/PropertyTreeNode'
import PropertyTreeFloatNode from '../../PropertyTree/Nodes/PropertyTreeFloatNode'

import { rad2deg, deg2rad } from '../../../../utils/utils'

const PointOfViewNode = ({
  id,
  value, // { fov, azimuth, polar} En radianes
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (nodeId, newValue) => {
      if (onChange) {
        if (nodeId === `${id}:fov`) {
          onChange(id, { ...value, fov: newValue })
        } else if (nodeId === `${id}:polar`) {
          onChange(id, { ...value, polar: deg2rad(newValue) })
        } else if (nodeId === `${id}:azimuth`) {
          onChange(id, { ...value, azimuth: deg2rad(newValue) })
        }
      }
    },
    [id, onChange, value]
  )

  return (
    <PropertyTreeNode
      id={id}
      input={
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >{`fov:${Intl.NumberFormat().format(
            value.fov
          )}  polar:${Intl.NumberFormat().format(
            rad2deg(value.polar)
          )}°  acimut:${Intl.NumberFormat().format(
            rad2deg(value.azimuth)
          )}°`}</div>
          <ReadButton id={id} value={value} onChange={onChange} />
        </div>
      }
      {...props}
    >
      <PropertyTreeFloatNode
        id={'fov'}
        label="FOV"
        value={value.fov}
        onChange={handleChange}
        min={10}
        max={100}
      />
      <PropertyTreeFloatNode
        id={'polar'}
        label="Polar"
        value={rad2deg(value.polar)}
        onChange={handleChange}
        min={0}
        max={180}
      />
      <PropertyTreeFloatNode
        id={'azimuth'}
        label="Acimut"
        value={rad2deg(value.azimuth)}
        onChange={handleChange}
        min={-180}
        max={180}
      />
    </PropertyTreeNode>
  )
}
export default memo(PointOfViewNode)
