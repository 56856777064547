import { useRef, useMemo, useState, useEffect } from 'react'
import { useGesture } from 'react-use-gesture'
import { Vector3 } from 'three'
import { useThree } from '@react-three/fiber'
import { useStore } from '../../../modules/store'
import {
  getPointMemo,
  setPointPosition2DAction,
  removePointsAction,
  setCurrentPointAction,
  currentPointSelector,
  modeSelector,
  EDITOR_MODE_IDS
} from '../../../modules/polygonEditorStore'

const vector3 = new Vector3()

const Point = ({ id }) => {
  const ref = useRef()
  const geomRef = useRef()
  const cameraControls = useThree((state) => state.controls)
  const camera = useThree((state) => state.camera)
  const [hover, setHover] = useState(false)
  const [doubleTimeStamp, setDoubleTimeStamp] = useState(0)
  const gPointMemo = useMemo(getPointMemo, [])
  const point = useStore((state) => gPointMemo(state, id))
  const setPointPosition2D = useStore(setPointPosition2DAction)
  const removePoints = useStore(removePointsAction)
  const setCurrentPoint = useStore(setCurrentPointAction)
  const currentPoint = useStore(currentPointSelector)
  const mode = useStore(modeSelector)
  const { REMOVE_POINT } = EDITOR_MODE_IDS

  useEffect(() => {
    if (ref.current) {
      ref.current.getWorldPosition(vector3)
      const scale = vector3.subVectors(vector3, camera.position).length() / 2
      ref.current.scale.set(scale, scale, scale)
    }
  }, [point, camera])

  const bind = useGesture(
    {
      onDragStart: ({ event }) => {
        cameraControls.enabled = false
      },
      onDrag: ({ xy, tap, dragging, event }) => {
        event.stopPropagation()
        if (!tap) {
          setPointPosition2D(
            id,
            xy[0],
            xy[1],
            ref.current.parent.parent,
            camera
          )
        } else {
          setCurrentPoint(id)
        }
      },
      onDragEnd: ({ tap, timeStamp, event }) => {
        cameraControls.enabled = true
        if (tap) {
          setDoubleTimeStamp(timeStamp)
          if (timeStamp - doubleTimeStamp < 500) {
            removePoints([id])
          }
          if (mode === REMOVE_POINT) {
            //Tiene que ser aqui. En onDrag falla
            removePoints([id])
          }
        }
      }
    },
    { filterTaps: true }
  )

  return point ? (
    <mesh
      {...bind()}
      ref={ref}
      position={[point.position[0], point.position[1], 0]}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
    >
      <sphereGeometry ref={geomRef} args={[0.02]} />
      <meshBasicMaterial
        transparent={true}
        opacity={0.6}
        color={hover ? 'blue' : currentPoint === id ? 'red' : 'green'}
      />
    </mesh>
  ) : null
}

export default Point
