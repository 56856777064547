// import { useCallback } from 'react'

import PropertyTreeNode from '../Node/PropertyTreeNode'
import BaseTextField from '../../Inputs/BaseTextField'

const PropertyTreeTextNode = ({ tooltipDisabled, readOnly, ...props }) => {
  return (
    <PropertyTreeNode
      tooltipDisabled={tooltipDisabled}
      {...props}
      input={readOnly ? <div>{props.value}</div> : <BaseTextField {...props} />}
    />
  )
}
export default PropertyTreeTextNode
